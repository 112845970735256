import React, { useState, useEffect } from 'react';


import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import SimpleBar from "simplebar-react";
import { arrayNotNull } from "../../../utilities/utilities";
import AddComment from './AddCommentPending'
import { MentionText } from './CommentHistory';
import { Button } from '@shadcn/components/ui/button';


const CommentHostory = ({initCommentType, initOrderName, title, comments, setComments}) => {
    const [orderName, setOrderName] = useState(initOrderName ? initOrderName.replace('#', '') : '');

    useEffect(() => {
        setOrderName(initOrderName ? initOrderName.replace('#', '') : '')
    }, [initOrderName])

    const [refr, setRefresh] = useState(false);
    const refresh = () => setRefresh(prev => !prev)

    const deleteComment = (id) => {
        setComments(prev => prev.filter((comment, key) => key !== id))
    }
    const addComment = (comment) => {
        setComments(prev => [...prev, comment])
    }

    const Body = () => {
        return (
            <div className="mt-4">
                <h4 className="card-title mb-4">{title ? title : 'Notifications'}</h4>
                <SimpleBar style={{ maxHeight: "700px" }}>
                    <ul className="list-group">
                        {(arrayNotNull(comments) && comments.map((comment, key) => {
                            
                            let isMe = true
                            return (
                                <li key={`_comment_${key}`} className="list-group-item">
                                    <div className='flex flex-col md:flex-row md:items-start md:space-x-4'>
                                        <div className="flex-1 min-w-0 break-words">
                                            <h5 className="font-size-14"></h5>
                                            {
                                                (arrayNotNull(comment.files) &&  (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(comment.files[0]) === false) ? (
                                                    <p className="text-slateGray break-all">
                                                        <a target="blank" href={comment.files[0]} className="hover:underline"> 
                                                            {comment.description || 'Attached file'} 
                                                        </a> 
                                                    </p>
                                                ) : (
                                                    <div className="break-words">
                                                        <MentionText text={comment.description} />
                                                    </div>
                                                )
                                            }
                                            
                                            {arrayNotNull(comment.files) ?
                                                <div className="mt-2">
                                                    {
                                                        comment.files.map((img, index) => {
                                                            return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(img) ? 
                                                                <img src={img} alt="" className="w-full max-w-full" key={index} /> : 
                                                                null
                                                        })
                                                    }
                                                </div> : null}
                                        </div>
                                        {
                                            isMe && (
                                                <div className="shrink-0 mt-2 md:mt-0">
                                                    <button
                                                        onClick={() => deleteComment(key)}
                                                        className="ml-0 md:ml-3 text-red-500"
                                                    >
                                                        &#x2715;
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </li>
                            )
                        }))
                        }
                    </ul>
                </SimpleBar>
                <br/>
                 <AddComment orderName={orderName} addComment={addComment} refresh={refresh}/>
            </div>
        )
    }
    if (initCommentType === 'Dashboard') {
        return <Body />
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Body />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default CommentHostory;
