import React, { useMemo, useState } from 'react'

import "../datatables.scss";
import { CSVLink } from 'react-csv';

import {
  Card,
  CardContent as CardBody
  } from '@shadcn/components/ui/card'

import Select from '@shadcn/components/ui/react-select';
import useQuery from '../../../helpers/JIFI/useQuery';
import { Link } from "react-router-dom";
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import { Col } from '@shadcn/components/ui/col';
import { Row } from '@shadcn/components/ui/row';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper
} from '@tanstack/react-table';

import {
  Table as TableShadcn,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
} from '@shadcn/components/ui/table';
import { Badge } from '@shadcn/components/ui/badge';
import { TablePagination } from '@components/supply-chain/TablePagination';


const statusOptions = [
  { value: '', label: 'All' },
  { value: 'draft', label: 'Draft' },
  { value: 'in-transit', label: 'In Transit' },
  { value: 'at-port', label: 'At Port' },
  { value: 'ready', label: 'Ready' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

const ASNSentOptions = [
  { value: '', label: 'All' },
  { value: 'sent', label: 'Sent' },
  { value: 'not-sent', label: 'Not Sent' },
];

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}


const columnHelper = createColumnHelper();

// const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
//   const pageCount = Math.ceil(count / perPage)
//   const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
//   const handleNumberClicked = (key) => {
//     setPage(key)
//   }
//   return (
//     <Pagination className="pagination pagination-rounded justify-content-end mb-2">
//       <div style={{ height: '100%', width: 140 }}>
//         <Select
//           styles={ {
//             control: (provided) => ({
//               ...provided,
//               border: "1px solid black",
//               boxShadow: "none",
//               '&:hover': {
//                 border: "1px solid black",
//               },
//             }),
//             indicatorSeparator: (provided) => ({
//               ...provided,
//               backgroundColor: "black",
//             }),
//             dropdownIndicator: (provided) => ({
//               ...provided,
//               color: "black",
//               '&:hover': {
//                 color: "black",
//               },
//             }),
//           }}

//           options={perPageOptions}
//           onChange={newPerPage => setPerPage(newPerPage.value)}
//           value={perPageOptions.find(val => val.value === perPage)}
//         />
//       </div>
//       <PaginationItem disabled={page === 1}>
//         <PaginationLink previous onClick={() => setPage(page - 1)} />
//       </PaginationItem>
//       {
//         pages.map(val => (
//           <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
//             <PaginationLink>
//               {val}
//             </PaginationLink>
//           </PaginationItem>
//         ))
//       }
//       <PaginationItem>
//         <PaginationLink next onClick={() => setPage(page + 1)} />
//       </PaginationItem>
//     </Pagination>
//   )
// }

const StatusToBadge = ({ status }) => {
  const conversion = {
    'draft': 'secondary',
    'packing': 'secondary',
    'in-transit': 'warning',
    'at-port': 'warning',
    'ready': 'success',
    'completed': 'success',
    'cancelled': 'succedangerss',
  }
  return <Badge className={"font-size-12 badge-soft-" + conversion[status]} color={conversion[status]} pill>
    <i className={"fab " + " font-size-12 badge-soft-primary"}></i> {status?.replace('-', ' ')?.toUpperCase()}
  </Badge>
}


const PullPage = () => {
  const [refresh, setRefresh] = useState(true)

  /*
    Pagination
  */
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');
  const [ASNSent, setASNSent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const  dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
  const [listContainers, listContainersState] = useQuery({
    url: `/inventory/fetchContainer`,
    refresh,
    queryData: {
      status,
      page,
      location,
      perPage,
      searchTerm,
      ASNSent,
    },
  });

  const [locations, stateLocations] = useQuery({
    url: `/inventory/locationsOptions`,
  });


  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Supplier Ref',
        cell: ({ row }) => (
          <Link to={`/container/${row.original._id}`} className="text-body font-weight-bold">
            {row.original.name}
          </Link>
        ),
      }),


      columnHelper.accessor('ref', {
        header: () => 'Container Ref',
        cell: ({ row: { original } }) => {
          return <div className="text-[13px] min-w-max !px-2">
            <Link  className="text-body font-weight-bold">{original.ref}</Link>
        </div>;
        },
      }),

      columnHelper.accessor('arrivalDate', {
        header: () => (<div className='min-w-max'>Arrival Date</div>),
        cell: ({ row: { original } }) => (new Date(original.arrivalDate)).toDateString() ?? ''

      }),



      columnHelper.accessor('portDate', {
        header: () => 'Port Date',
        cell: ({ row: { original } }) => (new Date(original.portDate)).toDateString() ?? ""
      }),


      columnHelper.accessor('status', {
        header: () => 'Container Status',
        cell: ({ row: { original } }) => <StatusToBadge status={original.status} />
      }),

      columnHelper.accessor('landingLocation', {
        header: () => 'Landing Location',
        cell: ({ row: { original } }) => original?.['landingLocation']?.name ?? ""
      }),

      columnHelper.accessor('freightCost', {
        header: () => 'Freight Cost',
        cell: ({ row: { original } }) => original?.['freightCost'] ?? ""
      }),

      columnHelper.accessor('localCost', {
        header: () => 'Local Cost',
        cell: ({ row: { original } }) => original['localCost'] ?? ""
      }),

      columnHelper.accessor('ASNSent', {
        header: () => 'ASN Sent',
        cell: ({ row: { original } }) => original['ASNSent'] ? 
          <Badge className="font-size-12 badge-soft-success" color="success" pill>
            Yes
          </Badge> :
          <Badge className="font-size-12 badge-soft-danger" color="danger" pill>
            No
          </Badge>
      })
    ],
    []
  );


  const replenishHistoryData = listContainers?.replenishObject
  const totalCount = listContainers?.totalCount
  let locationsOptions = useMemo(() => 
    locations?.map(val => ({ value: val.code, label: val.name })) ?? []
    , [locations]) 

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  const csvData = [
    [
      'Supplier Ref',
      'Container Ref',
      'Arrival Date',
      'Port Date',
      'Container Status',
      'Landing Location',
      'Freight Cost',
      'Local Cost'
    ],
    ...(replenishHistoryData?.length > 0 ? replenishHistoryData.map(line => [

      line['name'],
      line['ref'],
      (new Date(line.arrivalDate)).toDateString(),
      (new Date(line.portDate)).toDateString(),
      line['status'],
      line['landingLocation']?.name,
      line['freightCost'],
      line['localCost']

    ]
    ): [])
  ]
  


  const table = useReactTable({
    data: replenishHistoryData ?? [],
    columns,


    getCoreRowModel: getCoreRowModel(),

  });

  return <SupplyChainNav>
    <div>
      <Col className="w-full">
        <Card className="mb-[60px]">
          <CardBody className="pt-4">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.25rem' }}>
              <Link to='create-container'> <Button variant="success"> Create </Button> </Link>
              <CSVLink
                style={{ float: 'right' }}
                data={csvData}
                filename={`CONTAINERS-${dateString}.csv`}
              >
                <Button type="button" variant="secondary" className="btn-md btn-rounded">
                  CSV
                </Button>
              </CSVLink>
            </div>

            <Row>
                  <Col>
                    <label className="control-label">FILTER</label>
                    <Row className="gap-4 mb-4">
                      <Col className="sm:w-3/12">

                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onKeyDown={handleKeyDown}
                            />

                          </div>
                        </div>

                      </Col>
                      <Col className="sm:w-3/12">
                        <Select
                          options={statusOptions}
                          onChange={(val) => {
                            setPage(1);

                            setStatus(val.value);
                          }}
                          styles= {
                            {
                              control: (provided) => ({
                                ...provided,
                                height: "36px",
                                minHeight: "36px",
                                border: "1px solid black",
                                boxShadow: "none",
                                '&:hover': {
                                  border: "1px solid black",
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                backgroundColor: "black",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: "black",
                                '&:hover': {
                                  color: "black",
                                },
                              }),
                            }
                          }
                          value={statusOptions.find(
                            (val) => val.value === status
                          )}
                        ></Select>

                       
                    </Col>
                    <Col className="sm:w-3/12">
                    <Select
                          onChange={(val) => {
                            setPage(1);
                            setLocation(val.value);
                          }}
                          styles= {
                            {
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid black",
                                boxShadow: "none",
                                height: "36px",
                                minHeight: "36px",
                                '&:hover': {
                                  border: "1px solid black",
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                backgroundColor: "black",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: "black",
                                '&:hover': {
                                  color: "black",
                                },
                              }),
                            }
                          }
                          options={locationsOptions}></Select>
                          </Col>

                      <Col className="sm:w-3/12">
                        <Select
                            onChange={(val) => {
                              setPage(1);
                              setASNSent(val.value);
                            }}
                            value={ASNSentOptions.find(
                                (val) => val.value === ASNSent
                            )}
                            styles= {
                              {
                                control: (provided) => ({
                                  ...provided,
                                  border: "1px solid black",
                                  boxShadow: "none",
                                  height: "36px",
                                  minHeight: "36px",
                                  '&:hover': {
                                    border: "1px solid black",
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  backgroundColor: "black",
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  color: "black",
                                  '&:hover': {
                                    color: "black",
                                  },
                                }),
                              }
                            }
                            options={ASNSentOptions}
                        ></Select>
                      </Col>
                    </Row>

                  </Col>
                </Row>
            

       
             
                <div className="table-responsive">
                  <TableShadcn className="table table-centered table-nowrap">
                  <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                              return (
                                <TableHead
                                  key={header.id}
                                  className="text-left text-black"
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </TableHead>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableHeader>
                      {listContainersState === 'success' ?
                      <TableBody>
                        {table?.getRowModel().rows?.length > 0 ? (
                          table?.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && 'selected'}
                            >
                              {row?.getVisibleCells()?.map((cell) => (
                                <TableCell key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} className="text-center">
                              No results.
                            </TableCell>
                          </TableRow>
                        )}
                        </TableBody>
                      : <TableSkeleton columns={columns.length} perPage={perPage} />
                      }
                  </TableShadcn>
                </div>
             

            <TablePagination count={totalCount} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
          </CardBody>
        </Card>
      </Col>
    </div>
  </SupplyChainNav>
}

export default PullPage
