// src/pages/JIFI/locations/components/steps/AddressStep.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@shadcn/components/ui/card';
import { Input } from '@shadcn/components/ui/input';
import { Label } from '@shadcn/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';
import { Country, State } from 'country-state-city';
import AsyncSelect from 'react-select/async';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const AddressStep = ({ address, setAddress }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey,
    options: {
      fields: ['address_component', 'types', 'geocode'],
      types: ['geocode'],
    },
  });

  useEffect(() => {
  const loaded = new CustomEvent('loadedPlaced', {
    detail: placePredictions.map((placeLine) => ({
      label: placeLine.description,
      value: placeLine.place_id,
    })),
  });
  document.dispatchEvent(loaded);
  }, [placePredictions]);

  const loadAddressOptions = (inputValue, callback) => {
    getPlacePredictions({ input: inputValue });
    document.addEventListener('loadedPlaced', function (e) {
      callback(e.detail);
    });
  };

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(
        document.getElementById('test-map'),
        { zoom: 15 }
      );
      var request = {
        placeId: placeID,
        fields: ['address_components'],
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej(place);
        }
      }
    });
  };

  const handleAddressSelect = async (selected) => {
    console.log("selected 123", selected);
    
    if (!selected) return;

    const placeData = await getPlaceData(selected.value);
    
    let items = placeData.address_components;
    let streetNumberGoogleAPI,
      streetAddressGoogleAPI, 
      countryGoogleAPI,
      stateGoogleAPI,
      unitNoGoogleAPI,
      suburnGoogleAPI,
      postcodeGoogleAPI;

    items.map((item) => {
      if (item.types.includes('subpremise')) {
        unitNoGoogleAPI = item.long_name;
      }
      if (item.types.includes('street_number')) {
        streetNumberGoogleAPI = item.long_name; 
      }
      if (item.types.includes('route')) {
        streetAddressGoogleAPI = item.long_name;
      }
      if (item.types.includes('postal_code')) {
        postcodeGoogleAPI = item.long_name;
      }
      if (item.types.includes('locality')) {
        suburnGoogleAPI = item.long_name;
      }
      if (item.types.includes('administrative_area_level_1')) {
        stateGoogleAPI = item.long_name;
      }
      if (item.types.includes('country')) {
        countryGoogleAPI = item.long_name;
      }
    });

    const newAddress = {...address};

    newAddress.country = countryGoogleAPI;
    newAddress.province = stateGoogleAPI;
    
    newAddress.city = suburnGoogleAPI === undefined ? '' : suburnGoogleAPI;
    newAddress.zip = postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI;
    newAddress.address2 = '';

    if (streetAddressGoogleAPI) {
      if (streetNumberGoogleAPI) {
        if (unitNoGoogleAPI) {
          newAddress.address1 = unitNoGoogleAPI + '/' + streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI;
        } else {
          newAddress.address1 = streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI;
        }
      } else {
        newAddress.address1 = streetAddressGoogleAPI;
      }
    } else {
      newAddress.address1 = '';
    }
    newAddress.name = address.name ?? '';
    newAddress.phone = address.phone ?? '';
    console.log("newAddress", newAddress);

    setAddress(newAddress);

    
  };

  return (
    <Card>
    <CardContent className="space-y-4 p-6">
      <div className="space-y-2">
        <div style={{ display: 'none' }}>
          <div id="test-map" />
        </div>
        <Label>Search Address</Label>
        <AsyncSelect
          cacheOptions
          loadOptions={loadAddressOptions}
          onClick={handleAddressSelect}
          onChange={handleAddressSelect}
          defaultOptions
        />
      </div>

      <div className="space-y-2">
        <Label>Contact Name</Label>
        <Input
          value={address.name}
          onChange={(e) => setAddress({ ...address, name: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label>Phone</Label>
        <Input
          value={address.phone}
          onChange={(e) => setAddress({ ...address, phone: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label>Address Line 1</Label>
        <Input
          value={address.address1}
          onChange={(e) => setAddress({ ...address, address1: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label>Address Line 2 (Optional)</Label>
        <Input
          value={address.address2}
          onChange={(e) => setAddress({ ...address, address2: e.target.value })}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>Country</Label>
          <Select
            value={address.country}
            onValueChange={(value) =>
              setAddress({ ...address, country: value })
            }
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="z-[1600]">
              {Country.getAllCountries().map((country) => (
                <SelectItem key={country.isoCode} value={country.name}>
                  {country.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label>State/Province</Label>
          <Select
            value={address.province}
            onValueChange={(value) =>
              setAddress({ ...address, province: value })
            }
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="z-[1600]">
              {State.getStatesOfCountry(
                Country.getAllCountries().find(
                  (c) => c.name === address.country
                )?.isoCode
              ).map((state) => (
                <SelectItem key={state.isoCode} value={state.name}>
                  {state.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>City</Label>
          <Input
            value={address.city}
            onChange={(e) => setAddress({ ...address, city: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label>Postal Code</Label>
          <Input
            value={address.zip}
            onChange={(e) => setAddress({ ...address, zip: e.target.value })}
          />
        </div>
      </div>
    </CardContent>
  </Card>
  );
};

export default AddressStep;
