import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { BoardColumn, BoardContainer } from './BoardColumn';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { TaskCard } from './TaskCard'; 
import { hasDraggableData } from './utils'; 
import superagent from 'superagent'
import { coordinateGetter } from './multipleContainersKeyboardPreset';
import { backendUrl } from '@helpers/consts';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useQuery from '@helpers/JIFI/useQuery';

import { useWebSocketToken } from '@helpers/JIFI/useWebSocketToken';
import { useQueryParams } from '@helpers/JIFI/useQueryParams';


const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 7) + (Math.floor(Math.random()*10000) + 10000).toString().substring(1)
};

export function WarrantyKanbanBoard() { 

  const queryParams = useQueryParams();
  const [refreshTicket, setRefreshTicket] = useState(false);
  const [refreshColumns, setRefreshColumns] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [processedMessages, setProcessedMessages] = useState(new Set());
  const id = queryParams.get('id');
  const randomId = useRef(generateRandomId());
  const [statuses, state] = useQuery({
    url: '/warranty-tickets/getBoardStatuses',
    refresh: refreshColumns
  });

  
  const [data, tasksState] = useQuery({
    url: '/warranty-tickets',
    refresh: refreshTicket
  });


  

  const [columns, setColumns] = useState([]);
  const token = useWebSocketToken()
  console.log('test token', token)
  const {  lastMessage } = useWebSocket(`wss://f2ukczpz42.execute-api.ap-southeast-2.amazonaws.com/dev?token=${token}`,

    { shouldReconnect: () => false,
      retryOnError: false,
      share: true, 
      }
  )

  
  const pickedUpTaskColumn = useRef(null);
  const [columnsId, setColumnsId] = useState([]) ;
  const [tasks, setTasks] = useState([]);
  
  const [activeColumn, setActiveColumn] = useState(null);
  const [activeTask, setActiveTask] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: coordinateGetter })
  );
  useEffect(() => {
    if (state === 'loading' || tasksState === 'loading') {
      
    } else {
      setInitialLoad(false); // Disable initial load after first successful fetch
    }
  }, [state, tasksState, initialLoad]);

  const memoizedTasks = useMemo(() => (
    data?.map((task) => ({
      id: task._id,
      selected: id === task._id,
      ...task
    })) || []
  ), [data, id]);

  const renameColumn =async (id, newTitle, reorder) => {
    let newColumn 
    setColumns((prevColumns) => {
      newColumn = prevColumns.map((col) => {
        if (col.id === id) {
          return { ...col, title: newTitle, rename: false }; 
        }
        return col;
      });
      return newColumn
    });

    if(reorder) {
    sendColumnOrder(newColumn)
    } else {
    await superagent
        .post(`${backendUrl}/warranty-tickets/renameBoardStatus` )
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .send({
          name: newTitle,
          id: id,
          messageId: randomId.current
        });  
      
    }

  };

  const sendColumnOrder = async (cols) => {
    let changedColumn = columns
    if(cols) {
      changedColumn = cols
    }
    const statuses = changedColumn.map((col) => ({ name: col.title }));

    try {
  const response =  await superagent
        .post(`${backendUrl}/warranty-tickets/reorderBoardStatus` )
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .send({
          boardName: 'Warranty',
          statuses: statuses,
          messageId: randomId.current
        });

      if (response.status >= 200 && response.status < 300) {

        setColumns(response.body.statuses?.map((status)=> ({
          id: status._id,
          title: status.name,
          
        })));
       
      } else {
        console.error('Error sending column order:', response);
      }

      
    } catch (error) {
      console.error('Error sending column order:', error);
    }
  };

  const removeColumn = (id) => {
    let newCol 
    setColumns((prevColumns) => {
      newCol =  prevColumns.filter((col) => col.id !== id);
      return newCol
    });

    sendColumnOrder(newCol)
  };
  

  const addColumn = (id, position) => {
    const index = columns.findIndex((column) => column.id === id);
    const newColumn = {
      id: 'untitled',
      title: 'Untitled',
      rename: true
    };
    if(position === 'right') {
      setColumns([
        ...columns.slice(0, index + 1),
        newColumn,
        ...columns.slice(index + 1),
      ]);
    }

    if(position === 'left') {
      setColumns([
        ...columns.slice(0, index),
        newColumn,
        ...columns.slice(index),
      ]);
    }
    
  };

  
  const renderedColumns = useMemo(() => (
    <SortableContext items={columnsId}>
      {columns?.map((col) => (
        <BoardColumn
          key={col.id}
          renameColumn={renameColumn}
          column={col}
          removeColumn={removeColumn}
          addColumn={addColumn}
          tasks={tasks.filter((task) => task.status === col.id)}
        />
      ))}
    </SortableContext>
  ), [columns, tasks, columnsId]);

  useEffect(() => {
    if (lastMessage?.data) {
      const messageData = JSON.parse(lastMessage?.data || "{}");

      if(messageData.id === randomId.current) {
        randomId.current = generateRandomId();
        return
      }

      if (processedMessages.has(messageData.id)) return;

      setProcessedMessages((prev) => new Set(prev).add(messageData.id));
      
      if (messageData?.type === "changeWarrantyTicket") {
        setRefreshTicket(prev => !prev)
      }

      if (messageData?.type === "changeWarrantyColumn") {
        setRefreshColumns(prev => !prev)
      }
    }
  }, [lastMessage]);

  const memoizedColumns = useMemo(() => statuses?.map((status)=> ({
    id: status._id,
    title: status.name,
    
  }) || []), [statuses]);

  const memoizedColumnsId = useMemo(() => (
    memoizedColumns?.map((status) => status.id)
  ), [memoizedColumns]);


  

  useEffect(() => {
    
   if(memoizedTasks.length > 0) {
    setTasks(memoizedTasks);
   }
  }, [memoizedTasks]);

  useEffect(() => {
    setColumns(memoizedColumns);
    setColumnsId(memoizedColumnsId);
  }, [memoizedColumns, memoizedColumnsId]);

  if (initialLoad) 
    return (
      <div className="page-content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
    



  const updateOrderTicketStatus = (id, status, taskOrder) => {
    superagent
      .put(`${backendUrl}/warranty-tickets/${id}`)
      .send({ status, messageId: randomId.current, taskOrder })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {})
      .then((response) => {
        const code = response.status;
        
        if (code > 400) {
        } else {
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  function getDraggingTaskData(taskId, columnId) {
    const tasksInColumn = tasks.filter((task) => task.status === columnId);
    const taskPosition = tasksInColumn.findIndex((task) => task.id === taskId);
    const column = columns.find((col) => col.id === columnId);
    return {
      tasksInColumn,
      taskPosition,
      column,
    };
  }

  
  const announcements = {
    onDragStart({ active }) {
      if (!hasDraggableData(active)) return;

      
      if (active.data.current?.type === 'Column') {
        const startColumnIdx = columnsId.findIndex((id) => id === active.id);
        const startColumn = columns[startColumnIdx];
        return `Picked up Column ${startColumn?.title} at position: ${
          startColumnIdx + 1
        } of ${columnsId.length}`;
      }
      
      else if (active.data.current?.type === 'Task') {
        pickedUpTaskColumn.current = active.data.current.task.status;
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          active.id,
          pickedUpTaskColumn.current
        );
        return `Picked up Task ${
          active.data.current.task.content
        } at position: ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },

    
    
    onDragOver({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) return;

      if (
        active.data.current?.type === 'Column' &&
        over.data.current?.type === 'Column'
      ) {
        const overColumnIdx = columnsId.findIndex((id) => id === over.id);
        return `Column ${active.data.current.column.title} was moved over ${
          over.data.current.column.title
        } at position ${overColumnIdx + 1} of ${columnsId.length}`;
      }
      
      else if (
        active.data.current?.type === 'Task' &&
        over.data.current?.type === 'Task'
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task.status
        );
        if (over.data.current.task.status !== pickedUpTaskColumn.current) {
          return `Task ${
            active.data.current.task.content
          } was moved over column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was moved over position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },
    
   onDragEnd({ active, over }) {
  
      pickedUpTaskColumn.current = null;
      if (!hasDraggableData(active) || !hasDraggableData(over)) return;

      if (
        active.data.current?.type === 'Column' &&
        over.data.current?.type === 'Column'
      ) {
        
        const overColumnPosition = columnsId.findIndex((id) => id === over.id);
        return `Column ${
          active.data.current.column.title
        } was dropped into position ${overColumnPosition + 1} of ${
          columnsId.length
        }`;
      } else if (active.data.current?.type === 'Task') {
        if (over.data.current?.type === 'Task') {
       
          const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
            over.id,
            over.data.current.task.status
          );
        
        
          const taskOrder = tasksInColumn.map((task, index) => {return{
            id: task.id,
            position: index
          }})

          
          updateOrderTicketStatus(active.id, over.data.current.task.status,taskOrder);
          
          return `Task was dropped into position ${taskPosition + 1} of ${
            tasksInColumn.length
          } in column ${column?.title}`;
        }
        if (over.data.current?.type === 'Column') {
          const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
            over.id,
            over.data.current.column.id
          );

          const taskOrder = tasksInColumn.map((task, index) => {return{
            id: task.id,
            position: index
          }})

          updateOrderTicketStatus(active.id, over.id, taskOrder); 
        }
      }
    },
    
    onDragCancel({ active }) {
     
      pickedUpTaskColumn.current = null;
      if (!hasDraggableData(active)) return;
      return `Dragging ${active.data.current?.type} cancelled.`;
    },
  };

  const  onDragOver = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (!hasDraggableData(active) || !hasDraggableData(over)) return;

    const activeData = active.data.current;
    const overData = over.data.current;

    
    if (activeData?.type === 'Task' && overData?.type === 'Task') {
      
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);
        const activeTask = tasks[activeIndex];
        const overTask = tasks[overIndex];

        if (
          activeTask &&
          overTask &&
          activeTask.status !== overTask.status
        ) {
          activeTask.status = overTask.status;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }
        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    
    if (activeData?.type === 'Task' && overData?.type === 'Column') {
      
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const activeTask = tasks[activeIndex];

        if (activeTask) {
          activeTask.status = overId;
          return arrayMove(tasks, activeIndex, activeIndex);
        }
        return tasks;
      });
    }
  }

  
  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <BoardContainer>
        
          {renderedColumns}
        
      </BoardContainer>

      
      {'document' in window &&
        createPortal(
          <DragOverlay>
            {activeColumn && (
              <BoardColumn
                isOverlay
                column={activeColumn}
                tasks={tasks.filter(
                  (task) => task.status === activeColumn.id
                )}
              />
            )}
            {activeTask && <TaskCard task={activeTask} isOverlay />}
          </DragOverlay>,
          document.body
        )}
    </DndContext>
  );

  
  function onDragStart(event) {
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;
    if (data?.type === 'Column') {
      
      setActiveColumn(data.column);
      return;
    }
    if (data?.type === 'Task') {
      
      setActiveTask(data.task);
      return;
    }
  }

  
  function onDragEnd(event) {

    setActiveColumn(null);
    setActiveTask(null);
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;
    if (!hasDraggableData(active)) return;

    const activeData = active.data.current;

    
    if (activeData?.type === 'Column') {
      
      setColumns((columns) => {
        const activeColumnIndex = columns.findIndex(
          (col) => col.id === activeId
        );
        const overColumnIndex = columns.findIndex((col) => col.id === overId);
        const newColumnsOrder = arrayMove(columns, activeColumnIndex, overColumnIndex);
        sendColumnOrder(newColumnsOrder)
        return newColumnsOrder
      });
    }
  }

  
  
}
