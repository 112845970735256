import React, { useState, useEffect } from 'react';

import { Card, CardContent } from '@shadcn/components/ui/card';
import SimpleBar from 'simplebar-react';
import { arrayNotNull } from '../../../utilities/utilities';

import useQuery from '../../../helpers/JIFI/useQuery';
import getTimeInterval from '../../../helpers/human_timespan';

import AddComment from './AddComment';

import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';

import './skelton.css';

const CommentHostory = React.memo(
  ({ initCommentType, initOrderName, title, titleClass, overrideRefresh, maxHeight="700px" }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [commentType, setCommentType] = useState(initCommentType);
    const [orderName, setOrderName] = useState(
      initOrderName ? initOrderName.replace('#', '') : ''
    );

    useEffect(() => {
      setOrderName(initOrderName ? initOrderName.replace('#', '') : '');
    }, [initOrderName]);

    const [refr, setRefresh] = useState(false);
    const refresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
      refresh();
    }, [overrideRefresh]);

    const endpointLookup = {
      Dashboard: `/comments/general`,
      Order: `/comments/order`,
      Container: `/comments/container`,

      Factory: `/comments/factories`,
      PurchaseOrder: `/comments/purchaseOrders`,
      Release: `/comments/releases`,
    };

    const [data, state] = useQuery({
      url: endpointLookup[commentType],
      queryData: {
        page: page,
        perPage: perPage,
        orderName: orderName.split('-')[0],
      },
      refresh: refr,
    });
    const comments = data && data.data;

    const user = JSON.parse(localStorage.getItem('authUser'));

    const deleteComment = (id) => {
      superagent
        .post(`${backendUrl}/comments/${id}/delete`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {})
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            console.log(response.body);
            refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const Body = () => {
      return (
        <div className="mt-4">
          <h4 className={`card-title mb-4 ${titleClass}`}>{title ? title : 'Notifications'}</h4>
          <SimpleBar style={{ maxHeight: maxHeight }}>
            <ul className="list-group">
              {state === 'success' && arrayNotNull(comments) ? (
                comments.map((comment, key) => {
                  let isMe =
                    user._id === comment.user_model?._id ||
                    user.email === 'alex.n@july.com' ||
                    user.email === 'leo@july.com' ||
                    user?.permissions?.includes('DeleteAnyComment');
                  if (comment.visible === false) return <></>;
                  return (
                    <li key={`_comment_${key}`} className="list-group-item">
                      <div className="flex flex-col md:flex-row md:items-start md:space-x-4">
                        
                        <div className="avatar-xs mr-3 mb-3 md:mb-0">
                          <span className="avatar-title rounded-full bg-light flex items-center justify-center">
                            <img
                              src={
                                comment.user_model?.profile_image ||
                                '/logo-j.svg'
                              }
                              alt=""
                              height="32"
                              width="32"
                              className="rounded-full"
                              style={
                                comment.user_model?.profile_image
                                  ? {
                                      objectFit: 'cover',
                                      width: 32,
                                      height: 32,
                                    }
                                  : { width: 16, height: 16, margin: 8 }
                              }
                            />
                          </span>
                        </div>

                    
                        <div
                          className="flex-1 min-w-0"
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          <h5 className="text-sm font-bold"></h5>

                          {/* Comment Description */}
                          <div className="break-words">
                            {comment.description ? (
                              arrayNotNull(comment.files) &&
                              /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
                                comment.files[0]
                              ) === false ? (
                                <p className="text-slateGray">
                                  <a target="blank" href={comment.files[0]}>
                                    {comment.description || 'Attached file'}
                                  </a>
                                </p>
                              ) : (
                                <MentionText text={comment.description} />
                              )
                            ) : null}
                          </div>

                      
                          {arrayNotNull(comment.files) ? (
                            <div className="mt-2">
                              {comment.files.map((img, index) => {
                                return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
                                  img
                                ) ? (
                                  <img
                                    src={img}
                                    alt=""
                                    width="100%"
                                    key={index}
                                  />
                                ) : null;
                              })}
                            </div>
                          ) : null}

                          
                          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 gap-2">
                           
                            <p className="text-xs text-slateGray">
                              <i className="mdi mdi-account mr-1"></i>
                              {comment.user_model?.userName ||
                                comment.userName}{' '}
                              {comment.orderNameFull !== undefined &&
                                comment.orderName !== comment.orderNameFull && (
                                  <span className="ml-1">
                                    ({comment.orderNameFull})
                                  </span>
                                )}
                            </p>
                            <p className="text-xs text-gray-500 whitespace-nowrap">
                              {getTimeInterval(new Date(comment.comment_date))}
                            </p>
                          </div>
                        </div>

                        
                        {isMe && (
                          <button
                            onClick={() => deleteComment(comment._id)}
                            className="ml-3 text-red-500 shrink-0"
                          >
                            &#x2715;
                          </button>
                        )}
                      </div>
                    </li>
                  );
                })
              ) : (
                <li key={`_comment_${0}`} className="list-group-item">
                  <div className="flex items-start space-x-4">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          alt=""
                          height="32"
                          width="32"
                          className="rounded-circle"
                        />
                      </span>
                    </div>
                    <div className="flex-1" style={{ whiteSpace: 'pre-wrap' }}>
                      <h5 className="font-size-14"></h5>
                      <p className="text-slateGray placeholder"></p>
                      <div className="float-right">
                        <p className="text-slateGray mb-0 placeholder"> </p>
                      </div>
                      <p className="text-slateGray mb-0 placeholder"></p>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </SimpleBar>
          <br />
          <AddComment
            orderName={orderName}
            refresh={refresh}
            commentType={commentType}
          />
        </div>
      );
    };
    if (initCommentType === 'Dashboard') {
      return <Body />;
    }
    return (
      <React.Fragment>
        <Card>
          <CardContent>
            <Body />
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
);

export default CommentHostory;

export const MentionText = ({ text }) => {
    
    const pattern = /@\[(.*?)\]\((.*?)\)/g;
    const parts = text?.split(pattern);

    return (
        <p>
            {parts?.map((part, index) => {
                
                if (index % 3 === 1) {
                    return <strong key={index}>@{part}</strong>;
                }
                
                if (index % 3 === 0) {
                    return (
                        <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
                    );
                }
                
                return null;
            })}
        </p>
    );
};
