import CommentHostory from "@pages/Skote/Dashboard/CommentHistory"



export const NotesSection = ({ order, refresh }) => {
  return (
    <CommentHostory
      titleClass="text-xl font-medium"
      overrideRefresh={refresh}
      title="Order Notes"
      initCommentType={'Order'}
      initOrderName={order?.name}
    />
  );
};
