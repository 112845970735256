import React from "react";
import { Badge } from "@shadcn/components/ui/badge";

export const StatusBadge = ({ status }) => {
  const conversion = {
    'approved': 'success',
    'disapproved': 'danger',
    'pending': 'warning',
    
  }
  return <Badge className={"font-size-12 badge-soft-" + conversion[status]} color={conversion[status]} pill>
    <i className={"fab " + " font-size-12 badge-soft-primary"}></i> {status?.replace('-', ' ')?.toUpperCase()}
  </Badge>
}
