import  { useState, useEffect } from "react";

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card'

import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import "@pages/JIFI/datatables.scss";
import useQuery from '../../../../helpers/JIFI/useQuery';
import useProductData from "../../orders/useProductData";

import { useUrlState } from "../../../../helpers/useUrlState";
import Batch from "../component/Batch";
import CompletePersonalisation from "../component/CompletePersonalisation";


const DeliveriesPage = () => {
  const url = '/orders/getPersonalisationBatchesEWE'


  const [stateData, setStateData] = useUrlState({batchSearch: ''});

  const handleTermChange = (e) => {
    console.log('SETTING TO', e.target.value)
    setStateData({batchSearch: e.target.value})
  }

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [batches, state] = useQuery({ refresh, url, queryData: {
    search: stateData.batchSearch
  } });

  const [cachedBatchData, setCachedBatchData] = useState([])
  useEffect(() => {
    if (batches) {
      setCachedBatchData(batches)
    }
  }, [batches])

  let [doneID, setDone] = useState()

  let [openNames, setOpenNames] = useState([])

  const getVariant = useProductData()

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-6">
          <Row onClick={() => refreshData()}>
            <Col xs="11">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation Batches" />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mt-6">
                    <Col sm="4">
                      <Row>
                        <Col sm="10">
                          <div className="select2-container">
                            <label className="control-label">FILTER</label>
                            <div className="search-box mb-2 d-block">
                              <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Search..." value={stateData.batchSearch} onChange={handleTermChange}/>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="flex flex-wrap gap-6">
                    {
                      (cachedBatchData.length > 0) ? (
                        cachedBatchData.map(batch => (
                          <Col xs="12" id={batch.name}>
                            <Batch getVariant={getVariant} openNames={openNames} setOpenNames={setOpenNames} batch={batch} doneID={doneID} setDone={setDone} state={state}/>
                          </Col>
                        ))
                      ) : state === 'loading' ? (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          No batches found
                        </div>
                      )

                    }
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
       
          <CompletePersonalisation url='/orders/completePersonalisationEWE' lineItem={doneID} open={doneID !== undefined} refresh={refreshData}  togglemodal={() => setDone()} />
       
      </div>   
    </>
  );
}


export default DeliveriesPage;
