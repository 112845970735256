import { CSVReader } from 'react-papaparse';
import {  useState, useCallback, useMemo } from 'react';
import useVariantStore from '../../../../zustand/useVariantStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/components/ui/tabs';
import { Badge } from '@shadcn/components/ui/badge';

import { ScrollArea } from '@shadcn/components/ui/scroll-area';
import { Card, CardContent } from '@shadcn/components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import { uploadFileS3Bucket } from '@utilities/uploadFileS3Bucket';
import useProductStore from '../../../../zustand/useProductStore';
import { useParams } from 'react-router-dom';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';

const columnHelper = createColumnHelper();

const INITIAL_FORM_STATE = {
  name: '',
  sku: '',
  image: '',
  stock_buffer: '',
  status_au: '',
  oos_message: '',
  pause_preorder: false,
  stock_buffer_us: '',
  status_us: '',
  oos_message_us: '',
  pause_preorder_us: false,
  us_allow_au: false,
  stock_buffer_uk: '',
  status_uk: '',
  oos_message_uk: '',
  pause_preorder_uk: false,
  uk_allow_au: false,
  is_archived: false,
  no_reorder: false
};

const STATUS_OPTIONS = [
  { value: '', label: 'Select Status' },
  { value: 'unavailable', label: 'Unavailable' },
  { value: 'keep_selling', label: 'Keep Selling' },
  { value: 'discontinued', label: 'Discontinued' },
  { value: 'preorder', label: 'Preorder' }
];

const STATUS_COLORS = {
  'keep_selling': 'bg-green-100 text-green-800',
  'discontinued': 'bg-red-100 text-red-800', 
  'preorder': 'bg-yellow-100 text-yellow-800',
  'unavailable': 'bg-gray-100 text-gray-800'
};

const BulkInputSKUsStep = () => {
  const { productID } = useParams();
  const { setVariants, variants, deletedVariants } = useVariantStore((state) => ({
    setVariants: state.setVariants,
    variants: state.variants,
    deletedVariants: state.deletedVariants
  }));
  
  const { setImages, setSelectedImage, images, selectedImage } = useProductStore((state) => ({
    setImages: state.setImages,
    setSelectedImage: state.setSelectedImage,
    images: state.images,
    selectedImage: state.selectedImage
  }));

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleImageUpload = useCallback(async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = await Promise.all(
      files.map(file => uploadFileS3Bucket(file, `product_image_${Date.now()}`))
    );

    setImages([...images, ...uploadedImages]);
    if(productID) {
      await superagent
        .put(`${backendUrl}/product/addImages/${productID}`)
        .send({ images: uploadedImages })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
        .then((response) => {
          console.log(response);
        });
    }
  }, [setImages]);

  const validateVariant = useCallback((variant) => {
    const errors = {};
    if (!variant.name?.trim()) errors.name = 'Name is required';
    if (!variant.sku?.trim()) errors.sku = 'SKU is required';
    return errors;
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors(prev => ({...prev, [name]: ''}));
  }, []);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    setFormData(prev => ({...prev, image}));
  }, [setSelectedImage]);

  const resetForm = useCallback(() => {
    setFormData(INITIAL_FORM_STATE);
    setEditingIndex(null);
  }, []);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();
    const validationErrors = validateVariant(formData);
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const newVariant = {
      ...formData,
      continue_selling: formData.status_au === 'keep_selling',
      discontinued: formData.status_au === 'discontinued',
      preorder: formData.status_au === 'preorder',
      continue_selling_us: formData.status_us === 'keep_selling',
      discontinued_us: formData.status_us === 'discontinued', 
      preorder_us: formData.status_us === 'preorder',
      continue_selling_uk: formData.status_uk === 'keep_selling',
      discontinued_uk: formData.status_uk === 'discontinued',
      preorder_uk: formData.status_uk === 'preorder'
    };

    if (editingIndex !== null) {
      
      const updated = [...variants];
      updated[editingIndex] = newVariant;
      setVariants(updated);
      
    } else {
      setVariants([...variants, newVariant]);
    }

    resetForm();
  }, [formData, editingIndex, validateVariant, setVariants, resetForm]);

  const handleDelete = useCallback((index) => {
    const deleted = variants[index];
    deletedVariants.push(deleted);
    setVariants(variants.filter((_, i) => i !== index));
  }, [setVariants, deletedVariants]);

  const handleEdit = useCallback((variant, index) => {
    setFormData(variant);
    setEditingIndex(index);
  }, []);

  const handleOnDrop = (data) => {
    const uploadedVariants = [];
    const invalidVariants = [];

    data.forEach((row, index) => {
      if (index === 0) return;

      const item = row.data;
      if(item.length === 1) return;

      const variant = updateVariantFromCsv(item);
      const validationErrors = validateVariant(variant);

      if (Object.keys(validationErrors).length > 0) {
        invalidVariants.push({
          ...variant,
          errors: validationErrors,
          row: index
        });
      } else {
        uploadedVariants.push(variant);
      }
    });

    
    if(invalidVariants.length > 0) {
      toast.warning(`${invalidVariants.length} variants are invalid. Please check row ${invalidVariants.map(variant => variant.row).join(', ')} `);
      
    }
    setVariants([...variants, ...uploadedVariants]);
  };

  const ImageSelector = () => (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-full">
          {selectedImage ? 'Change Image' : 'Select Image'}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] z-[1600] p-0" align="start" style={{maxHeight: '80vh', overflowY: 'auto'}}>
        <div className="p-4 space-y-4">
          <div className="flex justify-between items-center">
            <h4 className="text-sm font-medium">Product Images</h4>
            <label className="cursor-pointer bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600">
              Upload New
              <input 
                type="file" 
                className="hidden" 
                accept="image/*"
                multiple
                onChange={handleImageUpload}
              />
            </label>
          </div>
          
          <ScrollArea className="h-[300px]">
            <div className="grid grid-cols-2 gap-2 p-2">
              
              {console.log(images)}
              { images?.length > 0 && images.map((image, index) => (
                <Card 
                  key={index}
                  className={`cursor-pointer transition-all ${selectedImage === image ? 'ring-2 ring-blue-500' : ''}`}
                  onClick={() => handleImageSelect(image)}
                >
                  <CardContent className="p-1">
                    <img 
                      src={image} 
                      alt={`Product ${index + 1}`}
                      className="w-full h-24 object-cover rounded"
                    />
                  </CardContent>
                </Card>
              ))}
            </div>
          </ScrollArea>
        </div>
      </PopoverContent>
    </Popover>
  );

  

  

  const updateVariantFromCsv = (item) => {
    const [
      name,                  
      sku,                   
      image,                 
      stock_buffer,          
      status_au,            
      oos_message,          
      pause_preorder,       
      stock_buffer_us,      
      status_us,            
      oos_message_us,       
      pause_preorder_us,    
      us_allow_au,          
      stock_buffer_uk,      
      status_uk,            
      oos_message_uk,       
      pause_preorder_uk,    
      uk_allow_au,          
      is_archived,          
      no_reorder,           
    ] = item;

    const statusMapping = (status, locale) => {
      const baseStatus = {
        continue_selling: false,
        discontinued: false, 
        preorder: false
      };
  
      if (status === 'unavailable') {
        if (locale === 'au') {
          return baseStatus;
        }
        return {
          [`continue_selling_${locale}`]: false,
          [`discontinued_${locale}`]: false,
          [`preorder_${locale}`]: false
        };
      }
  
      if (locale === 'au') {
        return {
          continue_selling: status === 'keep_selling',
          discontinued: status === 'discontinued',
          preorder: status === 'preorder'
        };
      }
  
      return {
        [`continue_selling_${locale}`]: status === 'keep_selling',
        [`discontinued_${locale}`]: status === 'discontinued',
        [`preorder_${locale}`]: status === 'preorder'
      };
    };
  

    const auStatus = statusMapping(status_au, 'au');
    const usStatus = statusMapping(status_us, 'us');
    const ukStatus = statusMapping(status_uk, 'uk');

    return {
      name,
      sku,
      image,
      stock_buffer,
      status_au,
      oos_message,
      pause_preorder: pause_preorder?.toUpperCase() === 'TRUE',
      stock_buffer_us,
      status_us,
      oos_message_us,
      pause_preorder_us: pause_preorder_us?.toUpperCase() === 'TRUE',
      us_allow_au: us_allow_au?.toUpperCase() === 'TRUE',
      stock_buffer_uk,
      status_uk,
      oos_message_uk,
      pause_preorder_uk: pause_preorder_uk?.toUpperCase() === 'TRUE',
      uk_allow_au: uk_allow_au?.toUpperCase() === 'TRUE',
      is_archived,
      no_reorder: no_reorder?.toUpperCase() === 'TRUE',
      ...auStatus,
      ...usStatus,
      ...ukStatus,
    }
  }

  const columns = useMemo(() => [
    columnHelper.accessor('name', {
      header: 'Name'
    }),
    columnHelper.accessor('sku', { 
      header: 'SKU'
    }),
    columnHelper.accessor('image', {
      header: 'Image',
      cell: ({ row }) => (
        row.original.image ? 
        <img src={row.original.image} alt={row.original.name} className="w-16 h-16 object-cover rounded" /> :
        'No image'
      )
    }),
    columnHelper.accessor('stock_buffer', {
      header: 'Stock Buffer',
    }),
    columnHelper.accessor('status_au', {
      header: 'Status AU',
      cell: ({ row }) => {
        const status = row.original.status_au;
        return <Badge className={STATUS_COLORS[status] || 'bg-gray-100'}>{status || 'N/A'}</Badge>;
      }
    }),
    columnHelper.accessor('oos_message', {
      header: 'OOS Message',
    }),
    columnHelper.accessor('pause_preorder', {
      header: 'Pause Preorder',
    }),
    columnHelper.accessor('stock_buffer_us', {
      header: 'Stock Buffer US',
    }),
    columnHelper.accessor('status_us', {
      header: 'Status US',
      cell: ({ row }) => {
        const status = row.original.status_us;
        return <Badge className={STATUS_COLORS[status] || 'bg-gray-100'}>{status || 'N/A'}</Badge>;
       
      }
    }),
    columnHelper.accessor('oos_message_us', {
      header: 'OOS Message US',
    }),
    columnHelper.accessor('pause_preorder_us', {
      header: 'Pause Preorder US',
    }),
    columnHelper.accessor('us_allow_au', {
      header: 'US Allow AU',
    }),
    columnHelper.accessor('stock_buffer_uk', {
      header: 'Stock Buffer UK',
    }),
    columnHelper.accessor('status_uk', {
      header: 'Status UK',
      cell: ({ row }) => {
        const status = row.original.status_uk;
        return <Badge className={STATUS_COLORS[status] || 'bg-gray-100'}>{status || 'N/A'}</Badge>;
      }
    }),
    columnHelper.accessor('oos_message_uk', {
      header: 'OOS Message UK',
    }),
    columnHelper.accessor('pause_preorder_uk', {
      header: 'Pause Preorder UK',
    }),
    columnHelper.accessor('uk_allow_au', {
      header: 'UK Allow AU',
    }),
    columnHelper.accessor('is_archived', {
      header: 'Is Archived',
    }),
    columnHelper.accessor('no_reorder', {
      header: 'No Reorder',
    }),
    columnHelper.accessor('actions', {
      header: 'Actions',
      cell: ({ row }) => (
        <div className="flex gap-2">

          <button 
            onClick={() => handleEdit(row.original, row.index)}
            className="text-blue-500 hover:text-blue-700"
          >
            <i className="bx bx-pencil"></i>
          </button>
          {!row.original._id && (
            <button 
              onClick={() => handleDelete(row.index)}
              className="text-red-500 hover:text-red-700"
          >
              <i className="bx bx-trash"></i>
            </button>
          )}
        </div>
      )
    }),
  ], [handleEdit, handleDelete]);

  console.log(variants);
  const table = useReactTable({
    data: variants,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderRegionFields = useCallback((region) => {
    const suffix = region === 'AU' ? '' : `_${region.toLowerCase()}`;
    const suffixStatus = `status_${region.toLowerCase()}`;
    
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4">
        <div className="space-y-4">
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">Stock Buffer {region}</label>
            <Input
              name={`stock_buffer${suffix}`}
              type="number"
              value={formData[`stock_buffer${suffix}`]}
              onChange={handleInputChange}
              className="w-full"
            />
          </div>
          
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">Status {region}</label>
            <select 
              name={suffixStatus}
              value={formData[suffixStatus]}
              onChange={handleInputChange}
              className="w-full border rounded-md p-2"
            >
              {STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">OOS Message {region}</label>
            <Input
              name={`oos_message${suffix}`}
              value={formData[`oos_message${suffix}`]}
              onChange={handleInputChange}
              className="w-full"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2 bg-gray-50 p-2 rounded">
              <Checkbox
                name={`pause_preorder${suffix}`}
                checked={formData[`pause_preorder${suffix}`]}
                onCheckedChange={(checked) =>
                  handleInputChange({target: {name: `pause_preorder${suffix}`, type: 'checkbox', checked}})}
              />
              <label className="text-sm">Pause Preorder {region}</label>
            </div>

            {region !== 'AU' && (
              <div className="flex items-center gap-2 bg-gray-50 p-2 rounded">
                <Checkbox
                  name={`${region.toLowerCase()}_allow_au`}
                  checked={formData[`${region.toLowerCase()}_allow_au`]}
                  onCheckedChange={(checked) =>
                    handleInputChange({target: {name: `${region.toLowerCase()}_allow_au`, type: 'checkbox', checked}})}
                />
                <label className="text-sm">{region} Allow AU</label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [formData, handleInputChange]);

  return (
    <Row>
      <Col>
        <div className="flex flex-col gap-8 max-w-6xl mx-auto">
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-6">Upload CSV</h3>
            
            <CSVReader onDrop={handleOnDrop}>
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-blue-500 transition-colors">
                <span className="text-gray-600">
                  Drop CSV file here or click to upload.
                </span>
              </div>
              
            </CSVReader>
            <div className="mt-2 d-flex justify-content-center">
              <a
              target="_blank"
              href="https://docs.google.com/spreadsheets/d/1YouX8Sbz8KP11EbCUf56H9AxIcXmMiIhcM6MneCGMxw/copy">
                CSV Template
              </a>
            </div>
         
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-6">Manual Input</h3>
            <form onSubmit={handleFormSubmit} className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div className="flex flex-col">
                  <label className="text-sm font-medium mb-1">Name</label>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full ${errors.name ? 'border-red-500' : ''}`}
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium mb-1">SKU</label>
                  <Input
                    name="sku"
                    value={formData.sku}
                    onChange={handleInputChange}
                    className={`w-full ${errors.sku ? 'border-red-500' : ''}`}
                  />
                  {errors.sku && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.sku}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium mb-1">Image</label>
                  <ImageSelector />
                </div>
              </div>

              <div className="bg-gray-50 rounded-lg">
                <Tabs defaultValue="au" className="w-full">
                  <TabsList className="grid w-full grid-cols-3 p-1">
                    <TabsTrigger value="au" className="data-[state=active]:bg-white">
                      AU
                    </TabsTrigger>
                    <TabsTrigger value="us" className="data-[state=active]:bg-white">
                      US  
                    </TabsTrigger>
                    <TabsTrigger value="uk" className="data-[state=active]:bg-white">
                      UK
                    </TabsTrigger>
                  </TabsList>
                  <TabsContent value="au">
                    {renderRegionFields('AU')}
                  </TabsContent>
                  <TabsContent value="us">
                    {renderRegionFields('US')}
                  </TabsContent>
                  <TabsContent value="uk">
                    {renderRegionFields('UK')}
                  </TabsContent>
                </Tabs>
              </div>

              <div className="flex justify-end">
                <Button type="submit" className="px-8">
                  {editingIndex !== null ? 'Update Variant' : 'Add Variant'}
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div className="overflow-x-auto max-w-[55vw] py-10">
          <div className="bg-white shadow rounded-lg p-6">
            <div className="overflow-x-auto">
              <table className="w-full ">
                <thead className="bg-gray-50">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className="px-4 py-3 text-left text-sm font-medium text-gray-700"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="border-t border-gray-200">
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="px-4 py-3 text-sm text-gray-900"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BulkInputSKUsStep;
