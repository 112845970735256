import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import { Switch } from "@shadcn/components/ui/switch"
import Select from '@shadcn/components/ui/react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import moment from 'moment';
import SKUToolTip from "../helpers/SKUToolTip";
import useSKUStatus from "../helpers/useSKUStatus";
import useSKULevels from "../helpers/useSKULevels";
import useUpcomingStock from "../helpers/useUpcomingStock";
import useUserStore from "../../../zustand/useUserStore";
import { Badge } from "@shadcn/components/ui/badge";
import { Button } from "@shadcn/components/ui/button";
import { Input } from "@shadcn/components/ui/input";
import { TablePagination } from "@components/supply-chain/TablePagination";
import { Row } from "@shadcn/components/ui/row";
import { Col } from "@shadcn/components/ui/col";
import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { TableSkeleton } from "@shadcn/components/ui/table";

const dueDateOptions = [
  { value: '', label: 'All' },
  { value: 'today', label: 'Today' },
  { value: 'within_7_days', label: 'Within 7 Days' },
  { value: 'within_30_days', label: 'Within 30 Days' },
  { value: 'within_year', label: 'Within the Year' },
];

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const priorityColor = (priority) => {
  let check = priority?.toLowerCase()
  if (check === 'high') return 'danger'
  if (check === 'medium') return 'warning'
  if (check === 'low') return 'secondary'
  if (check !== 'low' && check !== 'high' && check !== 'medium') return 'info'
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}





const OrdersPage = ({ url, initValues }) => {
  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  
  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedFlagType: null,
    filterFlagType: null,
    selectedAssignedUser: null,
    filterAssignedUser: null,
    selectedStatus: null,
    filterStatus: null,
    selectedPriority: null,
    filterPriority: null,
    selectedDueDate: null,
    filterDueDate: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Fulfilled', value: 'fulfilled' }, { label: 'Partially fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' },],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD' }, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
    priorityOptions: [{ label: 'Low', value: 'low' }, { label: 'Medium', value: 'medium' }, { label: 'High', value: 'high' }],
    flagTypesOptions: [{}]
  }

  const [searchMode, setSearchMode] = useState('simple');
  const [stateData, setStateData] = useState(initValues || initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [flagTypesFilters] = useQuery({
    url: `/orders/getFlagTypes`
  });

  const userList = useUserStore((state) => state.userList);

  useEffect(() => {
    if (flagTypesFilters) {
      let flagFiltersOptions = flagTypesFilters.map((e) => ({ label: e.name, value: e._id }))
      setStateData((prevState => {
        return {
          ...prevState,
          flagTypesOptions: flagFiltersOptions
        }
      }))
    }
  }, [flagTypesFilters])


  useEffect(() => {
    setStateData(initValues)
  }, [initValues])

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }

  const handleSelectFlagType = selectedFlagType => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedFlagType: selectedFlagType,
        filterFlagType: Array.isArray(selectedFlagType) && selectedFlagType.map(a => a.value)
      }
    }))
  };


  const handleSelectDueDate = selectedDueDate => {

    setStateData((prevState => {
      return {
        ...prevState,
        selectedDueDate: selectedDueDate,
        filterDueDate: selectedDueDate.value
      }
    }))
  };

  const handleSelectAssignedUser = selectedAssignedUser => {
    
    setStateData((prevState => {
      return {
        ...prevState,
        selectedAssignedUser: selectedAssignedUser,
        filterAssignedUser: Array.isArray(selectedAssignedUser) && selectedAssignedUser.map(a => a.value)
      }
    }))
  };


  const handleSelectPickStatus = selectedPickStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPickStatus: selectedPickStatus,
        filterPickStatus: Array.isArray(selectedPickStatus) && selectedPickStatus.map(a => a.value)
      }
    }))
  };

  const handleSelectStatus = selectedStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedStatus: selectedStatus,
        filterStatus: Array.isArray(selectedStatus) && selectedStatus.map(a => a.value)
      }
    }))
  };

  const handleSelectPriority = selectedPriority => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPriority: selectedPriority,
        filterPriority: Array.isArray(selectedPriority) && selectedPriority.map(a => a.value)
      }
    }))
  };

  const handleSelectRegion = selectedRegion => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedRegion: selectedRegion,
        filterRegion: Array.isArray(selectedRegion) && selectedRegion.map(a => a.value)
      }
    }))
  };

  const ResetField = e => {
    e.preventDefault();
    setStateData(prevState => {
      return {
        ...prevState,
        selectedRegion: null,
        filterRegion: null,
        selectedPickStatus: null,
        filterPickStatus: null,
        selectedFlagType: null,
        filterFlagType: null,
        selectedStatus: null,
        filterStatus: null,
        selectedPriority: null,
        filterPriority: null,
        searchTerm: '',
        orderStartDate: '',
        filterStartDate: '',
        orderEndDate: '',
        filterEndDate: '',
      };
    });
  };

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterPickStatus: stateData.filterPickStatus, filterFlagType: stateData.filterFlagType, filterRegion: stateData.filterRegion, filterPriority: stateData.filterPriority, filterAssignedUser: stateData.filterAssignedUser, filterDueDate: stateData.filterDueDate } });
  const orders = data && data.data;
  const count = data && data.count;

  const { orderStartDate, orderEndDate, selectedStatus, searchTerm, stepOptions, pickStatusOptions, selectedPickStatus, selectedFlagType, flagTypesOptions, selectedPriority, priorityOptions, selectedRegion, regionOptions, selectedDueDate } = stateData;

  const [selected, setSelected] = useState([])

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Corporate Orders" />
          
          <Row className='mb-6'>
            <Col xs="12">
              <Card className="pt-6">
                <CardBody>
                  
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium">
                          {searchMode === 'simple' ? 'Simple' : 'Advanced'} Search
                        </span>
                        <Switch
                          checked={searchMode === 'advanced'}
                          onCheckedChange={(checked) =>
                            setSearchMode(checked ? 'advanced' : 'simple')
                          }
                        />
                      </div>
                      <Button 
                        type="button" 
                        variant="outline"
                        onClick={ResetField}
                        className="px-4 py-2"
                      >
                        Reset Filters
                      </Button>
                    </div>

                    <div className="flex flex-col gap-4 mb-4">
                      {/* Simple Search */}
                      <div className="w-full">
                        <label className="control-label mb-2 block">Search Orders</label>
                        <Input 
                          type="text" 
                          className="w-full form-control" 
                          placeholder="Search by order ID, customer name, SKU..." 
                          value={searchTerm} 
                          onChange={handleTermChange}
                        />
                      </div>

                      {/* Advanced Search */}
                      {searchMode === 'advanced' && (
                        <>
                          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            <div>
                              <label className="control-label mb-2 block">Assigned User</label>
                              <Select
                                onChange={handleSelectAssignedUser}
                                placeholder="All Users"
                                options={userList?.length > 0 ? userList?.map((val) => ({ 
                                  label: val.userName, 
                                  value: val._id 
                                })) : []}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Priority</label>
                              <Select
                                value={selectedPriority}
                                onChange={handleSelectPriority}
                                placeholder="All Priorities"
                                options={priorityOptions}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Region</label>
                              <Select
                                value={selectedRegion}
                                onChange={handleSelectRegion}
                                placeholder="All Regions"
                                options={regionOptions}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Step</label>
                              <Select
                                value={selectedStatus}
                                onChange={handleSelectStatus}
                                placeholder="All Steps"
                                options={stepOptions}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Pick Status</label>
                              <Select
                                value={selectedPickStatus}
                                onChange={handleSelectPickStatus}
                                placeholder="All Pick Statuses"
                                options={pickStatusOptions}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Due Date</label>
                              <Select
                                value={selectedDueDate}
                                onChange={handleSelectDueDate}
                                placeholder="All Due Dates"
                                options={dueDateOptions}
                                className="basic-select"
                                classNamePrefix="select"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">Start Date</label>
                              <input 
                                className="form-control w-full h-[38px] border border-gray-300 rounded-md" 
                                name="orderStartDate"
                                value={orderStartDate}
                                onChange={handleChange}
                                type="date"
                              />
                            </div>

                            <div>
                              <label className="control-label mb-2 block">End Date</label>
                              <input
                                className="form-control w-full h-[38px] border border-gray-300 rounded-md"
                                name="orderEndDate"
                                value={orderEndDate}
                                onChange={handleChange}
                                type="date"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Created At</th>
                          <th>Due Date</th>

                          <th>Region</th>
                          <th>Pick Status</th>
                          <th>Fulfillment</th>
                          <th>Priority</th>
                          <th>Contains Personalisation</th>
                          <th>SKUs</th>
                          <th>Assigned User</th>
                        </tr>
                      </thead>
                      {orders && state === 'success' ?
                      (<tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) => {
                              let skuCount = {}
                              order.line_items.map(val => {
                                if (skuCount[val.sku] === undefined) {
                                  skuCount[val.sku] = val.quantity
                                } else {
                                  skuCount[val.sku] += val.quantity
                                }
                              })

                              const lineSum = Object.keys(skuCount).map(sku => {
                                return {
                                  sku: sku,
                                  quantity: skuCount[sku]
                                }
                              })

                              return lineSum.map((line, keyLine) => keyLine === 0 ? (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + key + "_" + keyLine}>
                                  <td><Link to={`/corp-orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>


                                  <td> {(new Date(order.orderCreated)).toDateString()} </td>
                                  <td> {order.deadline && (new Date(order.deadline)).toDateString()} </td>

                                  <td> {order?.shipping_address?.country} </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + pickToBadge(order.pick_status)} color={pickToBadge(order.pick_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {pickToName(order.pick_status)}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(order.fulfillment_status)} color={fulfillmentToBadge(order.fulfillment_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {order.fulfillment_status ? order?.fulfillment_status?.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                    </Badge>
                                  </td>
                                  <td>
                                    {
                                      order?.priority && (
                                        <Badge className={"font-size-12 badge-soft-" + priorityColor(order?.priority)} color={priorityColor(order?.priority)} pill>
                                          <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i>{order?.priority}
                                        </Badge>
                                      )
                                    }
                                  </td>
                                  <td>
                                    {
                                      order.contains_monogram === false ? (
                                        <Badge className={"font-size-12 badge-soft-success"} color={'success'} pill>
                                          FALSE
                                        </Badge>
                                      ) : (
                                        <Badge className={"font-size-12 badge-soft-info"} color={'info'} pill>
                                          TRUE
                                        </Badge>
                                      )
                                    }
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                    </Badge> x{line.quantity}
                                  </td>
                                  <td className="text-center">
                                    {order?.assigned_user?.userName ?? ""}
                                  </td>
                                </tr>
                              ) : (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + key + "_" + keyLine}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                    </Badge> x{line.quantity}
                                  </td>
                                  <td>
                                    {console.log("current order", order, order['name'])}
                                    {order?.assigned_user?.userName ?? ""}
                                  </td>
                                </tr>
                              )).flat()
                            })
                          )
                        }
                      </tbody>) :
                      <TableSkeleton perPage={perPage} columns={10} />
                    }
                    </table>
                  </div>
                  {
                    (orders && state === 'success') && (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) 
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default OrdersPage;
