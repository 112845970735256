import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Collapsible, CollapsibleContent } from "@shadcn/components/ui/collapsible";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';

import {
  Card,
  CardContent as CardBody,
  CardHeader,
} from '@shadcn/components/ui/card';

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
// Editable
import { useHistory } from 'react-router-dom';
import superagent from 'superagent';
import moment from 'moment';

//Import Breadcrumb
import useQuery from '../../../helpers/JIFI/useQuery';
import { backendUrl } from '../../../helpers/consts.js';

import { CSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';

import SupplyChainNav from '../../../components/supply-chain/nav.js';
import CommentHostory from '../../Skote/Dashboard/CommentHistory.js';
import { Badge } from '@shadcn/components/ui/badge';
import { Button } from '@shadcn/components/ui/button';
import { Input as InputShadcn } from '@shadcn/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@shadcn/components/ui/dialog';

const statusToBadge = (status) => {
  const conversion = {
    approved: 'badge-soft-success',
    disapproved: 'badge-soft-danger',
    pending: 'badge-soft-warning',
  };
  return conversion[status];
};

export const CustomInput = ({ value, onChange, isPrefix=false, preSufix="$", ...props }) => {
  return (
    <div
    
    className={`flex items-center border border-black  rounded-md ${!isPrefix  ? 'border-r-1': "border-l-0" }`}
    
  >
      {isPrefix && <div className="flex items-center px-3 bg-gray-100 border-r border-l rounded-l-md border-black  h-9">
        <span className="text-gray-500">{preSufix}</span>
      </div> }
      <InputShadcn
        type="number"
        value={value}
        onChange={onChange}
      
        className={`border-none font-normal text-base focus:ring-0 focus:outline-none rounded-none ${!isPrefix  ? 'rounded-l-md': "rounded-r-md"} h-9`}
        {...props}
      />
      {!isPrefix &&
      <div className="flex items-center px-3 bg-gray-100 border-l border-black rounded-r-md h-9">
        <span className="text-gray-500">{preSufix}</span>
      </div>
      }
    </div>
  );
};

const Accordian = ({ children, title, close }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (close === true) {
      setOpen(false);
    }
  }, [close]);
  return (
    <>
      <div id="accordion">
        <Card className="mb-1 bg-[#f6f6f6] border-b-0 border-b-[#f6f6f6] font-medium">
          <CardHeader
            onClick={() => setOpen((prev) => !prev)}
            className="p-3"
            id="headingOne"
          >
            <h6 className="m-0 font-14">
              <span style={{ cursor: 'pointer' }} className={''}>
                {title}
              </span>
            </h6>
          </CardHeader>
          <Collapsible open={open} onOpenChange={()  => setOpen((prev) => !prev)}>
          <CollapsibleContent>
            <Card>
              <CardBody>{children}</CardBody>
            </Card>
            </CollapsibleContent>
          </Collapsible>
        </Card>
      </div>
      <br />
      <br />
    </>
  );
};

const StockInput = ({ stock, setStock, productData, noCost }) => {
  const handleAddRow = () => {
    const selectedVariant = stock.map(
      (item) => item.variant._id ?? item.variant
    );

    const filterProductData = productData.filter(
      (product) => !selectedVariant.includes(product._id)
    );

    const item = {
      variant: filterProductData[0]._id || productData[0]._id,
      sku: filterProductData[0].sku || productData[0].sku,
      quantity: 0,
      cost: 0,
    };
    setStock((prev) => [...prev, item]);
  };

  const editVariantRow = (id, val) => {
    setStock((prev) => {
      let newList = [...prev];
      const variant = productData.find(
        (product) => `${product._id}` === `${val}`
      );

      newList[id]['variant'] = val;
      newList[id]['quantity'] = 0;
      newList[id]['cost'] = 0;
      newList[id]['sku'] = variant?.sku;

      return newList;
    });
  };

  const editQuantityRow = (id, val) => {
    setStock((prev) => {
      let newList = [...prev];
      let parsedInput = parseInt(val);
      if (parsedInput < 0) {
        parsedInput = 0;
      }
      newList[id]['quantity'] = parsedInput;
      return newList;
    });
  };

  const editcostRow = (id, val) => {
    setStock((prev) => {
      let newList = [...prev];
      let parsedInput = parseInt(val);
      if (parsedInput < 0) {
        parsedInput = 0;
      }
      newList[id]['cost'] = parsedInput;
      return newList;
    });
  };

  const removeRow = (id) => {
    console.log('current remove id');
    console.log(id, stock[id]);
    setStock((prev) => {
      console.log('test prev list', prev);
      let newList = prev.filter((item, key) => key !== id);
      console.log('test new list', newList);
      return newList;
    });
  };

  const getAvailableProducts = (line) => {
    const selectedVariants = stock
      .filter((item) => {
        const variantId = item.variant._id ?? item.variant;
        const lineId = line.variant._id ?? line.variant;
        return variantId !== lineId;
      })
      .map((item) => item.variant._id ?? item.variant);

    const result = productData.filter(
      (product) => !selectedVariants.includes(product._id)
    );
    return result;
  };

  return (
    <>
      <Row>
        <Col className="sm:w-4/12">Variant</Col>
        {noCost !== true && <Col className="sm:w-4/12">Cost</Col>}
        <Col className="sm:w-3/12">Quantity</Col>
      </Row>
      {productData &&
        stock.map((line, lineKey) => {
          const availableProducts = getAvailableProducts(line);

          return (
            <Row style={{ padding: '12px 0', gap: '12px' }}>
              <Col className="sm:w-4/12">
                <Select
                  key={line.variant?._id ?? line.variant}
                  defaultValue={line.variant?._id ?? line.variant}
                  onValueChange={(e) => {
                    editVariantRow(lineKey, e);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Please select variant" />
                  </SelectTrigger>
                  <SelectContent className="z-[1600]">
                    {availableProducts.map((val, key) => (
                      <SelectItem key={key} value={val._id}>
                        {val.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </Col>
              {noCost !== true && (
                <Col className="sm:w-4/12">
                  <CustomInput
                    value={stock[lineKey]['cost']}
                    onChange={(e) => editcostRow(lineKey, e.target.value)}
                  />
                </Col>
              )}
              <Col className="sm:w-3/12">
                <InputShadcn
                  type="number"
                  value={stock[lineKey]['quantity']}
                  onChange={(e) => editQuantityRow(lineKey, e.target.value)}
                />
              </Col>
              <Col className="sm:w-1/12">
                <Button onClick={() => removeRow(lineKey)} variant="destructive">
                  X
                </Button>{' '}
              </Col>
            </Row>
          );
        })}
      {productData?.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: 12,
          }}
        >
          <Button
            disabled={stock.length === productData.length}
            onClick={handleAddRow}
          
          >
            Add{' '}
          </Button>{' '}
          <span />
        </div>
      )}
    </>
  );
};

const PurchaseOrderPage = ({ match: { params } }) => {
  const { purchaseOrderID } = params;

  const [reference, setReference] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [factory, setFactory] = useState('');
  const [lines, setLines] = useState([]);

  const [requestedDate, setRequestedDate] = useState('');
  const [expectedReleaseDate, setExpectedReleaseDate] = useState('');
  const [originalReleaseDate, setOriginalReleaseDate] = useState('');

  const history = useHistory();
  const [loadingSave, setLoadingSave] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const refreshData = () => setRefresh((prev) => !prev);

  const [releaseModalOpen, setReleaseModalOpen] = useState(false);

  const [purchaseOrder, purchaseOrderState] = useQuery({
    url: `/purchase-order/${purchaseOrderID}`,
    refresh,
  });
  const [productData, productState] = useQuery({
    url: `/product`,
    refresh,
  });
  const [factories, factoriesState] = useQuery({
    url: `/factory/all`,
    refresh,
  });

  const factoryData = factories?.data;
  const variants = productData
    ? productData.data.map((product) => product.variants).flat()
    : [];
  const selectedFactory = factoryData?.find((val) => val._id === factory);
  const factoryVariantIds = selectedFactory?.variantLines.map(
    (val) => val.variant
  );

  const filteredVariants = variants.filter((val) => {
    return factoryVariantIds?.includes(val._id);
  });

  useEffect(() => {
    if (purchaseOrder) {
      setReference(purchaseOrder.reference);
      setLines(purchaseOrder.lines);
      setFactory(purchaseOrder.factory);
      setCurrency(purchaseOrder.currency || 'USD');

      setRequestedDate(
        moment(purchaseOrder.requestedDate).format('YYYY-MM-DD')
      );
      setExpectedReleaseDate(
        moment(purchaseOrder.expectedReleaseDate).format('YYYY-MM-DD')
      );
      setOriginalReleaseDate(
        moment(purchaseOrder.originalReleaseDate).format('YYYY-MM-DD')
      );
    }
  }, [purchaseOrder]);

  const handleSave = (e) => {
    e.preventDefault();
    if (loadingSave === true) return;

    setLoadingSave(true);
    if (purchaseOrderID === 'create') {
      superagent
        .post(`${backendUrl}/purchase-order/create`)
        .send({
          reference: reference,
          lines: lines,
          factory: factory,
          currency: currency,
          requestedDate,
          expectedReleaseDate,
          originalReleaseDate,
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.push(`/purchase-order/${response.body._id}`);
            setLoadingSave(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      superagent
        .put(`${backendUrl}/purchase-order/update/${purchaseOrderID}`)
        .send({
          reference: reference,
          lines: lines,
          factory: factory,
          currency: currency,
          requestedDate,
          expectedReleaseDate,
          originalReleaseDate,
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            setLoadingSave(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOnDrop = (data) => {
    let items = [];
    data.map((line, key) => {
      if (key > 0) {
        const quantity = line.data[1];
        const cost = line.data[2];
        const sku = line.data[0];

        let variant = variants.find((val) => val.sku === sku);
        if (variant != undefined) {
          const item = {
            variant: variant._id,
            quantity: quantity,
            cost: cost,
            sku: sku,
          };
          items.push(item);
        }
      }
    });

    setLines((prev) => {
      let newLines = [...prev];

      items.map((line) => {
        const alreadyExists = newLines.find(
          (oldLine) => oldLine.variant === line.variant
        );
        if (!alreadyExists) {
          newLines.push(line);
        } else {
          alreadyExists['quantity'] = line['quantity'];
          alreadyExists['cost'] = line['cost'];
          alreadyExists['sku'] = line['sku'];
        }
      });

      return newLines;
    });
  };
  const handleOnError = (err) => {
    console.log(err);
  };
  const handleOnRemoveFile = (file) => {
    console.log(file);
  };

  const csvData = [
    ['SKU', 'Quantity', 'Cost'],
    ...(lines?.map((line) => [
      variants.find((val) => val._id === line.variant)?.sku,
      line['quantity'],
      line['cost'],
    ]) || []),
  ];

  const poData = {};

  if (purchaseOrder) {
    purchaseOrder.lines.map((line) => {
      poData[line.sku] = {
        ordered: line.quantity,
        released: 0,
      };
    });

    if (purchaseOrder && purchaseOrder.releases) {
      purchaseOrder.releases.map((release) => {
        if (release.releaseLines) {
          release.releaseLines.map((releaseLine) => {
            const quantity =
              release.status === 'approved' ? releaseLine.quantity : 0;
            poData[releaseLine.variant.sku] =
              poData[releaseLine.variant.sku] || {};
            poData[releaseLine.variant.sku]['released'] =
              (poData[releaseLine.variant.sku]['released'] || 0) + quantity;
          });
        }
      });
    }
  }

  return (
    <>
      <SupplyChainNav
        commentComponent={
          <CommentHostory
            maxHeight="200px"
            title="Comments"
            initCommentType={'PurchaseOrder'}
            initOrderName={reference}
          />
        }
      >
        <div>
          <Row>
            <Col className="w-full">
              <Card className="p-4">
                <CardBody>
                  {purchaseOrderState !== 'loading' ? (
                    <div className="mt-4">
                      <Row className="flex-wrap">
                        <Col className="sm:w-6/12">
                          <div className="mb-4 mr-4">
                            <label htmlFor="locationname">Reference</label>
                            <InputShadcn
                              id="locationname"
                              name="locationname"
                              type="text"
                              className="form-control h-9"
                              value={reference}
                              onChange={(e) => setReference(e.target.value)}
                            />
                          </div>
                          <div className="mb-4 mr-4">
                            <label htmlFor="locationname">Factory</label>
                            <Select
                              value={factory}
                              onValueChange={(value) => setFactory(value)}
                            >
                              <SelectTrigger className="h-9">
                                <SelectValue placeholder="Select Factory" />
                              </SelectTrigger>
                              <SelectContent>
                                {factoryData?.map((val) => (
                                  <SelectItem key={val._id} value={val._id}>
                                    {val.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="mb-4 mr-4">
                            <label htmlFor="locationname">
                              Completed Released Date
                            </label>
                            <InputShadcn
                              className="h-9"
                              disabled={true}
                              type="text"
                              value={
                                purchaseOrder?.completedReleasedDate
                                  ? moment(
                                      purchaseOrder?.completedReleasedDate
                                    )?.format('DD/MM/YYYY')
                                  : ''
                              }
                              name="completedReleasedDate"
                              id="completedReleasedDate"
                            ></InputShadcn>
                          </div>
                        </Col>
                        <Col className="sm:w-6/12">
                          <div className="mb-4">
                            <label className="control-label">
                              Requested Date
                            </label>
                            <input
                              className="form-control border-black"
                              name="orderStartDate"
                              type="date"
                              value={requestedDate}
                              onChange={(e) =>
                                setRequestedDate(
                                  moment(e.target.value).format('YYYY-MM-DD')
                                )
                              }
                              defaultValue="2019-08-19"
                              id="example-date-input"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="control-label">
                              Expected Release Date
                            </label>
                            <input
                              className="form-control border-black"
                              name="orderStartDate"
                              type="date"
                              value={expectedReleaseDate}
                              onChange={(e) =>
                                setExpectedReleaseDate(
                                  moment(e.target.value).format('YYYY-MM-DD')
                                )
                              }
                              defaultValue="2019-08-19"
                              id="example-date-input"
                            />
                          </div>
                          <div className="mb-4">
                            <label className="control-label">
                              Original Release Date
                            </label>
                            <input
                              className="form-control border-black"
                              name="orderStartDate"
                              type="date"
                              value={originalReleaseDate}
                              onChange={(e) =>
                                setOriginalReleaseDate(
                                  moment(e.target.value).format('YYYY-MM-DD')
                                )
                              }
                              defaultValue="2019-08-19"
                              id="example-date-input"
                            />
                          </div>
                        </Col>
                        <Col className="mt-4 w-full">
                          <Accordian close={loadingSave} title="Stock Produced">
                            <Row>
                              <Col className="sm:w-11/12" />
                              <Col
                                className="sm:w-1/12"
                                style={{ paddingBottom: 8 }}
                              >
                                <CSVLink
                                  data={csvData}
                                  filename={`${reference}_stock_count.csv`}
                                >
                                  <Button
                                    type="button"
                                    variant="secondary"
                                    className="btn-lg btn-rounded"
                                  >
                                    CSV
                                  </Button>
                                </CSVLink>
                              </Col>
                            </Row>
                            <StockInput
                              stock={lines}
                              setStock={setLines}
                              productData={filteredVariants}
                            />
                            <div className="my-4">
                              <CSVReader
                                onDrop={handleOnDrop}
                                onError={handleOnError}
                                onRemoveFile={handleOnRemoveFile}
                              >
                                <span>
                                  Drop CSV file here or click to upload.
                                </span>
                              </CSVReader>
                              <div className="mt-2 d-flex justify-content-center">
                                <a className='text-[#1f3dd0]' href="https://docs.google.com/spreadsheets/d/1IKRTd60gREBhUgwmo29U81Scbb_fRLmqpKqO_NPNW1w/copy">
                                  {' '}
                                  CSV Template{' '}
                                </a>
                              </div>
                            </div>
                          </Accordian>
                          <Accordian title="Releases">
                            {purchaseOrder?.releases?.map((release) => {
                              const handleUpdateRelease = (status) => {
                                superagent
                                  .post(
                                    `${backendUrl}/purchase-order/release/update-status/${release._id}`
                                  )
                                  .send({ status })
                                  .set(
                                    'Authorization',
                                    `Bearer ${localStorage.getItem('authJWT')}`
                                  )
                                  .set('accept', 'json')
                                  .on('error', (response) => {
                                    const code = response.status;
                                    history.replace(history.location.pathname, {
                                      errorStatusCode: code,
                                    });
                                  })
                                  .then((response) => {
                                    refreshData();
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              };
                              return (
                                <div className="pt-6">
                                  <Accordian
                                    title={
                                      <>
                                        {moment(release.dateReleased).format(
                                          'YYYY-MM-DD'
                                        )}
                                        <Badge
                                          className={`float-right ${statusToBadge(release.status)}`}
                                          pill
                                 
                                        >
                                          {' '}
                                          {release.status}{' '}
                                        </Badge>
                                      </>
                                    }
                                  >
                                    {release.releaseLines.map((releaseLine) => {
                                      return (
                                        <div className="pt-6">
                                          <div>
                                            {releaseLine.sku} x{' '}
                                            {releaseLine.quantity}
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {release.status === 'pending' && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          padding: '8px 4px',
                                          marginTop: 24,
                                        }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleUpdateRelease('approved')
                                          }
                                          variant="success"
                                        >
                                          {' '}
                                          Approve{' '}
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleUpdateRelease('disapproved')
                                          }
                                          variant="destructive"
                                        >
                                          {' '}
                                          Disapprove{' '}
                                        </Button>
                                      </div>
                                    )}
                                  </Accordian>
                                </div>
                              );
                            })}
                          </Accordian>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="2" className="sm:w-2/12">
                          <Button
                            onClick={handleSave}
                            type="submit"
                            variant="success"
                            className="mr-1 waves-effect waves-light"
                          >
                            {loadingSave ? 'Saving...' : 'Save Changes'}
                          </Button>
                        </Col>
                        <Col className="xs:w-8/12"></Col>
                        <Col className="xs:w-2/12">
                          <Button
                            disabled={purchaseOrder?.status !== 'approved'}
                            onClick={() => setReleaseModalOpen((prev) => !prev)}
                            className="mr-1 waves-effect waves-light"
                            variant="warning"
                          >
                            {' '}
                            Create Release{' '}
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 16 }}>
                        <table className="mt-8" style={{ marginBottom: 60 }}>
                          <thead>
                            <tr>
                              <th>SKU</th>
                              <th>Ordered Quantity</th>
                              <th>Released Quantity</th>
                              <th>Remaining Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(poData)
                              .sort((a, b) => a.localeCompare(b))
                              .map((sku) => {
                                return (
                                  <tr>
                                    <td>{sku}</td>
                                    <td>{poData[sku]?.ordered}</td>
                                    <td>{poData[sku]?.released}</td>
                                    <td>
                                      {isNaN(
                                        poData[sku]?.ordered -
                                          poData[sku]?.released
                                      )
                                        ? 0
                                        : poData[sku]?.ordered -
                                          poData[sku]?.released}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </SupplyChainNav>
      <ReleaseModal
        refreshData={refreshData}
        releaseModalOpen={releaseModalOpen}
        setReleaseModalOpen={setReleaseModalOpen}
        purchaseOrder={purchaseOrder}
        filteredVariants={filteredVariants}
      />
    </>
  );
};

const ReleaseModal = ({
  releaseModalOpen,
  refreshData,
  setReleaseModalOpen,
  purchaseOrder,
  filteredVariants,
}) => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);

  const submitRelease = (e) => {
    e.preventDefault();
    if (loading === true) return;

    setLoading(true);
    superagent
      .post(`${backendUrl}/purchase-order/release/create`)
      .send({
        purchaseOrder: purchaseOrder._id,
        releaseLines: lines,
        dateReleased: new Date(),
        status: 'pending',
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          toast.error('Failed to create release');
        } else {
          setLoading(false);
          setReleaseModalOpen(false);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const errorMessage =
          err.response?.body?.message || err.message || 'An error occurred';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
      });
  };

  return (
    <Dialog
      open={releaseModalOpen}
      onOpenChange={() => setReleaseModalOpen((prev) => !prev)}
    >
      <DialogContent className="max-h-[80vh] overflow-y-auto z-[1500] min-w-[60vw]">
        <DialogHeader>Create release</DialogHeader>

        <div>
          <StockInput
            stock={lines}
            setStock={setLines}
            productData={filteredVariants}
            noCost={true}
          />
        </div>

        <DialogFooter>
          <Button variant="success" onClick={submitRelease}>
            {' '}
            Save{' '}
          </Button>
          <Button
            variant="destructive"
            onClick={() => setReleaseModalOpen((prev) => !prev)}
          >
            {' '}
            Cancel{' '}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseOrderPage;
