
import { Button } from '@shadcn/components/ui/button';
// Editable

import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@shadcn/components/ui/select';

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const perPageOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]


export const TablePagination =  ({
  count,
  page,
  setPage,
  perPage,
  className,
  setPerPage,
}) => {
  const pageCount = Math.ceil(count / perPage);
  const pages = paginate(
  Array.from({ length: pageCount }, (_, i) => i + 1), page)

  const handlePageChange = (page) => setPage(page);
  const handlePerPageChange = (newPerPage) => setPerPage(Number(newPerPage));

  return (
    <div className={`flex justify-between items-center space-x-4 ${className}`}>
     
      <div className="w-36">
        <Select
          
          defaultValue={perPage}
          onValueChange={handlePerPageChange}
        >
          <SelectTrigger>
            <SelectValue placeholder="Items per page" />
          </SelectTrigger>
          <SelectContent>
            {perPageOptions.map((option, key) => {
             return (
              <SelectItem key={key}  value={option.value}>
                {option.label} 
              </SelectItem>
            )
            })}
          </SelectContent>
        </Select>
      </div>

      
      <div className="space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>

        
        {pages.map((pageNumber) => (
          <Button
            key={pageNumber}
            variant={pageNumber === page ? 'default' : 'outline'}
            size="sm"
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Button>
        ))}

        <Button
          variant="outline"
          size="sm"
          onClick={() => setPage(page + 1)}
          disabled={page === pageCount}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
