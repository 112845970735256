import React, { useState, useMemo } from "react";
import { CSVLink } from "react-csv";
import { Button } from '@shadcn/components/ui/button';

import { Card, CardTitle, CardContent as CardBody } from '@shadcn/components/ui/card'
import { Col } from '@shadcn/components/ui/col'
import { Row } from '@shadcn/components/ui/row'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useQuery from "../../../helpers/JIFI/useQuery";

import { createColumnHelper, useReactTable, flexRender,getCoreRowModel } from '@tanstack/react-table';


const columnHelper = createColumnHelper();
const BasicTable = () => {
    const [refresh, setRefresh] = useState(true)

    const columns = useMemo(
        () => [
            columnHelper.accessor('sku', {
                header: () => <b>SKU</b>,
                cell: info => info.getValue() || 'N/A',
            }),
            columnHelper.accessor('average_cog_au', {
                header: () => <b>Australia</b>,
                cell: info => info.getValue() || 'N/A',
            }),
            columnHelper.accessor('average_cog_us', {
                header: () => <b>United States</b>,
                cell: info => info.getValue() || 'N/A',
            }),
        ],
        []
    );

    

    const refreshData = () => {
        setRefresh(prev => !prev)
    }


    const [products] = useQuery({
        url: `/product/findAll`,
        refresh,
    });

    let productList

    if (products) {
        productList = products.data.sort(line => line.name).map(product => product.variants.map(variant => ({ sku: variant.sku, average_cog_au: variant.average_cog_au, average_cog_us: variant.average_cog_us }))).flat()
    }

    const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
    const csvData = [
        ['SKU', 'AUSTRALIA', 'UNITED STATES'],
        ...(productList ? productList.map(line => [line.sku, line.average_cog_au, line.average_cog_us]).filter(lineItem => lineItem.sku) : [])
    ]

    const data = useMemo(() => productList?.filter(lineItem => lineItem.sku) || [], [productList]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });


    return (
        <div className="page-content">
            <div className="w-full px-3 mx-auto xl:max-w-[85%]">
                <Breadcrumbs title="Inventory" breadcrumbItem="COG" />
                {/* <Row>
                    <Col sm="2" style={{ paddingBottom: 8 }}>
                        <CSVLink
                            data={csvData}
                            filename={`COG_${dateString}.csv`}
                        >
                            <Button type="button" color="secondary" className="btn-lg btn-rounded">
                                CSV
                            </Button>
                        </CSVLink>
                    </Col>
                </Row> */}
                <Row>
                    <Col className='w-full'>
                        <Card>
                            <CardBody>
                                
                                <CardTitle className="text-lg">
                                    <Row className="justify-between items-center py-4">
                              
                                    Average COG (AU/US)
                              
                              
                                    <CSVLink
                                        data={csvData}
                                        filename={`COG_${dateString}.csv`}
                                    >
                                        <Button type="button" variant="secondary" className="btn-lg btn-rounded">
                                            CSV
                                        </Button>
                                    </CSVLink>
                              
                                    </Row>
                                </CardTitle>
                                {productList ? (
                <table className="table mb-0">
                    <thead style={{ position: 'sticky', top: 123, background: 'white' }}>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th
                                        key={header.id}
                                        className="table-header"
                                        style={{ cursor: 'default' }}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

export default BasicTable
