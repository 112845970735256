import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { PreviousOrders } from "../PreviousOrders";



export const CustomerInfoSection = ({ order, orderID }) => {
  return (
    <Card className="mb-6">
      <CardBody>
        {order?.order_source !== 'tmall' && (
          <>
            <h4 className="text-xl font-medium my-4">Customer Info</h4>
            <h6 className='font-semibold' style={{ marginBottom: 16 }}>
              {`${order?.customer?.first_name} ${order?.customer?.last_name}`}
            </h6>
            <div>
              <span style={{ opacity: 0.5 }}>EMAIL:</span>
              {order?.customer?.email}
            </div>
            <br />
          </>
        )}
      
        <PreviousOrders orderID={orderID} />
      </CardBody>
    </Card>
  );
};
