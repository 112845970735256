
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Button } from '@shadcn/components/ui/button'
import { TableSkeleton } from '@shadcn/components/ui/table'

const SharedTable = ({
  lineItems,
  selected,
  setSelected,
  handleGenerateBatch,
  selectedIds,
  loading
}) => {
  return (
    <>
      <Row>
        <Col className="sm:w-4/12"> </Col>
        <Col className="sm:w-4/12"> </Col>
        <Col className="sm:w-4/12">
          <Button
            onClick={handleGenerateBatch}
            className="w-full mb-4"
            disabled={
              !(selectedIds && selectedIds.length > 0 && selectedIds.length <= 16)
            }
          >
            Create Batch
          </Button>
        </Col>
      </Row>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap">
          
          <thead>
            <tr>
              <th>
                <div className="custom-control custom-checkbox">
                  <input
                    onChange={(e) =>
                      lineItems
                        ? setSelected(
                            lineItems.map((i, key) => (key < 16 ? e.target.checked : false))
                          )
                        : setSelected([])
                    }
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                  />
                  <label className="custom-control-label" htmlFor="customCheck1">&nbsp;</label>
                </div>
              </th>
              <th>Monogram ID</th>
              <th>Date</th>
              <th>Shipping</th>
              <th>SKU</th>
              <th>Monogram Text</th>
              <th>Monogram Font</th>
              <th>Monogram Color</th>
              <th>Monogram Secondary Color</th>
              <th>Monogram Location</th>
              <th>Pets</th>
            </tr>
          </thead>
          {loading ? <TableSkeleton checkboxIndex={[0]} perPage={10} columns={11} /> :
          <tbody>
            {lineItems.map((lineItem, key) => (
              <tr key={`_order_${key}`}>
                <td>
                  <div
                    className="custom-control custom-checkbox"
                    onClick={() =>
                      setSelected((prev) => {
                        let newSelected = [...prev];
                        newSelected[key] = !newSelected[key];
                        return newSelected;
                      })
                    }
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={lineItem.id}
                      checked={selected[key]}
                    />
                    <label className="custom-control-label" htmlFor={lineItem.id}>
                      &nbsp;
                    </label>
                  </div>
                </td>
                <td>{lineItem.monogramID}</td>
                <td>{new Date(lineItem.orderSent).toDateString()}</td>
                <td>{lineItem.shipping_type}</td>
                <td>{lineItem.sku}</td>
                <td>{lineItem.monogram_text}</td>
                <td>{lineItem.monogram_font}</td>
                <td>{lineItem.monogram_color}</td>
                <td>{lineItem.monogram_shadow}</td>
                <td>{lineItem.monogram_position}</td>
                <td>
                  {lineItem.monogram_pets &&
                    JSON.parse(atob(lineItem.monogram_pets))
                      .map((pet) => pet.name)
                      .join(' + ')}
                </td>
              </tr>
            ))}
          </tbody>
}
        </table>
      </div>
    </>
  );
};

export default SharedTable;
