import React, { useEffect, useState } from 'react';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from '@shadcn/components/ui/dropdown-menu';
import SimpleBar from 'simplebar-react';
import superagent from 'superagent';
// Import images

import useQuery from '../../../helpers/JIFI/useQuery';
import avatar_fallback from '../../../assets/images/avatar-fallback.svg';

import useUserStore from '../../../zustand/useUserStore';
import { backendUrl } from '../../../helpers/consts';
import { useHistory } from 'react-router-dom';

const NotificationDropdown = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);


  const [notifications] = useQuery({
    url: '/notifications',
    refresh: refresh,
  });

  const fetchUserList = useUserStore((state) => state.fetchUserList);

  useEffect(() => {
    fetchUserList();
  }, []);

  const timeAgo = (date) => {
    const now = new Date();
    const diffInMilliseconds = now - new Date(date);
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  const markSelectedAsRead = (id) => {
    superagent
      .post(`${backendUrl}/notifications/mark-as-read`)
      .send({ ids: [id] }) // Send the list of selected notification IDs
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.body?.message || err.message || 'Internal server error';
      });
  };

  return (
    <React.Fragment>
      <DropdownMenu
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownMenuTrigger
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada text-white opacity-60"></i>
          {notifications?.length && notifications?.filter(
                  (notification) => notification.read === false
                ).length > 0 && (
            <span className="badge badge-danger badge-pill">
              {
                notifications?.filter(
                  (notification) => notification.read === false
                ).length
              }
            </span>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[40vw] md:min-w-[35vw] lg:min-w-[25vw] nguyen223 bg-black text-white" >
          <DropdownMenuLabel>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 text-white">Notifications</h6>
                </Col>
                <div className="col-auto">
                  <a href="/notifications/inbox" className="small text-white hover:text-gray-300">
                    View All
                  </a>
                </div>
              </Row>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator className="bg-gray-600"/>
          <SimpleBar style={{ height: '230px' }}>
            {notifications?.map((notification, index) => (
              <DropdownMenuItem key={index} className="hover:bg-gray-800 focus:bg-gray-800 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                <a
                  href={notification.url ?? '#'}
                  onClick={() => markSelectedAsRead(notification.id)}
                  className="text-white  w-full"
                  key={notification.id}
                >
                  <div className="py-3 px-4 flex flex-wrap">
                    <img
                      onError={(e) => (e.currentTarget.src = avatar_fallback)}
                      src={notification.profileImage ?? avatar_fallback}
                      className="mr-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />

                    <div className="media-body">
                      <h6 className="mt-0 mb-1 d-flex justify-content-between align-items-center text-white">
                        {notification.senderName}
                        {!notification.read && (
                          <span className="badge badge-warning ml-2">Unread</span>
                        )}
                      </h6>
                      <div className="font-size-12 text-gray-300">
                        <p className="mb-1">{notification.commentPlainText}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{' '}
                          {timeAgo(notification.notificationDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </DropdownMenuItem>
            ))}
          </SimpleBar>
        </DropdownMenuContent>
      </DropdownMenu>
    </React.Fragment>
  );
};

export default NotificationDropdown;
