import { Card, CardHeader, CardContent, CardDescription } from '@shadcn/components/ui/card';

import { Col } from '@shadcn/components/ui/col'



const OptionTypeCard = ({ name, description, available, onClick }) => {
    const cardStyle = {
        cursor: 'pointer',
    };

    const getCardHeaderProps = () => {
        const baseProps = {
            className: 'bg-transparent',
        };

        if (available) {
            return {
                ...baseProps,
                text: name,
                color: 'primary',
                iconClassName: 'mdi mdi-bullseye-arrow',
                disable: false
            };
        } else {
            return {
                ...baseProps,
                text: name,
                color: 'danger',
                iconClassName: 'mdi mdi-block-helper',
                disable: true
            };
        }
    };

    const { text, color, iconClassName, disable } = getCardHeaderProps();

    return (
        <Col className="lg:w-[48%]" onClick={onClick} style={cardStyle}>
            <Card outline color={color} className="border"  disable={disable}>
                <CardHeader {...getCardHeaderProps()}>
                    <h5 className={`my-0 text-${color} text-[16px] font-medium`}>
                        <i className={`${iconClassName} mr-3`}></i>
                        {text}
                    </h5>
                </CardHeader>
                <CardContent>
                    <p className='text-neutral-500'>{description}</p>
                </CardContent>
            </Card>
        </Col>
    );
};

export default OptionTypeCard;
