import { useState, useEffect } from 'react'

const regions = [
    {
      url: "https://july.com/stock/AU.json"
    },
    {
      url: "https://july.com/stock/US.json"
    },
    {
      url: "https://july.com/stock/UK.json"
    },
  ]

const useSKUStatus = () => {
    const [stockData, setStockData] = useState({
        AU: null,
        US: null,
        UK: null
      });
    
      useEffect(() => {
        Promise.all(regions.map(region => fetch(region.url).then(response => response.json())))
          .then(data => {
            const [dataAU, dataUS, dataUK] = data;
            setStockData({
              AU: dataAU,
              US: dataUS,
              UK: dataUK
            });
          })
          .catch(error => console.error(error));
      }, []);

      const getStatus = (sku, region) => {
        const status = stockData[region]?.[sku];
        if (status) {
          if (status.avaliable === true && status.on_site === true && status.preorder === true) {
            return 'Preorder'
          }
          if (status.avaliable === false && status.on_site === true) {
            return 'Out of Stock'
          }
          if (status.on_site === false) {
            return 'Off Site'
          }
        }
        return 'Available';
      };

    return getStatus
}

export default useSKUStatus