import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import { Button } from '@shadcn/components/ui/button'
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import useQuery from '../../helpers/JIFI/useQuery';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import queryString from 'query-string';

import superagent from 'superagent'
import {backendUrl} from '../../helpers/consts.js'

const numberWithSign = (number) => {
    let signed = ''
    const positive = number > 0
    if(positive){
        signed += '+'
    }
    signed += number
    return <span className={`text-${positive || number === 0 ? 'success' : 'danger'}`}> {signed} </span>
} 

const ApprovalsBadge = ({status}) => {
    const statuses = {
        'pending': 'warning',
        'approved': 'success',
        'rejected': 'danger'
    }
    return <span style={{fontSize: 8}} className={`ml-2 badge badge-${statuses[status]}`}>{status}</span>
}

const ProjectsList = ({
    location
}) => {
    let params = queryString.parse(location.search);
    const [refresh, setRefresh] = useState(false)
    const [loadingSend, setLoadingSend] = useState(true)
    const refreshData = () => setRefresh(prev => !prev)
    const [data, state] = useQuery({
        url: `/inventory/approvalGroupOne`,
        queryData: {
            date: params['date'],
            location: params['location']
        },
        refresh
    });

    const approvals = data && data.data
    
    const [productData, productState] = useQuery({
        url: `/product`
    });
    const products = productData && productData.data
    const variants = products?.map(product => product.variants).flat()

    const history = useHistory();

    const [selectedApproval, setSelectedApproval] = useState(undefined)
    const handleChangeSelected = (key, val) => {
        setSelectedApproval(prev => {
            let newVal = [...prev]
            newVal[key] = val
            return newVal
        })
    }
    const handleSubmit = () => {
        if (loadingSend === false) {
            setLoadingSend(true)
            let ids = {
                accept: [],
                reject: []
            }
            selectedApproval.map((val, id) => {
                if (val !== undefined) {
                    if (val === true){
                        ids.accept.push(approvals[id]._id)
                    } else if (val === false) {
                        ids.reject.push(approvals[id]._id)
                    }
                }
            })
    
            superagent.post(`${backendUrl}/inventory/multipleApprovals`)
                .send(ids)
                .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
                .set('accept', 'json')
                .on('error', (response) => {
                    const code = response.status
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                })
                .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                    history.go(0)
                    setLoadingSend(false)
                }
                }).catch(err => {
                    console.log(err)
                });    
        }
    }
    useEffect(() => {
        if (approvals) {
            setSelectedApproval(approvals.map((val) => val.status !== 'pending' ? undefined : false))
            setLoadingSend(false)
        }
    }, [approvals])

    return (
        <>
            <div className="page-content">
            <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-10">
                    <Breadcrumbs title="Inventory" breadcrumbItem={`${data?.location?.name || ''} Approvals - ${params['date']}`} />
                    {
                        (
                            approvals === undefined || 
                            (
                                approvals && 
                                    (approvals.filter(val => val.status === 'pending').length > 0))
                            ) && (
                            <Button onClick={handleSubmit} variant="success" style={{position: 'fixed', right: 32, bottom: 48, paddingLeft: 48, paddingRight: 48, zIndex: 99}} className={`btn-lg btn-rounded btn btn-success px-6 ${loadingSend ? 'disabled' : ''}`}> 
                                {loadingSend ? (approvals === undefined ? 'Loading Audits...' : 'Submitting...') : 'Submit'} 
                            </Button>
                        )
                    }
                    {
                        approvals && products ? approvals.map((data, approvalKey) => {
                            const count = data.count
                            const virtual_count = data.virtual_count
                            return (
                                <Card className="mt-4">
                                    <CardBody className="pt-6">
                                        <Row className="ml-2" style={{display: 'flex', alignItems: 'center'}}>
                                            <h3 className='text-[23px] font-medium' style={{marginBottom: 0}}> {products.find(val => `${data.product}` === `${val._id}`)?.name} Count </h3> <ApprovalsBadge status={data.status} />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="">
                                                    <div className="table-responsive">
                                                        <table className="project-list-table table-nowrap table-centered table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <th>SKU</th>
                                                                    <th>Stock On Hand</th>
                                                                    <th>Display Stock</th>
                                                                    <th>Dead Stock</th>
                                                                    <th>Comment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    count && Object.keys(count).map((sku, key) => {
                                                                        let line = count[sku]
                                                                        return (
                                                                            <tr>
                                                                                <td> { sku } </td>
                                                                                <td> {(line.on_hand !== undefined && line.on_hand !== null) ? <>{line.on_hand} {numberWithSign(line.on_hand - virtual_count[sku].on_hand)}</> : 'None'}</td>
                                                                                <td> {(line.display !== undefined && line.display !== null) ? <>{line.display} {numberWithSign(line.display - virtual_count[sku].display)}</> : 'None'}</td>
                                                                                <td> {(line.dead !== undefined && line.dead !== null) ? <>{line.dead} {numberWithSign(line.dead - virtual_count[sku].dead)}</> : 'None'}</td>
                                                                                <td> {line.comment}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            selectedApproval && selectedApproval[approvalKey] !== undefined && (
                                                <Row className="mb-2">
                                                    <Col className="sm:w-11/12">
                                                        <div className="btn-group btn-group-toggle" data-toggle="buttons" >
                                                            <label className={ selectedApproval[approvalKey]  ? "btn btn-success active" : "btn btn-outline-success" } >
                                                                <input
                                                                    type="radio"
                                                                    name="options"
                                                                    id="option1"
                                                                    onClick={() => handleChangeSelected(approvalKey, true) }
                                                                />
                                                                Accept
                                                            </label>
                                                            <label className={ !selectedApproval[approvalKey]  ? "btn btn-danger active" : "btn btn-outline-danger" } >
                                                                <input
                                                                    type="radio"
                                                                    name="options"
                                                                    id="option2"
                                                                    onClick={() => handleChangeSelected(approvalKey, false) }
                                                                />
                                                                Reject
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </CardBody>
                                </Card>
                            )
                        }) : (
                            <>
                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                                    <div className="spinner-grow text-primary m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                    </div>
                                </div>

                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default ProjectsList;
