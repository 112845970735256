import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { Col } from '@shadcn/components/ui/col'
import { Badge } from "@shadcn/components/ui/badge";
import { StatusBadge } from "../StatusBadge";
import { shippingConverter } from "../../utils/shippingConverter";

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'bg-yellow-50 text-yellow-700 border-yellow-100',
    'monogram': 'bg-grey-50 text-grey-700 border-grey-100',
    'fulfillment': 'bg-green-50 text-green-700 border-green-100',
    'EWE': 'bg-green-50 text-green-700 border-green-100',
    'QV': 'bg-green-50 text-green-700 border-green-100',
    'B360': 'bg-green-50 text-green-700 border-green-100',
    'EWESYD': 'bg-green-50 text-green-700 border-green-100',
    'UNIS': 'bg-green-50 text-green-700 border-green-100',
    'EWE-MONO': 'bg-grey-50 text-grey-700 border-grey-100',

    'AIRBOX': 'bg-green-50 text-green-700 border-green-100',
    'AIRBOX-MONO': 'bg-grey-50 text-grey-700 border-grey-100',
  }
  return conversion[pick] || 'bg-green-50 text-green-700 border-green-100'
}

export const OrderDetailsSection = ({
  order,
  refreshData,
  EditModalShipping
}) => {
  return (
    <Col className="w-full">
      <Card className="p-6 pt-4">
        <CardBody className="p-0">
          <p className="text-xl font-medium mb-4">Order Summary</p>
          
          
          <div className="flex flex-col md:flex-row justify-between mb-8 gap-4">
          
            <div className="flex-1">
              <p className="font-medium mb-2">Customer</p>
              <p className="text-lg mb-1">{order?.shipping_address?.name}</p>
              <p className="text-gray-500">{order?.customer?.email}</p>
            </div>

          
            <div className="flex-1">
              <div className="flex justify-between">
                <p className="font-medium mb-2">Shipping Address</p>
              </div>
              <p className="mb-1">{order?.shipping_address?.address1}</p>
              <p className="mb-1">{order?.shipping_address?.city} {order?.shipping_address?.province} {order?.shipping_address?.zip}</p>
              
              <p className="mb-1">{order?.shipping_address?.country}</p>
              <p>{order?.shipping_address?.phone}</p>
            </div>

            <div className="flex-0.5 flex justify-start md:justify-end items-start">
              {EditModalShipping && (
                <EditModalShipping
                  order={order}
                  refresh={refreshData}
                />
              )}
            </div>
          </div>

          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 py-4 border-t border-b">
            <div className="flex flex-col justify-center items-center text-center">
              <p className="font-medium mb-2">Fulfillment</p>
              <StatusBadge
                status={order.fulfillment_status}
                type="fulfillment"
              />
            </div>
            <div className="flex flex-col justify-center items-center text-center">
              <p className="font-medium mb-2">Pick Status</p>
              <Badge className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium " + pickToBadge(order.pick_status)} >
                {pickToName(order.pick_status)}
              </Badge>
            </div>
            <div className="flex flex-col justify-center items-center text-center">
              <p className="font-medium mb-2">Shipping</p>
              <Badge className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-50 text-green-700 border-green-100">
                {shippingConverter(order?.shipping_type)?.toUpperCase() || ''}
              </Badge>
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="font-medium mb-2">Payment Status</p>
             <StatusBadge
                status={order.financial_status}
                type="payment"
              />
            </div>
            <div className="flex flex-col justify-center items-center text-center">
              <p className="font-medium mb-2">Package Protection</p>
              <Badge className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${order.freight_protection ? 'green' : 'red'}-50 text-${order.freight_protection ? 'green' : 'red'}-700 border-${order.freight_protection ? 'green' : 'red'}-100`}>
                {order.freight_protection ? 'YES' : 'NO'}
              </Badge>
            </div>
          </div>

          {/* Tracking Section */}
          {order.fulfillment_tracking_url && (
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4 gap-2">
              <p className="text-lg font-medium">Tracking</p>
                <a 
                  href={order.fulfillment_tracking_url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center text-black font-medium"
                >
                  View Tracking
                  <svg 
                    className="w-4 h-4 ml-1" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                    />
                  </svg>
                </a>
            </div>
          )}
          {/* <div className="flex flex-col sm:flex-col justify-start items-start sm:items-start mt-4 gap-2">
            <div className="text-lg text-danger font-medium text-left">WARNING</div>
            <div className="text-sm">
              This order is picked but not fulfilled. <br/>
              If you want it to be shipped please unfulfill.
            </div>
          </div>   */}
        </CardBody>
      </Card>
    </Col>
  );
};
