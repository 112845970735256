// SelectField.js
import { Controller } from 'react-hook-form';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '@shadcn/components/ui/select';

const SelectField = ({ label, name, control, defaultValue, error, options }) => (
  <div className="select2-container mb-4">
    <label className="control-label">{label}</label>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select {...field}
        onValueChange={(value) => field.onChange(value)}
        defaultValue={defaultValue}
  >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {options.map((option, index) => (
              <SelectItem key={index} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    />
    {/* {error && <div className="invalid-feedback">{error?.message}</div>} */}
  </div>
);

export default SelectField;
