import React from 'react';

const formatDate = (date) => {
    const d = new Date(date);
    const mm = d.getMonth() + 1;
    const dd = d.getDate();
    
    return [
        (dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        d.getFullYear(),
    ].join(' / ');
};


const ORDER_PREFIXES = {
    GETJULY: '#GETJULY',
    JULYNZ: '#JULYNZ',
    JULYUK: '#JULYUK',
    JULY: '#JULY'
};

const SharedPrintPage = React.forwardRef(({ monogramRecord, type }, ref ) => {
    const getLabelId = (orderName, monogramId) => {
        for (const [key, prefix] of Object.entries(ORDER_PREFIXES)) {
            if (orderName?.includes(prefix)) {
                return monogramId.replace(prefix, '');
            }
        }
        return monogramId;
    };

    return (
        <div className="Labels" ref={ref}>
            <div className="PrintCanvas">
                <div className="PrintLine"> {monogramRecord.batchID} </div>

                <div className="PrintTable">
                    {monogramRecord?.lineItems.map((lineItem, index) => (
                        <div key={index} className="PrintTableRowFlex">
                            <div 
                                style={{fontSize: 22, padding: '8px 12px'}} 
                                className="PrintTableRowHalfBlack"
                            > 
                                {getLabelId(lineItem.orderName, lineItem.monogramID)} 
                            </div>
                            <div 
                                style={{fontSize: 22, padding: '8px 12px'}} 
                                className="PrintTableRowHalfBlack"
                            > 
                                {formatDate(lineItem.orderSent)} 
                            </div>
                            <div 
                                style={{fontSize: 22, padding: '8px 12px'}} 
                                className="PrintTableRowHalfBlack"
                            > 
                                {lineItem.sku} 
                            </div>
                            <div 
                                style={{fontSize: 22, padding: '8px 12px'}} 
                                className="PrintTableRowHalf"
                            > 
                                {lineItem.monogram_text} 
                            </div>
                        </div>
                    ))}
                </div>

                <img 
                    style={{
                        right: 'calc(32px + 20mm)', 
                        bottom: 0, 
                        position: 'absolute', 
                        width: '20%'
                    }} 
                    src='/logo.svg'
                    alt="Logo"
                />
            </div>
        </div>
    )
})

export default SharedPrintPage;
