import React from "react";
import { Redirect } from "react-router-dom";

import OrderPage from "../pages/JIFI/orders";
import OrderCreatePage from "../pages/JIFI/orders/create";
import AwaitingStock from "../pages/JIFI/orders/awaiting-stock";
import OrdersAll from "../pages/JIFI/orders/all";
import OrdersMonograms from "../pages/JIFI/orders/monograms";
import OrdersDaily from "../pages/JIFI/orders/daily";
import Products from "../pages/JIFI/products";
import Stock from "../pages/JIFI/stock";
import Approvals from "../pages/JIFI/approvals";
import ApprovalGroup from "../pages/JIFI/approval-group";
import UserProfile from "../pages/JIFI/user/profile";
import UserEdit from "../pages/JIFI/user/all";
import VariantListPage from "../pages/JIFI/Products/variant/all";
import CorpOrdersAll from "../pages/JIFI/corp-orders/all";
import CorpOrder from "../pages/JIFI/corp-orders/index";


import Return from "../pages/JIFI/returns/index";
import AllReturns from "../pages/JIFI/returns/all";

import Embroidery from "../pages/JIFI/embroidery";
import Print from "../pages/JIFI/print";
import Pull from "../pages/JIFI/pull";
import PrintTest from "../pages/JIFI/testPrint";
import PrintLabels from "../pages/JIFI/print-labels";
import ForwardPrintLabels from "../pages/JIFI/forward-print-label";
import Transfer from "../pages/JIFI/transfer";

import ForcastMonths from '../pages/JIFI/forecast/months'
import ForecastPage from '../pages/JIFI/forecast/index'

import Deliveries from "../pages/JIFI/deliveries/list";
import Delivery from "../pages/JIFI/deliveries/index";
import DeliveryBatch from "../pages/JIFI/deliveries/batch-list";


import ExternalPersBatches from "../pages/JIFI/personalisation-batch/external-pers/batches";


import ExternalPersBatchesEWE from "../pages/JIFI/personalisation-batch/external-pers-ewe/batches";


import ExternalPersBatchesAirbox from "../pages/JIFI/personalisation-batch/external-pers-airbox/batches";


import ExternalPersBatchesLaunch from "../pages/JIFI/personalisation-batch/external-pers-launch/batches";


import BookContainer from "../pages/JIFI/containers/createContainer";
import ListContainer from "../pages/JIFI/containers/listContainers";
import EditContainer from "../pages/JIFI/containers/editContainer";
import UpcomingContainer from "../pages/JIFI/containers/upcomingContainers";
import Container from "../pages/JIFI/containers/container";
import MovementPage from "../pages/JIFI/movement-history";

import AllStock from "../pages/JIFI/all-stock";
import StockWarning from "../pages/JIFI/stock-warnings";
import EizConsignment from "../pages/JIFI/eiz/all";

// Authentication related pages
import Login from "../pages/Skote/Authentication/Login";
import Logout from "../pages/Skote/Authentication/Logout";
import Register from "../pages/Skote/Authentication/Register";
import ForgetPwd from "../pages/Skote/Authentication/ForgetPassword";
import LockScreen from "../pages/Skote/AuthenticationInner/auth-lock-screen";

  // Dashboard
import Dashboard from "../pages/Skote/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

import AllProducts from "../pages/JIFI/Products/all";
import EditProducts from "../pages/JIFI/Products/edit";
import EditVariant from "../pages/JIFI/Products/variant/variant";

import AllLocations from '../pages/JIFI/locations/all'
import EditLocations from '../pages/JIFI/locations/index'

import AllErrorLogs from '../pages/JIFI/error-logs/all'
import ErrorLogDetail from '../pages/JIFI/error-logs/index'
import AllFlagType from '../pages/JIFI/flagtype/all'
import EditFlagType from '../pages/JIFI/flagtype/index'

import BulkSendTo3PL from '../pages/JIFI/bulk-send'
import ParcelsPage from "../pages/JIFI/parcels";
import CogPage from '../pages/JIFI/cog'
// import StockOverTime from "../pages/JIFI/analytics/stockOverTime";
// import ProductOverTime from "../pages/JIFI/analytics/productsOverTime";
// import MonogramPerformance from "../pages/JIFI/analytics/monogramPerformance";
import Automations from "../pages/JIFI/automations/view-reccomendations";

import FactoryPage from "../pages/JIFI/factory/index";
import FactoryList from "../pages/JIFI/factory/list";

import PurchaseOrderPage from "../pages/JIFI/purchase-orders/index";
import PurchaseOrderList from "../pages/JIFI/purchase-orders/list";
import VariantBulkEditor from "../pages/JIFI/Products/variant/variant-bulk-edit";
import TransferStockPage from "../pages/JIFI/stock/transfers";
import ReplenishStockPage from "../pages/JIFI/stock/replenish";
import StockStatus from "../pages/JIFI/demand/stock-status";
import StockStatusMonth from "../pages/JIFI/demand/stock-status-month";

import BulkSendEmbossing from "../pages/JIFI/bulk-send/embossing";
import HistoricAllocation from "../pages/JIFI/demand/historicAllocation";
import HistoricStockLevels from "../pages/JIFI/demand/historicStockLevels";
import WarehouseTransferPage from "../pages/JIFI/stock/warehouse-transfer";
import ReleasesSupplyChain from "../pages/JIFI/purchase-orders/releases";
import ReleasesSupplyChainList from "../pages/JIFI/purchase-orders/releases/list";
import BasicTable from "../pages/JIFI/purchase-orders/stockMovement";
import NotificationListPage from "../pages/JIFI/notifications";
import FulFillmentLocationPage from "../pages/JIFI/fulfillment-location";

import OrderTicketPage from "@pages/JIFI/order-ticket";
import FeedbackPage from "@pages/JIFI/order-ticket/feedback";
import WarrantyClaimPage from "@pages/JIFI/warranty-claim/warranty-claim";
import WarrantyKanbanBoard from "@pages/JIFI/warranty-claim";
import CategoryListPage from "@pages/JIFI/category/all";
import CategoryPage from "@pages/JIFI/category";
import MisPickStock from "@pages/JIFI/orders/mispick-stock";


const authProtectedRoutes = [
	{ path: "/orders/:orderID", component: OrderPage },
	{ path: "/order/create", component: OrderCreatePage },
	{ path: "/orders", component: OrdersAll },

	{ path: "/demand/stock-status", component: StockStatus },
	{ path: "/demand/stock-status-month", component: StockStatusMonth },
	{ path: "/demand/historic-stock-alocation", component: HistoricAllocation },

	{ path: "/demand/historic-stock-levels", component: HistoricStockLevels },

	{ path: "/corp-orders/:orderID", component: CorpOrder },
	{ path: "/corp-orders", component: CorpOrdersAll },

	{ path: "/returns/:returnID", component: Return },
	{ path: "/returns", component: AllReturns },

	// { path: "/external-personalisation", component: ExternalPers },
	{ path: "/external-personalisation-batches", component: ExternalPersBatches },

	// { path: "/external-personalisation-ewe", component: ExternalPersEWE },
	{ path: "/external-personalisation-batches-ewe", component: ExternalPersBatchesEWE },

	// { path: "/external-personalisation-airbox", component: ExternalPersAirbox },
	{ path: "/external-personalisation-batches-airbox", component: ExternalPersBatchesAirbox },

	// { path: "/external-personalisation-launch", component: ExternalPersLaunch },
	{ path: "/external-personalisation-batches-launch", component: ExternalPersBatchesLaunch },

	// { path: "/analytics/stock-transfer-efficency", component: StockTransferEfficency },
	// { path: "/analytics/stock-over-time", component: StockOverTime },
	// { path: "/analytics/product-over-time", component: ProductOverTime },
	// { path: "/analytics/monogram-performance", component: MonogramPerformance },

	{ path: "/stock/transfer", component: TransferStockPage },
	{ path: "/stock/replenish", component: ReplenishStockPage },
	{ path: "/stock/warehouse-transfer", component: WarehouseTransferPage },


	{ path: "/automations/view-reccomendations", component: Automations },

	{ path: "/factories/:factoryID", component: FactoryPage },
	{ path: "/factories/", component: FactoryList },

	{ path: "/purchase-order/:purchaseOrderID", component: PurchaseOrderPage },
	{ path: "/purchase-order/", component: PurchaseOrderList },

	{ path: "/order/bulk-send", component: BulkSendTo3PL },
	{ path: "/order/embossing", component: BulkSendEmbossing },
	{ path: "/awaiting-stock", component:  AwaitingStock},
	{ path: "/mispick-stock", component: MisPickStock},
	{ path: "/daily-orders", component: OrdersDaily },
	{ path: "/monograms", component: OrdersMonograms },
	{ path: "/products", component: Products },
	{ path: "/stock", component: Stock },
	{ path: "/approvals", component: Approvals },
	{ path: "/approval", component: ApprovalGroup },
	{ path: "/pull", component: Pull },
	{ path: "/create-container", component: BookContainer },
	{ path: "/parcels", component: ParcelsPage },
	{ path: "/upcoming-container", component: UpcomingContainer },

	{ path: "/containers", component: ListContainer },

	{ path: "/container/edit/:containerID", component: EditContainer },
	{ path: "/container/:containerID", component: Container },
	
	{ path: "/transfer", component: Transfer },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/users/all", component: UserEdit },
	
	{ path: "/embroidery", component: Embroidery},
	{ path: "/print", component: Print},
	{ path: "/movement-history", component: MovementPage},

	{ path: "/deliveries", component: Deliveries},
	{ path: "/delivery/:deliveryId", component: Delivery},
	{ path: "/deliveries-batch", component: DeliveryBatch},

	{ path: "/location/all", component: AllLocations },
	{ path: "/error-logs/all", component: AllErrorLogs },
	{ path: "/error-logs/:id", component: ErrorLogDetail },
	{ path: "/location/edit/:locationID", component: EditLocations },
	{ path: "/location/new", component: EditLocations },

	{ path: "/flagtype/all", component: AllFlagType },
	{ path: "/flagtype/edit/:flagTypeID", component: EditFlagType },
	{ path: "/flagtype/new", component: EditFlagType },

	
	{ path: "/variant-bulk-editor", component: VariantBulkEditor },
	{ path: "/variant/all", component: VariantListPage },
	{ path: "/product/all", component: AllProducts },

	{ path: "/product/edit/:productID/variant/edit/:variantID", component: EditVariant },
	{ path: "/product/edit/:productID/variant/new", component: EditVariant },
	{ path: "/product/edit/:productID", component: EditProducts },
	{ path: "/product/new", component: EditProducts },

	{ path: "/forecast/months", component: ForcastMonths},
	{ path: "/forecast/", component: ForecastPage},

	{ path: "/po-overview/", component: BasicTable},

	{ path: "/releases/", component: ReleasesSupplyChainList},
	{ path: "/releases/:id", component: ReleasesSupplyChain},


	{ path: "/all-stock", component: AllStock},
	{ path: "/stock-warning", component: StockWarning},

	{ path: "/forward-print-labels", component:ForwardPrintLabels },
	{ path: "/cog", component: CogPage },

	{ path: "/eiz", component: EizConsignment},

	{path: "/notifications", component: NotificationListPage},

	{path: "/fulfillment/locations", component: FulFillmentLocationPage },

	{path: "/order-tickets", component:  OrderTicketPage },

	{path: "/fulfillment/feedback", component:  FeedbackPage },

	{path: "/warranty-claim", component:  WarrantyClaimPage },

	{path: "/warranty-board", component:  WarrantyKanbanBoard },

	{path: "/category/all", component: CategoryListPage},

	{path: "/category/new", component: CategoryPage},
	{path: "/category/edit/:categoryID", component: CategoryPage},
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path : "/auth-lock-screen", component: LockScreen },

	{ path: "/maintenance", component: PagesMaintenance },
	{ path: "/comingsoon", component: PagesComingsoon },
	{ path: "/404", component: Pages404 },
	{ path: "/500", component: Pages500 },

	{ path: "/test-print", component: PrintTest },
	{ path: "/print-labels", component:PrintLabels }
	
];

export { authProtectedRoutes, publicRoutes };
