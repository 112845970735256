import { useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import useQuery from '../../../../helpers/JIFI/useQuery';
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell, TableSkeleton } from '@shadcn/components/ui/table';
import { Input } from "@shadcn/components/ui/input";
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '@shadcn/components/ui/select';

import { TablePagination } from "@components/supply-chain/TablePagination";
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import SupplyChainNav from "@components/supply-chain/nav";
import { useQueryParams } from '@helpers/JIFI/useQueryParams';

const columnHelper = createColumnHelper();

const VariantListPage = () => {
  const product = useQueryParams().get('productID');
  const [refresh, setRefresh] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [productID, setProductID] = useState(product || '');
  const [productData, productLoading] = useQuery({ 
    refresh, 
    url: `/product/options`, 
    
  });

  const [data, loading] = useQuery({ 
    refresh, 
    url: `/product/findAllVariantsFull`, 
    queryData: { 
      perPage, 
      page, 
      searchTerm ,productID
    } 
  });

  const productColumns = useMemo(
    () => [
      columnHelper.accessor('image', {
        header: () => 'Image',
        cell: ({ row }) => (
          <div className="w-12 h-12">
            {row.original.image ? (
              <img 
                src={row.original.image} 
                alt={row.original.name}
                className="w-full h-full object-cover rounded-md"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                <span className="text-gray-400 text-xs">No image</span>
              </div>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('product', {
        header: () => 'Product',
        cell: ({ row }) => row.original.product?.name ?? ""
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: ({ row }) => (
          <Link
            className="text-body font-weight-bold px-2 cursor-pointer"
            to={`/product${row.original.product ? `/edit/${row.original.product._id}` : ''}/variant/edit/${row.original._id}`}
          >
            {row.original.name}
          </Link>
        ),
      }),
      columnHelper.accessor('SKU', {
        header: () => 'SKU',
        cell: ({ row }) => <div className='px-2'>{row.original.sku}</div>,
      }),
     
      columnHelper.accessor('isArchived', {
        header: () => <div >Status</div>,
        cell: ({ row }) => (
          <div>
            <span className={`px-2 py-1 rounded-full text-xs ${
              row.original.isArchived 
                ? 'bg-red-100 text-red-800' 
                : 'bg-green-100 text-green-800'
            }`}>
              {row.original.isArchived ? 'Archived' : 'Active'}
            </span>
          </div>
        ),
      }),
    ],
    []
  );

  const productTable = useReactTable({
    data: data?.results?.filter(variant => variant.sku !== '') || [],
    columns: productColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <SupplyChainNav menuType="products">
      <div>
        <div>
          <Card className="mb-10">        
            <CardBody className="pt-4">
              <Breadcrumbs title="Products" breadcrumbItem="All Variants" />
              <Row className='gap-6'>
                <Col className="w-3/12 mb-4">
                  <div className="select2-container">
                    <label className="control-label">FILTER</label>
                    <div className="search-box mb-2 d-block">
                      <Input
                        type="text"
                        className="form-control h-[32px] py-1 rounded-md h-9"
                        placeholder="Search..."
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPage(1);
                            setSearchTerm(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col className="w-3/12 mb-4">
                  <div className="select2-container">
                    <label className="control-label">Product</label>
                    <Select value={productID} onValueChange={(value) => {setProductID(value); setPage(1);}}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a product" />
                        <SelectContent>
                          {productData?.map(product => (
                            <SelectItem key={product._id} value={product._id}>{product.name}</SelectItem>
                          ))}
                        </SelectContent>
                      </SelectTrigger>
                    </Select>
                  </div>  
                </Col>

                
              </Row>

              <div className="table-responsive">
                <Table>
                  <TableHeader>
                    {productTable.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHead key={header.id} className="text-left">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  {loading !== 'success' ? (
                    <TableSkeleton perPage={perPage} columns={productColumns.length} />
                  ) : (
                    <TableBody>
                      {productTable?.getRowModel().rows?.length ? (
                      productTable?.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={productColumns.length} className="text-center">
                          No results.
                        </TableCell>
                      </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                count={data?.totalCount}
                className="my-4"
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </SupplyChainNav>
  );
}

export default VariantListPage;
