import React, { useState } from 'react';
import {
  Card,
  CardContent as CardBody,
  CardHeader,
} from '@shadcn/components/ui/card';


import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@shadcn/components/ui/dropdown-menu';


import '../datatables.scss';

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from 'react-router-dom';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';

import useProductData from '../orders/useProductData';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import CommentHostory from '../../Skote/Dashboard/CommentHistory.js';
import { Col } from '@shadcn/components/ui/col';
import { Badge } from '@shadcn/components/ui/badge';
const StatusToBadge = ({ status }) => {
  const conversion = {
    draft: 'secondary',
    packing: 'secondary',
    'in-transit': 'warning',
    'at-port': 'warning',
    ready: 'success',
    completed: 'success',
    cancelled: 'succedangerss',
  };
  return (
    <Badge
      className={'font-size-12 badge-soft-' + conversion[status]}
      color={conversion[status]}
      pill
    >
      <i className={'fab ' + ' font-size-12 badge-soft-primary'}></i>{' '}
      {status?.replace('-', ' ')?.toUpperCase()}
    </Badge>
  );
};

const ASNToBadge = ({ ASNSent }) => {
  const color = ASNSent === false ? 'warning' : 'success';
  const text = ASNSent === false ? 'ASN NOT SENT' : 'ASN SENT';

  return (
    <Badge className={'font-size-12 badge-soft-' + color} color={color} pill>
      <i className={'fab ' + ' font-size-12 badge-soft-primary'}></i>{' '}
      {text.toUpperCase()}
    </Badge>
  );
};

const PullPage = ({ match: { params }, google }) => {
  const { containerID } = params;

  const history = useHistory();
  const [refresh, setRefresh] = useState(true);
  const [stockPull, setStockPull] = useState([]);

  const [replenishHistory, setReplenishHistory] = useQuery({
    url: `/inventory/fetchContainer/${containerID}`,
    refresh,
  });
  const container = replenishHistory;
  const getVariant = useProductData();
  const volumes = {};

  const handelSendASN = () => {
    superagent
      .post(`${backendUrl}/inventory/convertContainerToASN/${containerID}`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          history.go(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SupplyChainNav
      commentComponent={
        <CommentHostory
          title="Comments"
          initCommentType={'Container'}
          initOrderName={container?.name}
        />
      }
    >
      <div>
        <Col className="w-full">
          <Card>
            {container && (
              <>
                <CardHeader>
                  <h3 className="text-[24px] font-medium flex gap-2 flex-wrap items-center">
                    Invoice Ref: {container.name}
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          onClick={() => {
                            history.push(`/container/edit/${containerID}`);
                          }}
                        >
                          Edit
                        </DropdownMenuItem>


                        <DropdownMenuItem
                          onClick={handelSendASN}
                        >
                          Send ASN to {container.landingLocation.name}{' '}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    {/* <UncontrolledDropdown
                      style={{ display: 'inline-block', padding: '0 16px' }}
                    >
                      <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {}}
                          href={`/container/edit/${containerID}`}
                        >
                          {' '}
                          Edit{' '}
                        </DropdownItem>
                        {container.ASNSent === false && (
                          <DropdownItem onClick={handelSendASN}>
                            {' '}
                            Send ASN to {container.landingLocation.name}{' '}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <StatusToBadge status={container.status} />
                    <ASNToBadge ASNSent={container.ASNSent} />
                  </h3>
                  <h6 className="font-medium">Container Ref: {container.ref}</h6>
                  <h6 className='font-medium'>
                    Arrival Date:{' '}
                    {new Date(container.arrivalDate).toDateString()}{' '}
                  </h6>
                  <h6 className='font-medium'>
                    Port Date: {new Date(container.portDate).toDateString()}{' '}
                  </h6>
                </CardHeader>
                <CardBody className="mb-[60px]">
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th></th>
                          <th>SKU</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Cost Per Item</th>
                          <th>Volumes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {container.containerLines.map((containerLine) => {
                          volumes[containerLine.variant.sku] = {
                            size:
                              Math.round(
                                (containerLine?.variant?.product?.width *
                                  containerLine?.variant?.product?.length *
                                  containerLine?.variant?.product?.depth) /
                                  1000
                              ) / 1000,
                            quantity: containerLine.quantity,
                          };

                          return (
                            <tr className="py-5">
                              <td className="text-right">
                                <div
                                  style={{
                                    width: '128px',
                                    height: '128px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={
                                      getVariant(containerLine.variant.sku)
                                        ?.image
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </td>
                              <td className="">{containerLine.variant.sku}</td>
                              <td className="">{containerLine.variant.name}</td>
                              <td className="">{containerLine.quantity}</td>
                              <td className="">
                                {containerLine.costPerItem}
                              </td>
                              <td>
                                {Math.round(
                                  (containerLine?.variant?.product?.width *
                                    containerLine?.variant?.product?.length *
                                    containerLine?.variant?.product?.depth) /
                                    1000
                                ) / 1000}
                              </td>
                            </tr>
                          );
                        })}

                        <tr className="py-5">
                          <td className="text-right"> TOTAL </td>
                          <td className=""></td>
                          <td className=""></td>
                          <td className="">
                            {container.containerLines.reduce(
                              (sum, containerLine) =>
                                sum + containerLine.quantity,
                              0
                            )}
                          </td>
                          <td className="">
                            {Math.round(
                              container.containerLines.reduce(
                                (sum, containerLine) =>
                                  sum +
                                containerLine.costPerItem *
                                containerLine.quantity,
                                0
                              ) * 100
                            ) / 100}
                          </td>
                          <td>
                            { Math.round(Object.values(volumes)?.reduce(
                              (sum, volume) =>
                                sum + volume.size * volume.quantity
                                , 0
                            )*1000) / 1000
                          }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                
                </CardBody>
              </>
            )}
          </Card>
        </Col>
      </div>
    </SupplyChainNav>
  );
};

export default PullPage;
