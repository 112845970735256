import { useMemo, useState } from 'react'
import useQuery from '../../../helpers/JIFI/useQuery';
import { Col } from '@shadcn/components/ui/col';
import { Input } from '@shadcn/components/ui/input';
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell, TableSkeleton } from '@shadcn/components/ui/table';
import { TablePagination } from '@components/supply-chain/TablePagination';
import Breadcrumbs from '@components/Common/Breadcrumb';
import { Row } from '@shadcn/components/ui/row';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper();

const ErrorLogListPage = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const [errorLogs, errorLogState] = useQuery({
    url: `/error-logs/list`,
    queryData: {
      page,
      perPage,
      searchTerm,
    },
  });

  const errorLogColumns = useMemo(
    () => [
      columnHelper.accessor('functionName', {
        header: () => 'Function Name',
        cell: ({ row }) => (
          <Link 
            to={`/error-logs/${row.original._id}`}
            className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
          >
            {row.original.functionName}
          </Link>
        ),
      }),
      columnHelper.accessor('errorName', {
        header: () => 'Error Name',
        cell: ({ row }) => (
          <div className="font-medium text-gray-900">
            {row.original.errorName}
          </div>
        ),
      }),
      columnHelper.accessor('errorMessage', {
        header: () => 'Error Message',
        cell: ({ row }) => (
          <div className="text-sm text-gray-500 max-w-md truncate">
            {row.original.errorMessage}
          </div>
        ),
      }),
      columnHelper.accessor('severity', {
        header: () => 'Severity',
        cell: ({ row }) => {
          const severity = row.original.severity;
          const severityColors = {
            critical: 'bg-red-100 text-red-800',
            high: 'bg-red-100 text-red-800',
            medium: 'bg-yellow-100 text-yellow-800',
            low: 'bg-green-100 text-green-800'
          };
          return (
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${severityColors[severity.toLowerCase()] || 'bg-gray-100 text-gray-800'}`}>
              {severity}
            </span>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: () => 'Created At',
        cell: ({ row }) => (
          <div className="text-sm text-gray-500">
            {new Date(row.original.createdAt).toLocaleString()}
          </div>
        ),
      }),
    ],
    []
  );

  const errorLogTable = useReactTable({
    data: errorLogs?.data || [],
    columns: errorLogColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="page-content">
        '
        <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-6">
          <Breadcrumbs title="System" breadcrumbItem="Error Logs" />
          <Col className="w-full">
            <Card className="mb-10 shadow-lg">
              <CardBody className="pt-4">
                <Row className='justify-between'>
                  <Col className="w-3/12 mb-4">
                    <div className="select2-container">
                      <label className="control-label font-medium mb-2">FILTER</label>
                      <div className="search-box mb-2 d-block">
                        <div className="position-relative">
                          <Input
                            type="text" 
                            className="form-control h-[32px] py-1 rounded-md h-9 border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Search..."
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.keyCode === 13) {
                                setPage(1);
                                setSearchTerm(e.target.value)
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* Table section */}
                <div className="table-responsive rounded-lg border border-gray-200">
                  <Table className="table-auto w-full">
                    <TableHeader>
                      {errorLogTable.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} className="bg-gray-50">
                          {headerGroup.headers.map((header) => (
                            <TableHead key={header.id} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {errorLogState === 'success' ? (
                      <TableBody>
                        {errorLogTable?.getRowModel().rows?.length > 0 ? (
                          errorLogTable?.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              className="hover:bg-gray-50 transition-colors"
                              data-state={row.getIsSelected() && 'selected'}
                            >
                              {row?.getVisibleCells()?.map((cell) => (
                                <TableCell key={cell.id} className="px-6 py-4 whitespace-nowrap">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} className="text-center py-8 text-gray-500">
                              No results found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableSkeleton
                        columns={errorLogColumns.length}
                        perPage={perPage}
                      />
                    )}
                  </Table>
                </div>
                <TablePagination
                  count={errorLogs?.total}
                  className="my-4"
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}

export default ErrorLogListPage
