import React, { useState, useEffect, useRef } from "react";

import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { Row } from "@shadcn/components/ui/row";
import { Col } from "@shadcn/components/ui/col";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "@pages/JIFI/datatables.scss";
import useQuery from "../../../../helpers/JIFI/useQuery";
import moment from "moment";
import superagent from "superagent";

import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import { backendUrl } from "../../../../helpers/consts";

import SharedBatchModal from "../component/BatchModal";
import Table from "../component/Table";

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

function downloadPdfs(lineItems, zipFilename) {
  const urls = lineItems.map((lineItem) => {
    if (!lineItem.pet) {
      return {
        url: `https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ""}-undefined${
          lineItem.sku.startsWith("IPC")
            ? `-IPC-${lineItem.monogram_position}`
            : ""
        }${
          lineItem.sku.startsWith("BOT") &&
          lineItem.monogram_position === "Top"
            ? "-Lid"
            : ""
        }.pdf?avoidTheCaches=1`}`,
        filename: `${moment(new Date(lineItem.orderSent)).format(
          "YYYY-MM-DD"
        )}-${lineItem.monogramID}.pdf`,
        pet: false,
      };
    } else {
      const petArray = JSON.parse(atob(lineItem.monogram_pets));

      return {
        petData: lineItem.monogram_airtable_line.map((val, key) => {
          return {
            id: val,
            filename: `${moment(new Date(lineItem.orderSent)).format(
              "YYYY-MM-DD"
            )}-${lineItem.monogramID}-pet#${key}-${petArray[key].name}`,
          };
        }),
        pet: true,
      };
    }
  });

  const zip = new JSZip();
  let count = 0;

  urls.forEach(async function (url) {
    if (url.pet === false) {
      const filename = url.filename;
      try {
        const file = await JSZipUtils.getBinaryContent(url.url);
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      let images = [];
      try {
        for (let pet of url.petData) {
          const res = (
            await superagent
              .post(`${backendUrl}/orders/fetchPetAirtable`)
              .send({
                airtableId: pet.id,
              })
              .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
              .set("accept", "json")
          ).body.fields;
          const completedIllustration = res["Completed illustration"];

          images = [
            ...completedIllustration.map((val, comKey) => {
              let filetype = val.filename.split(".")[1];
              let path = val.url;

              return {
                url: path,
                filename: `${pet.filename}-${comKey}.${filetype}`,
              };
            }),
          ];

          await sleep(500);
        }
        images.forEach(async function (url) {
          const filename = url.filename;
          try {
            const file = await JSZipUtils.getBinaryContent(url.url);
            zip.file(filename, file, { binary: true });
            if (count === urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);
              });
            }
          } catch (err) {
            console.log(err);
          }
        });
      } catch (err) {
        console.log(err);
      }
      count++;
    }
  });
}

const DeliveriesPage = () => {
  const url = "/orders/fetchEWEPersLineItems";

  const [batchModal, setBatchModal] = useState();
  const toggleBatchModal = () => setBatchModal((prev) => !prev);

  const [refresh, setRefresh] = useState(true);
  const refreshData = () => setRefresh((prev) => !prev);

  const [lineItems, state] = useQuery({ refresh, url });

  const [selected, setSelected] = useState([]);
  useEffect(() => {
    lineItems
      ? setSelected(lineItems.map(() => false))
      : setSelected([]);
  }, [lineItems]);
  const selectedIds =
    lineItems && lineItems.filter((val, key) => selected[key] === true);

  const handleGenerateBatch = () => {
    if (selectedIds && selectedIds.length > 0 && selectedIds.length <= 16) {
      toggleBatchModal();
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col className="sm:w-11/12">
            <Breadcrumbs
              title="Monograms"
              breadcrumbItem="Personalisation List"
            />
          </Col>
          <Col className="sm:w-1/12">
            <Link className="text-danger" to="/external-personalisation-batches-ewe">
              Batches
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="pt-6">
              <CardBody>
               
                  
                  <Table loading={state !== 'success'} lineItems={lineItems} selected={selected} setSelected={setSelected} handleGenerateBatch={handleGenerateBatch} selectedIds={selectedIds} />
               
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <SharedBatchModal
        isVisible={batchModal}
        refresh={refreshData}
        togglemodal={toggleBatchModal}
        selectedIds={selectedIds}
        downloadPdfs={downloadPdfs}
        type='ewe'
        location="EWE"
      />
   
    </>
  );
};



export default DeliveriesPage;
