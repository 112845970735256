import { create } from 'zustand'

const useProductStore = create((set) => ({
  images: [],
  setImages: (images) => set({ images }),
  selectedImage: null,
  setSelectedImage: (image) => set({ selectedImage: image }),
  factoryOptions: [],
  setFactories: (factoryOptions) => set({ factoryOptions }),
  categories: [],
  setCategories: (categories) => set({ categories }),
  addCategory: (category) => set((state) => ({
    categories: [...state.categories, category]
  })),
  addFactory: (factory) => set((state) => ({
    factoryOptions: [...state.factoryOptions, factory]
  })),
  productName: '',
  setProductName: (name) => set({ productName: name }),
  selectedCategory: null,
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  selectedFactory: null,
  setSelectedFactory: (factory) => set({ selectedFactory: factory }),
  reset: () => set({
    productName: '',
    selectedCategory: null,
    selectedFactory: null,
  })
  
}))

export default useProductStore
