import { KanbanBoard } from '@components/Kanban/KanbanBoard';
import { Dialog, DialogContent } from '@shadcn/components/ui/dialog';
import { useTaskCard } from '@zustand/useTaskCard';
import moment from 'moment';



const OrderTicketPage = () => {
 
  const selected = useTaskCard((state) => state.selected);
  const card = useTaskCard((state) => state.task);
  const selectedCard = useTaskCard((state) => state.selectedCard);
  
  return (
    <div className="page-content">
      <div className="container mx-auto px-4 lg:max-w-[80%]">
        <KanbanBoard/>
        <Dialog open={selected} onOpenChange={() => selectedCard({})}>
          <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw] ">
            <div>
              <p className="font-bold">Due date: </p>

              {moment(card?.dayNeededBy).format('MMMM Do, h:mm:ss a')}
            </div>
            <div className="font-bold">Fields:</div>
            <div className="border-2 flex flex-col">
              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">
                  Order Name
                </p>
                <p className="">{card?.order?.name ?? ''}</p>
              </div>

              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">Store</p>
                <p className="">{card?.store?.display_name ?? ''}</p>
              </div>
              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">
                  Department
                </p>
                <p className="">{card?.department ?? ''}</p>
              </div>
              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">
                  Category
                </p>
                <p className="">{card?.category?.name ?? ''}</p>
              </div>
              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">
                  Customer name
                </p>
                <p className="">{card?.customerName ?? ''}</p>
              </div>
              <div className="border-b-2 flex flex-row gap-4 px-4 !pl-1 leading-6">
                <p className="border-r-2 w-[150px] pr-2 font-medium">
                  Pickup location
                </p>
                <p className="">{card?.pickupLocation ?? ''}</p>
              </div>
            </div>
            <div>
              <b>Description:</b>
              <p>{card?.actionDescription ?? ''}</p>
            </div>
            <b>Attachment:</b>
            <div className="flex flex-col">
              <img
                className="max-h-[300px] object-contain"
                src={card?.attachments?.[0] ?? ''}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default OrderTicketPage;
