import { Button } from "@shadcn/components/ui/button";
import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";


export const LabelsSection = ({
  order,
  handlePrint,
  handlePrintLabel,
  printDeliveryLabels,
  nonhandlePrintLabel,
}) => {
  return (
    <Card>
      <p className="text-lg font-medium mb-4 pt-4 px-6">Labels</p>
      <CardBody className="grid grid-cols-1 sm:grid-cols-3 gap-3">
        
        <Button
          variant="secondary"
          className="flex items-center justify-center gap-2 py-3 px-4 bg-black text-white hover:bg-gray-800 transition-colors"
          onClick={handlePrint}
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <path d="M8 6V4H16V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 18H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 14H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 10H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Packing Slip
        </Button>

        
        <Button
          variant="secondary" 
          className="flex items-center justify-center gap-2 py-3 px-4 bg-black text-white hover:bg-gray-800 transition-colors"
          onClick={nonhandlePrintLabel}
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <path d="M6 9V2H18V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 18H4C3.44772 18 3 17.5523 3 17V10C3 9.44772 3.44772 9 4 9H20C20.5523 9 21 9.44772 21 10V17C21 17.5523 20.5523 18 20 18H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 14H18V22H6V14Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Print Labels
        </Button>

        {/* Delivery Label */}
        <Button
          variant="secondary"
          className="flex items-center justify-center gap-2 py-3 px-4 bg-black text-white hover:bg-gray-800 transition-colors"
          onClick={printDeliveryLabels}
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 2V9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Delivery Label
        </Button>

        
        {order.contains_monogram && (
          <Button
            variant="secondary"
            className="flex items-center justify-center gap-2 py-3 px-4 bg-black text-white hover:bg-gray-800 transition-colors"
            onClick={handlePrintLabel}
          >
            Print monogram labels
          </Button>
        )}

        {order.fulfillment_status === 'fulfilled' && order.order_source === 'tmall' && (
          <a
            target="_blank"
            href={order.line_items[0].shipping_label_url}
            rel="noreferrer"
            className="inline-block"
          >
            <Button
              variant="secondary"
              className="flex items-center justify-center gap-2 py-3 px-4 bg-black text-white hover:bg-gray-800 transition-colors w-full"
            >
              TMALL LABEL
            </Button>
          </a>
        )}
      </CardBody>
    </Card>
  );
};
