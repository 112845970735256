import React, { useEffect, useState } from "react";
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import { Card } from '@shadcn/components/ui/card'
import { Col } from '@shadcn/components/ui/col'
import { Row } from '@shadcn/components/ui/row'
import * as moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import useQuery from "../../../helpers/JIFI/useQuery";
import {backendUrl} from '../../../helpers/consts.js'
import superagent from 'superagent'
import Select from '@shadcn/components/ui/react-select';
import { Button } from "@shadcn/components/ui/button";
import { Input } from "@shadcn/components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@shadcn/components/ui/dialog";

const abstractPost = (sendData, url, history, callback) => {
  superagent.post(`${backendUrl}/${url}`)
    .send(sendData)
    .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
    .set('accept', 'json')
    .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
            errorStatusCode: code
        });
    })
    .then(response => {
      const code = response.status
      if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
      } else {
        callback(response.body)
      }
    }).catch(err => {
      console.log(err)
    });
}

const abstractGet = (url, callback) => {
  superagent.get(`${backendUrl}/${url}`)
    .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
    .set('accept', 'json')
    .then(response => {
      const code = response.status
      if (code > 400) {
      } else {
        callback(response.body)
      }
    }).catch(err => {
      console.log(err)
    });
}


const MonthsPage = () => {
  const history = useHistory()
  const [r, setR] = useState(false)
  const refresh = () => setR(prev => !prev)

  const [selectedLocation, setSelectedLocation] = useState({ label: 'AU', value: 'AU' })

  const [monthID, setMonthID] = useState()

  const [createdMonths, createdMonthsState] = useQuery({
    url: '/forecast/fetchAllMonths',
    refresh: r,
    queryData: {location: selectedLocation.value}
  })
  
  const addMonthFuture = () => abstractPost({location: selectedLocation.value}, 'forecast/addMonthFuture', history, refresh)
  const addMonthPast = () => abstractPost({location: selectedLocation.value}, 'forecast/addMonthPast', history, refresh)
  const addMonth = () => abstractPost({location: selectedLocation.value}, 'forecast/addMonth', history, refresh)

  return (
    <div>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Forecast" breadcrumbItem="Forecast" />
          <Row>
            <Col xs="4" className='sm:w-[30%]'>
              <div className="mb-4">
                <label className="control-label">Location</label>
                <Select value={selectedLocation} onChange={setSelectedLocation} placeholder="AU" title="Pickup Location" options={ [{ label: 'AU', value: 'AU' }, { label: 'US', value: 'US' }, { label: 'UK', value: 'UK' }]} />
              </div>
            </Col>
          </Row>
            {
              (createdMonthsState === 'success' && createdMonthsState !== undefined) ? (
                <Row className='flex-wrap gap-6 mb-6'>
                  {
                    createdMonths.length === 0 ? (
                      <Col className='sm:w-[22%]' onClick={addMonth}>
                        <Card style={{padding: '32px 8px', textAlign: 'center', fontWeight: 600, cursor: 'pointer'}}>
                          + Add Month
                        </Card>
                      </Col>
                    ) : (
                      <>
                        <Col className='sm:w-[22%]' onClick={addMonthPast}>
                          <Card style={{padding: '32px 8px', textAlign: 'center', fontWeight: 600, cursor: 'pointer'}}>
                            + Add Past Month
                          </Card>
                        </Col>
                        {
                          createdMonths && createdMonths.map(month => {
                            return <Col className='sm:w-[22%]'>
                              <Card style={{padding: '32px 8px', textAlign: 'center'}} onClick={() => setMonthID(month._id)}>
                                <div>{moment(month.startDate).format('MMMM YYYY')}</div>
                                <br/>
                                <div>{month.type}</div>
                              </Card>
                            </Col>
                          })
                        }
                        <Col className='sm:w-[22%]'onClick={addMonthFuture}>
                          <Card style={{padding: '32px 8px', textAlign: 'center', fontWeight: 600, cursor: 'pointer'}}>
                            + Add Future Month
                          </Card>
                        </Col>
                      </>
                    )
                  }
                </Row>
              ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            }
        </div>
      </div>
      <EditMonthModal monthID={monthID} setMonthID={setMonthID} refresh={refresh} />
    </div>
  )
}


const EditMonthModal = ({monthID, setMonthID, refresh}) => {
  const [loading, setLoading] = useState(false)
  const [monthData, setMonthData] = useState()
  const history = useHistory()

  const [revEst, setRevEst] = useState()
  const onRevEstChange = (e) => {
    setRevEst(e.target.value)
  }

  const [revReal, setRevReal] = useState()
  const onRevRealChange = (e) => {
    setRevReal(e.target.value)
  }

  let modalOpen = monthID != null
  
  useEffect(() => {
    if (monthID != null) {
      setLoading(true)
      abstractGet(`forecast/month/${monthID}`, (data) => {
        setMonthData(data)
        setRevReal(data.realRevenue || 0)
        setRevEst(data.estimatedRevenue || 0)
        setLoading(false)
      })
    } else {
      setMonthData()
    }
  }, [monthID])

  const toggleCancelOpen = () => {
    setMonthID(null)
  }

  const handleSave = () => {
    const sendData = monthData.type === 'Previous' ? {realRevenue: revReal} : {estimatedRevenue: revEst}

    setLoading(true)
    abstractPost(sendData, `forecast/month/${monthID}`, history, (data) => {
      setMonthID(null)
      setLoading(false)
    })

  }

  return (
    <Dialog open={modalOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" onOpenChange={toggleCancelOpen}>
        {
          loading ? (
            
              <DialogContent>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </DialogContent>
            
          ) : monthData && (
            <DialogContent>
              <DialogHeader>
                <h3>{moment(monthData.startDate).format('MMMM YYYY')} - {monthData.split}</h3>
              </DialogHeader>
              <div>
                {
                  monthData.type === 'Previous' ? (
                    <div className="form-group row">
                      <label htmlFor="est-rev" className="col-md-2 col-form-label"> Actual Revenue </label>
                      <div className="col-md-10">
                        <Input id="est-rev" type="number" value={revReal} onChange={onRevRealChange}/>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group row">
                      <label htmlFor="est-rev" className="col-md-2 col-form-label"> Estimated Revenue </label>
                      <div className="col-md-10">
                        <Input id="est-rev" type="number" value={revEst} onChange={onRevEstChange}/>
                      </div>
                    </div>
                  )
                }
                
              </div>
              <DialogFooter>
                <Button onClick={handleSave} color="success" className="btn-lg btn-rounded">
                  Save
                </Button>
              </DialogFooter>
            </DialogContent>
          )
          
        }

    </Dialog>
  )
}



export default MonthsPage
