import React, { useState, useEffect } from 'react';
import { Button } from '@shadcn/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/components/ui/select';
import { Input } from '@shadcn/components/ui/input';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import * as moment from 'moment';
const DAYS_OF_WEEK = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' }, 
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' }
];

const TopupSchedule = ({ schedule, onChange }) => {
  const [type, setType] = useState(schedule?.type || 'specific_date');
  
  useEffect(() => {
    if (schedule?.type) {
      setType(schedule.type);
    }
  }, [schedule]);

  const handleTypeChange = (newType) => {
    setType(newType);
    
    let newSchedule = {
      type: newType,
      isActive: true
    };
    console.log('newSchedule', newSchedule)
    onChange(newSchedule);
  };

  const getType = (type) => {
    switch(type) {
      case 'specificDate':
        return 'specific_date'
      case 'dayOfMonth':
        return 'monthly'
      case 'daysOfWeek':
        return 'weekly'
    }
    return undefined
  }

  const handleChange = (field, value) => {
    
    const type = getType(field)
    console.log('inside type', type)
    onChange({
      ...(type ? {type: type} : {}),
      ...schedule,
      [field]: value 
    });
  };



  return (
    <div className="space-y-4">
      <div>
        <label className="block mt-4 mb-4">Schedule Type</label>
        <Select value={type} defaultValue={'specific_date'} onValueChange={handleTypeChange}>
          <SelectTrigger>
            <SelectValue>
              {type === 'specific_date' ? 'Specific Date' : 
               type === 'monthly' ? 'Monthly' : 
               type === 'weekly' ? 'Weekly' : type}
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="z-[1600]">
            <SelectItem value="specific_date">Specific Date</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {type === 'specific_date' && (
        <div>
          <label className="block mb-2">Date</label>
          <Input
            type="date"
            value={schedule?.specificDate ? moment(new Date(schedule.specificDate)).format('YYYY-MM-DD') : ''}
            onChange={(e) => {
       
              handleChange('specificDate', new Date(e.target.value))
              
            }}  
          />
        </div>
      )}

      {type === 'monthly' && (
        <div>
          <label className="block mb-2">Day of Month</label>
          <Input
            type="number"
            min={1}
            max={31}
            value={schedule?.dayOfMonth || 1}
            onChange={(e) => handleChange('dayOfMonth', parseInt(e.target.value))}
          />
        </div>
      )}

      {type === 'weekly' && (
        <div>
          <label className="block mb-2">Days of Week</label>
          <div className="space-y-2">
            {DAYS_OF_WEEK.map(day => (
              <div key={day.value} className="flex items-center space-x-2">
                <Checkbox
                  id={day.value}
                  checked={(schedule?.daysOfWeek || []).includes(day.value)}
                  onCheckedChange={(checked) => {
                    const currentDays = schedule?.daysOfWeek || [];
                    const newDays = checked 
                      ? [...currentDays, day.value]
                      : currentDays.filter(d => d !== day.value);
                    handleChange('daysOfWeek', newDays);
                  }}
                />
                <label htmlFor={day.value}>{day.label}</label>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex items-center space-x-2">
        <Checkbox
          id="isActive"
          checked={schedule?.isActive}
          onCheckedChange={(checked) => handleChange('isActive', checked)}
        />
        <label htmlFor="isActive">Active</label>
      </div>
    </div>
  );
};

export default TopupSchedule;
