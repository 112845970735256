
import create from 'zustand'

const useShopifyLocationsStore = create((set) => ({
  locations: {},

  
  setLocations: (locations) => {
    set({ locations })
  },

  
  clearLocations: () => {
    set({ locations: {} })
  }
}))

export default useShopifyLocationsStore
