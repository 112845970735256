
import  { useState } from 'react';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Button } from '@shadcn/components/ui/button';
import useShopifyLocationsStore from '@zustand/useShopifyLocationsStore';



const ShopifyStep = ({
  location,
  history,
  shopifyLinks,
  setShopifyLinks,
  address,
  onBack
}) => {
  const { locations: shopifyLocations, setLocations: setShopifyLocations } = useShopifyLocationsStore()
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [showLocationsList, setShowLocationsList] = useState(false);
  const [syncType, setSyncType] = useState('NONE');

  // Xử lý khi chọn location
  const handleLocationSelect = (locale, locationId) => {
    setSelectedLocation({
      locale: locale,
      id: locationId
    });
    // Hiển thị dialog chọn sync type 
    setShowLocationsList(false);
  };

  // Xử lý khi chọn sync type
  const handleSyncTypeSelect = (type) => {
    setSyncType(type);
    // Tạo link mới
    const newLink = {
      location_id: selectedLocation.id,
      name: location?.name,
      store: selectedLocation.locale,
      sync_type: type
    };
    setShopifyLinks(prev => [...prev, newLink]);
    // Reset states
    setSelectedLocation(undefined);
    setSyncType('NONE');
  };

  const handleCreateLocation = (locale) => {
    // Xử lý tạo location mới
    console.log(`Creating location for ${locale}`);
  };

  return (
    <div className="p-2 md:p-4">
      <h4 className="text-lg md:text-xl font-medium mb-4 md:mb-6">Shopify</h4>

      <div className="mb-6 md:mb-8">
        <h5 className="font-medium mb-3 md:mb-4">Linked Locations</h5>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
          {shopifyLinks.map((link, index) => (
            <Card key={index} className="bg-gray-50">
              <CardBody className="p-3 md:pt-6">
                <div className="font-medium text-sm md:text-base">{link.name}</div>
                <div className="text-xs md:text-sm text-gray-600">
                  {link.store}.myshopify.com
                </div>
                <div className="text-xs md:text-sm">Sync: {link.sync_type}</div>
                <Button
                  variant="destructive"
                  size="sm"
                  className="mt-2"
                  onClick={() => setShopifyLinks(prev => 
                    prev.filter((_, i) => i !== index)
                  )}
                >
                  Remove
                </Button>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>

      {!selectedLocation ? (
        <>
          {/* Step 1: Chọn Location */}
          <div className="space-y-4 md:space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
              <h5 className="font-medium">Step 1: Select Shopify Location</h5>
              <Button
                onClick={() => setShowLocationsList(!showLocationsList)}
                variant="outline"
                className="w-full sm:w-auto"
              >
                {showLocationsList ? 'Hide Locations' : '+ Link Shopify Location'}
              </Button>
            </div>

            {showLocationsList && (
              <div className="space-y-6 md:space-y-8">
                {Object.keys(shopifyLocations).map((locale) => (
                  <div key={locale} className="mb-4 md:mb-6">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-3 md:mb-4">
                      <h3 className="text-base md:text-lg font-medium">
                        {locale}.myshopify.com
                      </h3>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleCreateLocation(locale)}
                      >
                        Create New
                      </Button>
                    </div>

                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-3 md:gap-4">
                      {shopifyLocations[locale].locations
                        ?.filter(loc => !shopifyLinks
                          .map(link => link.location_id)
                          .includes(loc.id)
                        )
                        .map((location) => (
                          <Card 
                            key={location.id}
                            className="cursor-pointer hover:bg-gray-50"
                            onClick={() => handleLocationSelect(locale, location.id)}
                          >
                            <CardBody className="p-3 md:pt-6">
                              <h4 className="font-medium text-sm md:text-base">{location.name}</h4>
                              <div className="text-xs md:text-sm text-gray-600">
                                {location.address1}
                                <br />
                                {location.city}, {location.province}
                                <br />
                                {location.country}
                              </div>
                            </CardBody>
                          </Card>
                        ))
                      }
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="space-y-3 md:space-y-4 mt-4 md:mt-6">
            <h5 className="font-medium">Step 2: Select Sync Type</h5>
            <div className="space-y-2 md:space-y-4">
              {['NONE', 'PUSH', 'PULL'].map((type) => (
                <Button
                  key={type}
                  variant={syncType === type ? 'default' : 'outline'} 
                  className="w-full justify-start text-sm md:text-base"
                  onClick={() => handleSyncTypeSelect(type)}
                >
                  {type}
                </Button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShopifyStep;
