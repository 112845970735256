

import StockList from "./components/StockList";

const MisPickStock = ()   => {

  return <StockList url={'/orders/mispick-stock'} />
}

export default MisPickStock;
