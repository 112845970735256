// DatePicker.js

import { Controller } from 'react-hook-form';

const DatePicker = ({ label, name, control, defaultValue, error }) => (
  <div className="select2-container mb-4">
    <label className="control-label">{label}</label>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <input

          {...field}
          className={`form-control ${error ? 'is-invalid' : ''} border-black`}
          type="date"
        />
      )}
    />
    {error && <div className="invalid-feedback">{error?.message}</div>}
  </div>
);

export default DatePicker;
