import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Button } from '@shadcn/components/ui/button';
import Select from 'react-select';
import { Col } from '@shadcn/components/ui/col';
import { Row } from '@shadcn/components/ui/row';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';

const RoleManagement = ({permissions= []}) => {
  const [roles, setRoles] = useState([]);
  
  const [newRoleName, setNewRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    superagent.get(`${backendUrl}/users/roles`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .then(response => setRoles(response.body))
      .catch(err => console.log(err));
  };

  const handleCreateRole = () => {
    
    const newRole = {
      name: newRoleName,
      permissions: selectedPermissions
    };
    if(isEditing && selectedRoleId){
      superagent.put(`${backendUrl}/users/roles/update/${selectedRoleId}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .send(newRole)
        .then(() => {
          fetchRoles();
          setNewRoleName('');
          setSelectedPermissions([]);
          setIsEditing(false);
        })
        .catch(err => console.log(err));
    } else {
      superagent.post(`${backendUrl}/users/roles`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .send(newRole)
      .then(() => {
        fetchRoles();
        setNewRoleName('');
        setSelectedPermissions([]);
      })
      .catch(err => console.log(err));
    }
  };

  const handleDeleteRole = (roleId) => {
    superagent.delete(`${backendUrl}/users/roles/${roleId}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .then(() => {
        fetchRoles();
        if(selectedRoleId === roleId) {
          handleCancelEdit();
        }
      })
      .catch(err => console.log(err));
  };

  const handleSelectChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions)
    setSelectedPermissions(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleCancelEdit = () => {
    setNewRoleName('');
    setSelectedPermissions([]);
    setIsEditing(false);
    setSelectedRoleId(null);
  };

  
  return (
    <div className="role-management py-5 pr-5 space-y-5">
      <Card>
        <CardBody className='pt-6'>
          <Row className='flex flex-row gap-6 flex-wrap'>
          <Col className="w-full md:w-[48%]">
          <h3 className="text-lg font-semibold mb-4">{isEditing ? 'Edit Role' : 'Create Role'}</h3>
          <div className="role-form flex flex-col md:flex-row md:space-x-10 space-y-5 md:space-y-0">
            <div className="flex-1">
              <input 
                type="text" 
                placeholder="Role Name" 
                value={newRoleName} 
                onChange={(e) => setNewRoleName(e.target.value)} 
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <Select
                isMulti
                value={selectedPermissions.map(permission => ({ value: permission, label: permission }))}
                options={permissions.map(permission => ({ value: permission, label: permission }))}
                onChange={handleSelectChange}
                className="mt-3"
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                menuShouldBlockScroll={true}
                onBlur={() => {
                  document.activeElement.blur();
                }}
              />
              <div className="flex flex-row gap-2">
              <Button onClick={handleCreateRole} className="mt-3 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                {isEditing ? 'Update Role' : 'Create Role'}
              </Button>
              {isEditing && (
                <>
                
                  <Button onClick={() => handleDeleteRole(selectedRoleId)} className="mt-3 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                    Delete Role
                  </Button>
                  <Button onClick={handleCancelEdit} className="mt-3 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                    Cancel
                  </Button>
                </>
              )}
            </div>
            </div>
            </div>
          </Col>
          <Col className="w-full md:w-[48%]">
          <h4 className="text-lg font-medium">Existing Roles</h4>
          <div className="existing-roles flex-1">
              <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                {roles.map(role => (
                  <div 
                    key={role._id} 
                    className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
                    onClick={() => {
                      setNewRoleName(role.name);
                      setSelectedPermissions(role.permissions);
                      setIsEditing(true);
                      setSelectedRoleId(role._id);
                    }}
                  >
                    <input
                      type="radio"
                      name="role"
                      className="mr-2"
                      checked={newRoleName === role.name}
                      onChange={() => {
                        setNewRoleName(role.name);
                        setSelectedPermissions(role.permissions);
                        setSelectedRoleId(role._id);
                        setIsEditing(true);
                      }}
                    />
                    <span>{role.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default RoleManagement;
