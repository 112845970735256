// src/pages/JIFI/locations/components/steps/BasicInfoStep.js
import React from 'react';
import { Input } from '@shadcn/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';
import { Label } from '@shadcn/components/ui/label';
import { Card, CardContent } from '@shadcn/components/ui/card';

import  MultiSelect  from '@shadcn/components/ui/react-select';

const logisticOptions = [
  { value: 'EWE', label: 'EWE Melbourne' },
  { value: 'EWESYD', label: 'EWE Sydney' },
  { value: 'EWEBRS', label: 'EWE Brisbane' },
  { value: 'EWEPTH', label: 'EWE Perth' },
  { value: 'UNIS', label: 'Unis' },
  { value: 'Airbox', label: 'Airbox' },

  { value: 'LaunchUtah', label: 'Launch Utah' },
  { value: 'LaunchKentucky', label: 'Launch Kentucky' },
];


const BasicInfoStep = ({
  locationName,
  setLocationName,
  code,
  setCode,
  region,
  setRegion,
  isRetailStore,
  setIsRetailStore,
  regionOptions,
  locationLogisticsProvider,
  setLocationLogisticsProvider,
  replenLocations,
  selectedReplenLocations,
  setSelectedReplenLocations
}) => {
  
  
  
  return (
    <Card>
      <CardContent className="space-y-6 p-6 space-y-4">
        <div className="space-y-2">
          <Label htmlFor="locationName">Location Name *</Label>
          <Input
            id="locationName"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            placeholder="Enter location name"
            required
          />
        </div>


        <div className="space-y-2">
          <Label htmlFor="code">Location Code *</Label>
          <Input
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter location code"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="region">Region *</Label>
          <Select value={region} onValueChange={setRegion}>
            <SelectTrigger>
              <SelectValue placeholder="Select a region" />
            </SelectTrigger>
            <SelectContent className='max-h-[200px] overflow-y-auto z-[1500]'>
              {regionOptions?.map((option) => (
                <SelectItem key={option.id} value={option.id}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="isRetail"
            checked={isRetailStore}
            onChange={(e) => setIsRetailStore(e.target.checked)}
            className="h-4 w-4"
          />
          <Label htmlFor="isRetail">This is a retail store</Label>
        </div>
        {!isRetailStore && (
        <div className="space-y-2">
          <Label htmlFor="logisticsProvider">Logistics Provider</Label>
          <Select value={locationLogisticsProvider} onValueChange={setLocationLogisticsProvider}>
            <SelectTrigger>
              <SelectValue placeholder="Select a logistics provider" />
            </SelectTrigger>
            <SelectContent className='max-h-[200px] overflow-y-auto z-[1500]'>  
              {logisticOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        )}

        {locationLogisticsProvider !== '' && !isRetailStore && (
          <div className="space-y-2">
            <Label htmlFor="replenLocations">Replenishment Locations</Label>
            <MultiSelect
              isMulti
              options={replenLocations?.map((val) => ({
                value: val._id,
                label: val.name,
              }))}
              value={selectedReplenLocations}
              onChange={setSelectedReplenLocations}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default BasicInfoStep;
