import React, { useState } from 'react';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import useQuery from '../../helpers/JIFI/useQuery';
import { useHistory } from "react-router-dom";
import Select from '@shadcn/components/ui/react-select';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';


import moment from 'moment';

import '../Skote/Dashboard/skelton.css'
import { TablePagination } from '@components/supply-chain/TablePagination';
import { TableSkeleton } from '@shadcn/components/ui/table';

const ApprovalsBadge = ({status}) => {
    const statuses = {
        'pending': 'warning',
        'approved': 'success',
        'rejected': 'danger'
    }
    return <span className={`badge badge-${statuses[status]}`}>{status}</span>
}



const ProjectsList = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [selectedStatus, setSelectedStatus] = useState('Pending')
    const [selectedLocation, setSelectedLocation] = useState()

    const [dataGroup, stateGroup] = useQuery({
        url: `/inventory/approvalGroup`,
        queryData: {
            page,
            perPage,
            location: selectedLocation,
            status: selectedStatus
        }
    });

    const [locations, stateLocations] = useQuery({
        url: '/inventory/getAllLocationNames'
    })

    const approvalsGroup = dataGroup && dataGroup.data
    const count = dataGroup && dataGroup.count

    const history = useHistory();

  
   

    const statusOptions = [{value: undefined, label: 'All'}, ...['Pending', 'Approved', 'Rejected'].map(val => ({value: val, label: val}))]
    const statusValue = statusOptions.find(val => val.value === selectedStatus)
    const locationOptions = locations ? [{value: undefined, label: 'All'}, ...locations?.map(val => ({value: val._id, label: val.display_name}))] : []
    const locationValue =  locationOptions.find(val => val.value === selectedLocation)

    return (
        <>
            <div className="page-content">
                <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-6">
                    <Breadcrumbs title="Inventory" breadcrumbItem="Approvals" />
                    <Row className='gap-6'>
                        <Col  className='sm:w-3/12'>
                            <div className='mb-4'>
                                <label>Status</label>
                                <Select 
                                    options={statusOptions}
                                    value={statusValue}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                />
                            </div>
                           
                        </Col>
                        <Col className='sm:w-3/12'>
                            <div className='mb-4'>
                                <label>Location</label>
                                <Select 
                                    options={locationOptions}
                                    value={locationValue}
                                    onChange={(e) => setSelectedLocation(e.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="w-full">
                            <div className="">
                                <div className="table-responsive">
                                    <table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Location</th>
                                                <th scope="col">Submission Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Count</th>
                                            </tr>
                                        </thead>
                                      
                                            {
                                                (stateGroup === 'success' && approvalsGroup) ? 
                                                <tbody> 
                                                    {approvalsGroup?.map((approval, key) => {
                                                    let status = 'approved'
                                                    approval.records.map(val => {
                                                        if (val.status === 'pending'){
                                                            status = 'pending'
                                                        }
                                                    })
                                                    return (
                                                        <tr style={{cursor: 'pointer'}} onClick={() => history.push(`/approval?date=${moment(new Date(approval._id.date)).format('YYYY-MM-DD')}&location=${approval?._id.location?._id}`)}>
                                                            <td>
                                                                <h5 className="text-truncate font-size-14">{approval?._id.location?.name}</h5>
                                                            </td>
                                                            <td>
                                                                {moment(new Date(approval._id.date)).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td>
                                                                <ApprovalsBadge status={status} />
                                                                </td>
                                                            <td>
                                                                {approval.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                                 : (
                                                    perPage && (
                                                        <TableSkeleton columns={4} perPage={perPage} />
                                                      
                                                      )

                                                )
                                            }
                                       
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>

              
                    <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                </div>
            </div>
        </>
    );
}

export default ProjectsList;
