import React, { useState } from 'react';
import {  Button } from '@shadcn/components/ui/button';

import ProductDetailStep from './ProductDetailStep';
import { Dialog, DialogContent, DialogTrigger } from '@shadcn/components/ui/dialog';
import WizardNav from '@pages/JIFI/orders/Wizard/WizardNav';
import BulkInputSKUsStep from './BulkInputSKUsStep';
import CreateParcelStep from './CreateParcelStep';
import superagent from 'superagent';
import useProductStore from '@zustand/useProductStore';
import useParcelStore from '@zustand/useParcelStore';
import useVariantStore from '@zustand/useVariantStore';
import { backendUrl } from '@helpers/consts';
import { Loader2 } from 'lucide-react';
import { useParams } from 'react-router-dom';
import SuccessStep from './SuccessStep';
import { toast } from 'react-toastify';

const STEPS = {
  BULK_INPUT_SKUS: 0,
  CREATE_PARCEL: 1,
  PRODUCT_DETAILS: 2,
  CONFIRMATION: 3,
  SUCCESS: 4
};

const LABELS = {
  BULK_INPUT_SKUS: 'Bulk Input',
  CREATE_PARCEL: 'Parcel',
  PRODUCT_DETAILS: 'Product',
  CONFIRMATION: 'Review',
  SUCCESS: 'Success'
}

const ProductWizard = ( {refresh}) => {
  const [productName, selectedCategory, selectedFactory] = useProductStore(state => [state.productName, state.selectedCategory, state.selectedFactory])
  const {productID} = useParams();
  const [parcels] = useParcelStore(state => [state.parcels])
  
  const [currentStep, setCurrentStep] = useState(STEPS.BULK_INPUT_SKUS);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {  resetState: resetVariantStore, variants } = useVariantStore();
  const {  reset: resetParcelStore } = useParcelStore();
  
  const nextStep = () => {
    if (currentStep < Object.keys(STEPS).length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    setCurrentStep(STEPS.BULK_INPUT_SKUS);
  };

  const onSave = () => {
    if(!productID) {  
      setIsLoading(true)
      superagent.post(`${backendUrl}/product/createProductWizard`).send({
        product: {
          name: productName,
          factory: selectedFactory.id,
          productCategory: selectedCategory.id,
        },
        parcels,
        variants: variants
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then(res => {
        setIsLoading(false)
        setCurrentStep(STEPS.SUCCESS);
      })
      .catch(err => {
        console.log("err", err?.response?.body?.response?.message)
        toast.error(err?.response?.body?.response?.message)
        setIsLoading(false)
      })
    } 
  }

  const renderConfirmationStep = () => {
    return (
      <div className="max-w-[58vw] mx-auto bg-white rounded-lg shadow-lg p-8">
        <h3 className="text-2xl font-bold text-gray-800 mb-8 border-b pb-4">Product Details Review</h3>
        
        <div className="space-y-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">Basic Information</h4>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Product Name</span>
                <span className="font-medium">{productName}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Category</span>
                <span className="font-medium">{selectedCategory?.name}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Factory</span>
                <span className="font-medium">{selectedFactory?.name}</span>
              </div>
            </div>
          </div>


          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">Variants</h4>
            <div className="space-y-4">
              {variants?.map((variant, index) => (
                <div key={index} className="p-4 bg-white rounded border">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h5 className="font-medium text-gray-700 mb-2">{variant.name || 'N/A'}</h5>
                      <p className="text-sm text-gray-500">SKU: {variant.sku || 'N/A'}</p>
                    </div>
                    
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <p className="text-sm font-medium">Australia</p>
                        <p className="text-xs text-gray-500">Buffer: {variant.stock_buffer || '0'}</p>
                        <p className="text-xs text-gray-500">Status: {variant.status_au || 'N/A'}</p>
                        <p className="text-xs text-gray-500">Preorder: {variant.preorder ? 'Yes' : 'No'}</p>
                      </div>
                      
                      <div>
                        <p className="text-sm font-medium">US</p>
                        <p className="text-xs text-gray-500">Buffer: {variant.stock_buffer_us || '0'}</p>
                        <p className="text-xs text-gray-500">Status: {variant.status_us || 'N/A'}</p>
                        <p className="text-xs text-gray-500">Preorder: {variant.preorder_us ? 'Yes' : 'No'}</p>
                      </div>

                      <div>
                        <p className="text-sm font-medium">UK</p>
                        <p className="text-xs text-gray-500">Buffer: {variant.stock_buffer_uk || '0'}</p>
                        <p className="text-xs text-gray-500">Status: {variant.status_uk || 'N/A'}</p>
                        <p className="text-xs text-gray-500">Preorder: {variant.preorder_uk ? 'Yes' : 'No'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">Parcels</h4>
            <div className="grid gap-4">
              {parcels?.map((parcel, index) => (
                <div key={index} className="p-4 bg-white rounded border">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="font-medium text-gray-700">{parcel.parcel_description || 'N/A'}</p>
                      <p className="text-sm text-gray-500">Declared Price: ${parcel.declared_price || '0'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Weight: {parcel.weight || '0'}kg</p>
                      <p className="text-sm text-gray-500">Dimensions: {parcel.length || '0'} x {parcel.width || '0'} x {parcel.depth || '0'} cm</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8 p-4 bg-blue-50 rounded-lg">
          <p className="text-sm text-blue-600 flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
            Please review all information carefully before proceeding with product creation
          </p>
        </div>
      </div>
    );
  };

  const renderStepContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-full">
          <Loader2 className="animate-spin h-10 w-10" />
        </div>
      );
    }

    switch (currentStep) {
      case STEPS.BULK_INPUT_SKUS:
        return (
          <>
            <BulkInputSKUsStep />
            <div className={`flex  mt-4 ${STEPS.BULK_INPUT_SKUS === currentStep ? 'justify-end' : ''}`}>
              {!productID && (
                <Button disabled={variants.length === 0} onClick={nextStep}>Next</Button>
              )}
            </div>
          </>
        );
      case STEPS.CREATE_PARCEL:
        return (
          <>
            <CreateParcelStep />
            <div className="flex justify-between mt-4">
              <Button onClick={previousStep}>Previous</Button>
              {!productID && (
                <Button disabled={parcels.length === 0} onClick={nextStep}>Next</Button>
              )}
            </div>
          </>
        );
      case STEPS.PRODUCT_DETAILS:
        return (
          <>
            <ProductDetailStep />
            <div className="flex justify-between mt-4">
              <Button onClick={previousStep}>Previous</Button>
              <Button 
                disabled={productName === '' || !selectedCategory || !selectedFactory}
                onClick={nextStep}>Next</Button>
            </div>
          </>
        );
      case STEPS.CONFIRMATION:
        return (
          <>
            {renderConfirmationStep()}
            <div className="flex justify-between mt-4">
              <Button onClick={previousStep}>Previous</Button>
              <Button onClick={onSave} variant="success">Save Changes</Button>
            </div>
          </>
        );
      case STEPS.SUCCESS:
        return <SuccessStep onClose={handleClose} />;
      default:
        return null;
    }
  };

  
  const toggleModal = () => {
    if(isOpen) {
      resetVariantStore();
      resetParcelStore();
    }
    setIsOpen(!isOpen);
  }
  

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogTrigger>
        <Button>Product Wizard</Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] z-[1500] min-w-[60vw] max-w-[60vw] overflow-y-auto">
          <WizardNav
            steps={Object.keys(STEPS)
              
              .map((step) => LABELS[step])}
            activeTabProgress={currentStep + 1}
          />
          {renderStepContent()}
      </DialogContent>
    </Dialog>
  );
};

export default ProductWizard;
