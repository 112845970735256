import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardContent, CardDescription, CardHeader } from "@shadcn/components/ui/card";
import { Button } from "@shadcn/components/ui/button";
import { cva } from "class-variance-authority"; 
import { GripVertical } from "lucide-react"; 
import { Badge } from "@shadcn/components/ui/badge";

import { useTaskCard } from "@zustand/useTaskCard";
import { useEffect } from "react";

const statusTitle = {
    todo: 'To do',
    'in-progress': 'In Progress',
    complete: 'Complete',
  };

export function TaskCard({ task, isOverlay }) {
    
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id, 
        data: {
            type: "Task",
            task, 
        },
        attributes: {
            roleDescription: "Task",
        },
    });

    const selectedCard = useTaskCard ((state) => state.selectedCard);
    const selected = useTaskCard((state => state.selected))
    const taskCard  = useTaskCard((state => state.task))

    useEffect(() => {
        if(task.selected) {
            if(selected === undefined) {
                selectedCard({...task})
            }
        }
    },[])
    
    const style = {
        transition, 
        transform: CSS.Translate.toString(transform),
     
      };

      const cardClass = task?.id === taskCard?.id
        ? "border-2 border-black shadow-lg"
        : "bg-white border border-gray-300 shadow-sm";

   
    const variants = cva("", {
        variants: {
            dragging: {
                over: "ring-2 opacity-30", 
                overlay: "ring-2 ring-primary",
            },
        },
    });
    const formattedDate = new Date(task.dayNeededBy).toLocaleDateString();


    return (
        <Card
            onClick={()=> {selectedCard(task)}}
            ref={setNodeRef} 
            style={style} 
            className={` ${cardClass} cursor-pointer ${variants({
              dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined, 
          })} transition-all duration-300 rounded-lg p-3`} 
        >
         
            <CardHeader className="px-3 py-3 space-between flex flex-row border-b-2 border-secondary relative">
                
                <Button
                    variant={"ghost"} 
                    {...attributes}
                    {...listeners}
                    className="p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab"
                >
                    <span className="sr-only">Move task</span> 
                    <GripVertical /> 
                </Button>
                
                <div className="flex flex-1 flex-col !align-end">
                <div className="flex flex-1 !justify-end">
                <Badge className={'mr-1'} variant={"outline"}>{task?.category?.name}</Badge>
                <Badge variant={"outline"}>{task.customerName}</Badge>
                </div>

               
                </div>
            </CardHeader>

            
            <CardContent className="px-3 pt-3 pb-6 text-left whitespace-pre-wrap">
            <CardDescription className='my-2 text-[13px] text-black'>
                {task.actionDescription}
              </CardDescription>

              <div className="flex flex-wrap gap-2 my-2">
                    
                    <Badge variant={"outline"}>{formattedDate}</Badge>
                   
                </div>
                <Badge variant={"outline"} className="ml-auto font-semibold">
                    {statusTitle[task.status]}
                </Badge>
             
            </CardContent>
        </Card>
    );
}
