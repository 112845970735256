import  { useState, useEffect } from "react";


import { Row } from '@shadcn/components/ui/row';
import { Col }  from '@shadcn/components/ui/col';

import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import "@pages/JIFI/datatables.scss";
import useQuery from '../../../../helpers/JIFI/useQuery';
import useProductData from "../../orders/useProductData";

import CompletePersonalisation from "../component/CompletePersonalisation";
import Batch from "../component/Batch";


const DeliveriesPage = () => {
  const url = '/orders/getPersonalisationBatches'

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [batches, state] = useQuery({ refresh, url });

  const [cachedBatchData, setCachedBatchData] = useState([])
  useEffect(() => {
    if (batches) {
      setCachedBatchData(batches)
    }
  }, [batches])


  let [doneID, setDone] = useState()

  let [openNames, setOpenNames] = useState([])

  const getVariant = useProductData()

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Row onClick={() => refreshData()}>
            <Col xs="11">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation Batches" />
            </Col>
          </Row>
          <Row className="flex flex-wrap gap-6 mb-10">
            {
              (cachedBatchData.length > 0) ? (
                cachedBatchData.map((batch, index) => (
                  <Col xs="12" id={batch.name}>
                     <Batch
                  key={index}
                  batch={batch}
                  doneID={doneID}
                  setDone={setDone}
                  openNames={openNames}
                  setOpenNames={setOpenNames}
                  getVariant={getVariant}
                  state={state}
                />
                    
                    
                  </Col>
                ))
              ) : 
                state !== 'success' ?
              (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div>No results</div>
              </div>
              )
            }
          </Row>
        </div>

        <CompletePersonalisation
        togglemodal={() => setDone()}
        refresh={refreshData}
        lineItem={doneID}
        open={!!doneID}
        url={url}
      />
        
         
      </div>   
    </>
  );
}



export default DeliveriesPage;
