import React, { useEffect, useState } from 'react';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { useParams } from 'react-router-dom';

const OrderViewersFab = () => {
  const [ws, setWs] = useState(null);
  const [viewers, setViewers] = useState([]);
  const { orderID } = useParams();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
    
    const socket = new WebSocket('wss://36z739qzy4.execute-api.ap-southeast-2.amazonaws.com/dev');

    socket.onopen = () => {
      const payload = {
        action: "viewOrder",
        orderId: orderID,
        userInfo: {
          username: authUser.userName,
          email: authUser.email,
          avatar: authUser.profile_image
        }
      };
      socket.send(JSON.stringify(payload));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "VIEWER_UPDATE") {
        setViewers(data.viewers);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setWs(socket);

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [orderID]);

  return (
    <>
      {viewers.map((viewer) => {
        const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16);
        
        return (
          <Action
            key={viewer.email}
            text={viewer.username}
            style={{ 
              backgroundColor: '#ffffff',
              boxShadow: '0 3px 6px rgba(0,0,0,0.15)',
              width: '35px',
              height: '35px', 
              margin: '6px',
              borderRadius: '50%',
              border: '1px solid #e5e7eb',
              position: 'relative'
            }}
          >
            {viewer.avatar ? (
              <img 
                src={viewer.avatar}
                alt={viewer.username}
                className="w-full h-full object-cover rounded-full"
              />
            ) : (
              <div
                className="w-full h-full flex items-center justify-center rounded-full text-white font-bold"
                style={{
                  backgroundColor: randomColor,
                  fontSize: '14px'
                }}
              >
                {viewer.username.charAt(0).toUpperCase()}
              </div>
            )}
            <div 
              className="absolute bottom-[-25px] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              {viewer.username}
            </div>
          </Action>
        );
      })}
    </>
  );
};


export default OrderViewersFab;
