import * as yup from 'yup';

export const containerSchema = yup.object({
  containerName: yup
    .string()
    .required('Supplier Invoice Reference is required'),
  containerRef: yup.string().required('Container Reference is required'),
  departureDate: yup.string().required('Departure Date is required'),
  requestedDate: yup.string().required('Requested Date is required'),
  warehouseDatePlanned: yup
    .string()
    .required('Warehouse Date Planned is required'),
  arrivalDate: yup.string().required('Arrival Date is required'),
  bookingDate: yup.string().required('Booking Date is required'),
  warehouseDateActual: yup
    .string()
    .required('Warehouse Date Actual is required'),
  portDate: yup.string().required('Port Date is required'),
  freightCost: yup
    .number()
    .required('Freight Cost is required')
    .positive('Freight Cost must be a positive number'),
  localCost: yup
    .number()
    .required('Local Cost is required')
    .positive('Local Cost must be a positive number'),
});
