import  { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { registerUser, apiError, registerUserFailed } from "../../../store/actions";
import { Card, CardHeader, CardContent, CardFooter } from "@shadcn/components/ui/card";
import { Input } from "@shadcn/components/ui/input";
import { Label } from "@shadcn/components/ui/label";
import { Button } from "@shadcn/components/ui/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logoImg from "../../../assets/images/logo.svg";

const Register = () => {
  const dispatch = useDispatch();
  const { user, registrationError } = useSelector((state) => state.Account);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    dispatch(registerUser(data));
  };

  useEffect(() => {
    if (user) {
      toast.success("Register User Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (registrationError) {
      toast.error(registrationError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [user, registrationError]);

  useEffect(() => {
    dispatch(apiError(""));
    dispatch(registerUserFailed(""));
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-black">
      <div className="w-full max-w-md px-4">
        <Card className="border">
          <CardHeader className="text-center p-4">
            <div className="flex flex-col items-center">
              <img src={logoImg} alt="Logo" className="h-12 mb-4" />
              <h2 className="text-lg font-semibold">Free Register</h2>
              <p className="text-sm">Get your free Skote account now.</p>
            </div>
          </CardHeader>
          <CardContent className="p-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register("email", { required: "Email is required" })}
                  className="bg-white border border-black"
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>

              <div className="space-y-2">
                <Label htmlFor="username">Username</Label>
                <Input
                  id="username"
                  type="text"
                  placeholder="Enter username"
                  {...register("username", { required: "Username is required" })}
                  className="bg-white border border-black"
                />
                {errors.username && <p className="text-red-500 text-sm">{errors.username.message}</p>}
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  {...register("password", { required: "Password is required" })}
                  className="bg-white border border-black"
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
              </div>

              <Button type="submit" className="w-full bg-black text-white border border-black">
                Register
              </Button>
            </form>
          </CardContent>
          <CardFooter className="p-4 text-center">
            <p className="text-sm">
              By registering, you agree to the Skote{" "}
              <Link to="#" className="text-black underline">
                Terms of Use
              </Link>
              .
            </p>
          </CardFooter>
        </Card>
        <div className="mt-4 text-center">
          <p>
            Already have an account?{" "}
            <Link to="/login" className="font-medium text-black underline">
              Login
            </Link>
          </p>
          <p className="text-sm">
            © {new Date().getFullYear()} Skote. Crafted with{" "}
            <span className="text-red-500">♥</span> by Themesbrand.
          </p>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Register;
