

import StockList from "./components/StockList";

const AwaitingStock = ()   => {

  return <StockList url={'/orders/awaiting'} />
}

export default AwaitingStock;
