import React, { useEffect } from "react";
import { Card, CardHeader, CardContent, CardFooter } from "@shadcn/components/ui/card";
import { Button } from "@shadcn/components/ui/button";
import { Input } from "@shadcn/components/ui/input";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userForgetPassword } from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../assets/images/logo.svg";

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Redux state
  const { forgetError, forgetSuccessMsg } = useSelector(
    (state) => state.ForgetPassword
  );

  // React Hook Form
  const { register, handleSubmit, formState: { errors } } = useForm();

  // Toast notifications
  useEffect(() => {
    if (forgetError) {
      toast.error(forgetError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (forgetSuccessMsg) {
      toast.success(forgetSuccessMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [forgetError, forgetSuccessMsg]);

  // Handle form submission
  const onSubmit = (data) => {
    dispatch(userForgetPassword(data, history));
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-black">
      <div className="w-full max-w-md px-4">
        <Card className="border">
          <CardHeader className="text-center p-4">
            <div className="flex flex-col items-center">
              <img src={logo} alt="Logo" className="h-12 mb-4" />
              <h2 className="text-lg font-semibold">Reset Password</h2>
              <p className="text-sm text-gray-500">
                Enter your email to reset your password.
              </p>
            </div>
          </CardHeader>
          <CardContent className="p-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="email">Email</label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  {...register("email", { required: "Email is required" })}
                  className="bg-white border border-black"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </div>
              <Button type="submit" className="w-full bg-black text-white border border-black">
                Reset Password
              </Button>
            </form>
          </CardContent>
          <CardFooter className="text-center p-4">
            <p className="text-sm">
              Go back to{" "}
              <Link to="/login" className="text-black underline">
                Login
              </Link>
            </p>
          </CardFooter>
        </Card>
        <ToastContainer />
        <div className="mt-4 text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Skote. Crafted with{" "}
            <span className="text-red-500">♥</span> by Themesbrand.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
