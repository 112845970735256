import { useMemo } from 'react';
import useQuery from '../../../helpers/JIFI/useQuery';
import { Card, CardContent } from '@shadcn/components/ui/card';
import { Badge } from '@shadcn/components/ui/badge';
import { ScrollArea } from '@shadcn/components/ui/scroll-area';
import { useParams } from 'react-router-dom';


const ErrorLogDetail = () => {
  const { id } = useParams();

  const [errorLog] = useQuery({
    url: `/error-logs/${id}`,
  });

  const getSeverityColor = useMemo(() => {
    const colors = {
      CRITICAL: 'destructive',
      HIGH: 'destructive', 
      MEDIUM: 'warning',
      LOW: 'success'
    };
    return colors[errorLog?.severity] || 'default';
  }, [errorLog?.severity]);

  const formatStackTrace = useMemo(() => {
    if (!errorLog?.stackTrace) return [];
    return errorLog.stackTrace.split('\n').map(line => line.trim());
  }, [errorLog?.stackTrace]);

  return (
    <div className='page-content'>

      <div className='w-full px-3 mx-auto xl:max-w-[85%]'>
          
        <Card>
          <CardContent className="pt-6">
          {/* Header */}
          <div className="mb-6">
            <h4 className="text-2xl font-semibold mb-2">Error Detail</h4>
            <div className="flex flex-wrap gap-2 items-center">
              <Badge variant="outline">
                {new Date(errorLog?.createdAt).toLocaleString()}
              </Badge>
              <Badge variant={getSeverityColor}>
                {errorLog?.severity}
              </Badge>
            </div>
          </div>

          {/* Main Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <p className="text-sm text-muted-foreground">Function Name</p>
              <h5 className="text-lg font-medium mt-1">
                {errorLog?.functionName}
              </h5>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Error Name</p>
              <h5 className="text-lg font-medium mt-1">
                {errorLog?.errorName}
              </h5>
            </div>
          </div>

          {/* Error Message */}
          <div className="mb-6">
            <p className="text-sm text-muted-foreground">Error Message</p>
            <Card className="mt-2 bg-muted">
              <CardContent className="pt-4">
                <p>{errorLog?.errorMessage}</p>
              </CardContent>
            </Card>
          </div>

          {/* Stack Trace */}
          <div>
            <p className="text-sm text-muted-foreground">Stack Trace</p>
            <ScrollArea className="h-[400px] mt-2 w-full rounded-md border p-4">
              {formatStackTrace.map((line, index) => (
                <div key={index} className="py-1">
                  <code className="text-sm bg-muted px-2 py-1 rounded">
                    {line}
                  </code>
                </div>
              ))}
            </ScrollArea>
          </div>
        </CardContent>
      </Card>
    </div>
    </div>
  );
};

export default ErrorLogDetail;
