

import useQuery from "@helpers/JIFI/useQuery";

import { Link } from "react-router-dom";
import { useState } from "react";



export const PreviousOrders = ({ orderID }) => {
  const [refresh, setRefresh] = useState(true);

  const [data, state] = useQuery({
    url: `/orders/${orderID}/customerOrders`,
    refresh,
  });

  if (!data || data.length === 0) return <> </>;

  const filteredOrders = data.filter((val) => orderID !== `${val._id}`);

  return (
    <div className="mt-2 pt-3 border-top border-light">
      <h5 className="mb-2 text-[15px] font-semibold"> {filteredOrders.length} Previous Orders </h5>
      <br />
      {filteredOrders.map((order) => {
        return (
          <div>
            <Link to={`/orders/${order._id}`}>
              {' '}
              <h6>
                {' '}
                {order.name} - ${order.total_price}
              </h6>{' '}
            </Link>
            <div className="mb-3">
              {order.line_items.map((lineItem) => (
                <div>
                  {' '}
                  {lineItem.quantity} x {lineItem.sku}{' '}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
