import  {useMemo, useState, useEffect} from 'react'


import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';

import { Col } from '@shadcn/components/ui/col';
import { Input } from '@shadcn/components/ui/input';
import { Button } from '@shadcn/components/ui/button';
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell, TableSkeleton } from '@shadcn/components/ui/table';
import { TablePagination } from '@components/supply-chain/TablePagination';
import Breadcrumbs from '@components/Common/Breadcrumb';
import { Row } from '@shadcn/components/ui/row';
import LocationWizard from './components/LocationWizard';
import { backendUrl } from '@helpers/consts';
import superagent from 'superagent';
import  useShopifyLocationsStore  from '@zustand/useShopifyLocationsStore';

const columnHelper = createColumnHelper();

const LocationListPage = ({}) => {

  const [refresh, setRefresh] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const {  setLocations } = useShopifyLocationsStore()
  const [locations, locationState] = useQuery({
    url: `/inventory/listLocations`,
    refresh,
    queryData: {
      page,
      perPage,
      searchTerm,
    },
  });

  const fetchShopifyLocations = () => {
    superagent
      .post(`${backendUrl}/inventory/fetchShopifyLocations`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;

      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
       
        } else {
          setLocations(response.body)
        
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchShopifyLocations();
  }, []);

  

  const locationColumns = useMemo(
    () => [
      columnHelper.accessor('display_name', {
        header: () => 'Display Name',
        cell: ({ row }) => (
          <Link
            className="text-body font-weight-bold px-2 cursor-pointer"
            to={`/location/edit/${row.original._id}`}
          >
            {row.original.display_name}
          </Link>
        ),
      }),
      columnHelper.accessor('code', {
        header: () => 'Code',
        cell: ({ row }) => <div>{row.original.code}</div>,
      }),
      columnHelper.accessor('address.name', {
        header: () => 'Location Name',
        cell: ({ row }) => <div>{row.original.address?.name || '-'}</div>,
      }),
      columnHelper.accessor('address.address1', {
        header: () => 'Address 1',
        cell: ({ row }) => <div>{row.original.address?.address1 || '-'}</div>,
      }),
      columnHelper.accessor('address.address2', {
        header: () => 'Address 2',
        cell: ({ row }) => <div>{row.original.address?.address2 || '-'}</div>,
      }),
      columnHelper.accessor('address.phone', {
        header: () => 'Phone',
        cell: ({ row }) => <div>{row.original.address?.phone || '-'}</div>,
      }),
      columnHelper.accessor('address.city', {
        header: () => 'City',
        cell: ({ row }) => <div>{row.original.address?.city || '-'}</div>,
      }),
      columnHelper.accessor('address.zip', {
        header: () => 'ZIP Code',
        cell: ({ row }) => <div>{row.original.address?.zip || '-'}</div>,
      }),
      columnHelper.accessor('address.province', {
        header: () => 'Province',
        cell: ({ row }) => <div>{row.original.address?.province || '-'}</div>,
      }),
      columnHelper.accessor('address.country', {
        header: () => 'Country',
        cell: ({ row }) => <div>{row.original.address?.country || '-'}</div>,
      }),
      columnHelper.accessor('logistics_provider', {
        header: () => 'Logistics Provider',
        cell: ({ row }) => <div>{row.original.logistics_provider || '-'}</div>,
      }),
    ],
    []
  );
  

  const locationTable = useReactTable({
    data: locations?.locations || [],
    columns: locationColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-6">
        <Breadcrumbs title="Inventory" breadcrumbItem="Locations" />
        <Row>
       
        </Row>
        <Col className="w-full">
          <Card className="mb-10">
            <CardBody className="pt-4">
              <Row className='justify-between'>
            <Col className="w-3/12 mb-4">
          <div className="select2-container">
            <label className="control-label">FILTER</label>
            <div className="search-box mb-2 d-block">
              <div className="position-relative">
                <Input
                  type="text" 
                  className="form-control h-[32px] py-1 rounded-md h-9"
                  placeholder="Search..."
                 
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                      setPage(1);
                      setSearchTerm(e.target.value)
                    }
                   }}
                />
              </div>
            </div>
          </div>
        </Col>

            

              <LocationWizard></LocationWizard>
              </Row>
              <div className="table-responsive">
                <Table className="table table-centered table-nowrap">
                  <TableHeader>
                    {locationTable.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHead key={header.id} className="text-left">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  {locationState === 'success' ? (
                    <TableBody>
                      {locationTable?.getRowModel().rows?.length > 0 ? (
                        locationTable?.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}
                          >
                            {row?.getVisibleCells()?.map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={11} className="text-center">
                            No results.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableSkeleton
                      columns={locationColumns.length}
                      perPage={perPage}
                    />
                  )}
                </Table>
              </div>
              <TablePagination
                count={locations?.totalCount}
                className="my-4"
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
          </Col>
     
        </div>
      </div>
    </>
  );
}

export default LocationListPage
