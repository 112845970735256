import { navBarMenu } from './nav-menu';
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  useSidebar,
} from '@shadcn/components/ui/sidebar';
import { Row } from '@shadcn/components/ui/row';
import { Button } from '@shadcn/components/ui/button';

const CustomSidebarToggle = () => {
  const { toggleSidebar, isOpen } = useSidebar();

  return (
    <Button
      className="m-4 text-black relative"
      variant="ghost"
      onClick={toggleSidebar}
    >
      {isOpen ? "Close Sidebar" : "Open Sidebar"}
    </Button>
  );
};

const SupplyChainNav = ({ children, commentComponent = undefined, menuType = 'supply-chain' }) => {
  const user = JSON.parse(localStorage.getItem('authUser'));
  const { permissions } = user;
  
  const navItems = navBarMenu[menuType].filter((nav) => {
    if (nav.permission) {
      return permissions.includes(nav.permission);
    }
    return true;
  });

  return (
    <SidebarProvider style={{ '--sidebar-width': commentComponent ? '400px': '250px' }}>
      <div className="relative flex min-h-screen w-full">
    
        <SidebarWithToggle navItems={navItems} commentComponent={commentComponent} />
        
    
        <div  className="pt-[70px] flex-1 overflow-x-hidden  transition-all duration-300 bg-white mx-auto">
        <div className='px-6 py-2'>
        <SidebarTrigger />
        </div>
          {children}
        </div>
      </div>
    </SidebarProvider>
  );
};

const SidebarWithToggle = ({ navItems, commentComponent }) => {
  

  return (
    <>
      

      <div className={`w-3/12' md:block  top-[70px] left-0 h-full z-50 bg-white`}>
        
        <Sidebar className={`w-[250px] ${commentComponent ? 'w-[400px]' : 'w-[250px]'}`}>
          <SidebarContent className="sm:w-full">
            <SidebarGroup className="p-0 py-2">
              <SidebarGroupContent className="pt-[70px]">
                <SidebarMenu className="relative p-4 gap-3">
                  {navItems.map((val) => {
                    const isSelected = window.location.href.includes(val.lookup);

                    return (
                      <SidebarMenuItem key={val.name}>
                        <SidebarMenuButton asChild className={isSelected ? 'bg-black text-white' : ''}>
                          <a
                            href={val.url}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0.5rem',
                              textDecoration: 'none',
                              userSelect: 'none',
                              cursor: 'pointer',
                            }}
                          >
                            <span>{val.name}</span>
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    );
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </SidebarContent>
          {commentComponent}
        </Sidebar>
      </div>
    </>
  );
};

export default SupplyChainNav;
