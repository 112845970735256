import React, {  useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { 
  Card,
  CardHeader,
  CardContent as CardBody
} from '@shadcn/components/ui/card';
import {
  Row
} from '@shadcn/components/ui/row';
import { Button } from '@shadcn/components/ui/button';
import { Col } from '@shadcn/components/ui/col';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@shadcn/components/ui/dialog';
import RoleManagement from './roles';

import Select from 'react-select';




const generateWelcomeString = (email, userName, password) => {
return ` Welcome ${userName} to JIFI,

Your credentials are
Email: ${email}
Password: ${password}

Please go in and change your password and add a profile picture. 
You can find the profile settings in the top right.`
}

const UserProfile = ({ props }) => {
    const history = useHistory();

    const [currentUser, setCurrentUser] = useState(
      JSON.parse(localStorage.getItem('authUser')) || {}
    );
    const [users, setUsers] = useState([])
    const [permissions, setPermissions] = useState([])
    const [selectedUserKey, setSelectedUserKey] = useState(0)
    const [selectedRoles, setSelectedRoles] = useState()
    const [createModal, setCreateModal] = useState(false)
    const [roles, setRoles] = useState([])
    useEffect(() => {
      getAllUsers()
      fetchPermissions()
      fetchRoles()
    }, [])

    console.log("all permission", permissions)

    const setPreset = (permissions) => {
      overridePermissions(permissions)
    }


    const fetchRoles = () => {
      superagent.get(`${backendUrl}/users/roles`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .then(response => setRoles(response.body))
        .catch(err => console.log(err));
    };

    const fetchPermissions = () => {
      superagent.get(`${backendUrl}/users/permissions/`)
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('accept', 'json')
          .on('error', (response) => {
              const code = response.status
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          })
          .then(response => {
              const code = response.status
              if (code > 400) {
                  history.replace(history.location.pathname, {
                      errorStatusCode: code
                  });
              } else {
                setPermissions(response.body)
              }
          }).catch(err => {
              console.log(err)
          });
  }

    const getAllUsers = () => {
        superagent.get(`${backendUrl}/users/all/`)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                  if(currentUser.email === users?.[selectedUserKey]?.email){
                    localStorage.setItem("authUser", JSON.stringify(response.body.data[selectedUserKey]))
                  }
                  setUsers(response.body.data.map((val, key) => ({...val, isActive: key === 0 ? true : false})))
                  setSelectedRoles(response?.body?.data[selectedUserKey]?.roles?.map(role => ({
                    id: role._id,
                    value: role.permissions,
                    label: role.name
                  })))
                }
            }).catch(err => {
                console.log(err)
            });
    }

    const toggleDiabled = () => {
      superagent.get(`${backendUrl}/users/toggleDisabled/${users[selectedUserKey]._id}`)
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('accept', 'json')
          .on('error', (response) => {
              const code = response.status
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          })
          .then(response => {
              const code = response.status
              if (code > 400) {
                  history.replace(history.location.pathname, {
                      errorStatusCode: code
                  });
              } else {
                getAllUsers()
              }
          }).catch(err => {
              console.log(err)
          });
  }

  

  const saveUser = () => {
    const sendDataCopy = { 
      userName: users[selectedUserKey].userName,
      email: users[selectedUserKey].email,
      permissions: users[selectedUserKey].permissions,
      roles:  selectedRoles?.map(role => role.id)
    }
    const sendData = JSON.parse(JSON.stringify(sendDataCopy))
    console.log(sendData)
    setUsers([])

    superagent.post(`${backendUrl}/users/updateUser/${users[selectedUserKey]._id}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .send(sendData)
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            } else {              
              getAllUsers()
            }
        }).catch(err => {
            console.log(err)
        });
  }

    const changeSelected = (selectedKey) => {
      setUsers(prev => prev.map((val, key) => ({...val, isActive: key === selectedKey ? true : false})))
      setSelectedUserKey(selectedKey)
    }

    const handleAlterPermission = (permission) => {
      if (users[selectedUserKey].permissions.includes(permission)) {
        setUsers(prev => {
          let updatedUsers = [...prev]
          let updateUser = updatedUsers[selectedUserKey]
          updateUser.permissions = updateUser.permissions.filter(val => val !== permission)
          updatedUsers[selectedUserKey] = updateUser
          return updatedUsers
        })
      } else {
        setUsers(prev => {
          let updatedUsers = [...prev]
          let updateUser = updatedUsers[selectedUserKey]
          updateUser.permissions.push(permission)
          updatedUsers[selectedUserKey] = updateUser
          return updatedUsers
        })
      }
    }

    const overridePermissions = (permissions) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.permissions = updateUser.permissions = permissions
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }

    const handleAlterName = (name) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.userName = name
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }

    const handleAlterEmail = (email) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.email = email
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }


    toast.configure({ autoClose: 8000, hideProgressBar: true })

    
 
    return (
        <React.Fragment>
          
            <div className="page-content">
            <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          {currentUser.permissions.includes('ViewRole') && <RoleManagement  permissions={permissions}/>}
          
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Setting" breadcrumbItem="Users" />
                    <Row className="flex flex-wrap gap-6 ">
                        <Col className="w-full lg:w-[23%]">
                            <Card>
                                <CardHeader>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                    All Users
                                    <Button style={{float: 'right'}} variant="success" onClick={() => setCreateModal(true)}> Create </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                <div>
                                  <ul className="list-unstyled chat-list">
                                    {
                                      users.length > 0 ? users.map((user, key) => {
                                        if (user.disabled)
                                          return

                                        return (
                                          <li key={user._id} className={user.isActive ? "active" : ""}>
                                            <Link to="#" onClick={() => { changeSelected(key) }}>
                                              <div className='flex flex-start'>
                                                <div className="align-self-center mr-3">
                                                  <i className={`mdi mdi-circle text-${user.disabled ? 'danger' : 'success'} font-size-10`}></i>
                                                </div>
                                                <div className="align-self-center mr-3">
                                                  <img style={{objectFit: 'cover'}} src={user.profile_image} className="rounded-circle avatar-xs" alt="" />
                                                </div>
                                                <div className="flex flex-start overflow-hidden" body>
                                                  <h5 className="text-truncate font-size-14 mb-1">{user.userName}</h5>
                                                  <p className="text-truncate mb-0">{user.email}</p>
                                                </div>
                                              </div>
                                            </Link>
                                          </li>
                                        )
                                      }) : (
                                        <div className="d-flex justify-content-center">
                                          <div className="spinner-border text-primary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </ul>
                                </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="9" className="w-full lg:w-[73%]">
                          {
                            (users.length > 0 && users[selectedUserKey] !== undefined) ? (
                              <Card className="mb-6">
                                <CardHeader>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                    {users[selectedUserKey].userName}
                                    <Button onClick={saveUser} style={{float: 'right'}} variant="success"> Save </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Row className="gap-6 flex-wrap">
                                    <Col  className="lg:w-[63%]">
                                      <div  className="mb-4 flex flex-row">
                                        <label htmlFor="userName" className="col-form-label col-lg-2">User Name</label>
                                        <Col  className="lg:w-10/12">
                                          <input name="userName" type="text" className="form-control" placeholder="Enter User Name..." value={users[selectedUserKey].userName}
                                              onChange={(e) => handleAlterName(e.target.value)} />
                                        </Col>
                                      </div>
                                      <div className="flex flex-row mb-4" row>
                                        <label htmlFor="email" className="col-form-label col-lg-2">Email</label>
                                        <Col  className="lg:w-10/12">
                                          <input name="email" type="text" className="form-control" placeholder="Enter User Email..." value={users[selectedUserKey].email}
                                              onChange={(e) => handleAlterEmail(e.target.value)} />
                                        </Col>
                                      </div>
                                      <div className='flex flex-row mt-8'>
                                        <label className='col-form-label col-lg-2' htmlFor='roles'>Select Roles</label>
                                        <Col className='lg:w-10/12'>
                                        <Select
                                          
                                          isMulti
                                          value={selectedRoles}
                                          options={roles.map(role => ({
                                            id: role._id,
                                            value: role.permissions,
                                            label: role.name
                                          }))}
                                          onChange={(selectedOptions) => {
                                            
                                            const combinedPermissions = selectedOptions?.reduce((acc, option) => {
                                              return [...acc, ...option.value];
                                            }, []);
                                            setSelectedRoles(selectedOptions)
                                            setPreset([...new Set(combinedPermissions)]);
                                          }}
                                          className="w-full"
                                          closeMenuOnSelect={false}
                                        />
                                        </Col>
                                      </div>
                                      <br/>
                                      {
                                        users[selectedUserKey].disabled ? (
                                          <Button onClick={toggleDiabled} style={{float: 'right'}} variant="success"> Enable </Button>
                                        ) : (
                                          <Button onClick={toggleDiabled} style={{float: 'right'}} variant="destructive"> Disable </Button>
                                        )
                                      }
                                      <br/>
                                      
                                    </Col>
                                    <Col  className="lg:w-[30%]">
                                      <div className="table-responsive">
                                        <table className="table table-nowrap table-centered table-hover mb-0">
                                          <tbody>
                                              {
                                                permissions.length > 0 && permissions.map( permission =>
                                                  <tr>
                                                    <td style={{width : "50px"}}>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`customCheck${permission}`}
                                                          checked={users[selectedUserKey].permissions.includes(permission)}
                                                          onChange={() => handleAlterPermission(permission)}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`customCheck${permission}`}/>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <h5 className="text-truncate font-size-14 mb-1"><Link
                                                          to="#"
                                                          className="text-dark"
                                                        >{permission}</Link></h5>
                                                      {/* <p className="text-slateGray mb-0">Assigned to Mark</p> */}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            </tbody>
                                        </table>
                                      </div>

                                    </Col>
                                  </Row>

                                </CardBody>
                              </Card>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )
                          }
                        </Col>
                    </Row>
                </div>
            </div>
            <CreateDialog setUsers={setUsers} getAllUsers={getAllUsers} createModal={createModal} setCreateModal={setCreateModal}/>
        </React.Fragment>
    );
}


const CreateDialog = ({createModal, setCreateModal, setUsers, getAllUsers}) => {
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')

  const [welcomeString, setWelcomeString] = useState(undefined)

  const [permissions, setPermissions] = useState(['ViewOrders'])

  const togglemodal = () => setCreateModal(prev => !prev)

  const history = useHistory();

  const createPassword = () => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var password = "";

    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }

    return password
  }

  const handleCreateUser = () => {
    const password = createPassword()
    superagent.post(`${backendUrl}/users/`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .send({
        userName: userName,
        email: email,
        password: password
      })
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          } else {
            navigator.clipboard.writeText(generateWelcomeString(email, userName, password));
            setWelcomeString(generateWelcomeString(email, userName, password))
          }
      }).catch(err => {
          console.log(err)
      });
  }

  const closeModal = () => {
    setUsers([])
    getAllUsers()
    togglemodal()
  }

  const handleAlterName = (name) => {
    setUserName(name)
  }

  const handleAlterEmail = (email) => {
    setEmail(email)
  }


  return (
    <div>
      <Dialog open={createModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" onOpenChange={() => togglemodal()}>
      <DialogContent className="max-h-[80vh] overflow-y-auto z-[1500] min-w-[60vw]">
        <DialogHeader toggle={togglemodal}>
          <DialogTitle>
          Create User
          </DialogTitle>
        </DialogHeader >
        <div> 
        {
          welcomeString === undefined ? (
            <>
              <div className="mb-4 flex flex-row">
                <label htmlFor="userName" className="col-form-label col-lg-2">User Name</label>
                <Col className="lg:w-10/12">
                  <input name="userName" type="text" className="form-control" placeholder="Enter User Name..." value={userName}
                      onChange={(e) => handleAlterName(e.target.value)} />
                </Col>
              </div>
              <div className="mb-4 flex flex-row">
                <label htmlFor="email" className="col-form-label col-lg-2">Email</label>
                <Col  className="lg:w-10/12">
                  <input name="email" type="text" className="form-control" placeholder="Enter User Email..." value={email}
                      onChange={(e) => handleAlterEmail(e.target.value)} />
                </Col>
              </div>
            </>
          ) : (
            <>
              The welcome text has been copied to your clipboard. Please paste this to the user so they can get set up.
            </>
          )
        }

        </div>
        <DialogFooter>
          {
            welcomeString === undefined ? (
              <Button variant="success" onClick={handleCreateUser}> Create </Button>
            ) : (
              <Button variant="success" onClick={closeModal}> Done </Button>
            )
          }

        </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default UserProfile;
