import React, { useState, useEffect } from 'react';
import { Button } from '@shadcn/components/ui/button';

import WizardNav from './WizardNav'; // Import WizardNav component if it exists
import superagent from 'superagent'; // Make sure to import superagent or replace it with your preferred HTTP client
import { backendUrl } from '../../../../helpers/consts';

import { DialogFooter } from '@shadcn/components/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/components/ui/select';

const OrderWizardFlaggedModalContent = ({
  options,
  loading,
  selectedFlagType,
  handleFlagLineItemChanged,
  updateLineItem,
  order,
  handleFlag,
}) => (
  <div>
    <WizardNav steps={['Select a flag type']} activeTabProgress={1} />
    {loading ? (
      <div>
        <div className="modal-content">
          <div>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div>
          <div>
           
              
              <Select
                disabled={order.flagged}
                defaultValue={selectedFlagType}
                onValueChange={handleFlagLineItemChanged}
                >
                <SelectTrigger>
                  <SelectValue placeholder="Select a flag type" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {options.map((option) => (
                    <SelectItem value={option?._id} key={option?._id}>
                      {option?.name}    
                    </SelectItem>
                  ))}
                  </SelectContent>
              </Select>
              
            
          </div>
          <DialogFooter className='mt-4'>
            {order.flagged === true ? (
              <Button variant="success" onClick={(e) => handleFlag(e)}>
                Unflag
              </Button>
            ) : (
              <Button variant="success" onClick={updateLineItem}>
                Add
              </Button>
            )}
            <br />
          </DialogFooter>
        </div>
      </div>
    )}
  </div>
);

const OrderWizardFlaggedModal = ({ togglemodal, refresh, orderID, order }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFlagType, setSelectedFlagType] = useState();

  const handleFlag = (e) => {
    e.preventDefault();
    setLoading(true);
    superagent.get(`${backendUrl}/orders/${orderID}/getFlagged`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          refresh();
          setLoading(false);
          togglemodal();
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    superagent.get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          setOptions(response.body);
          setSelectedFlagType(response.body.data._id);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleFlagLineItemChanged = (e) => {
    setSelectedFlagType(e);
  };

  const updateLineItem = () => {
    let newLineItem = {
      orderID: orderID,
      flagTypeId: selectedFlagType !== undefined ? selectedFlagType : options[0]?._id,
    };

    setLoading(true);
    superagent.post(`${backendUrl}/orders/${orderID}/setFlagged`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };


  return (
    <OrderWizardFlaggedModalContent
      options={options}
      loading={loading}
      selectedFlagType={selectedFlagType}
      handleFlagLineItemChanged={handleFlagLineItemChanged}
      updateLineItem={updateLineItem}
      order={order}
      handleFlag={handleFlag}
    />
  );
};

export default OrderWizardFlaggedModal;
