import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Col } from '@shadcn/components/ui/col'
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card'

const FlagTypeCard = ({flagtype, key}) => {
  

  return (
    <Col className={`sm:w-[49%] xl:w-[32%]`}  key={"_flagtype_" + key} >
      <Link to={`/flagtype/edit/${flagtype._id}`}>
        <Card>
          <CardBody className="pl-3 p-5">
            <div>
              <div className="overflow-hidden" body>
                <h5 className="text-truncate font-size-15"><Link to={`/flagtype/edit/${flagtype._id}`} className="text-dark">{flagtype.name}</Link></h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Link>
    </Col>
  )
}


export default FlagTypeCard;
