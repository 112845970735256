import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '@shadcn/components/ui/input';
import Select from '@shadcn/components/ui/react-select';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import Dropzone from 'react-dropzone';
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useParcelStore from '@zustand/useParcelStore';
import useVariantStore from '@zustand/useVariantStore';

import { Button } from '@shadcn/components/ui/button';

import VariantBulkEditor from './variant/variant-bulk-edit';
import {
  Card,
  CardContent as CardBody,
  CardTitle,
} from '@shadcn/components/ui/card';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { uploadFileS3Bucket } from '../../../utilities/uploadFileS3Bucket';
import {
  Collapsible,
  CollapsibleContent,
} from '@shadcn/components/ui/collapsible';

import CreateOrEditParcelDialog from './components/CreateOrEditParcelDialog';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableSkeleton,
} from '@shadcn/components/ui/table';
import { Pencil, Trash2 } from 'lucide-react';
import SupplyChainNav from '@components/supply-chain/nav';
import BasicTable from '../all-stock';

import useProductStore from '@zustand/useProductStore';

const ProductListPage = ({ match: { params } }) => {
  const history = useHistory();
  const { productID } = params;

  const [productName, setProductName] = useState('');
  const [productImage, setProductImage] = useState('');

  const [productCategory, setProductCategory] = useState('');



  const [factoryOptions, setFactoryOptions] = useState([]);

  const [productFactory, setProductFactory] = useState('');
  const [isArchived, setIsArchived] = useState(false);

  const { setVariants, resetState: resetVariantStore } = useVariantStore();
  const { setIsOpen, setParcelData, setEdit, setParcels, reset: resetParcelStore } = useParcelStore();
  
  const [refresh, setRefresh] = useState(false);
  const refreshData = () => setRefresh((prev) => !prev);


  const { setFactories, setCategories, categories, setProductName: setProductNameStore, setImages , setSelectedCategory, setSelectedFactory, reset: resetProductStore  } = useProductStore();



  
  const revertStatus = (variant, locale) => {
    const getStatusFields = (loc) => {
      const suffix = loc === 'au' ? '' : `_${loc}`;
      
      if(variant[`continue_selling${suffix}`] === false && variant[`discontinued${suffix}`] === false && variant[`preorder${suffix}`] === false) {
        return 'unavailable';
      }
      
      return {
        continue_selling: variant[`continue_selling${suffix}`],
        discontinued: variant[`discontinued${suffix}`], 
        preorder: variant[`preorder${suffix}`]
      };
    };

    const status = getStatusFields(locale);

    if (status.discontinued === true && !status.continue_selling && !status.preorder) {
      return 'discontinued';
    }

    if (status.continue_selling === true && !status.discontinued && !status.preorder) {
      return 'keep_selling';
    }

    if (!status.continue_selling && !status.discontinued && !status.preorder) {
      return 'unavailable';
    }

    if (!status.continue_selling && !status.discontinued && status.preorder === true) {
      return 'preorder';
    }
  };

  const handleEditParcel = (parcelData) => {
    setParcelData(parcelData);
    setEdit(true);
    setIsOpen(true);
  };

  const [product, state] = useQuery({
    refresh,
    url: `/product/${productID}`,
  });

  const [parcels, stateParcels] = useQuery({
    url: `/product/parcels/${productID}`,
    refresh,
  });

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [stockStatus, stockStatusLoading] = useQuery({
    url: `/product/stock-status/${productID}`,
    queryData: {
      startDate,
      endDate,
    },
    refresh,
  });

  useEffect(() => {
    if (parcels) {
      setParcels(parcels);
    }
  }, [parcels]);

  
  useEffect(() => {
    return () => {
      resetProductStore();
      resetVariantStore();
      resetParcelStore();
    };
  }, []);

  useEffect(() => {
    if (product != undefined) {
      setProductName(product.name);
      setProductImage(product.image);
      

      if(product.variants && product.variants.length > 0) {
      setVariants(product.variants.map(variant => ({
        ...variant,
        status_au: revertStatus(variant, 'au'),
        status_us: revertStatus(variant, 'us'),
        status_uk: revertStatus(variant, 'uk'),
      })));
      
      }
      setProductCategory(product.category);


      setSelectedCategory({id: categories.find(val => val.name === product.category)?._id, name: product.category, label: product.category});
      setProductNameStore(product.name);
      setImages(product?.files ?? [])
      
      

      if (
        factoryOptions.length > 0 &&
        product.factory !== '' &&
        product.factory !== undefined
      ) {
        let fac = factoryOptions.find(
          (val) => `${val._id}` === `${product.factory}`
        );
        if (fac !== undefined) {
          setProductFactory({
            value: product.factory,
            label: fac.name,
          });
          setSelectedFactory({id: product.factory, name: fac.name, label: fac.name});
        }
      } else {
        setProductFactory({ value: product.factory });
      }
    }
  }, [product]);

  useEffect(() => {
    superagent
      .get(`${backendUrl}/product/productCategory`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then((response) => {
        if (response.status === 200) {
          
          setCategories(response?.body);
          
        }
      });
  }, []);

  useEffect(() => {
    superagent
      .post(`${backendUrl}/product/productFactories`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          setFactoryOptions(response.body);
          setFactories(response.body);
          setProductFactory((prev) => {
            console.log(prev);
            if (prev !== '' && prev.value !== undefined) {
              return {
                value: prev.value,
                label: response.body.find((val) => `${val._id}` === prev.value)
                  .name,
              };
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleIsArchived = () => {
    setIsArchived((prev) => !prev);
  };

  const handleAcceptedFiles = async (files) => {
    const cancel = !files.length;
    if (cancel) return;
    let images = await Promise.all(
      files.map((file) =>
        uploadFileS3Bucket(file, `comment_file_${Date.now()}`)
      )
    );

    superagent
      .put(`${backendUrl}/product/addImages/${productID}`)
      .send({ images: images })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          history.refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProduct = (e, image) => {
    e.preventDefault();
    if (image) {
      setProductImage(image);
    }

    if (product == undefined) {
      superagent
        .post(`${backendUrl}`)
        .send({
          name: productName,
          image: image || productImage,
          factory: productFactory.value,
          isArchived: isArchived,

          productCategory: categories.find(
            (val) => val.name === productCategory
          ),
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.push(`/product/edit/${response.body._id}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      superagent
        .put(`${backendUrl}/product/${productID}`)
        .send({
          name: productName,
          image: image || productImage,
          factory: productFactory.value,
          isArchived: isArchived,

          productCategory: categories.find(
            (val) => val.name === productCategory
          ),
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [imagesOpen, setimagesOpen] = useState(false);

  const handleDeleteParcel = (parcelId) => {
    if (window.confirm('Do you want to delete this parcel?')) {
      superagent
        .post(`${backendUrl}/orders/deleteParcel/${parcelId}`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .then((response) => {
          toast.success('Deleted successfully');
          refreshData();
        })
        .catch(() => toast.error('Something went wrong'));
    }
  };

  return (
    <SupplyChainNav menuType="products">
      <div className="p-6 mb-10">
        <Breadcrumbs
          title="Ecommerce"
          breadcrumbItem={
            productID != undefined ? 'Edit Product' : 'New Product'
          }
        />
        <Row>
          <Col className="w-full">
            <Card className="pt-6">
              <CardBody>
                <CardTitle className="text-[15px] mb-2">
                  Product Information
                </CardTitle>


                <div>
                  <Row className="gap-4">
                    <Col className="w-6/12">
                      <div className="mb-4">
                        <label htmlFor="productname">Product Name</label>
                        <Input
                          id="productname"
                          name="productname"
                          type="text"
                          className="form-control"
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </div>

                      <Col className="w-full lg:w-[48%]">
                        <div className="select2-container mb-4">
                          <label className="control-label">Factory</label>
                          <Select
                            options={factoryOptions.map((val) => ({
                              value: val._id,
                              label: val.name,
                            }))}
                            value={productFactory}
                            onChange={(selected) => setProductFactory(selected)}
                            placeholder="All"
                            title="Factory"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: '1px solid black',
                                boxShadow: 'none',
                                '&:hover': {
                                  border: '1px solid black',
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                backgroundColor: 'black',
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: 'black',
                                '&:hover': {
                                  color: 'black',
                                },
                              }),
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="w-full lg:w-[48%]">
                        <div className="select2-container mb-4">
                          <label className="control-label">Category</label>
                          <Select
                            options={categories.map((val) => ({
                              value: val.name,
                              label: val.name,
                            }))}
                            value={{
                              label: productCategory,
                              value: productCategory,
                            }}
                            onChange={(selected) => {
                              setProductCategory(selected.value);
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: '1px solid black',
                                boxShadow: 'none',
                                '&:hover': {
                                  border: '1px solid black',
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                backgroundColor: 'black',
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: 'black',
                                '&:hover': {
                                  color: 'black',
                                },
                              }),
                            }}
                          />
                        </div>
                      </Col>
                      <div className="mb-4">
                        <label htmlFor="isArchived">Archived</label>
                        <Input
                          id="isArchived"
                          name="isArchived"
                          type="checkbox"
                          onChange={handleIsArchived}
                          checked={isArchived}
                          style={{
                            width: '20px',
                            height: '20px',
                            marginLeft: '2px',
                            cursor: 'pointer',
                          }}
                          className="w-3 h-3 form-control"
                        />
                      </div>
                    </Col>
                    <Col className="w-6/12">
                      <Row className="flex-wrap gap-4"></Row>
                    </Col>
                  </Row>
                  <Button
                    onClick={updateProduct}
                    type="submit"
                    variant="primary"
                    className="mr-1 waves-effect waves-light"
                  >
                    Save Changes
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card className="pt-6  mt-6">
              <CardBody>
                <CardTitle className="text-[15px]">Variants</CardTitle>
                <Link to={`/product/edit/${productID}/variant/new`}>
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      variant="success"
                      className="btn-rounded waves-effect waves-light mb-6 mr-2"
                    >
                      <i className="mdi mdi-plus mr-1"></i> Create new
                    </Button>
                  </div>
                </Link>
                <Row className="flex-wrap gap-4">
                  {/* {
                                product && product.variants.map((variant, key) => (
                                  <Col className="w-[15%]"  key={`_variant_${key}`}>
                                    <Link className="shadow-sm rounded border border-light w-[100%] h-[100%] flex flex-col justify-center items-center" to={`/product/edit/${productID}/variant/edit/${variant._id}`}>
                                      <img className="w-[100%] h-[100%]" src={variant.image}/>
                                      <span className='text-black mb-2'>{variant.sku}</span>
                                    </Link>
                                  </Col>
                                ))
                              } */}

                  <VariantBulkEditor showTitle={false} productID={productID} />
                </Row>
              </CardBody>
            </Card>
            <Card className="pt-6 my-6">
              <CardBody>
                <CardTitle
                  style={{ cursor: 'pointer' }}
                  onClick={() => setimagesOpen((prev) => !prev)}
                  className="mb-3 text-[15px]"
                >
                  Product Images
                </CardTitle>
                <Collapsible
                  open={imagesOpen}
                  onOpenChange={() => setimagesOpen((prev) => !prev)}
                >
                  <CollapsibleContent>
                    <div className="p-3 mb-3 bg-light rounded">
                      <Row className="flex-wrap gap-4">
                        {product &&
                          product.files &&
                          product.files.map((image, key) => (
                            <Col
                              className="w-[15%]"
                              key={`_product_image_${key}`}
                              onClick={(e) => {
                                updateProduct(e, image);
                              }}
                            >
                              <img
                                className={`rounded ${
                                  image == productImage
                                    ? 'border border-success'
                                    : ''
                                }`}
                                style={{ width: '100%' }}
                                src={image}
                              />
                            </Col>
                          ))}
                      </Row>
                    </div>
                    <div>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>
                                <h4 className="text-[20px] font-medium">
                                  Drop files here or click to upload.
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </CardBody>
            </Card>

            <Card className="pt-6 mt-6">
              <CardBody>
                <div className="flex justify-between items-center mb-4">
                  <CardTitle className="text-[15px]">Parcels</CardTitle>
                  <CreateOrEditParcelDialog
                    productID={productID}
                    refresh={refreshData}
                  />
                </div>

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Dimensions (cm)</TableHead>
                      <TableHead>Weight (kg)</TableHead>
                      <TableHead>Price ($)</TableHead>
                      <TableHead>Description</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  {stateParcels === 'success' ? (
                    <TableBody>
                      {parcels?.map((parcel) => (
                        <TableRow key={parcel._id}>
                          <TableCell className="px-4">{`${parcel.length} x ${parcel.width} x ${parcel.depth}`}</TableCell>
                          <TableCell className="px-4">
                            {parcel.weight}
                          </TableCell>
                          <TableCell className="px-4">
                            {parcel.declared_price}
                          </TableCell>
                          <TableCell className="px-4">
                            {parcel.parcel_description}
                          </TableCell>
                          <TableCell className="px-4">
                            <div className="flex gap-2">
                              <button
                                onClick={() => handleEditParcel(parcel)}
                                className="p-1 hover:bg-gray-100 rounded"
                              >
                                <Pencil className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => handleDeleteParcel(parcel._id)}
                                className="p-1 hover:bg-gray-100 rounded text-red-600"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableSkeleton columns={5} perPage={4} />
                  )}
                </Table>
              </CardBody>
            </Card>
            <Card className="pt-6 mt-6">
              <CardBody>
                <div className="mb-6">
                  <h3 className="text-[15px] font-semibold mb-2">
                    Total Sold by Region
                  </h3>
                  <div className="flex gap-4 mb-4">
                    <Input
                      type="date"
                      className="w-[30%]"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      placeholder="Start Date"
                    />
                    <Input
                      type="date"
                      className="w-[30%]"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      placeholder="End Date"
                    />
                  </div>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Region</TableHead>
                        <TableHead>Total Sold</TableHead>
                      </TableRow>
                    </TableHeader>
                    {stockStatusLoading === 'success' ? (
                      <TableBody>
                        {stockStatus?.length > 0 ? (
                          stockStatus?.map((stat) => (
                            <TableRow key={stat.region}>
                              <TableCell className="px-4">
                                {stat.region}
                              </TableCell>
                              <TableCell className="px-4">
                                {stat.salesVelocity}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} className="px-4 text-center">
                              No data found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableSkeleton columns={2} perPage={10} />
                    )}
                  </Table>
                </div>

                <div>
                  <h3 className="text-[15px] font-semibold mb-2">
                    Stock by Region & Color
                  </h3>
                  <BasicTable
                    fromProduct={true}
                    productID={productID}
                    skus={product?.variants?.map((val) => val.sku)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </SupplyChainNav>
  );
};

export default ProductListPage;
