import useStockStore from '@zustand/useStockStore'

const ContainerSummary = ({ productData }) => {
  const { stock } = useStockStore()
  
  return (
    <div>
      <h4 className="card-title mb-4">CONTAINER SUMMARY</h4>
      <br />
      {productData && stock.length > 0 && 'Count:'}
      {productData &&
        stock.map((line, lineKey) => (
          <div key={lineKey}>
            <span className="text-success">+{line.quantity}</span> x {line.variant?.sku}
          </div>
        ))}
    </div>
  );
};

export default ContainerSummary;
