import { create } from 'zustand'

export const useSearchOrder = create((set, get) => ({
  searchText: "",
  
  setSearchText: (text) => {
    set({ searchText: text })
  },

  
  getSearchText: () => get().searchText
}))
