import React from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { Plus ,AlignJustify } from 'lucide-react';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OrderViewersFab from '../pages/JIFI/orders/components/OrderViewersFab';

const AppRoute = ({
	history,
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => {

	const navigate = useHistory();
	const location = useLocation();
	const isOrderDetailPage = /\/orders\/[a-zA-Z0-9]+/.test(location.pathname);

 return (
		<Route
			{...rest}
			render={props => {

				if (isAuthProtected && !localStorage.getItem("authJWT")) {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: rest.location.pathname
								}
							}}
						/>
					);
				}

			
				return (
					<Layout history={history}>
						<Component {...props} />
						{isAuthProtected &&  <Fab
							icon={<AlignJustify size={16} />}
							mainButtonStyles={{ 
								backgroundColor: '#000',
								width: '40px',
								height: '40px'
							}}
							alwaysShowTitle={false}
							onClick={() => {
								navigate.push('/order-tickets');
							}}
						>
							<Action
								style={{ 
									backgroundColor: '#000',
									width: '35px',
									height: '35px'
								}}
								onClick={() => {
									navigate.push('/fulfillment/feedback');
								}}
							>
								<Plus size={14} />
							</Action>
							{isOrderDetailPage && <OrderViewersFab />}
						</Fab>
						}
					</Layout>
				);
			}}
		/>
	)};

export default AppRoute;
