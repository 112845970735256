import create from 'zustand'

const useStockStore = create((set, get) => ({
  stock: [],
  setStock: (newStock) => set({ stock: newStock }),
  addStock: (item) => set((state) => ({ stock: [...state.stock, item] })),
  updateStock: (id, field, value) => set((state) => {
    const newList = [...state.stock]
    newList[id][field] = value
    return { stock: newList }
  }),
  removeStock: (id) => set((state) => ({
    stock: state.stock.filter((_, key) => key !== id)
  })),
  clearStock: () => set({ stock: [] }),
  getStock: () => get().stock,
}))

export default useStockStore
