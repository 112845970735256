import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Input } from "@shadcn/components/ui/input"
import { Link } from 'react-router-dom';

import { Badge } from '@shadcn/components/ui/badge';

import { CSVLink } from 'react-csv';

import { Switch } from "@shadcn/components/ui/switch"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@shadcn/components/ui/dropdown-menu';

import { useUrlState } from '../../../helpers/useUrlState';
import { createColumnHelper } from '@tanstack/react-table';
import PrintPage from '../monogram/PrintPage';
import PickList from './PickList';

import { backendUrl } from '../../../helpers/consts.js';

import { useReactToPrint } from 'react-to-print';

import '../../Skote/Dashboard/skelton.css';


import Breadcrumbs from '../../../components/Common/Breadcrumb';
import '../datatables.scss';
import useQuery from '../../../helpers/JIFI/useQuery';

import moment from 'moment';
import { MultiSelect } from '@shadcn/components/ui/multi-select';

import superagent from 'superagent';
import { useHistory } from 'react-router-dom';
import SKUToolTip from '../helpers/SKUToolTip.js';
import useSKULevels from '../helpers/useSKULevels.js';
import useUpcomingStock from '../helpers/useUpcomingStock.js';
import useSKUStatus from '../helpers/useSKUStatus.js';
import useUserStore from '../../../zustand/useUserStore.js';
import { Checkbox } from '@shadcn/components/ui/checkbox';

import { Button } from '@shadcn/components/ui/button';
import {
  Table as TableShadcn,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
} from '@shadcn/components/ui/table';

import {

  flexRender,
  getCoreRowModel,

  useReactTable,
} from '@tanstack/react-table';
import { Dialog, DialogHeader, DialogContent, DialogDescription , DialogFooter, DialogTitle} from '@shadcn/components/ui/dialog';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@shadcn/components/ui/select';
import { Card, CardContent } from '@shadcn/components/ui/card';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryParams } from '@helpers/JIFI/useQueryParams';
import { useSearchOrder } from '@zustand/useSearchOrder';



const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
];

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning';
  if (fulfillment === 'awaiting-stock') return 'secondary';
  const conversion = {
    fulfilled: 'success',
    partially_fulfilled: 'error',
  };
  return conversion[fulfillment];
};

const pickToName = (pick) => {
  const conversion = {
    none: 'UNPICKED',
    monogram: 'TO-MONOGRAM',
    fulfillment: 'TO-SHIP',
    EWE: 'EWE',
    QV: 'QV',
    EWESYD: 'EWE Sydney',
    B360: 'B360',
    UNIS: 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    AIRBOX: 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  };
  return conversion[pick] || pick;
};

const pickToBadge = (pick) => {
  const conversion = {
    none: 'warning',
    monogram: 'info',
    fulfillment: 'success',
    EWE: 'success',
    QV: 'success',
    B360: 'success',
    EWESYD: 'success',
    UNIS: 'success',
    'EWE-MONO': 'info',
    AIRBOX: 'success',
    'AIRBOX-MONO': 'info',
  };
  return conversion[pick] || 'success';
};

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2],
  ].filter(function (el) {
    return el != null;
  });
};


const TablePagination =  ({
  count,
  page,
  setPage,
  perPage,
  setPerPage,
}) => {
  const pageCount = Math.ceil(count / perPage);
  const pages = paginate(
  Array.from({ length: pageCount }, (_, i) => i + 1), page)

  const handlePageChange = (page) => setPage(page);
  const handlePerPageChange = (newPerPage) => setPerPage(Number(newPerPage));

  return (
    <div className="flex justify-between items-center space-x-4">
     
      <div className="w-36">
        <Select
          
          defaultValue={perPage}
          onValueChange={handlePerPageChange}
        >
          <SelectTrigger>
            <SelectValue placeholder="Items per page" />
          </SelectTrigger>
          <SelectContent>
            {perPageOptions.map((option, key) => {
             return (
              <SelectItem key={key} value={option.value}>
                {option.label} 
              </SelectItem>
            )
            })}
          </SelectContent>
        </Select>
      </div>

      
      <div className="space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>

        
        {pages.map((pageNumber) => (
          <Button
            key={pageNumber}
            variant={pageNumber === page ? 'default' : 'outline'}
            size="sm"
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Button>
        ))}

        <Button
          variant="outline"
          size="sm"
          onClick={() => setPage(page + 1)}
          disabled={page === pageCount}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
const removeQuotes = (str) => {
  return str?.replace(/['"]+/g, '')
}
const SearchInput = ({...props}) => {
  let localSearchTerm = useQueryParams().get('searchTerm')
  localSearchTerm = removeQuotes(localSearchTerm)
  const searchTerm = useSearchOrder((state) => state.searchText)
  const setSearchTerm = useSearchOrder((state) => state.setSearchText)

  useEffect(() => {
    setSearchTerm(localSearchTerm)
  }, [localSearchTerm])

  return (
    <Input
      type="text" 
      className="h-9"
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      {...props}
    />
  )
}

const columnHelper = createColumnHelper();

const isDefaultState = (stateData) => {
  // Check if any filters are active
  const filterKeys = [
    'selectedRegion',
    'selectedSource', 
    'selectedPickStatus',
    'selectedFlagType',
    'selectedAssignedUser',
    'selectedState',
    'selectedStatus',
    'selectedOrder',
    'selectedShipping',
    'orderStartDate',
    'orderEndDate'
  ];

  return filterKeys.every(key => {
    const value = stateData[key];
    return !value || (Array.isArray(value) && value.length === 0);
  });
};

const OrdersPage = ({ url, initValues, location }) => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)

  const [selectAll, setSelectAll] = useState(false); 
  
  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedSource: null,
    filterSource: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedFlagType: null,
    filterFlagType: null,
    selectedAssignedUser: null,
    filterAssignedUser: null,
    selectedState: null,
    filterState: null,
    selectedStatus: null,
    filterStatus: null,
    selectedOrder: null,
    filterOrder: null,
    selectedShipping: null,
    filterShipping: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    regionOptions: [
      { label: 'Australia', value: 'Australia' },
      { label: 'China', value: 'China' },
      { label: 'International', value: 'International' },
    ],
    orderSourceOptions: [
      { label: 'AU Shopify', value: 'shopify_au' },
      { label: 'TMALL', value: 'tmall' },
      { label: 'US Shopify', value: 'shopify_us' },
      { label: 'NZ Shopify', value: 'shopify_nz' },
      { label: 'UK Shopify', value: 'shopify_uk' },
      { label: 'QV Store', value: 'qv_store' },
      { label: 'Greene St', value: 'greene' },
      { label: 'Galeries Store', value: 'galeries_store' },
      { label: 'Bondi Store', value: 'bondi' },
      { label: '80 Collins', value: '80_collins' },
      { label: 'HQ Store', value: 'hq_store' },
      { label: 'Pitt St', value: 'pitt_st' },
      { label: 'PacFair', value: 'pacfair' },
      { label: 'Goop', value: 'goop' },
      { label: 'Custom', value: 'custom' },
    ],
    stateOptions: [
      { label: 'KY', value: 'KY' },
      { label: 'VIC', value: 'Victoria' },
      { label: 'NSW', value: 'New South Wales' },
      { label: 'ACT', value: 'Australian Capital Territory' },
      { label: 'QLD', value: 'Queensland' },
      { label: 'WA', value: 'Western Australia' },
      { label: 'SA', value: 'South Australia' },
      { label: 'NT', value: 'Northern Territory' },
      { label: 'TAS', value: 'Tasmania' },
    ],
    stepOptions: [
      { label: 'Unfulfilled', value: 'unfulfilled' },
      { label: 'Fulfilled', value: 'fulfilled' },
      { label: 'Partially fulfilled', value: 'partially-fulfilled' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
    orderOptions: [
      { label: 'Has personalisation', value: 'Has-personalisation' },
      { label: 'Flagged', value: 'Flagged' },
      { label: 'POS', value: 'POS' },
      { label: 'Ready to ship', value: 'ready-to-ship' },
      { label: 'Partially fulfilled', value: 'partially-fulfilled' },
      { label: 'Contains Pets', value: 'contains-pets' },
      { label: 'Art Ready', value: 'art-ready' },
      { label: 'Art Not Ready', value: 'art-not-ready' },
    ],
    shippingOptions: [
      { label: 'Standard', value: 'standard' },
      { label: 'Express', value: 'express' },
      { label: 'Same Day', value: 'same-day' },
      { label: 'Next Day', value: 'next-day' },
      { label: 'QV Pickup', value: 'pickup' },
      { label: 'HQ Pickup', value: 'pickup_hq' },
      { label: 'Uber', value: 'Uber' },
      { label: 'Overnight', value: 'overnight' },
    ],
    pickStatusOptions: [
      { label: 'Unpicked', value: 'none' },
      { label: 'To Ship', value: 'fulfillment' },
      { label: 'To Monogram', value: 'monogram' },
      { label: 'EWE Monogram', value: 'EWE-MONO' },
      { label: 'EWE', value: 'EWE' },
      { label: 'EWE Sydney', value: 'EWESYD' },
      { label: 'B360', value: 'B360' },
      { label: 'UNIS', value: 'UNIS' },
    ],
    flagTypesOptions: [{}],
  };

  const [stateData, setStateData, done] = useUrlState(initValues || initialState);
  
  const history = useHistory();
  const [modal, setModal] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  
  
  const userList = useUserStore((state) => state.userList);

  const [flagTypesFilters] = useQuery({
    url: `/orders/getFlagTypes`,
  });

  useEffect(() => {
    if (flagTypesFilters) {
      let flagFiltersOptions = flagTypesFilters.map((e) => ({
        label: e.name,
        value: e._id,
      }));
      setStateData((prevState) => {
        return {
          ...prevState,
          flagTypesOptions: flagFiltersOptions,
        };
      });
    }
  }, [flagTypesFilters]);

  const [exportOpen, setExportModal] = useState();
  const toggleExportOpen = () => setExportModal((prev) => !prev);

    
  const handleKeyDown = (e) => {
    

    if (e.key === 'Enter' || e.keyCode === 13) {
      console.log("getSearchTerm keydown", getSearchTerm())
      setStateData((prevState) => {
        return {
          ...prevState,
          searchTerm: getSearchTerm(),
        };
      });
    }
  };

  const handleSubmitSearchTerm = () => {
    const searchTerm = getSearchTerm()
    console.log("searchTerm", searchTerm)
    setStateData((prevState) => {
      return {
        ...prevState,
        searchTerm: searchTerm,
      };
    });
    
  };

  const handleSelectRegion = (selectedRegion) => {
    
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedRegion: selectedRegion,
        filterRegion: selectedRegion,
      };
    });
  };

  const handleSelectFlagType = (selectedFlagType) => {
    
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedFlagType: selectedFlagType,
        filterFlagType: selectedFlagType,
      };
    });
  };

  const handleSelectAssignedUser = (selectedAssignedUser) => {
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedAssignedUser: selectedAssignedUser,
        filterAssignedUser: selectedAssignedUser,
      };
    });
  };

  const handleSelectPickStatus = (selectedPickStatus) => {
    
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedPickStatus: selectedPickStatus,
        filterPickStatus:selectedPickStatus

      };
    });
  };

  const handleSelectSource = (selectedSource) => {
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedSource: selectedSource,
        filterSource: selectedSource,
      };
    });
  };

  const handleSelectStatus = (selectedStatus) => {
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedStatus: selectedStatus ?? [],
        filterStatus: selectedStatus ?? [],
      };
    });
  };

  const handleSelectOrder = (selectedOrder) => {
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedOrder: selectedOrder,
        filterOrder: selectedOrder,
      };
    });
  };

  const handleSelectShipping = (selectedShipping) => {
    setStateData((prevState) => {
      return {
        ...prevState,
        selectedShipping: selectedShipping,
        filterShipping: selectedShipping,
      };
    });
  };

  const ResetField = (e) => {
    e.preventDefault();
    history.push('/orders/');
  };

  const handleChange = useCallback((e) => {
    e.persist();
    setStateData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate:
          e.target.name === 'orderStartDate'
            ? moment(e.target.value).format('YYYY-MM-DD')
            : prevState.filterStartDate,
        filterEndDate:
          e.target.name === 'orderEndDate'
            ? moment(e.target.value).format('YYYY-MM-DD')
            : prevState.filterEndDate,
      };
    });
  }, [setStateData]);

  const togglemodal = () => {
    setModal((prev) => !prev);
    // printLabels()
  };

  const [radioState, changeRadioState] = useState({
    radio1: true,
    radio2: false,
    radio3: false,
  });

  const setRadioState = (value) => {
    if (value.radio2 == true || value.radio3 == true) {
      handleSelectStatus(['unfulfilled']);
    }
    changeRadioState(value);
  };
  const pickFilter = radioState.radio2
    ? 'monogram'
    : radioState.radio3
    ? 'fulfillment'
    : undefined;

  const [refresh, setRefresh] = useState(true);
  const refreshData = () => {
    setRefresh((prev) => !prev);
  };
  const [searchMode, setSearchMode] = useState('simple');
  
  const [data, state] = useQuery({
    refresh,
    url,
    queryData: {
      ...(pickFilter ? { pickCategory: pickFilter } : {}),
      page: page,
      perPage: perPage,
      searchTerm: stateData.searchTerm?.replace(/#/g, ''),
      ...(searchMode === 'advanced' ? { 
      filterStatus: stateData.filterStatus,
      filterStartDate: stateData.filterStartDate,
      filterEndDate: stateData.filterEndDate,
      filterOrder: stateData.filterOrder,
      filterShipping: stateData.filterShipping,
      filterState: stateData.filterState,
      filterRegion: stateData.filterRegion,
      filterPickStatus: stateData.filterPickStatus,
      filterSource: stateData.filterSource,
      filterFlagType: stateData.filterFlagType,
      filterAssignedUser: stateData.filterAssignedUser,
      } : {}),
    },
  });

  const orders = data && data.data;
  const count = data && data.count;
  

  const {
    orderStartDate,
    orderEndDate,
    selectedRegion,
    selectedState,
    selectedStatus,
    selectedOrder,
    selectedShipping,
    searchTerm,
    regionOptions,
    stepOptions,
    orderOptions,
    shippingOptions,
    flagTypesOptions,
    pickStatusOptions,
    selectedPickStatus,
    orderSourceOptions,
    selectedSource,
    selectedFlagType,
    selectedAssignedUser,
  } = stateData;

  const [selected, setSelected] = useState([]);

  useEffect(() => {
  
    if (selectAll && orders) {
      const selectedIds = orders.map((value, index) => {
        
        return value._id
      })

      
      setSelected(selectedIds); 
    } else {
      setSelected([]);
    }
  }, [selectAll, orders]);

  const selectedIds =
    orders && orders.filter((val, key) =>  {
      return       selected.includes(val._id)
    }
);

    
  
  const savedUrls = localStorage.getItem('savedUrls')
    ? JSON.parse(localStorage.getItem('savedUrls'))
    : [];
  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [filterName, setFilterName] = useState('');
  const saveCurrentUrl = () => {
    const url = location.pathname + location.search;
    const name = filterName;
    localStorage.setItem(
      'savedUrls',
      JSON.stringify([...savedUrls, { url, name }])
    );
    setSaveModal(false);
    setFilterName('');
  };

  
  const getSearchTerm = useSearchOrder((state) => state.getSearchText)

  const handleDelete = (deleteKey) => {
    const newSavedUrls = savedUrls.filter((savedUrl, key) => key !== deleteKey);
    localStorage.setItem('savedUrls', JSON.stringify(newSavedUrls));
  };
  const findSKULevel = useSKULevels();
  const findUpcomingStock = useUpcomingStock();
  const findSKUStatus = useSKUStatus();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        header: () => (
          <div className="align-middle">
            <Checkbox
              checked={selectAll}
              onCheckedChange={() => setSelectAll(!selectAll)}
              className="w-4 h-4 border-slate-950"
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="align-middle">
            <Checkbox
           
              checked={selected.includes(row.original._id)}
              onCheckedChange={() => {
                
                setSelected((prevSelected) =>
                  prevSelected.includes(row.original._id)
                    ? prevSelected.filter((i) => i !== row.original._id)
                    : [...prevSelected, row.original._id]
                )
              }
                
              }
              className="w-4 h-4 border-slate-950"
            />
          </div>
        ),
      }),
      

      columnHelper.accessor('name', {
        header: () => 'Order ID',
        cell: ({ row: { original } }) => {

          
          return <div className="text-[13px] min-w-max !px-4">
            <Link to={`/orders/${original['_id']}`} className="text-body font-weight-bold">{original.name}</Link>
            
            </div>;
        },
      }),

      columnHelper.accessor('pick_status', {
        header: () => (<div className='min-w-max'>Pick Status</div>),
        cell: ({ row: { original } }) => (
          <Badge
            className={`!px-4 min-w-max text-[12px] badge-soft-${pickToBadge(original.pick_status)}`}
            color={pickToBadge(original.pick_status)}
            
          >
            {pickToName(original.pick_status)}
          </Badge>
        ),
      }),

    

      columnHelper.accessor('fulfillment_status', {
        header: () => 'Fulfillment',
        cell: ({ row: { original } }) => {
          return (
            <Badge
              className={`!px-4 text-[12px] badge-soft-${fulfillmentToBadge(
                original.fulfillment_status
              )}`}
              
            >
              {original.fulfillment_status
                ? original.fulfillment_status.replace('_', ' ').toUpperCase()
                : 'UN-FULFILLED'}
            </Badge>
          );
        },
      }),

      columnHelper.accessor('order_source', {
        header: () => 'Source',
        cell: ({ row: { original } }) => {
          return <p className='!px-4 text-[13px]'>{original.order_source?? ""}</p>;
        },
      }),
      
      // Shipping column
      columnHelper.accessor('shipping_type', {
        header: () => 'Shipping',
        cell: ({ row: { original } }) => {
          return <p className='!px-4 text-[13px]'>{original.shipping_type } </p>;
        },
      }),
      
      
      columnHelper.accessor('shipping_address', {
        header: () => 'Region',
        cell: ({ row: { original } }) => {
          const { province, country } = original.shipping_address || {};
          return (
          <div className='min-w-max !px-4 text-[13px]'>
          {province && country ? `${province}, ${country}` : '' }
          </div>);
        },
      }),

      columnHelper.accessor('shipping_address.name', {
        header: () => ( <div className='min-w-max'>Billing Name </div>),
        cell: ({ row: { original } }) => {
          return <div className='min-w-max !px-4 text-[13px]'>
        
          {original.shipping_address?.name || ''}
          
          </div>
        },
      }),

      columnHelper.accessor('orderCreated', {
        header: () => 'Date',
        cell: ({ row: { original } }) => {
          return <div className='min-w-max text-[13px]'>
          {original.orderCreated ? new Date(original.orderCreated).toDateString() : ""}
          </div>
        },
      }),

      columnHelper.accessor('skus', {
        header: () => 'SKUs',
        cell: ({ row: { original }, row }) => {
        
        
          if (original.lineSum?.length === 1) {
            
            return (
              <Badge
                key={original.lineSum[0].sku}
                className="font-size-12 badge-soft-primary"
                variant="primary"
                
              >
                <SKUToolTip sku={original.lineSum[0].sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
              </Badge>)
          }
          
          let firstSKU = original.lineSum[0]?.sku || '';
          let firstQuantity = original.lineSum[0]?.quantity || '';

          
          return (
            <div className="flex items-center">
            <Badge
              key={firstSKU}
              className="badge-soft-primary font-size-12"
              variant="primary"
              
            >
              <SKUToolTip sku={firstSKU} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /> x {firstQuantity}   
            
            </Badge>
            <button
              onClick={() => row.toggleExpanded()}
              aria-label="expand row"
              className={`ml-2 w-4 h-4 flex items-center justify-center rounded-full text-white ${
                row.getIsExpanded() ? 'bg-blue-500' : 'bg-gray-500'
              }`}
            >
              {row.getIsExpanded() ? '-' : '+'}
            </button>
            </div>
          );
        },
      }),

     
      columnHelper.accessor('total_price', {
        header: () => 'Total',
        cell: ({ row: { original } }) => {
          return <p className='!px-4 text-[13px]'> {original.total_price ? `$${original.total_price}` : '$0.00' } </p>;
        },
      }),

      columnHelper.accessor('financial_status', {
        header: () => 'Payment',
        cell: ({ row: { original } }) => {
          return (
            <Badge
              className={`!px-4 text-[12px] badge-soft-${original.badgeClass || 'secondary'}`}
              
            >
              {original.financial_status?.toUpperCase() || 'No Status'}
            </Badge>
          );
        },
      }),

      columnHelper.accessor('flagtype.name', {
        header: () => <div className='min-w-max'>Flag Type</div> ,
        cell: ({ row: { original } }) => {
          return <p className='!px-4 min-w-max text-[13px]'> {original.flagtype?.name || ''} </p>;
        },
      }),

      columnHelper.accessor('assigned_user.userName', {
        header: () => <div className='min-w-max'>Assigned User</div>,
        cell: ({ row: { original } }) => {
          return  <p className='!px-4 min-w-max text-[13px]'> {original.assigned_user?.userName || ''} </p>;
        },
      }),

    ],
    [selected, findSKULevel, findUpcomingStock, findSKUStatus] 
  );

  const dataTables = orders?.map((order) => {
    let skuCount = {};
    order.line_items.forEach((val) => {
      skuCount[val.sku] = (skuCount[val.sku] || 0) + val.quantity;
    });

    const lineSum = Object.keys(skuCount).map((sku) => ({
      sku,
      quantity: skuCount[sku],
    }));

    return { ...order, lineSum, subRows: lineSum };
  });



  
  const table = useReactTable({
    data: dataTables ?? [],
    columns,
   
    rowCount: count,
    state: {
      pagination: {
        pageIndex: page - 1,
        pageSize: perPage,
      },
    },
    
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
  });

  const addOn = (
    <>
      <DropdownMenu
        isOpen={isSaveDropdownOpen}
        direction="left"
        
      >
        <DropdownMenuTrigger className='size-10 bg-[#34c38f] text-white rounded-sm'>
          
        <i className="bx bx-save"></i>
        </DropdownMenuTrigger>
       
        <DropdownMenuContent>
          {savedUrls.map((savedUrl, key) => (
            <DropdownMenuItem>
              <div className="d-flex align-items-center justify-content-between">
                <Link key={key} to={savedUrl.url}>
                  {savedUrl.name}
                </Link>
                <div className="text-danger ml-4 cursor-pointer" onClick={() => handleDelete(key)}>
                  {' '}
                  x{' '}
                </div>
              </div>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem
            className="text-success"
            onClick={() => setSaveModal(true)}
          >
            + Save current
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        size="lg"
        open={saveModal}
        onOpenChange={() => setSaveModal((prev) => !prev)}
        
      >

        <DialogContent>
        <DialogHeader>
          <p className='text-[16px] font-medium'>
          Save Filter
          </p>
 
        </DialogHeader>
        <div>
          <div className='mb-4'>
            <label> Filter Name </label>
            <Input
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="success" onClick={saveCurrentUrl}>
            Save
          </Button>
        </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
  
  // Add effect to switch modes when filters are active
  useEffect(() => {
    if (selected.length > 0 || !isDefaultState(stateData)) {
      setSearchMode('advanced');
    }
  }, [selected, stateData]);

  // Add effect to handle radio state changes
  useEffect(() => {
    if (!radioState.radio1) {
      setSearchMode('advanced');
    }
  }, [radioState]);

  return (
    <>
      <div className="page-content">
      <div className="mx-auto px-4 xl:max-w-[85%]">
          <Breadcrumbs
            title="Fulfillment"
            addOn={addOn}
            breadcrumbItem="All Orders"
          />
          <div className="flex flex-wrap">
          <div className="w-full px-2">
              <Card className="mb-4">
                <CardContent className="p-4">
                
                
                <div className="flex flex-wrap items-center justify-between gap-4 w-full mb-4">
                  <div className="lg:w-1/2 w-full">
                    {perPage <= 20 && searchMode === 'advanced' && (
                      <div className="space-y-2">
                        <label className="text-sm font-medium">
                          PICK CATEGORY
                        </label>
                        <div className="flex flex-wrap gap-2">
                          <button
                            className={`px-4 py-2 rounded-md transition-colors ${
                              radioState.radio1
                                ? '!bg-primary text-primary-foreground hover:bg-primary/90'
                                : 'bg-background border border-black hover:bg-gray-100'
                            }`}
                            onClick={() =>
                              setRadioState({
                                radio1: true,
                                radio2: false,
                                radio3: false,
                              })
                            }
                          >
                            None
                          </button>
                          <button 
                            className={`px-4 py-2 rounded-md transition-colors ${
                              radioState.radio2
                                ? '!bg-primary text-primary-foreground hover:bg-primary/90'
                                : 'bg-background border border-black hover:bg-gray-100'
                            }`}
                            onClick={() =>
                              setRadioState({
                                radio2: true,
                                radio1: false,
                                radio3: false,
                              })
                            }
                          >
                            Monogram
                          </button>
                          <button
                            className={`px-4 py-2 rounded-md transition-colors ${
                              radioState.radio3
                                ? '!bg-primary text-primary-foreground hover:bg-primary/90'
                                : 'bg-background border border-black hover:bg-gray-100'
                            }`}
                            onClick={() =>
                              setRadioState({
                                radio3: true,
                                radio2: false,
                                radio1: false,
                              })
                            }
                          >
                            Fulfillment
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center gap-8">
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium">
                        {searchMode === 'simple' ? 'Simple' : 'Advanced'}
                      </span>
                      <Switch
                        checked={searchMode === 'advanced'}
                        onCheckedChange={(checked) =>
                          setSearchMode(checked ? 'advanced' : 'simple')
                        }
                      />
                    </div>

                    <Button 
                      variant="secondary" 
                      className="text-base"
                      onClick={toggleExportOpen}
                    >
                      Export
                    </Button>
                  </div>
                </div>
                  <div className="mb-2 w-full flex flex-row flex-wrap !gap-4">
                  
                  

                    {searchMode === 'simple' && (
                      <div className="w-full flex gap-4">
                        <div className="flex-1">
                          <SearchInput
                            type="text"
                            className="h-9 w-full" 
                            placeholder="Search orders..."
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        <Button
                          variant="secondary"
                          onClick={handleSubmitSearchTerm}
                        >
                           Search
                        </Button>
                      </div>
                    )}

                    
                    {searchMode === 'advanced' && (
                      <>
                        <div className="sm:w-3/12 w-full">
                          <div className='flex flex-row w-full flex-wrap'>
                            <div className="sm:w-9/12 pr-4">
                              <div className="select2-container mb-4">
                                <label className="control-label">FILTER</label>
                                <div className="search-box mb-2 d-block">
                                  <div className="position-relative justify-content-space-between">
                                    <SearchInput
                                      type="text"
                                      className="h-9"
                                      placeholder="Search..."
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sm:w-3/12 d-flex justify-content-end align-items-end">
                              <div className="select2-container mb-4">
                                <label className="control-label"> </label>
                                <Button
                                  type="button"
                                  variant="success"
                                  className="btn-rounded waves-effect waves-light text-base"
                                  onClick={handleSubmitSearchTerm}
                                >
                                  {' '}
                                  Search{' '}
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div className='flex flex-row flex-wrap w-full'>
                            <div className='sm:w-6/12 pr-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">STARTING</label>
                                <input
                                  className="form-control border border-black"
                                  name="orderStartDate"
                                  value={orderStartDate || ''}
                                  onChange={handleChange}
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="example-date-input"
                                />
                              </div>
                            </div>
                            <div className='sm:w-6/12 pl-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">ENDING</label>
                                <input
                                  className="form-control border border-black"
                                  name="orderEndDate"
                                  value={orderEndDate || ''}
                                  onChange={handleChange}
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="example-date-input"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='sm:w-4/12 w-full'>
                          <div className='flex flex-row w-full flex-wrap'>
                            <div className='sm:w-6/12 w-full pr-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">REGION</label>
                                {done && <MultiSelect
                                  options={regionOptions}
                                  className="py-0"
                                  onValueChange={handleSelectRegion}
                                  defaultValue={selectedRegion || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                            <div className='sm:w-6/12 w-full pl-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">ORDER SOURCE</label>
                                {done && <MultiSelect
                                  options={orderSourceOptions}
                                  onValueChange={handleSelectSource}
                                  defaultValue={selectedSource || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                          </div>
                          <div className='flex flex-row w-full flex-wrap'>
                            <div className='sm:w-6/12 w-full pr-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">SHIPPING</label>
                                {done && <MultiSelect
                                  options={shippingOptions}
                                  onValueChange={handleSelectShipping}
                                  defaultValue={selectedShipping || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                            <div className='sm:w-6/12 w-full pl-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">STEP</label>
                                {done && <MultiSelect
                                  options={stepOptions}
                                  onValueChange={handleSelectStatus}
                                  defaultValue={selectedStatus}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='sm:w-4/12 w-full'>
                          <div className='flex flex-row w-full flex-wrap'>
                            <div className='sm:w-6/12 w-full pr-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">ORDER</label>
                                {done && <MultiSelect
                                  options={orderOptions}
                                  onValueChange={handleSelectOrder}
                                  defaultValue={selectedOrder || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                            <div className='sm:w-6/12 w-full pl-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">Flag Type</label>
                                {done && <MultiSelect
                                  options={flagTypesOptions ?? []}
                                  onValueChange={handleSelectFlagType}
                                  defaultValue={selectedFlagType || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                          </div>
                          <div className='flex flex-row w-full flex-wrap'>
                            <div className='sm:w-6/12 w-full pr-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">PICK STATUS</label>
                                {done && <MultiSelect
                                  options={pickStatusOptions ?? []}
                                  onValueChange={handleSelectPickStatus}
                                  defaultValue={selectedPickStatus || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                            <div className='sm:w-6/12 w-full pl-2'>
                              <div className="select2-container mb-4">
                                <label className="control-label">USER</label>
                                {done && <MultiSelect
                                  options={
                                    userList?.map((val) => ({
                                      label: val.userName,
                                      value: val._id,
                                    })) ?? []
                                  }
                                  onValueChange={handleSelectAssignedUser}
                                  defaultValue={selectedAssignedUser || []}
                                  placeholder="All"
                                  variant="inverted"
                                  animation={2}
                                  maxCount={2}
                                />}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='sm:w-1/12 w-full'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div className="select2-container mb-4">
                            <Button
                              type="button"
                              variant="info"
                              className="btn-rounded waves-effect waves-light text-base"
                              onClick={() => {
                                ResetField();
                                const params = new URLSearchParams(window.location.search);
                                params.delete('advanced');
                                window.history.pushState({}, '', `?${params.toString()}`);
                                window.location.reload();
                              }}
                            >
                              {' '}
                              Reset
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                      {/* <FormGroup className="select2-container mt-4">
                        <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New Order</Button>
                      </FormGroup> */}
                    
                  
                  {perPage <= 20 &&
                    radioState.radio1 === false &&
                    selectedIds?.length > 0 && (
                      <div className="flex  flex-row flex-wrap mb-4">
                        <div className="w-full">
                          <Button variant="primary" onClick={togglemodal}>
                            {' '}
                            Generate Picklist{' '}
                          </Button>
                        </div>
                      </div>
                    )}
                  
                  <div className="table-responsive">
                    <TableShadcn className="w-full table-auto">
                      <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <TableHead key={header.id}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </TableHead>
                            ))}
                          </TableRow>
                        ))}
            
                      </TableHeader>
                      {
                      state === 'loading' ?
                      (
                        
                       <TableSkeleton perPage={perPage} columns={columns.length} checkboxIndex={[0]} />
                      )
                      :
                      <TableBody>
                        {table.getRowModel().rows?.length ? (
                          table.getRowModel().rows.map((row) => (
                            <React.Fragment key={row.id}>
                              <TableRow style={row.original?.flagged ? { background: "#fde1e1" } : {}}>
                                { 
                                row.original?.lineSum[0]?.sku  && row.getVisibleCells().map((cell) => (
                                  
                                  <TableCell 
                                  isCheckBox={cell.column.id === 'select'}
                                  key={cell.id}>
                                  
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>

                             
                              {row.getIsExpanded() &&
                                row.subRows?.length > 0 &&
                                row.subRows.map((subRow, subRowIndex) => (
                                  subRowIndex !== 0 &&
                                  <TableRow
                                    key={`${row.id}-subRow-${subRowIndex}`}
                                  >
                                    {row
                                      .getVisibleCells()
                                      .map((cell, index) => (
                                        <TableCell key={index} className="p-2">
                                
                                          {index === 9 ? (
                                             <Badge
                                             key={subRow.original.sku}
                                             className="font-size-12 badge-soft-primary"
                                             color="primary"
                                            
                                           >
                                             <SKUToolTip sku={subRow.original.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /> x {subRow.original.quantity}   
                                           </Badge>
                                          ) : 
                                          null}
                                        </TableCell>
                                      ))}
                                  </TableRow>
                                ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={columns?.length}
                              className="h-24 text-center"
                            >
                              No results.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
}
                    </TableShadcn>
                  </div>
                  <div className="flex items-center justify-end space-x-2 py-4">
        
        {/* <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPage(page-1)}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => 
            setPage(page+1)
            }
            
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div> */}
        <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
      </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>

      
        <PickModal
          refresh={refreshData}
          monogram={radioState.radio2 ? true : false}
          togglemodal={togglemodal}
          modal={modal}
          selectedIds={selectedIds}
        />
      

      
        <ExportModal exportOpen={exportOpen ?? false} toggleModal={toggleExportOpen} stateData={stateData} />
      
    </>
  );
};

const ExportModal = ({ exportOpen, toggleModal, stateData }) => {
  

  
  const [data, state] = useQuery({
    url: '/orders/export',
    queryData: {
      searchTerm: stateData.searchTerm,
      filterStatus: stateData.filterStatus,
      filterStartDate: stateData.filterStartDate,
      filterEndDate: stateData.filterEndDate,
      filterOrder: stateData.filterOrder,
      filterShipping: stateData.filterShipping,
      filterState: stateData.filterState,
      filterRegion: stateData.filterRegion,
      filterPickStatus: stateData.filterPickStatus,
      filterSource: stateData.filterSource,
      filterFlagType: stateData.filterFlagType,
    },
    runQuery: exportOpen
  });
  const orders = data && data.lines;
  const count = data && data.count;

  return (
    <Dialog open={exportOpen} onOpenChange={toggleModal}>
      <DialogContent>
      <DialogHeader>
        <DialogTitle>
        Export Orders
        </DialogTitle></DialogHeader>
      <DialogDescription>
      Exporting {count} orders.
      </DialogDescription>
      
      <DialogFooter>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}
        >
          {!data ? (
            <div className="spinner-grow text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CSVLink data={orders} filename={`order_export.csv`}>
              <Button variant="primary"> Download </Button>
            </CSVLink>
          )}
        </div>
      </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const PickModal = ({ togglemodal, selectedIds, monogram, refresh, modal }) => {
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [monogramRecords, setMonogramRecords] = useState([]);
  const labelData = monogramRecords.map((data) => ({
    monogramId: data.monogramId,
    location: data.monogram_position,
    letters: data.monogram_text,
    font: data.monogram_font,
    mainCol: data.monogram_color,
    backCol: data.monogram_shadow,
    created_at: data.created_at,
    shopifyId: data.orderName,
    sku: data.sku,
    quantity: data.quantity,
    shippingType: data.shippingType,
    lineItems: data.lineItems,
    country: data.country,
    embroided: data.embroided,

    pet: data.pet,
    monogram_pets: data.monogram_pets && JSON.parse(atob(data.monogram_pets)),
    monogram_airtable_line: data.monogram_airtable_line,
    completedThumbnails: data.completedThumbnails,
  }));

  const [labelsPrinted, setLabelsPrinted] = useState(false);
  const [pickListPrinted, setPickListPrinted] = useState(false);

  useEffect(() => {
    if (modal && selectedIds?.length> 0) {
      loadData();
    }
  }, [modal]);
  
  useEffect(() => {
    // loadData();
    setLabelsPrinted(false);
    setPickListPrinted(false);
  }, [selectedIds, monogram]);

  const loadData = () => {
    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/getPicklist`)
      .send({
        ids: selectedIds?.map((val) => val._id),
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          setOrderData(response.body);
          let lineItems = [];
          let monogramIndex = 0;
          response.body.map((order) => {
            const data = order.line_items.map((lineItem, key) => {
              let monogramId;
              if (lineItem.monogram) {
                monogramId = `${order.name.replace('#', '')}-${monogramIndex}`;
                monogramIndex += 1;
              } else {
                monogramIndex = 0;
              }
              if (lineItem.fulfillment_status !== 'deleted') {
                lineItems.push({
                  ...lineItem,
                  shippingType: order.shipping_type,
                  created_at: order.orderCreated,
                  country: order.shipping_address.country,
                  orderName: order.name,
                  completedThumbnails: order.completedThumbnails,
                  ...(monogramId ? { monogramId: monogramId } : {}),
                  lineItems: order.line_items
                    .filter(
                      (val) =>
                        val._id !== lineItem._id &&
                        val.fulfillment_status !== 'deleted'
                    )
                    .map((val) =>
                      Array(val.quantity)
                        .fill()
                        .map((i) => val.sku)
                    )
                    .flat(),
                });
              }
            });
            monogramIndex = 0;
            return data;
          });
          if (monogram) {
            setSelectedLineItems(
              lineItems.filter(
                (val) => val.fulfillment_status === 'to-be-monogrammed'
              )
            );
            setMonogramRecords(
              lineItems.filter(
                (val) =>
                  val.monogram === true && val.fulfillment_status !== 'deleted'
              )
            );
          } else {
            setSelectedLineItems(
              lineItems.filter(
                (val) => val.fulfillment_status === 'unfulfilled'
              )
            );
            setMonogramRecords(
              lineItems.filter(
                (val) =>
                  val.monogram === false && val.fulfillment_status !== 'deleted'
              )
            );
          }

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const save = () => {
    if (labelsPrinted && pickListPrinted) {
      setLoading(true);
      superagent
        .post(`${backendUrl}/orders/setPickStatus`)
        .send({
          orderIds: selectedIds.map((val) => val._id),
          status: monogram ? 'monogram' : 'fulfillment',
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoading(false);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            togglemodal();
            refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const packingSlipRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingSlipRef.current,
  });
  const handlePrintLabel = () => {
    printLabels();
    setLabelsPrinted(true);
  };

  const picklistRef = useRef();
  const printPicklist = useReactToPrint({
    content: () => picklistRef.current,
  });
  const handlePrintPicklist = () => {
    printPicklist();
    setPickListPrinted(true);
  };

  
  return (
    <Dialog open={modal} onOpenChange={togglemodal}>
      <div className="modal-content">
          <DialogContent>
             <DialogHeader toggle={togglemodal}>Create Picklist</DialogHeader>
      
          <div>
            {
              loading ? (
                  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                    <div className="spinner-grow text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', borderRight: '1px solid #eeeeeeee'}}>
                      <Button variant={labelsPrinted ? 'secondary' : 'primary'} className="m-2" onClick={handlePrintLabel}>
                        { labelsPrinted && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                        Print Labels
                      </Button>
                      <Button variant={pickListPrinted ? 'secondary' : 'primary'} className="m-2" onClick={handlePrintPicklist}>
                        { pickListPrinted && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                        Print Picklist
                      </Button>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                      <Button type="button" variant={labelsPrinted && pickListPrinted ? 'success' : 'secondary'} onClick={save}>Pick</Button>
                    </div>
                  </div>
              )
            }
          </div>
        </DialogContent>
      </div>
      <div style={{ display: 'none' }}>
        <PrintPage
          ref={packingSlipRef}
          monogramRecords={labelData}
          monogram={monogram}
        />
      </div>

      <div style={{ display: 'none' }}>
        <PickList
          ref={picklistRef}
          lineItems={selectedLineItems}
          orderNames={orderData.map((val) => val.name)}
          monogram={monogram}
        />
      </div>
    </Dialog>
  );
};

export default OrdersPage;
