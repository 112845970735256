import  { useState } from "react";
import { Link } from "react-router-dom";
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Button } from "@shadcn/components/ui/button";

import { Card, CardContent as CardBody } from '@shadcn/components/ui/card'

import '../../Skote/Dashboard/skelton.css'
import Select from '@shadcn/components/ui/react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { Badge } from "@shadcn/components/ui/badge";
import { Input } from "@shadcn/components/ui/input";
import { TablePagination } from "@components/supply-chain/TablePagination";




const stageToBadge = (pick) => {
  const conversion = {
    'SENT_TO_CUSTOMER': 'info',
    'RECEIVED_BY_WAREHOUSE': 'warning',
    'REPAIR_UNSUCCESSFUL': 'danger',
    'REPAIR_COMPLETED': 'success',
    'REAPIR_DISPACHED': 'success'
  }
  return conversion[pick] || 'success'
}



const stages = [
  'SENT_TO_CUSTOMER',
  'RECEIVED_BY_WAREHOUSE',
  'REPAIR_UNSUCCESSFUL',
  'REPAIR_COMPLETED',
  'REAPIR_DISPACHED'
]

const returnTypes = [
  'TSA_LOCK',
  'HANDLE_TELESCOPIC',
  'HANDLE_CARRY',
  'TRUNK_LOCK',
  'SNAPSLEEVE_FIDLOCK',
  'TRUNK_CLIP',
  'LUGGAGE_FEET',
  'MONOGRAM_REPRINT',
  'ZIP'
]

const regions = [
  'Australia',
  'New Zealand',
  'United States',
  'United Kingdom'
]


const OrdersPage = () => {
  const url = '/returns/allReturns'
  
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  

  const [searchTerm, setSearchTerm] = useState()
  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setSearchTerm(newTerm)
  }
  const [orderStartDate, setOrderStartDate] = useState('')
  const [orderEndDate, setOrderEndDate] = useState('')
  const handleChange = (e) => {
    e.persist();
    if (e.target.name === 'orderStartDate') {
      setOrderStartDate(moment(e.target.value).format('YYYY-MM-DD'))
    } else if (e.target.name === 'orderEndDate') {
      setOrderEndDate(moment(e.target.value).format('YYYY-MM-DD'))
    }
  };
  const [selectedStage, setSelectedStage] = useState()
  const [selectedType, setSelectedType] = useState()
  const [selectedRegion, setSelectedRegion] = useState()

  const queryObject = {
    startDate: orderStartDate,
    endDate: orderEndDate,
    stage: selectedStage?.map(val => val.value),
    type: selectedType?.map(val => val.value),
    region: selectedRegion?.map(val => val.value),
    searchTerm: searchTerm
  }
  Object.keys(queryObject).forEach(key => (queryObject[key] === undefined || queryObject[key] === '') && delete queryObject[key])

  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, ...queryObject } });

  const returns = data && data.data;
  const count = data && data.count;

  let dataToCsv = []
  if (data) {
    dataToCsv = data.map((line, key) => {
      return [
        line.orderNumber,
        line.cxDate,
        line.shipping_address[0].country,
        line.shipping_address[0].name,
        line.reason,
        line.stage,
      ]
    })
  }

  const csvData = [
    ['Order ID', 'Date', 'Country', 'Customer Name', 'Reason', 'State'],
    ...dataToCsv
  ]

  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Repairs" />
          <Row className>
            <Col xs="12">
              <Card className="py-6 mb-6">
                <CardBody>
                  <Row className="mb-2 flex-wrap gap-6">
                    <Col sm="6" className="sm:w-[48%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">FILTER</label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control"  value={searchTerm} onChange={handleTermChange} />
                          
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="5" className="sm:w-[38%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">REGION</label>
                        <Select  className='border-black' value={selectedRegion} onChange={setSelectedRegion} placeholder="All" title="Country" options={regions.map(val => ({ value: val, label: val }))} isMulti />
                      </div>
                    </Col>
                    <Col sm="3" className="sm:w-[23%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">STARTING</label>
                        <input className="form-control border-black" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </div>
                    </Col>
                    <Col sm="3" className="sm:w-[23%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">ENDING</label>
                        <input className="form-control border-black" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </div>
                    </Col>
                    <Col sm="3" className="sm:w-[23%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">TYPE</label>
                        <Select value={selectedType} onChange={e => setSelectedType(e)} placeholder="All" title="Type" options={returnTypes.map(val => ({ value: val, label: val }))} isMulti />
                      </div>
                    </Col>
                    <Col sm="2" className="sm:w-[14%]">
                      <div className="select2-container mb-4">
                        <label className="control-label">STAGE</label>
                        <Select value={selectedStage} onChange={e => setSelectedStage(e)} placeholder="All" title="Stage" options={stages.map(val => ({ value: val, label: val }))} isMulti />
                      </div>
                    </Col>
                    <Col sm="1" className="sm:w-[8%]" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 8 }}>
                      <CSVLink
                        data={csvData}
                        filename={`Repairs_${new Date()}.csv`}
                      >
                        <Button type="button" variant="secondary" className="btn-lg btn-rounded">
                          CSV
                        </Button>
                      </CSVLink>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order</th>
                          <th>Date</th>
                          <th>Country</th>
                          <th>Customer Name</th>
                          <th>Reason</th>
                          <th>Stage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state !== 'loading' ?
                            data?.map(returnLine => {
                              return (
                                <tr key={returnLine._id}>
                                  <td><Link to={`/returns/${returnLine._id}`} className="text-body font-weight-bold">{returnLine.orderNumber}</Link></td>
                                  <td>{returnLine.cxDate}</td>
                                  <td>{returnLine.shipping_address[0].country}</td>
                                  <td>{returnLine.shipping_address[0].name}</td>
                                  <td>{returnLine.reason}</td>
                                  <td>
                                    { }
                                    <Badge className={"font-size-12 badge-soft-" + stageToBadge(returnLine.stage)} color={stageToBadge(returnLine.stage)} pill>
                                      <i className={"fab " + " mr-1 font-size-12 badge-soft-primary"}></i> {returnLine.stage}
                                    </Badge>
                                  </td>
                                </tr>
                              )
                            }) : (
                              perPage && (
                                [...Array(perPage)].map((_, i) => (
                                  <tr className="py-8" style={{ padding: '16px 0' }} key={"_order_" + i}>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                  </tr>

                                ))
                              )
                            )
                        }
                      </tbody>
                    </table>
                  </div>
                  <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}


export default OrdersPage;
