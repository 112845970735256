import  { useState,useCallback, useEffect } from 'react'

import {
  Button
} from '@shadcn/components/ui/button'
import {
  Row 
} from '@shadcn/components/ui/row'

import {
  Col 
} from '@shadcn/components/ui/col'
import {
  Card,
  CardContent as CardBody,
  CardHeader
  
} from '@shadcn/components/ui/card';

import { toast } from 'react-toastify';
import "../datatables.scss";
import { useYupValidationResolver } from './hooks/useYupValidationResolver';
import { containerSchema } from './schema/containerSchema';

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import LocationSelector from '../shared/LocationSelector'
import {useSelector, useDispatch} from 'react-redux'
import {
	CHANGE_LOCATION
} from "../../../store/layout/actionTypes";

import moment from 'moment';


import { CSVReader } from 'react-papaparse';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import CommentHostory from '../../Skote/Dashboard/CommentHistory.js';

import { Dialog, DialogContent, DialogFooter } from '@shadcn/components/ui/dialog';
import InputField from './components/InputField';
import SelectField from './components/SelectField';
import { useForm } from 'react-hook-form'
import DatePicker from './components/DatePicker';
import useStockStore from '@zustand/useStockStore';
import StockInput from './components/StockInput';
import ContainerSummary from './components/ContainerSummary';







const PullPage = ({
  match: {
    params
  }
}) => {
  const { containerID } = params
  const resolver = useYupValidationResolver(containerSchema)
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  
  const [modal, setModal] = useState(false)
  const [loading, setlLoading] = useState(false)
  const selectedLocation = useSelector(state => state.Layout.location)
  
  
  const dispatch = useDispatch()
  


  const setStock = useStockStore((state) => state.setStock);
  const getStock = useStockStore((state) => state.getStock);
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState: formStateReactHook,
    getValues,
    reset
  } = useForm({
    resolver
  });
  




  const [data, productState] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data
  const [purchaseOrdersPagination, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh,
    queryData: {
      status: 'approved'
    }
  });

  const purchaseOrders = purchaseOrdersPagination && purchaseOrdersPagination.data
  const togglemodal = () => setModal(prev => !prev)


  

  const validate = () => {
    
    return Object.keys(errors).length === 0;
  };

  const onSubmit = async (data) => {
    
   const stockPull = getStock()
   console.log("current stock pull", stockPull)
   setlLoading(true)
    const exportData = {
      containerID: containerID,
      stock: stockPull?.map(item => ({
        variant: item.variant._id,
        sku: item.variant.sku,
        quantity: item.quantity,
        costPerItem: item.costPerItem,
        containerLineId: item.containerLineId,
        purchaseOrder: item.purchaseOrder
      })),
      ...data,
      name: data.containerName,
      ref: data.containerRef,
      status: data.containerState,
      location: selectedLocation,
      
    }

    
    
    superagent.post(`${backendUrl}/inventory/updateContainer/${containerID}`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.push(`/container/${containerID}`)
        }
      }).catch(err => {
        setlLoading(false)
        const errorMessage =
        err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
        console.log(err)
      });
  }

  const getContainer =  useCallback(() => {
    setlLoading(true)
    
    superagent.get(`${backendUrl}/inventory/fetchContainer/${containerID}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
         
          reset({
            containerName: response.body.name,
            containerRef: response.body.ref,
            destinationPort: response.body.destinationPort,
            originPort: response.body.originPort,
            shippingCarrier: response.body.shippingCarrier,
            containerType: response.body.containerType,
            containerState: response.body.status,
            departureDate: moment(response.body.departureDate).format('YYYY-MM-DD'),
            arrivalDate: moment(response.body.arrivalDate).format('YYYY-MM-DD'),
            portDate: moment(response.body.portDate).format('YYYY-MM-DD'),
            requestedDate: moment(response.body.requestedDate).format('YYYY-MM-DD'),
            bookingDate: moment(response.body.bookingDate).format('YYYY-MM-DD'),
            warehouseDatePlanned: moment(response.body.warehouseDatePlanned).format('YYYY-MM-DD'),
            warehouseDateActual: moment(response.body.warehouseDateActual).format('YYYY-MM-DD'),
            freightCost: response.body.freightCost,
            localCost: response.body.localCost
          });
        
          let items = []
          for(let containerLine of response.body.containerLines){
            const item = {
              product: containerLine.variant.product,
              variant: containerLine.variant,
              quantity: containerLine.quantity,
              costPerItem: containerLine.costPerItem,
              containerLineId: containerLine._id,
              purchaseOrder: containerLine.purchaseOrder,
            };
            items.push(item)
          }
          setStock(items)
         
          if (response.body?.landingLocation?.name) {
            dispatch({ type: CHANGE_LOCATION, payload: response.body?.landingLocation?.name })
          }
          setlLoading(false)
        }
      }).catch(err => {
        console.log(err)
      });
  },[containerID, dispatch, history])
  

  useEffect(() => {
    if(containerID !== undefined){
      getContainer()
    }
  }, [containerID, getContainer])

  const handleOnDrop = (data) => {
    let items = []
    let invalidSkus = [];
    data?.map((line, key) => {
      if (key > 0) {
        const count = line.data[1]
        const sku = line.data[0]

        let product = productData.find(val => (val.variants.find(variant => variant.sku === sku) != undefined))
        if (product != undefined && sku !== '') {
          let variant = product.variants.find(variant => variant.sku === sku)
          if (variant != undefined) {
            const item = {
              product: product._id,
              variant: variant._id,
              quantity: count,
              sku: sku
            }; 
            items.push(item)
          }
        } else if (sku !== '') {
          invalidSkus.push({
            line: key,
            sku: sku
          });
        }
      }
    })

    items = items.sort((a, b) => (a.sku < b.sku) ? -1 : (a.sku > b.sku) ? 1 : 0);
    setStock(items)
    if (invalidSkus.length > 0) {
      toast.warning(`The following SKUs do not exist in JIFI: ${invalidSkus.map(item => `Line ${item.line}: ${item.sku}`).join(', ')}`, {
        autoClose: false
      });
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    
  };

  if(productState !== 'success' && purchaseOrderState !== 'success') 
    return (
      <div className="fixed inset-0 flex items-center justify-center">
      <div className="spinner-grow text-primary m-1" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )

  return <SupplyChainNav
    commentComponent={<CommentHostory title="Comments" initCommentType={'Container'} initOrderName={getValues("containerName")} />}
  >
    <div className='p-4'>
      <div>
      <Row className="flex-wrap mb-14 lg:flex-nowrap gap-6">
        <Col className="w-full lg:w-8/12 gap-4" >
          <Card>
            <CardBody className="pt-6">
            <Row className="gap-2">
                <Col  className="sm:w-6/12">
                  <div className="select2-container  mb-4">
                   
                    <InputField 
                    label="Supplier Invoice Reference"  
                    control={control}
                    name="containerName"
                    placeholder="Name"
                    defaultValue={formStateReactHook.containerName}
                    error={errors.containerName}
                    />
                  </div>
                </Col>
                <Col xs="6" className="sm:w-6/12">
                  <div className="select2-container mb-4">
                    
                  <InputField 
                    label="Container Reference"  
                    control={control}
                    name="containerRef"
                    placeholder="Name"
                    defaultValue={formStateReactHook.containerRef}
                    error={errors.containerRef}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gap-2">
                <Col xs="6" className="sm:w-6/12">
                  <div className="select2-container mb-4">
                    
                    <SelectField
                    label="Destination Port"  
                    control={control}
                    name="destinationPort"
                    placeholder="Name"
                    options={
                      [ {label: 'Port 1', value: 'draft' }]}
                    defaultValue={formStateReactHook.destinationPort}
                    error={errors.destinationPort}
                    />
                    
                  </div>
                </Col>
                <Col xs="6" className="sm:w-6/12">
                  <div className="select2-container mb-4">
                  

                    <SelectField
                    label="Origin Port"  
                      control={control}
                    name="originPort"
                    placeholder="Name"
                    options={
                      [ {label: 'Port 1', value: 'draft'}]}
                    defaultValue={formStateReactHook.originPort}
                    error={errors.originPort}
                    />
                   
                  </div>
                </Col>
              </Row>
              <Row className="gap-2">
                <Col xs="6" className="sm:w-6/12">
                  <div className="select2-container mb-4">
                   
                      <SelectField
                    label="Shipping Carrier"  
                    control={control}
                    name="shippingCarrier"
                    placeholder="Name"

                    options={
                      [ {label: 'Carrier 1', value: 'draft'}]}
                    defaultValue={formStateReactHook.shippingCarrier}
                    error={errors.shippingCarrier}
                    />
                  </div>
                </Col>
                <Col xs="6" className="sm:w-6/12">
                  <div className="select2-container mb-4">
                   
                    
                    <SelectField
                    label="Container Type"  
                    control={control}
                    name="containerType"
                    placeholder="Name"
                   
                    options={
                      [ {label: '40ft', value: 'draft'}]}
                    defaultValue={formStateReactHook.containerType}
                    error={errors.containerType}
                    />
                  </div>
                </Col>
              </Row>
              <div className="select2-container mb-4">
                
                <SelectField
                label="Container State"  
                  control={control}
                name="containerState"
                placeholder="Name"
               
                options={
                  [ {label: 'Draft', value: 'draft'}, {label: 'Packing', value: 'packing'}, {label: 'In Transit', value: 'in-transit'}, {label: 'At Port', value: 'at-port'}, {label: 'Ready to unload', value: 'ready'}, {label: 'Completed', value: 'completed'}, {label: 'Cancelled', value: 'cancelled'}  ]}
                defaultValue={formStateReactHook.containerState}
                error={errors.containerState}
                />
              </div>
              <div className="select2-container mb-4">
                    <label className="control-label">Landing Location</label>
                <LocationSelector />
              </div>
             
              <Row className="gap-2">
                <Col xs="4" className="sm:w-4/12">
                 

                  <DatePicker
                  label="Departure Date"
                  control={control}
                  name="departureDate"
                  placeholder="Name"
                  type="date"
                  defaultValue={formStateReactHook.departureDate}
                  error={errors.departureDate}
                  />
                  
                    <DatePicker
                    label="Arrival Date"
                    control={control}
                    name="arrivalDate"
                    placeholder="Name"
                    type="date"
                    defaultValue={formStateReactHook.arrivalDate}
                    error={errors.arrivalDate}
                    />
                            
                    <DatePicker
                    label="Port Date"
                    control={control}
                    name="portDate"
                    placeholder="Name"
                    type="date"
                    defaultValue={formStateReactHook.portDate}
                    error={errors.portDate}
                    />
                  
                </Col>
                <Col xs="4" className="sm:w-4/12">
                  <DatePicker
                  label="Requested Date"
                  control={control}
                  name="requestedDate"
                  placeholder="Name"
                  type="date"
                  defaultValue={formStateReactHook.requestedDate}
                  error={errors.requestedDate}
                  />
                 
                  <DatePicker
                  label="Booking Date"
                  control={control}
                  name="bookingDate"
                  placeholder="Name"
                  type="date"
                  defaultValue={formStateReactHook.bookingDate}
                  error={errors.bookingDate}
                  />
                
                </Col>
                <Col xs="4" className="sm:w-4/12">
                
                  <DatePicker
                  label="Warehouse Date Planned"
                  control={control}
                  name="warehouseDatePlanned"
                  placeholder="Name"
                  type="date"
                  defaultValue={formStateReactHook.warehouseDatePlanned}
                  error={errors.warehouseDatePlanned}
                  />

                  <DatePicker
                  label="Warehouse Date Actual"
                  control={control}
                  name="warehouseDateActual"
                  placeholder="Name"
                  type="date"
                  defaultValue={formStateReactHook.warehouseDateActual}
                  error={errors.warehouseDateActual}
                  />
                
                </Col>
              </Row>
              <div className="select2-container mb-4">
              
                <InputField
                label="Freight Cost - $USD"  
                control={control}
                name="freightCost"
                placeholder="Name"
                type="number"
                defaultValue={formStateReactHook.freightCost}
                error={errors.freightCost}
                />
              </div>
             
              <InputField
              label="Local Cost - $USD"  
              control={control}
              name="localCost"
              placeholder="Name"
              type="number"
              defaultValue={formStateReactHook.localCost}
              error={errors.localCost}
              />
              <br/>
              <div>
                <div className="mb-2 text-[#1f3dd0]">
                  <a href="https://jifi-storage-staging.s3-ap-southeast-2.amazonaws.com/comment_file_1637273421528.csv"> CSV Template </a>
                </div>
                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  onRemoveFile={handleOnRemoveFile}
                >
                  <span>Drop CSV file here or click to upload.</span>
                </CSVReader>
              </div>
              <br/>
              <div id="accordion">
                <Card className="mb-1">
                  <CardHeader className="p-3" id="headingOne">
                    <h6 className="m-0 font-14">
                      <span style={{ cursor: "pointer" }} className="text-dark">
                        Book Stock 
                      </span>
                    </h6>
                  </CardHeader>
                  
                      <StockInput 
                      label="Update Container"
                      validate={validate}
                      toggleModal={togglemodal}
                      productData={productData}
                      handleSubmit={handleSubmit}
                      purchaseOrders={purchaseOrders}/>
                    
                </Card>
              </div>

              
            </CardBody>
          </Card>
        </Col>
        <Col  className="w-full lg:w-4/12 mt-6 md:mt-0">
          <Card>
            <CardBody className="pt-4">
             <ContainerSummary productData={productData} />
            </CardBody>
          </Card> 
        </Col>
      </Row>
      </div>
    </div>
    <Dialog open={modal} onOpenChange={togglemodal} >
      <DialogContent>
      <CardBody className="pt-6">
      <ContainerSummary productData={productData} />
      </CardBody>
      
      <DialogFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit(onSubmit)} className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </DialogFooter>
      </DialogContent>
    </Dialog>
  </SupplyChainNav>
}

export default PullPage
