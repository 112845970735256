import React, { useState } from 'react';
import {Button } from '@shadcn/components/ui/button';
import {Input } from '@shadcn/components/ui/input';

const ProductVariantSelector = ({
    products,
    setSelectedVariant,
    setSelectedProduct,
    selectedProduct,
    selectedVariant,
    customContainerStyle,
    hidePersonalisationOptions,
  }) => {
    const [localSelectedProduct, setLocalSelectedProduct] = useState();
    const setSelectedProductCurrent = setSelectedProduct
      ? setSelectedProduct
      : setLocalSelectedProduct;
    const selectedProductCurrent = selectedProduct
      ? selectedProduct
      : localSelectedProduct;
  

      
    const setSelectedVariantCurrent = setSelectedVariant
      ? setSelectedVariant
      : () => {};
    const selectedVariantCurrent = selectedVariant || undefined;
  
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedVariant, setExpandedVariant] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const searchListOfProducts = searchTerm
      ? products.filter(
          (val) =>
            val.name.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            val.variants?.some((variant) =>
              variant?.sku?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              variant?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            )
        )
      : products;


    const findProductNameById = (selectedProduct) => {
      const product = products?.find(option =>
          option?._id?.toLowerCase() === selectedProduct
      )
      if (product) {
          return product?.name
      }
      return ''
  };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
      setIsDropdownOpen(true);
      setSelectedVariantCurrent(null);
      setSelectedProductCurrent(null);
    };
  
    const ensureSinglePersonalization = (sku) => {
      
      const baseSku = sku.replace(/-P+$/, '');
      
      return baseSku + '-P';
    };
  
    const handleVariantSelect = (product, variant, persSKU) => {
      if (persSKU) {
        variant.sku = ensureSinglePersonalization(variant.sku);
      } else {
        variant.sku = variant.sku.replace(/-P+$/, '');
      }
      setSelectedProductCurrent(product);
      setSelectedVariantCurrent(variant);
      setSearchTerm('');
      setIsDropdownOpen(false);
    };
  
    const handleProductSelect = (data) => {
      setSelectedProductCurrent(data);
      setSearchTerm('');
      setIsDropdownOpen(false);
    };
  
    const toggleVariant = (name) => {
      setExpandedVariant(expandedVariant === name ? null : name);
    };
  
    const placeholderValue =
      setSelectedProduct === undefined
        ? 'Search for a variant...'
        : 'Search for a product...';
  
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    };
  
    return (
      <div style={customContainerStyle ? customContainerStyle : containerStyle}>
        <Input
          type="search"
          placeholder={placeholderValue}
          value={setSelectedProduct === undefined ? (selectedVariantCurrent ? selectedVariantCurrent?.name : searchTerm) : (selectedProductCurrent?.name ? selectedProductCurrent?.name : selectedProduct !== null ? findProductNameById(selectedProduct) : searchTerm)}
          onChange={handleSearchChange}
          onFocus={() => setIsDropdownOpen(true)}
        />
        {isDropdownOpen && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              background: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
              maxHeight: '300px',
              overflowY: 'auto',
              zIndex: 999,
              width: '100%',
            }}
          >
            {searchListOfProducts.map((product) => (
              <div key={product.sku || product.name}>
                <div
                  className="flex items-center justify-between p-2 cursor-pointer border-b"
                  onClick={() => toggleVariant(product.name)}
                >
                  {product.name}
                  {expandedVariant === product.name ? (
                    <i className="fa fa-chevron-up" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  )}
                </div>
                {expandedVariant === product.name && (
                  <div className="pl-4">
                    {product.sku !== undefined && (
                        <>
                         <div className="flex items-center justify-between p-2 border-b">
                      <span>{product.name} / Default</span>
                      <Button
                        onClick={() =>
                          handleVariantSelect(product, product, false)
                        }
                      >
                        Select
                      </Button>
                    </div>
                    {/* Check if personalisation options should be shown and add Select 789 */}
                    {(['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'SLG', 'JET', 'COM'].includes(
                      product.sku?.slice(0, 3)
                    ) ||
                      ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(
                        product.sku?.slice(0, 7)
                      ) ||
                      ['CAR-WKDP'].includes(product.sku?.slice(0, 8))) &&
                      !hidePersonalisationOptions && (
                        <div className="flex items-center justify-between p-2">
                          <span>
                            {product.name} / <strong>Personalised</strong>
                          </span>
                          <Button
                            onClick={() =>
                              handleVariantSelect(product, product, true)
                            }
                          >
                            Select
                          </Button>
                        </div>
                      )}
                        </>
                    )}
                   
                    {/* Mapping over product variants */}
                    {product.variants?.map((variant) => (
                      <div key={variant.sku} className="pl-4">
                        {/* Default variant selection for specific variants */}
                        <div className="flex items-center justify-between p-2 border-b">
                          <span>{variant.name} / Default</span>
                          <Button
                            onClick={() =>
                              handleVariantSelect(product, variant, false)
                            }
                          >
                            Select
                          </Button>
                        </div>
                        {/* Personalised variant selection (Select 131415) */}
                        {(['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'SLG', 'JET', 'COM'].includes(
                          variant?.sku?.slice(0, 3)
                        ) ||
                          ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(
                            variant?.sku?.slice(0, 7)
                          ) ||
                          ['CAR-WKDP'].includes(variant?.sku?.slice(0, 8))) &&
                          !hidePersonalisationOptions && (
                            <div className="flex items-center justify-between p-2">
                              <span>
                                {variant.name} / <strong>Personalised</strong>
                              </span>
                              <Button
                                onClick={() =>
                                  handleVariantSelect(product, variant, true)
                                }
                              >
                                Select
                              </Button>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default ProductVariantSelector;
