import React, { Component, Fragment, useState, useEffect } from 'react';
 

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadFileS3Bucket } from '../../../utilities/uploadFileS3Bucket';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { MentionsInput, Mention } from 'react-mentions';

import useUserStore from '../../../zustand/useUserStore.js';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import { Col } from '@shadcn/components/ui/col';


const AddComment = React.memo(({ orderName, refresh, commentType }) => {
  const history = useHistory();
  

  const initialState = {
    clientId: '',
    userName: '',
    user: '',
    orderName: '',
    title: '',
    description: '',
    comment_file: null,
    isFileUploaded: false,
    commentType: 'General',
    mentions: [],
    url: `${window.location.href}`,
    plainText: '',
  };

  

  
  const userList = useUserStore((state) => state.userList);
  
  const [state, setState] = useState(initialState);

  const endpointLookup = {
    Dashboard: `General`,
    Order: `Order`,
    Container: `Container`,

    Factory: `Factories`,
    PurchaseOrder: `PurchaseOrders`,
    Release: `Releases`,
  };

  useEffect(() => {
    let userData = localStorage.getItem('authUser');
    if (userData) {
      userData = JSON.parse(userData);
      let userName = userData.userName;
      let user = userData._id;
      setState((prevState) => {
        return {
          ...prevState,
          userName: userName,
          user: user,
          orderName: orderName ? orderName.split('-')[0] : '',
          orderNameFull: orderName ? orderName : '',
          commentType: endpointLookup[commentType],
        };
      });
    }
  }, [orderName]);

  const handleInputChange = (
    name,
    newValue,
    mentions,
    plainText
  ) => {
    
    const ids = mentions.map(({ id }) => id);
    const mentionsUser = userList.filter((user) => ids.includes(user._id));
    
    setState((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
        mentions: mentionsUser,
        plainText: plainText
      };
    });
  };

  const handleFileChange = async (e) => {
    e.preventDefault();

    const cancel = !e.target.files.length;
    if (cancel) {
    
      return;
    }

    let [commentFile] = await Promise.all([uploadFileS3Bucket(e.target.files[0], `comment_file_${Date.now()}`)]);

    console.log(commentFile)

    setState((prevState) => {
      console.log("UPDATE TO", {
        ...prevState,
        comment_file: commentFile,
        isFileUploaded: true,
      })
      return {
        ...prevState,
        comment_file: commentFile,
        isFileUploaded: true,
      };
    });
  };

  const deleteImage = async (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        comment_file: null,
        isFileUploaded: null,
      };
    });
  };

  const handleCreateComment = async (e) => {
    e.preventDefault();
    const {
      commentType,
      orderName,
      userName,
      title,
      description,
      comment_file,
      isFileUploaded,
      user,
      orderNameFull,
      mentions,
      plainText,
      url
    } = state;

    let commentFields = {
      orderName: orderName,
      orderNameFull: orderNameFull,
      userName: userName,
      files: null,
      title: title,
      description: description,
      comment_type: commentType,
      user: user,
      mentions: mentions,
      plainText: plainText,
      url: url
    };

    commentFields = {
      ...commentFields,
      files: comment_file ? [comment_file] : [],
    };
    superagent
      .post(`${backendUrl}/comments`)
      .send(commentFields)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          refresh();
          toast.success('Comment created successfully');
          setState((prevState) => {
            return {
              ...prevState,
              title: '',
              description: '',
              comment_file: null,
              isFileUploaded: false,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toast.configure({ autoClose: 8000, hideProgressBar: true });
  const { title, description, comment_file } = state;


  
  return (
    <form className="outer-repeater">
      <div data-repeater-list="outer-group" className="outer">
        <div data-repeater-item className="outer">
          <div className="flex flex-row flex-wrap mb-4" row>
            <Col lg="12" className="d-flex align-items-center">
              <MentionsInput
                value={description}
                onChange={(e, newValue, plainText, mentions) =>
                  handleInputChange(
                    'description',
                    newValue,
                    mentions,
                    plainText
                  )
                }
                style={{
                  width: '100%',
                  control: {
                    backgroundColor: '#fff',
                    fontSize: 13,
                  },

                  '&multiLine': {
                    control: {
                      fontFamily: 'monospace',
                      minHeight: 60,
                    },
                    highlighter: {
                      padding: 9,
                      border: '1px solid transparent',
                    },
                    input: {
                      padding: 9,
                      border: '1px solid black',
                    },
                  },

                  '&singleLine': {
                    display: 'inline-block',
                    width: 180,

                    highlighter: {
                      padding: 1,
                      border: '2px inset transparent',
                    },
                    input: {
                      padding: 1,
                      border: '2px inset',
                    },
                  },

                  suggestions: {
                    zIndex: 100,
                    list: {
                      backgroundColor: 'white',
                      border: '1px solid rgba(0,0,0,0.15)',
                      fontSize: 12,
                    },
                    item: {
                      padding: '5px 15px',
                      borderBottom: '1px solid rgba(0,0,0,0.15)',
                      '&focused': {
                        backgroundColor: '#cee4e5',
                      },
                    },
                  },
                }}
                placeholder="Enter Comment..."
              >
                <Mention
                  trigger="@"
                  data={
                    userList?.map((item) => {
                      return { id: item._id, display: item.userName };
                    }) ?? []
                  }
                  style={{ backgroundColor: '#daf4fa' }}
                />
              </MentionsInput>
            </Col>
            {
              state.comment_file && (
                <Col
                  lg="12"
                  className="flex-row justify-between mt-2"
                  style={{ display: 'flex' }}
                >
                  <img src={state.comment_file} style={{width: '100%'}} onClick={deleteImage}/>
                </Col>
              )
            }
            
            <Col
              lg="12"
              className="flex-row justify-between mt-2"
              style={{ marginLeft: -8, display: 'flex' }}
            >

              <Button
                variant="outline"
                className="ml-2 bg-white text-black"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('comment_file').click();
                }}
              >
                Add image or file
              </Button>
              <Input
                accept="*"
                type="file" 
                className="hidden"
                id="comment_file"
                name="comment_file"
                onChange={(e) => handleFileChange(e)}
              />
              
              {/* <CustomInput
                accept="*"
                className=" ml-2"
                type="file"
                id="comment_file"
                name="comment_file"
                label={comment_file || 'Add image or file'}
                onChange={(e) => handleFileChange(e)}
              /> */}

              <Button
                style={{ width: '15%', marginRight: -8, minWidth: 60 }}
                className="ml-2"
                onClick={(e) => handleCreateComment(e)}
                type="submit"
                variant="primary"
              >
                Post
              </Button>
            </Col>
          </div>
        </div>
      </div>
    </form>
  );
});

export default AddComment;
