import  { useState } from "react";
import {  Card, CardContent as CardBody } from "@shadcn/components/ui/card";

import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Link } from "react-router-dom";


import { Button } from "@shadcn/components/ui/button";
import { CSVLink } from "react-csv";

import moment from 'moment';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";
import useQuery from '../../helpers/JIFI/useQuery';

import SKUToolTip from "./helpers/SKUToolTip";
import useSKUStatus from "./helpers/useSKUStatus";
import useSKULevels from "./helpers/useSKULevels";
import useUpcomingStock from "./helpers/useUpcomingStock";
import { Badge } from "@shadcn/components/ui/badge";
import { TablePagination } from "@components/supply-chain/TablePagination";


const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}



const OrdersPage = () => {
  const initialState = {
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
  }
  const [stateData, setStateData] = useState(initialState);

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const [data, state] = useQuery({ refresh, url: '/orders/print-summary/', queryData: { page: page, perPage: perPage, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate } });
  const summary = data && data.summary;
  const count = 0

  const { orderStartDate, orderEndDate } = stateData;

  const dateString = `${orderStartDate}-${orderEndDate}`

  let merged = []
  if (summary) {
    merged = summary.map((sum, key) => {
      return [
        sum.order?.name,
        sum.lineItem?.sku,
        sum.lineItem.monogram_text,
        sum.lineItem.monogram_font,
        sum.lineItem.monogram_color,
        (new Date(sum.lineItem.monogram_state_updated)).toDateString(),
        sum.lineItem.fulfillment_status
      ]
    })
  }

  const csvData = [
    ['Order ID', 'SKU', 'Monogram Text', 'Monogram Font', 'Monogram Color', 'Date', 'State'],
    ...merged
  ]

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Stock" breadcrumbItem="Embroidery Summary" />
          <Row>
            <Col xs="12">
              <Card className='pt-6'>
                <CardBody>
                  <Col>
                    <Row className='flex-wrap gap-6'>
                      <Col sm="5" className='sm:w-[39%]'>
                        <div className="select2-container mb-4">
                          <label className="control-label">STARTING</label>
                          <input className="form-control border-black" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                        </div>
                      </Col>
                      <Col sm="5" className='sm:w-[39%]'>
                        <div className="select2-container mb-4">
                          <label className="control-label">ENDING</label>
                          <input className="form-control border-black" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                        </div>
                      </Col>
                      <Col sm="2" className='sm:w-[14%]' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 8 }}>
                        <CSVLink
                          data={csvData}
                          filename={`EMBROIDERY_${dateString}.csv`}
                        >
                          <Button type="button" variant="secondary" className="btn-lg btn-rounded">
                            CSV
                          </Button>
                        </CSVLink>
                      </Col>
                    </Row>
                  </Col>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>SKU</th>
                          <th>Monogram Text</th>
                          <th>Monogram Font</th>
                          <th>Monogram Color</th>
                          <th>Date</th>
                          <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            summary && summary.map((sum, key) => (
                              <tr key={"_embroidery_" + key}>
                                <td>
                                  <Link to={`/orders/${sum.order?._id}`}>{sum.order?.name}</Link>
                                </td>
                                <td>
                                  <SKUToolTip sku={sum.lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                </td>
                                <td>
                                  {sum.lineItem.monogram_text}
                                </td>
                                <td>
                                  {sum.lineItem.monogram_font}
                                </td>
                                <td>
                                  {sum.lineItem.monogram_color}
                                </td>
                                <td>
                                  {(new Date(sum.lineItem.monogram_state_updated)).toDateString()}
                                </td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(sum.lineItem.fulfillment_status)} color={fulfillmentToBadge(sum.lineItem.fulfillment_status)} pill>
                                    {sum.lineItem.fulfillment_status ? sum.lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                  </Badge>
                                </td>
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  {
                    (summary && state === 'success') ? (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                        <div className="spinner-grow text-primary m-1" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}


export default OrdersPage;
