import { useForm } from 'react-hook-form'; 
import { Input } from '@shadcn/components/ui/input'; 
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select'; 

import { DialogFooter } from '@shadcn/components/ui/dialog';
import { Textarea } from '@shadcn/components/ui/textarea'; 
import { Button } from '@shadcn/components/ui/button';
import { Label } from '@shadcn/components/ui/label';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card'; // Card component
import {
  Command,
  CommandInput,
  CommandList,
  CommandItem,
} from '@shadcn/components/ui/command';

import { debounce } from 'lodash'; 

import superagent from 'superagent';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { cn } from '@shadcn/lib/utils'
import { useEffect, useState } from 'react';
import { backendUrl } from '@helpers/consts';
import { uploadFileS3Bucket } from '@utilities/uploadFileS3Bucket';
import useQuery from '@helpers/JIFI/useQuery';

import WizardNav from '@pages/JIFI/orders/Wizard/WizardNav';

const stores = [
  { value: 'QV' },
  { value: 'HQ' },
  { value: 'Galeries' },
  { value: 'Bondi' },
  { value: 'Chadstone' },
  { value: 'Pitt St' },
  { value: 'James St' },
  { value: 'Parramatta' },
];

const commands = [
  { value: 'test order', label: 'test order' },
  { value: '#GETJULY1824235801', label: '#GETJULY1824235801' },
];
const department = [{ value: 'Operations' }, { value: 'Customer Experience' }];

const category = [
  {
    value: 'JIFI Changes',
  },
  {
    value: 'Repair/Warranty',
  },
  {
    value: 'Personalisation',
  },
  {
    value: 'Order status',
  },
  {
    value: 'Shipping',
  },
  {
    value: 'Stock Transfer',
  },
  {
    value: 'Other',
  },
  {
    value: 'Pet Personalisation',
  },
];

const pickupLocation = [
  {
    value: 'QV',
  },
  {
    value: '80 Colins',
  },
  {
    value: 'Colling wood',
  },
  {
    value: 'Galeries',
  },
  {
    value: 'Bondi',
  },
];

export default function FeedBack({
  isWizard = false,
  isForm = false, onModalChange, orderName

}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      order: orderName
    }
  });


  const [loading, setLoading]  = useState(false)
  const [data, state] = useQuery({
    url: '/categories', 
  });

  const [retailStores, stateRetailStore] = useQuery({
    url: `/inventory/getRetailStore`,
  });


  const onSubmit = async (data) => {
    setLoading(true)
    if (data['files']) {
      for (const file of data['files']) {
        const fileUploaded = await uploadFileS3Bucket(
          file,
          `attachment_${Date.now()}`
        );

        data['attachments'] = [...(data['attachments'] || []), fileUploaded];
      }
    }

    superagent
      .post(`${backendUrl}/order-tickets`)
      .send(data)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
       
        toast.error(response?.response?.body?.message ?? "Internal server errors");
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
       
          toast.error(response?.response?.body?.message ?? "Internal server errors");
        } else {
          if(onModalChange) {
            onModalChange()
          }
          else {
            toast.success('Feedback submitted successfully');
          }
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  };



  return (
    <div>
     {isWizard && <WizardNav steps={['Create Order Feedback']} activeTabProgress={1} />}
    <div className={`${isForm ? '' : 'page-content'}`}>
      <div className={`${isForm ? '' : 'container mx-auto px-4 lg:max-w-[85%]'}`}>
        <Card className={`${isForm ? '' : 'max-w-lg mx-auto my-10 p-6'}`}>
          {!isForm && <CardHeader className="text-2xl font-semibold">
            Retail Order Notes - Ops & CX
          </CardHeader>}
          <CardContent className={`${isForm ? 'pt-6' : ''}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <Label htmlFor="store">Store*</Label>
                <Select
                  onValueChange={(val) =>
                    setValue('store', val, { shouldValidate: true })
                  }
                  {...register('store', { required: true })}
                  id="store"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {retailStores?.map((option) => (
                      <SelectItem key={option._id} value={option._id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.store && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Department Selection */}
              <div className="mb-4">
                <Label htmlFor="department">Department*</Label>
                <Select
                  onValueChange={(val) =>
                    setValue('department', val, { shouldValidate: true })
                  }
                  {...register('department', { required: true })}
                  id="department"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {department.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.department && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Category */}
              <div className="mb-4">
                <Label htmlFor="category">Category*</Label>
                <Select
                  onValueChange={(val) => {
                  
                    setValue('category', val, { shouldValidate: true })
                  }
                    
                  }
                  {...register('category', { required: true })}
                  id="category"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {data?.map((option) => (
                      <SelectItem key={option._id} value={option._id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.category && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Customer Name */}
              <div className="mb-4">
                <Label htmlFor="customerName">Customer Name</Label>
                <Input
                  id="customerName"
                  className="text-black"
                  placeholder="Enter customer name"
                  {...register('customerName')}
                />
              </div>

              {/* Order Number */}
              <div className="mb-4">
                <Label htmlFor="orderNumber">Order Number</Label>
                
                <CommandSearch orderName={orderName}  setValue={setValue}
           />
                {/* <Input
                  id="order"
                  placeholder="Enter order number"
                  {...register('order')}
                /> */}
              </div>

              {/* Day Needed By */}
              <div className="mb-4">
                <Label htmlFor="dayNeededBy">Day Needed By</Label>
                <Input
                  type="date"
                  id="dayNeededBy"
                  {...register('dayNeededBy')}
                />
              </div>

              {/* Pickup Location */}
              <div className="mb-4">
                <Label htmlFor="pickupLocation">Pickup Location</Label>
                <Select
                  onValueChange={(val) =>
                    setValue('pickupLocation', val, { shouldValidate: true })
                  }
                  {...register('pickupLocation')}
                  id="pickupLocation"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {pickupLocation.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* To Be Actioned */}
              <div className="mb-4">
                <Label htmlFor="actionDescription">To be actioned*</Label>
                <Textarea
                  className="border-black"
                  id="actionDescription"
                  placeholder="Please include only essential information"
                  {...register('actionDescription', { required: true })}
                />
                {errors.actionDescription && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Attachments */}
              <div className="mb-4">
                <Label htmlFor="files">Attachments</Label>
                <Input accept="image/*" type="file" multiple id="files" {...register('files')} />
              </div>

              {/* Submit Button */}
              <DialogFooter>
              <Button type="submit" className={`${!isForm ? 'w-full' : ''}`} disabled={loading}>
              {loading && <LoadingSpinner className="mr-2"/> } 
              Submit
              </Button>
              </DialogFooter>
            </form>
          </CardContent>
          <ToastContainer />
        </Card>
      </div>
    </div>
    </div>
  );
}

export function CommandSearch({ setValue, orderName   }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(orderName ?? '');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const handleValueChange = (value) => {
    setInputValue(value);
    setOpen(!!value);
    debouncedFetchOrders(value);
    
  };

  const fetchOrders = async (searchTerm) => {
    setLoading(true);
    try {
      const response = await superagent
        .get(`${backendUrl}/orders`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .query({
          page: 1,
          perPage: 10,
          searchTerm: searchTerm,
        });
      setOrders(response.body); // Assuming response.body contains the orders array
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };


  const debouncedFetchOrders = debounce((value) => {
    if (value.length > 3) {
      fetchOrders(value);
    }
  }, 500);

  const filteredCommands = Array.isArray(orders?.data)
    ? orders?.data?.filter((order) => {
     return order.name.toLowerCase().includes(inputValue?.toLowerCase())
    })
    : [];


  return (
    <Command className="rounded-lg border border-black">
      <CommandInput
      
        value={inputValue}
        placeholder="Type a command or search..."
        onValueChange={handleValueChange}
      />
      {
        <CommandList>
          {open &&
            filteredCommands.length > 0 &&
            filteredCommands.map((command) => (
              <CommandItem
                onSelect={() => { 
                  setInputValue(command.name);
                  setValue('order', command);
                  
                  setOpen(false);
                }}
                key={command._id}
              >
                {command.name}
              </CommandItem>
            ))}
        </CommandList>
      }
    </Command>
  );
}

const LoadingSpinner = ({
  size = 24,
  className,
  ...props
}) => {
 return   <svg
 xmlns="http://www.w3.org/2000/svg"
 width={size}
height={size}
{...props}
 viewBox="0 0 24 24"
 fill="none"
 stroke="currentColor"
 strokeWidth="2"
 strokeLinecap="round"
 strokeLinejoin="round"
 className={cn("animate-spin", className)}
>
 <path d="M21 12a9 9 0 1 1-6.219-8.56" />
</svg>
}
