import React, { useState } from 'react';
import { DialogContent, DialogFooter, DialogHeader, Dialog } from "@shadcn/components/ui/dialog";
import { Button } from "@shadcn/components/ui/button";
import superagent from 'superagent';
import { backendUrl } from "../../../../helpers/consts";

const CompletePersonalisation = ({togglemodal, refresh, lineItem, open, url}) => {
  const [loading, setLoading] = useState(false);

  const send = () => {
    superagent.post(`${backendUrl}${url}/${lineItem._id}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', () => {
        setLoading(false);
      })
      .then(response => {
        if (response.status <= 400) {
          togglemodal();
          refresh();
        }
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      {
        lineItem !== undefined && (
          <DialogContent>
            <DialogHeader>
              Confirm Personalisation Complete - {lineItem.monogramID}
            </DialogHeader>
            <div>
              This will fulfill this item in the system.
            </div>
            <DialogFooter>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                <Button type="button" variant="warning" onClick={send}>Complete</Button>
              </div>
            </DialogFooter>
          </DialogContent>
        )
      }
    </Dialog>
  );
}

export default CompletePersonalisation;
