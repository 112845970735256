import { create } from 'zustand'

export const useTaskCard = create((set) => ({
  task: undefined,
  selected: undefined,
  selectedCard: (task) => { 
    set((state) => ({
    task:task,
    selected: !state.selected}))
}}))
