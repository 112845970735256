import  { useMemo, useState } from 'react';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper
} from '@tanstack/react-table';

import {
  Table as TableShadcn,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
} from '@shadcn/components/ui/table';

import  {Card, CardContent as CardBody} from '@shadcn/components/ui/card';
import '../../datatables.scss';
import Select from '@shadcn/components/ui/react-select';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SupplyChainNav from '../../../../components/supply-chain/nav.js';
import useQuery from '../../../../helpers/JIFI/useQuery.js';
import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts.js';
import { StatusBadge } from '../../../../components/supply-chain/StatusBadge/index.js';
import { Input } from '@shadcn/components/ui/input';
import { Col } from '@shadcn/components/ui/col';
import { Button } from '@shadcn/components/ui/button';
import { TablePagination } from '@components/supply-chain/TablePagination';

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'disapproved', label: 'Disapproved' },
];


const columnHelper = createColumnHelper();
const PullPage = () => {
  const [refresh, setRefresh] = useState(true);
  const history = useHistory();
  /*
    Pagination
  */
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const refreshData = () => setRefresh((prev) => !prev);

  const dateString = new Date()
    .toDateString()
    .replace(' ', '-')
    .replace(' ', '-')
    .replace(' ', '-');

  const handleUpdateRelease = (status, id) => {
    superagent
      .post(`${backendUrl}/purchase-order/release/update-status/${id}`)
      .send({ status })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [listRelease, listReleaseState] = useQuery({
    url: `/purchase-order/release/all`,
    refresh,
    queryData: {
      page,
      perPage,
      status,
      searchTerm,
    },
  });
  const listReleaseData = listRelease?.releases;
  const totalCount = listRelease?.totalCount;

  const columns = useMemo(
    ()=> [
      columnHelper.accessor('reference', {
        header: 'Purchase Order',
        cell: ({row}) => (
          <Link to={`/purchase-order/${row.original.purchaseOrder._id}`} className="text-body font-weight-bold pl-2">
            {row.original.purchaseOrder.reference}
          </Link>
        )
      }),
      
      columnHelper.accessor('expectedReleaseDate', {
        header: 'Expected Release Date',
        cell: ({row}) => {
          const expectedReleaseDate = new Date(
            row.original?.purchaseOrder?.expectedReleaseDate
          ).toDateString() ||
          ''
          return <p className='pl-2'>{expectedReleaseDate}</p> }
      }),
      columnHelper.accessor('releaseLinesCount', {
        header: 'SKUs Releases',
        cell: ({row}) => 
        <p className='text-center'> { row.original?.releaseLines?.length} </p>
      }),
      columnHelper.accessor('totalReleaseQuantity', {
        header: 'Total Release Count',
        cell: ({row}) => 
         <p className='text-center'> {row.original?.releaseLines.reduce(
            (sum, line) => sum + line.quantity,
            0
          )}
          </p>
      }),
      columnHelper.accessor('actualDateReleased', {
        header: 'Actual Release Date',
        cell: ({row}) => 
          <p className='pl-2'>{row.original?.actualDateReleased &&
            new Date(
              row.original?.actualDateReleased
            ).toDateString() ||
            ''}
            </p>
      }),

      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({row}) =>   <StatusBadge
        status={row?.original?.status}
      />
      
      }),
    ]
  )


  const table = useReactTable({
    data: listReleaseData ?? [],
    columns,
   
    
    getCoreRowModel: getCoreRowModel(),
    
  });


  const csvData = [
    [
      'Purchase Order',
      'Expected Release Date',
      'SKUs Releases',
      'Total Release Count',
      'Actual Release Date',
      'Status',
    ],
    ...(listReleaseData?.length > 0
      ? listReleaseData.map((releaseData) => {
          return [
            releaseData['purchaseOrder']?.reference,
            (releaseData?.purchaseOrder?.expectedReleaseDate &&
              new Date(
                releaseData?.purchaseOrder?.expectedReleaseDate
              ).toDateString()) ||
              '',
              releaseData?.releaseLines?.length,
              releaseData.releaseLines.reduce(
              (sum, line) => sum + line.quantity,
              0
            ),
            (releaseData?.actualDateReleased &&
              new Date(
                releaseData?.actualDateReleased
              ).toDateString()) ||
              '',
              releaseData?.status,
          ];
        })
      : []),
  ];
  const handleKeyDown = (e) => {
    
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <SupplyChainNav>
      
      <Col className="w-full">
          <Card className="pt-6 mb-[60px]">
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', gap: 20 }}>
                  <div className="search-box mb-2 d-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyDown={handleKeyDown}
                      />
                     
                    </div>
                  </div>

                  <div style={{ width: '60%' }}>
                    <Select
                      options={statusOptions}
                      onChange={(val) => {
                        setPage(1);

                        setStatus(val.value);
                      }}
                      value={statusOptions.find((val) => val.value === status)}
                      styles= {
                        {
                          control: (provided) => ({
                            ...provided,
                            height: "36px",
                            minHeight: "36px",
                            border: "1px solid black",
                            boxShadow: "none",
                            '&:hover': {
                              border: "1px solid black",
                            },
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            backgroundColor: "black",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "black", 
                            '&:hover': {
                              color: "black",
                            },
                          }),
                        }
                      }
                    ></Select>
                  </div>
                </div>

                <CSVLink
                  style={{ float: 'right', marginBottom: '1.25rem' }}
                  data={csvData}
                  filename={`PURCHASE_ORDERS_RELEASE_${dateString}.csv`}
                >
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-md btn-rounded"
                  >
                    CSV
                  </Button>
                </CSVLink>
              </div>

              
                <TableShadcn className="w-full table-auto">
                  <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                              return (
                                <TableHead
                                  key={header.id}
                                  className="text-left text-black"
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </TableHead>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableHeader>
                      {listReleaseState === 'success' ?
                      <TableBody>
                        {table?.getRowModel().rows?.length > 0 ? (
                          table?.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && 'selected'}
                            >
                              {row?.getVisibleCells()?.map((cell) => (
                                <TableCell key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} className="text-center">
                              No results.
                            </TableCell>
                          </TableRow>
                        )}
                        </TableBody>
                      : <TableSkeleton columns={columns.length} perPage={perPage} />
                      }
                  </TableShadcn>
             

              <TablePagination
                className={'pt-6'}
                count={totalCount}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
        </Col>
      
    </SupplyChainNav>
  );
};

export default PullPage;
