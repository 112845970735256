import { Card } from "@shadcn/components/ui/card"
import { cn } from "@shadcn/lib/utils"

export const InfoBox = ({ label, value, icon, className }) => (
  <Card className={cn("p-4 bg-muted/50", className)}>
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2 text-sm text-muted-foreground">
        {icon}
        <span>{label}</span>
      </div>
      <div className="text-base font-medium truncate">
        {value || <span className="text-muted-foreground">-</span>}
      </div>
    </div>
  </Card>
)
