import React from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@shadcn/components/ui/dialog";

import { Button } from '@shadcn/components/ui/button';


import useParcelStore from '@zustand/useParcelStore'
import { toast , ToastContainer} from "react-toastify";
import superagent from "superagent";
import { backendUrl } from "@helpers/consts";
import ParcelForm from "./ParcelForm";

const CreateOrEditParcelDialog = ({ productID, refresh }) => {
  
  const { isOpen, initialData, setIsOpen, reset ,edit} = useParcelStore()
  

  const defaultValues = {
    parcel_description: '',
    declared_price: '',
    weight: '',
    length: '',
    width: '',
    depth: ''
  }

  const { register, handleSubmit, formState: { errors }, reset: resetForm } = useForm({
    defaultValues: initialData || defaultValues
  });

  React.useEffect(() => {
    if (initialData) {
      resetForm(initialData)
    } else {
      resetForm(defaultValues)
    }
  }, [initialData, resetForm])

  const onOpenChange = () => {
    if(isOpen) {
      resetForm(defaultValues)
      reset()
    }
    setIsOpen(!isOpen)
  }

  const onSubmit = async(data) => {
    try {
      const endpoint = edit 
        ? `/orders/editParcel/${initialData._id}/`
        : '/orders/createParcel/'

      const payload = {
        product: productID,
        ...data
      }

      const response = await superagent
        .post(`${backendUrl}${endpoint}`)
        .send(payload)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')

      console.log(response)
      if(response.status > 400) {
        toast.error(`Failed to ${edit ? 'update' : 'create'} parcel`)
      } else {
        toast.success(`Parcel ${edit ? 'updated' : 'created'} successfully`)
      }
      refresh()
      onOpenChange()

    } catch (error) {
      console.error(error)
      toast.error(`Error ${edit ? 'updating' : 'creating'} parcel`)
      onOpenChange()
    }
  }
  




  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <ToastContainer />
      <DialogTrigger asChild>
        <Button>{ "Create Parcel" }</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{edit ? "Edit Parcel" : "Create Parcel"}</DialogTitle>
        </DialogHeader>
        <ParcelForm initialData={initialData} onSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrEditParcelDialog;
