import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row } from "@shadcn/components/ui/row";
import { Col } from "@shadcn/components/ui/col";
import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { Button } from "@shadcn/components/ui/button";

// Import images
import profileImg from "../../../assets/images/profile-img.png";
import logoImg from "../../../assets/images/logo.svg";
import avatar from "../../../assets/images/users/avatar-1.jpg";

const LockScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Submitted with:", data);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-12 sm:pt-12">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Row className="justify-content-center">
            <Col className="md:w-8/12 lg:w-6/12 xl:w-5/12">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="w-7/12">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Lock screen</h5>
                        <p>Enter your password to unlock the screen!</p>
                      </div>
                    </Col>
                    <Col className="w-5/12 self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
                      <div className="user-thumb text-center mb-4">
                        <img
                          src={avatar}
                          className="rounded-circle img-thumbnail avatar-md"
                          alt="thumbnail"
                        />
                        <h5 className="font-size-15 mt-3">Maria Laird</h5>
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          id="password"
                          type="password"
                          placeholder="Enter Password"
                          className="form-control"
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters",
                            },
                          })}
                        />
                        {errors.password && (
                          <div className="text-danger mt-2">
                            {errors.password.message}
                          </div>
                        )}
                      </div>

                      <div className="form-group row mb-0">
                        <Col xs="12" className="text-right">
                          <Button
                            className="w-md waves-effect waves-light"
                            type="submit"
                          >
                            Unlock
                          </Button>
                        </Col>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-12 text-center">
                <p>
                  Not you? Return{" "}
                  <Link
                    to="/login"
                    className="font-weight-medium text-primary"
                  >
                    Sign In
                  </Link>
                </p>
                <p>
                  © 2020 Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LockScreen;
