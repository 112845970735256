

import {useSelector, useDispatch} from 'react-redux'

import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
 
} from '@shadcn/components/ui/select';
import {
	CHANGE_LOCATION
} from "../../../store/layout/actionTypes";

import useQuery from '../../../helpers/JIFI/useQuery';

const LocationSelector = ({parentSelected, parentSetSelected}) => {
  const dispatch = useDispatch()

  let selectedLocation = useSelector(state => state.Layout.location)
  let changeLocation = (value) => {
    dispatch({ type: CHANGE_LOCATION, payload: value })
  }

  let selected = selectedLocation
  let setSelected = changeLocation
  if (parentSelected !== undefined && parentSetSelected !== undefined) {
    selected = parentSelected
    setSelected = parentSetSelected
  }

  const [locations, state] = useQuery({
    url: `/inventory/getLocations`
  })

  return <Select 
  value={selected} 
  onValueChange={(value) => setSelected(value)} 
>
  <SelectTrigger>
    <SelectValue />
  </SelectTrigger>
  <SelectContent>
    {locations ? (
      locations.map((val) => (
        <SelectItem key={val.name} value={val.name}>
          {val.display_name}
        </SelectItem>
      ))
    ) : (
      <SelectItem value="HQ2">HQ2</SelectItem>
    )}
  </SelectContent>
</Select>
}

export default LocationSelector
