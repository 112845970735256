import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import { Input } from '@shadcn/components/ui/input';
import { Dialog, DialogContent, DialogFooter } from '@shadcn/components/ui/dialog';
import { Col } from '@shadcn/components/ui/col'
import { Row } from '@shadcn/components/ui/row'
import { Button } from '@shadcn/components/ui/button'
import { Card , CardContent as CardBody} from '@shadcn/components/ui/card'
import superagent from 'superagent';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import useQuery from '../../helpers/JIFI/useQuery';
import { backendUrl } from '../../helpers/consts.js';
import "./datatables.scss";
import ProductVariantSelector from './helpers/ProductVariantSelector.js';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@shadcn/components/ui/select';

const ProductRow = ({
  lineKey,
  item,
  products, 
  editQuantityRow,
  editVariantRow,
  editProductRow,
  removeRow,
}) => {
  const handleRemoveRow = () => {
    removeRow(lineKey)
  }

  const setSelectedVariant = (a, b) => {
    editProductRow(lineKey, a?.product)
    editVariantRow(lineKey, a?._id, a?.sku, a?.name)
  }
  
  return (
    <Row className='gap-6 mb-6'>
      <Col md="8" className='sm:w-[60%]'>
        <ProductVariantSelector
          products={products}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={item}
          hidePersonalisationOptions
        />
      </Col>
      <Col md="3" className='sm:w-[30%]'>
        <Input style={{ width: '90%', ...(item['quantity'] <= 0 ? { border: '1px solid red' } : {}) }} type="number" value={item['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)} />
      </Col>
      <Col xs="1" className='sm:w-[6%]'>
        <Button onClick={handleRemoveRow} variant="destructive">
          X
        </Button>{' '}
      </Col>
    </Row>
  );
};

const StockInput = ({stock, setStock, productData}) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]._id
    }
    const item = {
      product: "",
      variant: "",
      name: "",
      quantity: 0
    };
    setStock(prev => [...prev, item])
  };

  const editProductRow = (id, productId) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['product'] = productId
      const variant = productData.find(val => `${val._id}` === `${productId}`)
      newList[id]['variant'] = variant?.variants[0]?._id
      return newList
    })
  };

  const editVariantRow = (id, val, sku, name) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      newList[id]['quantity'] = 0
      newList[id]['sku'] = sku
      newList[id]['name'] = name
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['quantity'] = parseInt(val)
      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((_, key) => key !== id)
      return newList
    })
  };

  useEffect(() => {
    if(productData){
      // editProductRow(0, productData[0]._id)
      // editVariantRow(0, productData[0]['variants'][0]._id)
    }
  }, [productData])


  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id),
        sku: variant.sku,
        product: product._id
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="8" className="sm:w-[60%]">
        Variant
      </Col>
      <Col xs="4" className="sm:w-[30%]">
        Quantity
      </Col>
    </Row>
    <br/>
    {
       productData && stock.map((stockObjectRow, lineKey) =>
       <ProductRow
         key={lineKey}
         item={stockObjectRow}
         lineKey={lineKey}
         products={products}
         editQuantityRow={editQuantityRow}
         editProductRow={editProductRow}
         editVariantRow={editVariantRow}
         removeRow={removeRow}
       />
     )
    }
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
      <Button onClick={handleAddRow} variant="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
  </>
}


const PullPage =  () => {
  const history = useHistory();

  const [refresh, setRefresh]           = useState(true)
  const [stockPush, setStockPush]       = useState([])
  const [stockPull, setStockPull]       = useState([])
  const [col1, setCol1]                 = useState(false)
  const [col2, setCol2]                 = useState(false)
  const [modal, setModal]               = useState(false)
  const [stockType, setStockType]       = useState('on_hand') 
  const [stockTypeTo, setStockTypeTo]   = useState('on_hand') 
  const [locationFrom, setLocationFrom] = useState('HQ2') 
  const [locationTo, setLocationTo]     = useState('HQ2') 
  const [loading, setlLoading]          = useState(false)

  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data

  const togglemodal =  () => setModal(prev => !prev)

  const handleSubmit = () => {
    setlLoading(true)
    const exportData = {
      locationFrom: locationFrom,
      locationTo: locationTo,
      pushStock: stockPush,
      stockTypeFrom: stockType,
      stockTypeTo: stockTypeTo
    }
    // console.log(exportData)
    
    superagent.post(`${backendUrl}/inventory/transfer`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
      const code = response.status
      if (code > 400) {
          history.replace(history.location.pathname, {
          errorStatusCode: code
          });
      } else {
        history.go(0)
      }
      }).catch(err => {
      console.log(err)
      });
  } 

  return <div className="page-content">
    <div className="w-full px-3 mx-auto xl:max-w-[85%]">
      <Row className='gap-6'>
        <Col xs="8" className="sm:w-[60%]">
          <Breadcrumbs title="Inventory" breadcrumbItem={'Transfer Stock'} />
        </Col>
        <Col xs="4" className="sm:w-[30%]">
          <Link  to="/pull" className="dropdown-item text-danger">Add/Remove Stock</Link>
        </Col>
      </Row>
      <Row className="mb-2 gap-6">
        <Col  xs="12" m="8" lg="8" xl="8" className="sm:w-[60%]" >
          <Card className='pt-6'>
            <CardBody>
              <Row>
                <Col xs="5">
                  <div> Location: </div>
                  <Select
  defaultValue="HQ2"
  onValueChange={(value) => {
    setLocationFrom(value);
    if (value === 'QV Store' || value === '80 Collins') {
      setStockType('on_hand');
    }
  }}
>
  <SelectTrigger>
    <SelectValue placeholder="Select Location" />
  </SelectTrigger>
  <SelectContent>
    <SelectItem value="HQ2">HQ2</SelectItem>
    <SelectItem value="QV Store">QV Store</SelectItem>
    <SelectItem value="80 Collins">80 Collins</SelectItem>
  </SelectContent>
</Select>

                  <br/>
                  <div> Stock Type: </div>
                  <Select
  defaultValue="on_hand"
  onValueChange={(value) => setStockType(value)}
>
  <SelectTrigger>
    <SelectValue placeholder="Select Stock Type" />
  </SelectTrigger>
  <SelectContent>
    <SelectItem value="on_hand">On Hand</SelectItem>
    {locationFrom === 'HQ2' && (
      <>
        <SelectItem value="dead">Dead</SelectItem>
        <SelectItem value="display">Display</SelectItem>
      </>
    )}
  </SelectContent>
</Select>

                </Col>
                <Col xs="1">
                  <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                    <i className="bx bx-right-arrow"></i>
                  </div>
                </Col>
                <Col xs="6">
                  <div> Location: </div>
                  <div style={{ width: '100%' }}>
  <Select
    defaultValue="HQ2"
    onValueChange={(value) => {
      setLocationTo(value);
      if (value === 'QV Store' || value === '80 Collins') {
        setStockTypeTo('on_hand');
      }
    }}
  >
    <SelectTrigger>
      <SelectValue placeholder="Select Location" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="HQ2">HQ2</SelectItem>
      <SelectItem value="QV Store">QV Store</SelectItem>
      <SelectItem value="80 Collins">80 Collins</SelectItem>
    </SelectContent>
  </Select>
</div>
<br />
<div> Stock Type: </div>
<div style={{ width: '100%' }}>
  <Select
    defaultValue="on_hand"
    onValueChange={(value) => setStockTypeTo(value)}
  >
    <SelectTrigger>
      <SelectValue placeholder="Select Stock Type" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="on_hand">On Hand</SelectItem>
      {locationTo === 'HQ2' && (
        <>
          <SelectItem value="dead">Dead</SelectItem>
          <SelectItem value="display">Display</SelectItem>
        </>
      )}
    </SelectContent>
  </Select>
</div>

                </Col>
              </Row>
              <br/><br/>
              <div id="accordion">
                <StockInput stock={stockPush} setStock={setStockPush} productData={productData} />
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
                <span />
                {(productData && (stockPull.length > 0 || stockPush.length > 0)) && (
                  <Button onClick={togglemodal} variant="primary" className="btn-lg btn-rounded">
                    Submit
                  </Button>
                ) }
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" m="4" lg="4" xl="4" className="sm:w-[30%]">
          <Card className='pt-6'>
            <CardBody>
              <h4 className="card-title mb-4">Summary</h4>

              {
                productData && stockPush.length > 0 && 'Adding:'
              }
              {
                productData && stockPush.map((line, lineKey) => {
                  return <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
                })
              }
            </CardBody>
          </Card> 
        </Col>
      </Row>
    </div>
    <Dialog open={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" onOpenChange={togglemodal}>
              <DialogContent>
      <div>
        <h4 className="card-title mb-4">INTERNAL STOCK SUMMARY</h4>
        <br />
        {
          productData && stockPull.length > 0 && 'Removing:'
        }
        {
          productData && stockPull.map((line, lineKey) => {
            return <div>  <span className="text-danger">-{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
          })
        }
        <br />
        {
          productData && stockPush.length > 0 && 'Adding:'
        }
        {
          productData && stockPush.map((line, lineKey) => {
            return <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
          })
        }
      </div>
      <DialogFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} variant="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
}

export default PullPage
