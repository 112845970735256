import React, {  useState, useEffect } from "react";
import { Col } from '@shadcn/components/ui/col'
import { Row } from '@shadcn/components/ui/row'

import { Link } from "react-router-dom";
import { Card, CardContent as CardBody }  from '@shadcn/components/ui/card'
import { CSVLink } from "react-csv";
import useQuery from '../../../helpers/JIFI/useQuery';

import moment from 'moment';


import Select from '@shadcn/components/ui/react-select'
import { Button } from "@shadcn/components/ui/button";

const mapToObj = (inputMap) => {
  let obj = {};

  inputMap.forEach(function(value, key){
    obj[key] = value
  });
  return obj;
}

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return mapToObj(map);
}

const categories =  [
  'CLASSIC',
  'LIGHT',
  'TRUNK',
  'SET',
  'BAG',
  'ACCESSORIES',
  'MISC',
]

const countries = [
  'All',
  'AU',
  'AU - VIC',
  'AU - QLD',
  'AU - NSW',
  'US',
  'UK'
]

const countriesToLocations = {
  All: ["QV Store", "EWE - Sydney", "USA - Dallas", "UNIS", "HQ2", "EWE", "DJ Store", "Galeries Sydney", "80 Collins", "Bondi", "Airbox UK", 'Launch Fulfilment Salt Lake City', 'Launch Fulfilment Kentucky', , 'EWE - Brisbane', 'EWE - Perth'],
  AU: ["QV Store", "EWE - Sydney", "HQ2", "EWE", "DJ Store", "Galeries Sydney", "80 Collins", "Bondi", 'EWE - Brisbane', 'EWE - Perth'],
  'AU - VIC': ['EWE'],
  'AU - NSW': ['EWE - Sydney'],
  'AU - QLD': ['EWE - Brisbane'],
  'AU - WA': ['EWE - Perth'],
  US: ["UNIS", "USA - Dallas", 'Launch Fulfilment Salt Lake City', 'Launch Fulfilment Kentucky'],
  UK: ["Airbox UK"]
}

const reverse=a=>[...a].map(a.pop,a)

const BasicTable = () => {
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }

  const [selectedCountry, setSelectedCountry] = useState('AU - VIC')

  const [tableData, setTableData] = useState([])
  let [searchTerm, setSearchTerm] = useState('')

  const [products, productState] = useQuery({
    url: `/product/findAll`,
    refresh,
  });

  const [containers, containerState] = useQuery({
    url: `/inventory/fetchUpcomingContainer/`,
    refresh,
  });

  let [groupedData, setGroupedData] = useState([])

  let [expandedProducts, setExpandedProducts] = useState([])
  const toggleProductSelected = (id) => {
    if (expandedProducts.includes(id)) {
      setExpandedProducts(expandedProducts.filter(curId => id!== curId))
    } else {
      setExpandedProducts([...expandedProducts, id])
    }
  }

  useEffect(() => {
    if (products && containers) {

      let internalGroupedData = []

      products.data.map(product => {
        let lines = []
        product.variants.map(variant => {
          const sku = variant.sku
          let containerLineData = [...(containers ? containers : []).filter(container => countriesToLocations[selectedCountry].includes(container.landingLocation.display_name))].reverse().map(container => {
            let count = container.containerLines.find(variantCount => variantCount.variant.sku === sku)
            if (count == undefined){
              return 0
            }
            return count.quantity
          })
          if ((searchTerm === '' || sku.toUpperCase().includes(searchTerm.toUpperCase()))) {
            lines.push([sku, ...containerLineData])
          }
          
        })
        internalGroupedData.push({
          product: {
            ...product,
            categoryIndex: categories.findIndex(val => val === product.category) || 10,
          }, 
          lines
        })
      })

      internalGroupedData = internalGroupedData.filter(val => val.lines.length !== 0).sort((a, b) => a.product.name.localeCompare(b.product.name))
      const categoryGrouped = groupBy(internalGroupedData, productGroup => productGroup.product.category);
      setGroupedData(categoryGrouped)
    }
  }, [products, containers, selectedCountry, expandedProducts, searchTerm])

  useEffect(() => {
    if (products && containers) {
      const updatedTable = []

      products.data.map(product => {
        product.variants.map(variant => {
          const sku = variant.sku
          let containerLineData = [...containers].reverse().map(container => {
            let containerLine = container.containerLines.find(variantCount => variantCount.variant.sku === sku)
            if (containerLine == undefined){
              return 0
            }
            return containerLine.quantity
          })
          updatedTable.push([sku, ...containerLineData])
        })
      })

      setTableData(updatedTable)
    }
  }, [products, containers])
  
  const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
  const csvData = (products && containers) ? [
      ['SKU', ...([...containers].reverse()).map(container => (`${moment(container.arrivalDate).format('YYYY-MM-DD')} - ${container.landingLocation.display_name}`))],
      ...tableData.map(line => ( line.map(cell => cell !== 0 ? cell : '') ))
  ] : []

  const filteredContainers = (containers ? containers : []).filter(container => countriesToLocations[selectedCountry].includes(container.landingLocation.display_name))

  const allSelected = expandedProducts.length === products?.data?.length || searchTerm !== ''
  const toggleProductSelectedAll = () => {
    if (allSelected) {
      setExpandedProducts([])
      setSearchTerm('')
    } else {
      setExpandedProducts(products.data.map(product => product._id))
    }
  }

  const user = JSON.parse(localStorage.getItem("authUser"));

  return <>
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col>
            <Card className='mb-6'>
              <div style={{margin: '0 12px'}}>
                <h4 className="card-title my-4">Upcoming Containers</h4>
                <Row style={{background: '#fff', borderTopLeftRadius: 16, borderTopRightRadius: 16}}>
                  <Col sm="2" className="sm:w-2/12" style={{padding: 16}}>
                    <Button type="button" variant={!allSelected ? 'success' : 'warning'} className="btn-lg btn-rounded" onClick={toggleProductSelectedAll}> {!allSelected ? 'Expand All' : searchTerm !== '' ? 'Close All & Clear' : 'Close All'} </Button>
                  </Col>
                  <Col sm="5" className="sm:w-5/12" style={{paddingBottom: 8}}>
                    <div className="app-search d-none d-lg-block">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..." 
                          value={searchTerm} 
                          onChange={(e) => setSearchTerm(e.target.value)} 
                        />
                        <span className="bx bx-search-alt"></span>
                      </div>
                    </div>
                  </Col>
                  <Col sm="3" className="sm:w-3/12" style={{padding: 16}}>
                    <div>
                      <div className="mb-4" style={{width: '100%'}}>
                        <Select
                          options={countries.map(country => ({label: country, value: country}))}
                          onChange={selectedCountry => setSelectedCountry(selectedCountry.value)}
                          value={{label: selectedCountry, value: selectedCountry}}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="2" className="sm:w-2/12" style={{padding: 16}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <div>
                        {
                          user.permissions.includes('EditContainer') && (
                            <Link to='containers'> <Button variant="success"> Edit </Button> </Link>
                          )
                        }
                        <CSVLink 
                          data={csvData}
                          filename={`UPCOMING_CONTAINERS_${dateString}.csv`}
                        >
                          <Button type="button" variant="secondary" className="btn-lg btn-rounded">
                          CSV
                          </Button>
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody>
                <div style={{
                  display: 'block',
                  width: '100%',
                  overflowX: 'auto',
                  overflowY: 'scroll',
                  height: 'calc(70vh)',
                  whiteSpace: 'nowrap'
                }}>
                {
                  products && containers ? (
                    <table className="table mb-0">
                        <thead style={{position: 'sticky', top: 0, background: 'white', }}>
                            <tr>
                                <th>SKU</th>
                                {
                                  ([...filteredContainers].reverse()).map(container => {
                                    if (!user.permissions.includes('EditContainer'))
                                      return (<th> {moment(container.arrivalDate).format('YYYY-MM-DD')} <br/> {container.landingLocation.display_name} </th>)
                                    return (<th> <Link to={`/container/${container._id}`}> {moment(container.arrivalDate).format('YYYY-MM-DD')} <br/> {container.landingLocation.display_name} <br/> {container.name} </Link> </th>)
                                  })
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {
                          categories.map((category, index) => {
                            const categoryGroup = groupedData[category]
                            if (!categoryGroup) return

                            const handleCatClicked = () => {
                              let allProducts = categoryGroup.map(groupedProduct => groupedProduct.product._id)
                              
                              let checker = (arr, target) => target.every(v => arr.includes(v));

                              if (checker(expandedProducts, allProducts)) {
                                setExpandedProducts(prev => prev.filter(val => !allProducts.includes(val)))
                              } else {
                                setExpandedProducts(prev => [...prev,...allProducts])
                              }
                            }

                            return <>
                              <tr onClick={handleCatClicked} className="bg-black" key={`cat-${category}${index}`} style={{userSelect: 'none',  color: '#fff', cursor: 'pointer'}}>
                                <td><b>{category}</b></td>
                              </tr>
                              {
                                categoryGroup.map((groupedProduct, index) => {
                                  const {
                                    product, 
                                    lines
                                  } = groupedProduct

                                  const isExpanded = expandedProducts.includes(product._id) || searchTerm !== ''

                                  if (lines.length === 0) return 

                                  return <>
                                    <tr key={`prod-${product.name}${index}`} style={{cursor: 'pointer', userSelect: 'none'}} onClick={() => toggleProductSelected(product._id)}>
                                      <td><b>{product.name}</b></td>
                                    </tr>
                                    {
                                      isExpanded && lines.map((line, lineIndex) => (
                                        <tr>
                                          {
                                            line.map(cell => cell !== 0 ? <th scope="row" style={{borderLeft: '2px solid #eff2f7'}}> {cell} </th> : <th style={{borderLeft: '2px solid #eff2f7'}}></th>)
                                          }
                                        </tr>
                                      ))
                                    }
                                  </>
                                })
                              }
                            </>
                          })
                        }
                        </tbody>
                    </table>
                  ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
                }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </>
}

export default BasicTable
