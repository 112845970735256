import  { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginUser, apiError } from '../../../store/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleLogin from 'react-google-login';
import useGoogleAuthentication from './useGoogleAuthentication';
import { Button } from '@shadcn/components/ui/button';
import { Card, CardHeader, CardFooter, CardContent } from '@shadcn/components/ui/card';
import { Input } from '@shadcn/components/ui/input';

import { Checkbox } from '@shadcn/components/ui/checkbox';
import logo from '../../../assets/images/logo.svg';

const throttle = (func, interval) => {
  let lastCall = 0;
  return function () {
    const now = Date.now();
    if (lastCall + interval < now) {
      lastCall = now;
      return func.apply(this, arguments);
    }
  };
};

const GoogleButton = ({ history, path }) => {
  const { handleSuccess } = useGoogleAuthentication(history, path);

  return (
    <GoogleLogin
      clientId="532750800945-jglcsgkpac7h1fgr2flkc0u30cb3j90r.apps.googleusercontent.com"
      buttonText="Log in"
      onSuccess={handleSuccess}
      style={{
        width: '100%',
        display: 'block',
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #fff',
      }}
    />
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error } = useSelector((state) => state.Login);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    dispatch(loginUser(data, history));
  };

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-white">
      <div className="w-full max-w-md px-4">
        <Card className="border">
          <CardHeader className="bg-white text-black p-4">
            <div className="flex flex-col items-center">
              <img src={logo} alt="Logo" className="h-12 mb-4" />
              <h2 className="text-lg font-semibold">Welcome Back!</h2>
              <p className="text-sm">Sign in to continue to JIFI</p>
            </div>
          </CardHeader>
          <CardContent className="p-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="email" className="text-black">Email</label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register('email', { required: 'Email is required' })}
                  className="bg-white border border-black text-black"
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>

              <div className="space-y-2">
                <label htmlFor="password" className="text-black">Password</label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  {...register('password', { required: 'Password is required' })}
                  className="bg-white border border-black text-black"
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
              </div>

              <div className="flex items-center">
                <Checkbox id="remember" className="border border-black" />
                <label htmlFor="remember" className="ml-2 mb-0 text-black">Remember me</label>
              </div>

              <Button type="submit" className="w-full bg-black text-white border border-white">
                Log In
              </Button>
            </form>
          </CardContent>
          <CardFooter className="p-4 text-center flex flex-col">
            <div className="text-gray-400 mb-2">@july.com emails</div>
            <GoogleButton path={history.location?.state?.from} history={history} />
          </CardFooter>
        </Card>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
