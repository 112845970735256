import React, { useEffect, useState } from 'react';
import { Progress } from '@shadcn/components/ui/progress';
import { cn } from '@shadcn/lib/utils';

const WizardNav = ({ steps, activeTabProgress }) => {
  const stepProgress = steps.length + 0.5;
  const [progress, setProgress] = useState({
    activeTab: 1,
    activeTabProgress: 1,
    progressValue: 100 / stepProgress,
  });

  const toggleTabProgress = (tab) => {
    if (progress.activeTabProgress !== tab) {
      const progressProgression = (tab / steps.length) * 100;
      setProgress((prev) => ({
        ...prev,
        activeTabProgress: tab,
        progressValue: progressProgression,
      }));
    }
  };

  useEffect(() => {
    toggleTabProgress(activeTabProgress);
  }, [activeTabProgress]);

  const renderSteps = () => {
    return steps?.map((step, index) => (
      <li key={index} className="nav-item">
        <a
          className={cn('nav-link', { active: activeTabProgress === index + 1 })}
          style={{ textAlign: 'center' }}
        >
          <span className="step-number mr-2">{`0${index + 1}`}</span>
          {step}
        </a>
      </li>
    ));
  };

  return (
    <div id="progress-wizard" className="twitter-bs-wizard">
      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills" style={{ padding: '0 16px' }}>
        {steps?.length > 0 && renderSteps()}
      </ul>
      <div id="bar" className="mt-4" style={{ margin: '1.5rem 0.5rem' }}>
        <Progress value={progress.progressValue} className="h-4">
          
        </Progress>
      </div>
    </div>
  );
};

export default WizardNav;
