import React, {  useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@shadcn/components/ui/dialog";
import { Card , CardContent as CardBody, CardTitle} from '@shadcn/components/ui/card'
import { Row  } from '@shadcn/components/ui/row'
import { Col  } from '@shadcn/components/ui/col'
import { Button } from "@shadcn/components/ui/button";
import { CSVLink } from "react-csv";

import {AddProduct, AddVariant} from './AddProduct'


import "react-datepicker/dist/react-datepicker.css";

import useQuery from '../../helpers/JIFI/useQuery';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {useSelector} from 'react-redux'

import LocationSelector from './shared/LocationSelector'
import { TooltipContent, TooltipProvider, Tooltip, TooltipTrigger } from "@shadcn/components/ui/tooltip";


const BasicTable = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [modal, setModal] = useState()
    const [createVariant, setCreateVariant] = useState()

    const [refresh, setRefresh] = useState(true)
    const refreshData = () => {
        setRefresh(prev => !prev)
    }

    const togglemodal = () => {
        setModal(prev => !prev)
    }
    const toggleVariant = () => {
        setCreateVariant(prev => undefined)
    }
    const handleChange = date => {
        setStartDate(date)
    };

    const selectedLocation = useSelector(state => state.Layout.location)
    useEffect(() => {
        refreshData()
    }, [selectedLocation])

    const [data, state] = useQuery({
        url: `/inventory/fetchStockCount`,
        refresh,
        queryData: {
            location: selectedLocation
        }
    });

    const [pendingData, pendingState] = useQuery({
        url: `/inventory/pendingStock`,
        refresh,
    });
    
    const products = data && data
    const count = data && data.count

    const productDataArrays = products ? 
        products.map(product => product.variantCounts.map(variantCounts => ([variantCounts.variant.sku, variantCounts.count?.on_hand, variantCounts.count?.display, variantCounts.count?.dead]))) 
    : [[]]
    const merged = [].concat.apply([], productDataArrays);

    const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
    const csvData = [
        ['SKU', 'ON_HAND', 'DISPLAY', 'DEAD'],
        ...merged
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Inventory" breadcrumbItem="Products" />
                    <Row className="mb-2 flex-wrap">
                        <Col  className="sm:w-8/12">
                            <CSVLink 
                                data={csvData}
                                filename={`STOCK_COUNT_${dateString}.csv`}
                            >
                                <Button type="button" variant="secondary" className="btn-lg btn-rounded">
                                CSV
                                </Button>
                            </CSVLink>
                        </Col>
                        <Col  className="sm:w-2/12">
                            {/* <div className="text-sm-right">
                                <Button onClick={togglemodal} type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                            </div> */}
                        </Col>
                        <Col  className="sm:w-2/12">
                            <LocationSelector />
                        </Col>
                    </Row>
                    {
                        state === 'success' && products && products.map(product => (
                        <Row className="mb-6">
                            <Col md={12}>
                                <Card className="pt-6">
                                    <CardBody>
                                        <CardTitle className="text-[16px]">
                                            {product.productName}
                                            <div to="" onClick={() => {setCreateVariant(product['_id']);}} className="mr-3 text-primary float-right">
                                                
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                        <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            Create
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                                
                                            </div>
                                        </CardTitle>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SKU</th>
                                                        <th>Variant Name</th>
                                                        <th>Pending</th>
                                                        <th>Stock On Hand</th>
                                                        <th>Display Stock</th>
                                                        <th>Dead Stock</th>
                                                        <th>Monogramming</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        product.variantCounts.sort(function(a, b){
                                                            if(a.variant.sku < b.variant.sku) { return -1; }
                                                            if(a.variant.sku > b.variant.sku) { return 1; }
                                                            return 0;
                                                          }).map(variantCounts => {
                                                            return (
                                                                <tr>
                                                                    <th scope="row">{variantCounts.variant.sku}</th>
                                                                    <td>{variantCounts.variant.name}</td>
                                                                    <td>{pendingData && pendingData[variantCounts.variant.sku] || 0}</td>
                                                                    <td>{variantCounts.count?.on_hand}</td>
                                                                    <td>{variantCounts.count?.display}</td>
                                                                    <td>{variantCounts.count?.dead}</td>
                                                                    <td>{variantCounts.count?.monogram}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>                
                        </Row>
                        ))
                    }
                </div>
            </div>
          {modal &&  <Dialog open={modal}  onOpenChange={togglemodal}>
                <div className="modal-content">
                    <DialogHeader>
                        <DialogTitle>
                        Create Product
                        </DialogTitle>
                    </DialogHeader >
                    <DialogContent>
                        <AddProduct refreshData={refreshData} toggle={toggleVariant} />
                    </DialogContent>
                </div>
            </Dialog>
}

           {createVariant !== undefined && <Dialog open={createVariant !== undefined}  onOpenChange={toggleVariant}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                        Create Variant
                        </DialogTitle>
                        </DialogHeader >
                  
                        <AddVariant id={createVariant} refreshData={refreshData} toggle={toggleVariant} />
                  
                </DialogContent>
            </Dialog>
}
        </React.Fragment>
    );
}

export default BasicTable;
