
import { Link } from 'react-router-dom';
import useQuery from '../../../helpers/JIFI/useQuery';
import CategoryCard from './card-category';
import { Button } from '@shadcn/components/ui/button';

const CategoryListPage = () => {
  const [data, state] = useQuery({
    url: '/categories', 
  });

  const categories = data;

  if(state === 'loading') {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
      <div className="spinner-grow text-primary m-1" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
  }
  return (
    <>
      <div className="page-content">
        <div className="container mx-auto px-4 lg:max-w-[85%]">
          
          <div className="flex justify-between items-center m-4">
            <div>
              <h2 className="text-lg font-medium">Categories</h2>
           
            </div>
            <Link to="/category/new"> 
              <Button variant="success">
                <i className="mdi mdi-plus mr-1"></i> Create New
              </Button>
            </Link>
          </div>

          
          <div className="flex justify-end mb-4">
           
          </div>

          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories &&
              categories.map((val, key) => <CategoryCard category={val} key={key} />)
}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryListPage;
