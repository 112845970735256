import  { useState, useEffect } from "react";


import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Card, CardContent as CardBody, CardTitle, CardDescription as CardText , CardHeader, CardFooter} from "@shadcn/components/ui/card";
import { Link } from "react-router-dom";
import { Button } from '@shadcn/components/ui/button'
import { Badge } from "@shadcn/components/ui/badge";
import { Progress } from "@shadcn/components/ui/progress.jsx";

import {
  TabsContent,
  Tabs
} from '@shadcn/components/ui/tabs';


import {backendUrl} from '../../../helpers/consts.js'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import superagent from 'superagent'

import Select from "@shadcn/components/ui/react-select";
import CommentHostory from "../../Skote/Dashboard/CommentHistory.js";
import useProductData from "../orders/useProductData.js";
import SKUToolTip from "../helpers/SKUToolTip.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useSKUStatus from "../helpers/useSKUStatus.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";

import classnames from "classnames";
import { Dialog, DialogContent } from "@shadcn/components/ui/dialog.jsx";

const nestingData = {
  'COPB': ['CHK', 'PLS'], 
  'CHK': ['PLS'], 
  'PLS': [], 

  'SET-CHKCOPB': [], 

  'TRK-COPB': ['CHK', 'PLS'], 
  'TRK-CHK': [], 

  'LGT-CO': ['CHK', 'PLS'], 
  'LGT-COEX': ['CHK', 'PLS'], 
  'COES': ['CHK', 'PLS'], 

  'PRO-COPB': ['CHK', 'PLS'], 
  'PROM-COPB': ['CHK', 'PLS'], 
  'TMA-COPB': ['CHK', 'PLS'], 

  'CAR-WKDP': ['CHK', 'PLS'], 

  'CAR-TOT': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
  'TOT': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
  'CAR-BPK': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
  'BPK': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
  'CAR-WKD': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
  'WKD': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 


  'CAR-DPK': [], 
  'CAR-SLI': [], 
  'CAR-POC': [], 

  'DBK-WKD': [], 
  'DBK-BPK': [], 
  'DBK-TOT': [], 
  'VOL-BPK': [], 
  'VOL-WKD': [], 

  'PRO-SLEE': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB' ], 
}

const accNestingdata = {
  'PB': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'CME': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  '4PC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  '8PC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'TEC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'COV-TRK': [], 
  'TPC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-WKDP'], 
  'APC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'NBK': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'TAG': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'BOT': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'IPC': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'], 
  'STR': [ 'COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP', 'CME'], 
  'TOY': [], 
}

const DIPPostcodes = [
  3067,
  3040,
  3042,
  3021,
  3206,
  3020,
  3078,
  3018,
  3028,
  3025,
  3022,
  3143,
  3032,
  3147,
  3147,
  3195,
  3195,
  3049,
  3034,
  3183,
  3103,
  3104,
  3175,
  3153,
  3153,
  3807,
  3193,
  3160,
  3160,
  3160,
  3081,
  3204,
  3165,
  3806,
  3193,
  3130,
  3130,
  3130,
  3196,
  3155,
  3977,
  3128,
  3129,
  3128,
  3195,
  3019,
  3088,
  3186,
  3187,
  3047,
  3012,
  3056,
  3057,
  3055,
  3105,
  3083,
  3121,
  3023,
  3023,
  3125,
  3151,
  3023,
  3037,
  3124,
  3061,
  3126,
  3053,
  3054,
  3163,
  3023,
  3197,
  3201,
  3162,
  3145,
  3161,
  3162,
  3148,
  3196,
  3196,
  3192,
  3116,
  3169,
  3168,
  3169,
  3068,
  3978,
  3978,
  3058,
  3058,
  3066,
  3048,
  3064,
  3977,
  3977,
  3977,
  3977,
  3977,
  3121,
  3136,
  3136,
  3136,
  3136,
  3047,
  3175,
  3175,
  3175,
  3103,
  3023,
  3037,
  3026,
  3089,
  3172,
  3008,
  3108,
  3109,
  3111,
  3754,
  3177,
  3084,
  3002,
  3196,
  3185,
  3095,
  3095,
  3184,
  3802,
  3076,
  3040,
  3041,
  3041,
  3040,
  3177,
  3078,
  3060,
  3156,
  3065,
  3068,
  3031,
  3011,
  3131,
  3199,
  3200,
  3199,
  3185,
  3043,
  3163,
  3146,
  3150,
  3046,
  3043,
  3088,
  3059,
  3046,
  3803,
  3188,
  3188,
  3976,
  3122,
  3123,
  3202,
  3135,
  3084,
  3081,
  3081,
  3190,
  3037,
  3029,
  3166,
  3166,
  3099,
  3079,
  3079,
  3047,
  3977,
  3064,
  3766,
  3021,
  3036,
  3038,
  3033,
  3038,
  3036,
  3042,
  3031,
  3101,
  3102,
  3173,
  3137,
  3137,
  3021,
  3083,
  3012,
  3180,
  3144,
  3075,
  3910,
  3028,
  3026,
  3140,
  3093,
  3975,
  3975,
  3156,
  3156,
  3085,
  3012,
  3144,
  3145,
  3032,
  3204,
  3048,
  3000,
  3004,
  3045,
  3194,
  3754,
  3064,
  3206,
  3082,
  3132,
  3127,
  3129,
  3094,
  3765,
  3039,
  3189,
  3194,
  3138,
  3195,
  3931,
  3930,
  3796,
  3149,
  3170,
  3163,
  3805,
  3804,
  3805,
  3015,
  3042,
  3174,
  3174,
  3051,
  3113,
  3070,
  3168,
  3131,
  3046,
  3166,
  3166,
  3167,
  3809,
  3204,
  3810,
  3114,
  3195,
  3052,
  3044,
  3044,
  3197,
  3090,
  3030,
  3207,
  3181,
  3072,
  3054,
  3023,
  3095,
  3073,
  3121,
  3134,
  3135,
  3134,
  3185,
  3084,
  3178,
  3064,
  3977,
  3191,
  3179,
  3028,
  3198,
  3018,
  3011,
  3159,
  3977,
  3062,
  3015,
  3205,
  3752,
  3006,
  3141,
  3006,
  3015,
  3171,
  3172,
  3021,
  3088,
  3182,
  3183,
  3182,
  3041,
  3041,
  3020,
  3020,
  3020,
  3127,
  3037,
  3029,
  3037,
  3038,
  3160,
  3106,
  3107,
  3154,
  3074,
  3071,
  3142,
  3012,
  3032,
  3029,
  3043,
  3156,
  3158,
  3133,
  3133,
  3084,
  3152,
  3152,
  3113,
  3134,
  3134,
  3195,
  3087,
  3087,
  3096,
  3030,
  3030,
  3012,
  3003,
  3049,
  3150,
  3027,
  3016,
  3016,
  3181,
  3750,
  3115,
  3024,
  3085,
  3091,
  3013,
]

const DIPSydneyPostcodes = [
  2176,
  2046,
  2763,
  2753,
  2560,
  2015,
  2234,
  2100,
  2218,
  2560,
  2038,
  2156,
  2159,
  2205,
  2148,
  2064,
  2193,
  2168,
  2131,
  2077,
  2144,
  2179,
  2107,
  2555,
  2093,
  2093,
  2041,
  2041,
  2234,
  2216,
  2019,
  2200,
  2200,
  2000,
  2234,
  2565,
  2207,
  2207,
  2197,
  2153,
  2104,
  2100,
  2015,
  2155,
  2119,
  2191,
  2153,
  2023,
  2192,
  2085,
  2141,
  2765,
  2081,
  2082,
  2159,
  2217,
  2209,
  2207,
  2207,
  2770,
  2107,
  2107,
  2041,
  2143,
  2770,
  2148,
  2560,
  2559,
  2221,
  2756,
  2026,
  2026,
  2022,
  2226,
  2177,
  2177,
  2176,
  2019,
  2566,
  2753,
  2765,
  2560,
  2137,
  2216,
  2556,
  2024,
  2100,
  2570,
  2767,
  2230,
  2134,
  2136,
  2168,
  2137,
  2166,
  2166,
  2747,
  2747,
  2747,
  2570,
  2570,
  2142,
  2062,
  2560,
  2050,
  2194,
  2046,
  2166,
  2166,
  2193,
  2229,
  2229,
  2118,
  2218,
  2171,
  2163,
  2221,
  2168,
  2069,
  2154,
  2068,
  2749,
  2170,
  2557,
  2171,
  2178,
  2021,
  2067,
  2067,
  2119,
  2126,
  2162,
  2036,
  2008,
  2170,
  2046,
  2190,
  2105,
  2747,
  2756,
  2107,
  2559,
  2206,
  2093,
  2031,
  2142,
  2570,
  2761,
  2097,
  2097,
  2760,
  2226,
  2137,
  2138,
  2200,
  2221,
  2145,
  2034,
  2756,
  2081,
  2749,
  2090,
  2090,
  2099,
  2230,
  2065,
  2132,
  2133,
  2096,
  2567,
  2032,
  2027,
  2010,
  2008,
  2085,
  2000,
  2761,
  2099,
  2565,
  2114,
  2112,
  2114,
  2770,
  2229,
  2219,
  2767,
  2028,
  2030,
  2047,
  2084,
  2203,
  2117,
  2117,
  2158,
  2558,
  2206,
  2213,
  2071,
  2070,
  2113,
  2766,
  2036,
  2018,
  2122,
  2176,
  2027,
  2174,
  2101,
  2570,
  2011,
  2171,
  2570,
  2770,
  2750,
  2750,
  2136,
  2233,
  2560,
  2042,
  2121,
  2115,
  2759,
  2043,
  2558,
  2015,
  2165,
  2165,
  2165,
  2165,
  2094,
  2046,
  2037,
  2087,
  2086,
  2096,
  2159,
  2198,
  2145,
  2111,
  2037,
  2557,
  2560,
  2761,
  2167,
  2156,
  2745,
  2157,
  2768,
  2072,
  2142,
  2570,
  2232,
  2168,
  2190,
  2745,
  2176,
  2230,
  2065,
  2557,
  2145,
  2753,
  2753,
  2161,
  2161,
  2227,
  2227,
  2045,
  2170,
  2567,
  2150,
  2761,
  2000,
  2233,
  2770,
  2168,
  2111,
  2036,
  2168,
  2753,
  2142,
  2173,
  2140,
  2140,
  2171,
  2077,
  2077,
  2175,
  2171,
  2110,
  2148,
  2111,
  2111,
  2193,
  2220,
  2220,
  2234,
  2565,
  2101,
  2750,
  2226,
  2747,
  2224,
  2232,
  2558,
  2155,
  2155,
  2178,
  2033,
  2156,
  2560,
  2071,
  2087,
  2147,
  2148,
  2032,
  2208,
  2747,
  2570,
  2232,
  2061,
  2217,
  2217,
  2231,
  2089,
  2216,
  2221,
  2036,
  2195,
  2147,
  2066,
  2066,
  2066,
  2163,
  2166,
  2060,
  2040,
  2171,
  2179,
  2770,
  2560,
  2049,
  2138,
  2141,
  2229,
  2040,
  2070,
  2066,
  2036,
  2170,
  2747,
  2232,
  2753,
  2564,
  2066,
  2234,
  2745,
  2210,
  2170,
  2564,
  2565,
  2113,
  2036,
  2095,
  2093,
  2765,
  2148,
  2035,
  2204,
  2765,
  2122,
  2020,
  2036,
  2145,
  2756,
  2060,
  2114,
  2114,
  2234,
  2160,
  2160,
  2068,
  2158,
  2171,
  2168,
  2000,
  2214,
  2061,
  2770,
  2566,
  2566,
  2228,
  2103,
  2217,
  2021,
  2170,
  2223,
  2137,
  2088,
  2567,
  2079,
  2770,
  2080,
  2190,
  2170,
  2178,
  2745,
  2756,
  2567,
  2567,
  2065,
  2101,
  2099,
  2209,
  2765,
  2089,
  2127,
  2106,
  2042,
  2076,
  2093,
  2026,
  2099,
  2121,
  2100,
  2101,
  2151,
  2754,
  2151,
  2113,
  2760,
  2137,
  2060,
  2074,
  2076,
  2068,
  2063,
  2152,
  2066,
  2761,
  2765,
  2117,
  2223,
  2161,
  2146,
  2570,
  2748,
  2100,
  2760,
  2225,
  2021,
  2211,
  2211,
  2035,
  2108,
  2213,
  2768,
  2150,
  2210,
  2210,
  2145,
  2145,
  2120,
  2750,
  2222,
  2049,
  2036,
  2213,
  2172,
  2761,
  2027,
  2036,
  2229,
  2143,
  2011,
  2176,
  2170,
  2148,
  2196,
  2112,
  2073,
  2009,
  2763,
  2022,
  2096,
  2566,
  2217,
  2217,
  2031,
  2016,
  2143,
  2745,
  2212,
  2212,
  2138,
  2753,
  2753,
  2765,
  2066,
  2210,
  2216,
  2046,
  2141,
  2766,
  2760,
  2029,
  2018,
  2142,
  2196,
  2560,
  2069,
  2069,
  2557,
  2155,
  2039,
  2560,
  2011,
  2046,
  2116,
  2112,
  2168,
  2219,
  2172,
  2219,
  2762,
  2092,
  2162,
  2147,
  2770,
  2747,
  2128,
  2567,
  2164,
  2034,
  2142,
  2221,
  2750,
  2074,
  2145,
  2756,
  2570,
  2566,
  2759,
  2560,
  2075,
  2075,
  2176,
  2065,
  2760,
  2044,
  2768,
  2048,
  2135,
  2136,
  2130,
  2010,
  2232,
  2044,
  2000,
  2127,
  2224,
  2224,
  2026,
  2229,
  2117,
  2044,
  2111,
  2084,
  2769,
  2000,
  2120,
  2146,
  2770,
  2074,
  2205,
  2007,
  2566,
  2030,
  2163,
  2765,
  2172,
  2076,
  2077,
  2176,
  2745,
  2046,
  2074,
  2102,
  2170,
  2233,
  2017,
  2030,
  2173,
  2024,
  2060,
  2127,
  2145,
  2747,
  2747,
  2747,
  2171,
  2125,
  2073,
  2114,
  2120,
  2145,
  2164,
  2770,
  2107,
  2097,
  2195,
  2770,
  2068,
  2068,
  2756,
  2756,
  2153,
  2205,
  2065,
  2560,
  2767,
  2164,
  2025,
  2011,
  2230,
  2110,
  2232,
  2233,
  2199,
  2753,
  2233,
  2161,
  2228,
  2017
]


const FulFillmentTypeCard = ({name, description, avaliable, onClick}) => {
  if (avaliable) {
    return <Col onClick={onClick} lg={6} className="md:w-[47%] md:min-w-[47%] min-h-[200px]" style={{cursor: 'pointer'}}>
      <Card outline color="primary" className="border h-full">
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-primary"><i className="mdi mdi-bullseye-arrow mr-3"></i>Avaliable</h5>
        </CardHeader>
        <CardBody>
          <CardTitle className="mt-0">{name}</CardTitle>
          <CardText>{description}</CardText>
        </CardBody>
      </Card>
    </Col>
  }
  return (
    <Col lg={6} className="md:w-[47%] md:min-w-[47%] min-h-[200px]">
      <Card outline variant="destructive" className="border h-full">
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-danger"><i className="mdi mdi-block-helper mr-3"></i>Unavaliable</h5>
        </CardHeader>
        <CardBody>
          <CardTitle className="mt-0">{name}</CardTitle>
          <CardText>{description}</CardText>
        </CardBody>
      </Card>
    </Col>
  )
}


const AvaliableCard = ({courier, price, serviceLevel, estimate, onClick, disabled, surgeCharged}) => {
  var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
  const surge = surgeCharged ? surgeCharged[courier] : undefined
  const formattedSurge = surgeCharged ? formatter.format(surge) : undefined
  
  return (

    <Col className="md:w-[45%]" style={{cursor: 'pointer'}} onClick={disabled ? () => {} :onClick} >
      <Card outline color={disabled ? "danger" : "primary"} className="border">
        <CardHeader className="bg-transparent">
          
          <h5 className="my-0 text-primary"><i className="mdi mdi-bullseye-arrow mr-3"></i>Avaliable</h5>
        </CardHeader>
        <CardBody>
        
          <CardTitle className="mt-0 text-[15px] mb-2">{courier} - ${price} {surge !== undefined ? ` + ${formattedSurge} Surge`: ''}</CardTitle>
          <CardText>
            <p>
              Time estimate: {estimate} <br/>
              {
                serviceLevel && <> Service level: {serviceLevel} </>
              }
            </p>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
}

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const distanceToBadge = (distance) => {
  if (distance < 10000) return 'success'
  return 'danger'
}

const shippingConverter = (pick) => {
  const conversion = {
    'pickup': 'Pickup QV',
    'pickup_hq': 'Pickup HQ',
    'standard': 'Standard',
    
    'next-day': 'Next Day',
    'same-day': 'Same Day',

    'express': 'Express',
    'uber': 'Uber',
    'Uber': 'Uber',
    'overnight': 'Overnight',
  }
  return conversion[pick] || pick
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',

    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const FulfillmentModal = ({selectedItems, lineItems, orderID,  setModalOpen, refresh, shipping_type, locationSelected, postcode, sendFunction, isPers, open}) => {
  const [progress, setProgress] = useState({
    activeTab: 1,
    activeTabProgress: 1,
    progressValue : 25
  })

  const selectedLineItems = selectedItems
  .map((val, key) => val ? lineItems[key] : null)
  .filter(el => el != null);

  const [bookItems, setBookItems] = useState(selectedLineItems.map(val => true))

  const bookLineItems = bookItems
  .map((val, key) => val ? lineItems[key] : null)
  .filter(el => el != null);

  const initParcel = {
    lineItems: [],
    lineItemsFlat: undefined,
    product: undefined,
    productData: undefined,
    parcelSize: undefined,
    parcelSizeData: undefined,
    quantity: 1
  }

  const [parcelData, setParcelData] = useState([initParcel])
  const [returnItems, setReturnItems] = useState([])

  const [data, stateProduct] = useQuery({
    url: `/product`
  });
  const productData = data && data.data

  const [dataParcel, stateParcel] = useQuery({
    url: `/orders/fetchAllParcels`
  });
  const parcels = dataParcel

  useEffect(() => {
    setParcelData(prev => {
      const tempReturnItems = selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
        sku: lineItem.sku,
        quantity: 1,
        id: lineItem.id,
        dupeId: `${lineItem._id}-${i}`,
        selected: undefined
      }))).flat()

      if (!productData || !parcels) {
        return [initParcel]
      }

      let flatItems = tempReturnItems.map(item => {
        const rootSku = item.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
        const filteredProducts = productData.map((product, productId) => {
          const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
          if (filteredVariants.length === 0){
            return undefined
          }
          return {id: productId, variant: filteredVariants }
        }).filter(val => val !== undefined)
        let p, pd
        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id]
          p = selectedProduct && selectedProduct._id
          pd = {label: selectedProduct?.name, value: selectedProduct?._id}    
        }

        let filteredParcelSize = parcels.filter(parcel => `${parcel.product._id}` == `${p}`)
        let parcelSize, parcelSizeData, cubic

        let parcel = filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0]
        parcelSize = parcel._id
        parcelSizeData = {label: `${parcel?.product?.name} - ${parcel?.length}x${parcel?.width}x${parcel?.depth} ${parcel?.weight}kg`, value: parcel._id}
        cubic = parcel?.length * parcel?.width * parcel?.depth


        return ({
          lineItems: [item.dupeId],
          lineItemsFlat: [{label: item.sku, value: item.dupeId}],
          product: p,
          productData: pd,
          parcelSize: parcelSize,
          parcelSizeData: parcelSizeData,
          quantity: 1,
          cubic,
          sku: item.sku
        })
      })

      // sort items smallest to biggest
      let sortedItems = flatItems.sort( (a,b) => a.cubic - b.cubic );

      if (isPers) {
        return sortedItems
      }

      return sortedItems

    
    })
    setReturnItems(selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
      sku: lineItem.sku,
      quantity: 1,
      id: lineItem._id,
      dupeId: `${lineItem._id}-${i}`,
      selected: undefined
    }))).flat())
  }, [productData, parcels])

  useEffect(() => {
    if (returnItems) {
      setReturnItems(prev => {
        return prev.map((returnItem, rID) => {
          let parcelReduced = parcelData.map((parcel, parcelId) => ({id: parcelId, lineItem: parcel.lineItems.find(val => val === returnItem.dupeId)}))
          parcelReduced = parcelReduced.filter(val => val.lineItem !== undefined)
          let updatedReturnItem = {...returnItem}
          if (parcelReduced.length > 0) {
            updatedReturnItem.selected = parcelReduced[0].id
          } else {
            updatedReturnItem.selected = undefined
          }
          return updatedReturnItem
        })
      })
    }
  }, [parcelData])
  
  const addParcel = () => {
    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines.push(initParcel)
      return updatedLines
    })
  }

  const deleteParcel = (id) => {
    setParcelData(prev => {
      return prev.filter((val, parcelId) => parcelId !== id)
    })
  }

  const handleSelectProduct = (selectedProduct, id) => {
    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines[id].parcelSize = selectedProduct && selectedProduct.value
      updatedLines[id].parcelSizeData = selectedProduct
      return updatedLines
    })

  }

  const handleSelectSkus = (selectedSkus, id) => {
    let updatedSelectedSkus
    if (selectedSkus && selectedSkus.filter(val => val.value === 'All').length > 0) {
      let currentSelectedItems = selectedSkus.filter(val => val.value !== 'All')
      let allRemainingItems = returnItems.filter(val => (val.selected === undefined)).map((val, id) => ({ label: val.sku, value: val.dupeId}))
      updatedSelectedSkus = [...currentSelectedItems, ...allRemainingItems]
    } else {
      updatedSelectedSkus = selectedSkus
    }

    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines[id].lineItems = (Array.isArray(updatedSelectedSkus) && updatedSelectedSkus.map(a => a.value)) || []
      updatedLines[id].lineItemsFlat = updatedSelectedSkus

      if ( updatedLines[id].lineItems.length === 1) {
        const selectedLineId = updatedLines[id].lineItems[0]
        const selectedLine = returnItems.find(val => val.dupeId === selectedLineId)
        const rootSku = selectedLine.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
        const filteredProducts = productData.map((product, productId) => {
          const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
          if (filteredVariants.length === 0){
            return undefined
          }
          return {id: productId, variant: filteredVariants }
        }).filter(val => val !== undefined)

        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id]
          updatedLines[id].product = selectedProduct && selectedProduct._id
          updatedLines[id].productData = {label: selectedProduct.name, value: selectedProduct._id}    
        }
      }
      return updatedLines
    })

  };


  const sendLineItems = selectedLineItems.map((value, key) => {
    return {
      ...value,
      fulfill: true,
      book: bookItems[key]
    }
  })

  const [loadingData, setLoadingData] = useState(false)
  const [quoteOptions, setQuoteOptions] = useState(undefined)
  const [shippingLabel, setShippingLabel] = useState(undefined)
  const [fulfillmentType, setFulfillmentType] = useState('shippit')


  const [sendToEWE, setSendToEWE] = useState(true)
  const toggleSendToEWE = () => {setSendToEWE(prev => !prev)}
  const handleEWESendChange = () => {
    setSendToEWE(prev => !prev)
  }

  useEffect(() => {
    if(progress.activeTabProgress === 3) {
      if (fulfillmentType === 'shippit'){
        quoteOrders()
      } else if (fulfillmentType === 'easyship'){
        quoteOrdersEasyship()
      } else if (fulfillmentType === 'deliverInPerson'){
        quoteOrdersDeliverInPerson()
      } else if (fulfillmentType === "eiz") {
        quoteOrdersEIZ();
      }
    }
    if(progress.activeTabProgress === 2) {
      // console.log(bookItems)
    }
  }, [progress])

  useEffect(() => {
    setBookItems(selectedLineItems.map(val => true))
  }, [selectedItems])

  const quoteOrders = () => {
    setLoadingData(true)
    setQuoteOptions(undefined)
    superagent.post(`${backendUrl}/orders/quoteParcels`)
      .send({ 
        ids: bookLineItems.map(val => val._id), 
        orderId: orderID,
        parcelData: parcelData
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoadingData(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setQuoteOptions(response.body)
          setLoadingData(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingData(false)
      });
  }

  const quoteOrdersEIZ = () => {
    setLoadingData(true);
    setQuoteOptions(undefined);
    superagent
      .post(`${backendUrl}/eiz/quoteParcels`)
      .send({
        orderId: orderID,
        parcelData: parcelData,
        locationName: {label: locationSelected},
      })
      .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
      .set("accept", "json")
      .on("error", (response) => {
        console.log(response);
        setLoadingData(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          setQuoteOptions(response.body);
          setLoadingData(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingData(false);
      });
  };

  const bookOrdersEIZ = (courier_allocation, trackingUrl) => {
    setLoadingData(true);
    setQuoteOptions(undefined);
    superagent
      .post(`${backendUrl}/eiz/bookParcels`)
      .send({
        line_items: sendLineItems,
        orderId: orderID,
        courier_allocation: courier_allocation,
        sendToEWE: sendToEWE,
        parcelData: parcelData,
        eweLocation: locationSelected,
        locationName: {label: locationSelected},
        trackingUrl: trackingUrl,
      })
      .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
      .set("accept", "json")
      .on("error", (response) => {
        console.log(response);
        setLoadingData(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response);


          setShippingLabel(response.body.labelUrl);
          setLoadingData(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingData(false);
      });
  };

  const quoteOrdersDeliverInPerson = () => {
    setLoadingData(true)
    setQuoteOptions(undefined)
    superagent.post(`${backendUrl}/orders/quoteParcelsDeliverInPerson`)
      .send({ 
        ids: bookLineItems.map(val => val._id), 
        orderId: orderID,
        parcelData: parcelData
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoadingData(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setQuoteOptions(response.body)
          setLoadingData(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingData(false)
      });
  }

  const quoteOrdersEasyship = () => {
    setLoadingData(true)
    setQuoteOptions(undefined)
    superagent.post(`${backendUrl}/orders/quoteParcelsEasyship`)
      .send({ 
        ids: bookLineItems.map(val => val._id), 
        orderId: orderID,
        parcelData: parcelData
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoadingData(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setQuoteOptions(response.body)
          setLoadingData(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingData(false)
      });
  }

  const bookOrders = (courier_allocation, trackingUrl) => {
    setLoadingData(true)
    setQuoteOptions(undefined)

    if (isPers) {
      superagent.post(`${backendUrl}/orders/createEWEPersonalisationOrder`)
        .send({ 
          line_items: sendLineItems, 
          orderId: orderID,
          courier_allocation: courier_allocation,
          trackingUrl: trackingUrl,
          sendToEWE: sendToEWE,
          eweLocation: 'EWE',
          parcelData: parcelData
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoadingData(false)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            setShippingLabel(response.body.response.qualified_url)
            setLoadingData(false)
          }
        }).catch(err => {
          console.log(err)
          setLoadingData(false)
        });
    } else {
      superagent.post(`${backendUrl}/orders/bookParcels`)
        .send({ 
          line_items: sendLineItems, 
          orderId: orderID,
          courier_allocation: courier_allocation,
          trackingUrl: trackingUrl,
          sendToEWE: sendToEWE,
          eweLocation: locationSelected,
          parcelData: parcelData
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoadingData(false)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            setShippingLabel(response.body.response.qualified_url)
            setLoadingData(false)
          }
        }).catch(err => {
          console.log(err)
          setLoadingData(false)
        });
    }
  }

  const bookOrdersDeliverInPerson = (courier_allocation, trackingUrl) => {
    setLoadingData(true)
    setQuoteOptions(undefined)

    if (isPers) {
      
    } else {
      superagent.post(`${backendUrl}/orders/bookParcelsDIP`)
        .send({ 
          line_items: sendLineItems, 
          orderId: orderID,
          courier_allocation: courier_allocation,
          trackingUrl: trackingUrl,
          sendToEWE: sendToEWE,
          eweLocation: locationSelected,
          parcelData: parcelData
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoadingData(false)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            setShippingLabel(response.body.label_url)
            setLoadingData(false)
          }
        }).catch(err => {
          console.log(err)
          setLoadingData(false)
        });
    }
  }

  const bookOrdersEasyship = (courier_allocation, trackingUrl) => {
    setLoadingData(true)
    setQuoteOptions(undefined)
    superagent.post(`${backendUrl}/orders/bookParcelsEasyship`)
      .send({ 
        line_items: sendLineItems, 
        orderId: orderID,
        courier_allocation: courier_allocation,
        trackingUrl: trackingUrl,
        sendToEWE: sendToEWE,
        eweLocation: 'EWESYD',
        parcelData: parcelData
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoadingData(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setShippingLabel(response.body)
          setLoadingData(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingData(false)
      });
  }

  const bookOrderWefleet = () => {
    setFulfillmentType("manual");
    toggleTabProgress(progress.activeTabProgress + 2);

    setLoadingData(true);
    setQuoteOptions(undefined);
    if (locationSelected === 'EWESYD') {
      superagent
        .post(`${backendUrl}/orders/bookParcelsDeliveryEWESYD`)
        .send({
          line_items: sendLineItems,
          orderId: orderID,
          parcelData: parcelData
        })
        .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
        .set("accept", "json")
        .on("error", (response) => {
          // console.log(response);
          setLoadingData(false);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            setLoadingData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingData(false);
        });
    } else if (locationSelected === 'EWE') {
      superagent
        .post(`${backendUrl}/orders/bookParcelsDeliveryEWE`)
        .send({
          line_items: sendLineItems,
          orderId: orderID,
          parcelData: parcelData
        })
        .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
        .set("accept", "json")
        .on("error", (response) => {
          // console.log(response);
          setLoadingData(false);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            setLoadingData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingData(false);
        });
    }
  }

  const toggleTabProgress = (tab) => {
    if (progress.activeTabProgress !== tab) {
        if(tab >= 1 && tab <=4 ){
          setProgress(prev => ({
            ...prev,
            activeTabProgress: tab
          }));

          if(tab === 1) { setProgress(prev => ({...prev, progressValue : 25})) }
          if(tab === 2) { setProgress(prev => ({...prev, progressValue : 50})) }
          if(tab === 3) { setProgress(prev => ({...prev, progressValue : 75})) }
          if(tab === 4) { setProgress(prev => ({...prev, progressValue : 100})) }
        }
    }
  }

  const buttons = [
    <></>,
    <>
      <li
        className={
          progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"
        }
      >
        {
          returnItems.filter(val => val.selected === undefined).length === 0 && (
            sendFunction === null ? (
              <Button
                to="#"
                onClick={() => {
                  toggleTabProgress(progress.activeTabProgress + 1);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                to="#"
                onClick={() => {
                  sendFunction({
                    line_items: sendLineItems,
                    orderId: orderID,
                    parcelData: parcelData
                  })
                }}
              >
                Send
              </Button>
            )
          )
        }
      </li>
    </>,
    <>
      <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"}><Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress - 1);} }>Back</Button></li>
    </>,
    <>
      <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"}><Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress - 1);} }>Back</Button></li>
    </>,
    <>
      <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"}><Button to="#" onClick={() => {setModalOpen(false); refresh(); } }>Done</Button></li>,
    </>
   
  ]

  return <Dialog open={open} onOpenChange={setModalOpen}>
    <DialogContent className="z-[1600] min-w-[60vw]" >
  <div className="modal-header">
            <h5 className="text-[16px] font-medium">Fulfillment method</h5>
           
          </div>
  <div className="modal-body">
    <div id="progrss-wizard mg-s" className="twitter-bs-wizard">
      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
              <li>
                <a
                  className={classnames( 'nav-link', {
                    active: progress.activeTabProgress === 1,
                  })}
                >
                 <span className="step-number mr-2">01</span>
                Nesting
                </a>
              </li>
        
              <li>
              <a
                className={classnames('nav-link',{
                  active: progress.activeTabProgress === 2,
                })}
              >
                <span className="step-number mr-2">02</span>
                <span>Fulfillment Type</span>
              </a>
            </li>
        
            <li>
              <a
                className={classnames('nav-link',{
                  active: progress.activeTabProgress === 3,
                })}
              >
                <span className="step-number mr-2">03</span>
                Booking
              </a>
            </li>
            <li>
              <a
                className={classnames('nav-link',{
                  active: progress.activeTabProgress === 4,
                })}
              >
                <span className="step-number mr-2">04</span>
                Labels
              </a>
            </li>
      </ul>
      <div id="bar" className="mt-4">
          <Progress  value={progress.progressValue} />
          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
      </div>
      <div style={{fontWeight: 500, textAlign: 'center'}}>SENDING TO {locationSelected.replace('EWESYD', 'EWE Sydney')}</div>
      <Tabs defaultValue={1} value={progress.activeTabProgress} className="twitter-bs-wizard-tab-content">
          <TabsContent value={1}>
            <>
              {
                (stateParcel === 'loading' || stateProduct === 'loading' || parcels === undefined || productData === undefined) ? (
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-5">  PARCEL SIZE </div>
                      <div className="col-md-4"> SKUS </div>
                      <div className="col-md-1" />
                      <div className="col-md-1" />
                    </div>
                    {
                      parcelData.map((parcel, id) => {
                        return (
                          <div className="form-group row">
                            <div className="col-md-5">
                              {
                                productData && (
                                  <Select 
                                    
                                    value={parcel.parcelSizeData} 
                                    onChange={(val => {handleSelectProduct(val, id)})} 
                                    options={parcels.map((val, id) => ({ label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`, value: val._id}))} 
                                  />
                                )
                              }
                            </div>
                            <div className="col-md-4">
                              <Select 
                                
                                value={parcel.lineItemsFlat} 
                                onChange={(val => {handleSelectSkus(val, id)})} 
                                options={[
                                  ...returnItems.filter(val => (val.selected === id || val.selected === undefined)).map((val, id) => ({ label: val.sku, value: val.dupeId})),
                                  ...(returnItems.filter(val => val.selected === undefined).length > 1 ? [{label: 'All', value: 'All'}] : [])
                                ]} 
                                isMulti 
                              />
                            </div>
                            <div className="col-md-1" />
                            <div className="col-md-1">
                              <Button variant="destructive" onClick={() => deleteParcel(id)}> X </Button>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      returnItems.filter(val => val.selected === undefined).length > 0 && (
                        <Button variant="primary" onClick={addParcel}> Add Line + </Button>
                      )
                    }
                  </>
                )
              }
            </>

          </TabsContent>
          <TabsContent value={2}>
            <div>
            
              <Row className="gap-4 flex-wrap" >
                {
                  (locationSelected === 'EWE' || locationSelected === 'EWESYD' || locationSelected === 'EWEBRS') && (
                    <>
                      <FulFillmentTypeCard onClick={() => { toggleTabProgress(progress.activeTabProgress + 1); setFulfillmentType('shippit') } } name="Shippit" description="This pulls from multiple Australian carriers to fulfill order" avaliable={true}/>
                      <FulFillmentTypeCard
                        onClick={() => {
                          toggleTabProgress(progress.activeTabProgress + 1);
                          setFulfillmentType("eiz");
                        }}
                        name="EIZ"
                        description="This pulls from multiple Australian carriers to fulfill order"
                        avaliable={true}
                      />
                      {
                        (locationSelected === 'EWE' ? DIPPostcodes : DIPSydneyPostcodes).includes(parseInt(postcode)) && !isPers && (
                          <FulFillmentTypeCard onClick={() => { toggleTabProgress(progress.activeTabProgress + 1); setFulfillmentType('deliverInPerson') } } name="Deliver In Person" description="This pulls rates for Deliver In Person courier" avaliable={true}/>
                        )
                      }
                      {
                        !isPers && (
                          <FulFillmentTypeCard onClick={() => { toggleTabProgress(progress.activeTabProgress + 1); setFulfillmentType('easyship') } } name="EasyShip" description="This pulls from Auspost and Fedex" avaliable={true}/>
                        )
                      }
                    </>
                  )
                }
              </Row>
            </div>
          </TabsContent>
          <TabsContent value={3}>
            SHIPPING: <b>{shippingConverter(shipping_type)?.toUpperCase()} </b>
            <br/>
            {/* {
              ['easyship', 'shippit'].includes(fulfillmentType) && (
                <>
                  <br/>
                  <div     <div className="page-content">
                    className="custom-control custom-checkbox"
                    onClick={toggleSendToEWE} 
                  >
                    <Input 
                      type="checkbox" 
                      className="custom-control-input" 
                      id={'toggleEWE'} 
                      onChange={handleEWESendChange}
                      checked={sendToEWE} />
                    <Label className="custom-control-label" htmlFor={'toggleEWE'}>Send to EWE</Label>
                  </div>
                  <br/>
                </>
              )
            } */}

            {
              fulfillmentType === 'easyship' ? (
                <div>
                {
                  loadingData && (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
                }
                <Row className="gap-6 flex-wrap">
                {
                  quoteOptions && quoteOptions.rates.map(val => true ? <AvaliableCard 
                    onClick={() => { bookOrdersEasyship(val.courier_id);toggleTabProgress(progress.activeTabProgress + 1);} } 
                    courier={val.courier_name} 
                    estimate={`${val.min_delivery_time !== val.max_delivery_time ? `${val.min_delivery_time}-`: ''}${val.max_delivery_time} days`}
                    price={`${val.total_charge}${val.currency}`}
                    disabled={val.success === false}
                  /> : <></>
                  ) 
                }
                </Row>
              </div>
              ) : fulfillmentType === 'shippit' ? (
                <div>
                  {
                    loadingData && (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary m-1" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }
                  
                  <Row className="gap-6 flex-wrap">
                  {
                    quoteOptions && quoteOptions.response.map(val => val.success ? 
                      <AvaliableCard 
                        onClick={() => { bookOrders(val.courier_type);toggleTabProgress(progress.activeTabProgress + 1);} } 
                        courier={val.courier_type} 
                        serviceLevel={val.service_level}
                        estimate={val.quotes[0]?.estimated_transit_time}
                        price={`${val.quotes[0]?.price}`}
                        disabled={val.success === false}
                        surgeCharged={quoteOptions.surgeCharged}
                      />
                    : 
                      <>
                      </>
                    )
                  }
                  </Row>
                </div>
              ) : fulfillmentType === 'deliverInPerson' ? (
                <Row className="gap-6 flex-wrap">
                  {
                    quoteOptions && quoteOptions.map(val => {
                      const courier_type = val.itemsPrices[0].deliveryType
                      return (
                        <AvaliableCard 
                          onClick={() => { bookOrdersDeliverInPerson(courier_type);toggleTabProgress(progress.activeTabProgress + 1);} } 
                          courier={courier_type} 
                          serviceLevel={courier_type}
                          estimate={''}
                          price={`${val.totalPrice.value}`}
                        />
                      )
                    })
                  }
                </Row>
              ) : fulfillmentType === 'eiz' ? (
                <div>
                  {loadingData && (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  <Row className="gap-6 flex-wrap">
                    {quoteOptions &&
                      quoteOptions.status === 200 ? (
                      <>
                        {
                          quoteOptions.data.data.filter((e) => e.amount !== null).map((delivery, index) => (
                            <AvaliableCard
                              onClick={() => {
                                bookOrdersEIZ(delivery.shippingMethod.id);
                                toggleTabProgress(progress.activeTabProgress + 1);
                              }}
                              courier={delivery.shippingMethod.serviceProvider}
                              serviceLevel={delivery.displayName}
                              estimate={''}
                              price={`${delivery.amount}`}
                              surgeCharged={delivery.amount}
                            />
                          ))
                        }
                      </>
                    ) : (
                      <></>
                    )
                    }
                  </Row>
                </div>
              )  : (
                <>

                </>
              )
            }
          </TabsContent>
          <TabsContent value={4}>
            <div className="row justify-content-center">
              <Col lg="12">
              {
                  loadingData ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="mb-4">
                        <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                      </div>
                    </div>
                  )
                }
              </Col>
            </div>
          </TabsContent>
      </Tabs>
      <ul className="pager wizard twitter-bs-wizard-pager-link">
        {
          loadingData ? (
            <div className="d-flex justify-content-end">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            buttons[progress.activeTabProgress]
          )
        }
      </ul>
    </div>
  </div>
  </DialogContent>
  </Dialog>
}


const BulkSendTo3PL = () => {

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const options = [
    'EWE',
    'EWESYD',
    'EWEBRS',
    'UNIS',
    'Launch',
    'Airbox UK',
  ].map(val => ({label: val, value: val }))

  const optionsCountries = [
    'Australia',
    'New Zealand',
    'International'
  ].map(val => ({label: val, value: val }))

  const optionsType = [
    'Standard',
    'Personalisation',
    'Embroidery'
  ].map(val => ({label: val, value: val }))

  const [selected3PL, setSelected3PL] = useState(options[0])
  const [selectedCountry, setSelectedCountry] = useState(optionsCountries[0])
  const [selectedType, setSelectedType] = useState(optionsType[0])
  const [orderIndex, setOrderIndex] = useState(0)
  const [refresh, setRefresh] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  

  console.log(selected3PL, selectedCountry, selectedType)

  const [modalOpen, setModalOpenVar] = useState(false)
  const [customLabel, setCustomLabel] = useState(false)

  const setModalOpen = (val) => {
    setModalOpenVar(val)
    if (val === false) {
      setCustomLabel(false)
    }
  }

  useEffect(() => {
    if (selectedType.value === 'Embroidery' && selected3PL.value !== 'Airbox UK' && selected3PL.value !== 'Launch' && selected3PL.value !== 'EWE') {
      setSelectedType({value: 'Personalisation', label: 'Personalisation'})
    }
  }, [selected3PL])

  const [filterNoStock, setFilterNoStock] = useState(false)

  const nextOrder = () => {
    setOrderIndex(prev => prev + 1)
  }

  const handle3PLChange = (val) => {
    setSelected3PL(val)
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
  }

  const handleTypeChange = (val) => {
    setSelectedType(val)
  }

  const [allOrdersUnfiltered, stateA] = useQuery({
    url: `/orders/fetch${selected3PL.value.replace(' UK', '')}${(selected3PL.value === 'UNIS' || selected3PL.value === 'EWE' || selected3PL.value === 'Airbox UK' || selected3PL.value === 'Launch') ? (selectedType.value === 'Personalisation' ? 'Pers' : (selectedType.value === 'Embroidery' ? 'Embroidery' : '')) : ''}Orders`,
    refresh,
    queryData: (selected3PL.value === 'EWE' || selected3PL.value === 'EWESYD'|| selected3PL.value === 'EWEBRS') ? {country: selectedCountry.value} : {}
  });

  useEffect(() => {
    setOrderIndex(0)
  }, [allOrdersUnfiltered])

  const getVariant = useProductData()

  const [stockCount, setStockCount] = useState({})
  const [stockCountFull, stockCountState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: selected3PL.value.replace('EWESYD', 'EWE - Sydney').replace('EWEBRS', 'EWE - Brisbane').replace('AIRBOX', 'Airbox UK').replace('Launch', 'LCHSLC')
    }
  });

  useEffect(() => {
    let collatedStockCount = {}
    if (stockCountFull) {
      stockCountFull.map(product => {
        product.variantCounts.map(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand
        })
      })
      setStockCount(collatedStockCount)
    }
  }, [stockCountFull])

  const [selectedItems, setSelectedItems] = useState([])

  const usePers = (selected3PL.value === 'Launch' ||  selected3PL.value === 'UNIS' || selected3PL.value === 'Airbox UK' || (selected3PL.value === 'EWE' && (selectedCountry.value === 'Australia' || selectedCountry.value === 'New Zealand' || selectedCountry.value === 'International'))) && (selectedType.value === 'Personalisation')
  const useEmbroidery = (selected3PL.value === 'Airbox UK' || selected3PL.value === 'Launch' || selected3PL.value === 'EWE') && selectedType.value === 'Embroidery'

  const allOrders = allOrdersUnfiltered && allOrdersUnfiltered.filter(order => {
    const includesSkus = order.line_items.filter(val => val.sku != undefined).length > 0

    const canSendPart = filterNoStock === true ? order.line_items.filter(lineItem => {
      const rootSku = lineItem.sku.split('-').filter(val => val !== 'P').join('-')
      return ((usePers || useEmbroidery) ? (useEmbroidery ? lineItem.monogram && lineItem?.monogram_font?.includes('all') : (selected3PL.value === 'Airbox UK' || selected3PL.value === 'Launch' || selected3PL.value === 'EWE') ? lineItem.monogram && !lineItem?.monogram_font?.includes('all') : lineItem.monogram) : !lineItem.monogram) && stockCount[rootSku] >= lineItem.quantity
    }).length > 0 : true

    return includesSkus && canSendPart
  })

  const order = allOrders ? allOrders[orderIndex] : {}

  useEffect(() => {
    if (stockCount && Object.keys(stockCount).length > 0 && order != {} && order !== undefined && order.line_items && order.line_items.length > 0){
      if (usePers || useEmbroidery) {
        setSelectedItems(prev => {
          const lineItemsAvaliable = order.line_items.map(lineItem => {
            const rootSku = lineItem.sku.split('-').filter(val => val !== 'P').join('-')

            return ((usePers || useEmbroidery) ? (useEmbroidery ? lineItem.monogram && lineItem?.monogram_font?.includes('all') : (selected3PL.value === 'Airbox UK' || selected3PL.value === 'Launch' || selected3PL.value === 'EWE') ? lineItem.monogram && !lineItem?.monogram_font?.includes('all') : lineItem.monogram) : !lineItem.monogram) && stockCount[rootSku] >= lineItem.quantity
          })
          return lineItemsAvaliable
        })

      } else {
        setSelectedItems(prev => {
          const lineItemsAvaliable = order.line_items.map(lineItem => {
            const rootSku = lineItem.sku
            return stockCount[rootSku] >= lineItem.quantity
          })
          return lineItemsAvaliable
        })
  
      }
    }
  }, [orderIndex, stockCount, usePers, useEmbroidery, filterNoStock])


  const handleDomesticEWEUrlBook = (sendData) => {
    setModalOpen(false)
    if (selectedType.value === 'Personalisation') {
      superagent.post(`${backendUrl}/orders/book_order_ewe_personalistion`)
        .send(sendData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else if (selectedType.value === 'Embroidery') {
      superagent.post(`${backendUrl}/orders/book_order_ewe_embroidery`)
        .send(sendData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.post(`${backendUrl}/orders/bookParcelsDomesticEWE`)
        .send(sendData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    }
    nextOrder()
  }

  const handleDomesticEWESydUrlBook = (sendData) => {
    setModalOpen(false)
    superagent.post(`${backendUrl}/orders/bookParcelsDomesticEWESydney`)
      .send(sendData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
        }
      }).catch(err => {
        console.log(err)
      });
      nextOrder()
  }

  const handleDomesticEWEBrsUrlBook = (sendData) => {
    setModalOpen(false)
    superagent.post(`${backendUrl}/orders/bookParcelsDomesticEWEBrisbane`)
      .send(sendData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
        }
      }).catch(err => {
        console.log(err)
      });
      nextOrder()
  }

  const handleDomesticUNISUrlBook = (sendLineItems) => {
    if (selectedType.value === 'Personalisation') {
      superagent.post(`${backendUrl}/orders/bookParcelsPersonalisationUNIS`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.post(`${backendUrl}/orders/bookParcelsDomesticUNIS`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    }
    nextOrder()
  }

  const handleDomesticLaunchUrlBook = (sendLineItems) => {
    if (selectedType.value === 'Personalisation') {
      superagent.post(`${backendUrl}/orders/bookParcelsPersonalisationLaunch`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else if (selectedType.value === 'Embroidery') {
      superagent.post(`${backendUrl}/orders/bookParcelsEmbroideryLaunch`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            nextOrder()
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.post(`${backendUrl}/orders/bookParcelsDomesticLaunch`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    }
    nextOrder()
  }


  const handleDomesticAirboxUrlBook = (sendLineItems) => {
    if (selectedType.value === 'Personalisation') {
      superagent.post(`${backendUrl}/orders/bookParcelsPersonalisationAirbox`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else if (selectedType.value === 'Embroidery') {
      superagent.post(`${backendUrl}/orders/bookParcelsEmbroideryAirbox`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.post(`${backendUrl}/orders/bookParcelsDomesticAirbox`)
        .send({ 
          line_items: sendLineItems, 
          orderId: order._id
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
          }
        }).catch(err => {
          console.log(err)
        });
    }
    nextOrder()
  }

  const handleSendTo3PL = () => {
    if (selectedItems.filter(val => val).length > 0){
      const selectedLineItems = selectedItems
        .map((val, key) => val ? order.line_items[key] : null)
        .filter(el => el != null);

      const bookItems = selectedLineItems.map(val => true)
      const sendLineItems = selectedLineItems.map((value, key) => {
        return {
          ...value,
          fulfill: true,
          book: bookItems[key]
        }
      })

      if (selected3PL.value === 'EWE') {
        setModalOpen(true)
      } else if(selected3PL.value === 'UNIS'){
        handleDomesticUNISUrlBook(sendLineItems)
      } else if(selected3PL.value === 'Airbox UK'){
        handleDomesticAirboxUrlBook(sendLineItems)
      } else if(selected3PL.value === 'Launch'){
        handleDomesticLaunchUrlBook(sendLineItems)
      } else {
        setModalOpen(true)
      }
    }
  }

  let sendFunction = null
  if (selected3PL.value === 'EWE') {
    sendFunction = handleDomesticEWEUrlBook
  } else if(selected3PL.value === 'EWESYD'){
    sendFunction = handleDomesticEWESydUrlBook
  } else if(selected3PL.value === 'EWEBRS'){
    sendFunction = handleDomesticEWEBrsUrlBook
  }


  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Orders" breadcrumbItem="Bulk Send To 3PL" />
          <Row>
            <Col xs="12">
              <Card>
                {
                  stateA === 'loading' ? (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                      <div className="spinner-grow text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <CardHeader>
                        <div className="select2-container mb-4">
                          <Select  value={selected3PL} onChange={handle3PLChange} title="3PL" options={options} />
                        </div>
                        <div className="select2-container mb-4">
                          {
                            (selected3PL.value === 'EWE' || selected3PL.value === 'EWESYD'|| selected3PL.value === 'EWEBRS') && (
                              <Select  value={selectedCountry} onChange={handleCountryChange} title="Country" options={optionsCountries} />
                            )
                          }

                          {
                            ( selected3PL.value === 'Launch' || selected3PL.value === 'UNIS' || selected3PL.value === 'Airbox UK' || (selected3PL.value === 'EWE' && (selectedCountry.value === 'Australia' || selectedCountry.value === 'New Zealand' || selectedCountry.value === 'International'))) && (
                              <Select  value={selectedType} onChange={handleTypeChange} title="Country" options={optionsType.filter(val => (selected3PL.value !== 'Airbox UK' && selected3PL.value !== 'Launch' && selected3PL.value !== 'EWE') ? val.value !== 'Embroidery' : true)} />
                            )
                          }
                        </div>
                        <div className="">
                          <label className="mt-4" htmlFor={'filter'}>Filter only avaliable:{' '}</label>
                          <input className="" type="checkbox" onChange={() => setFilterNoStock(prev => !prev)} checked={filterNoStock} id="filter" name="filter"/>
                        </div>
                        
                        <div className="mt-3">
                          {
                            order !== undefined && (
                              <>
                                {
                                  loadingData || stockCountState === 'loading' ? (
                                    <div className="spinner-grow text-primary m-1" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    <>
                                      {/* <Button disabled={orderIndex === 0} onClick={prevOrder} color="warning"  className="mr-2"> Back </Button> */}
                                      <Button onClick={nextOrder} variant="warning"  className="mr-2"> Skip </Button>
                                      {
                                        (selected3PL.value !== 'EWE' || order.order_source !== 'shopify_us') && <Button onClick={handleSendTo3PL} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Send </Button>
                                      }
                                      
                                      {
                                        (selected3PL.value === 'EWE' || selected3PL.value === 'EWESYD'|| selected3PL.value === 'EWEBRS') && selectedType.value === 'Standard' && (
                                          <Button onClick={() => { setModalOpen(true); setCustomLabel(true) }} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Custom Label </Button>
                                        )
                                      }
                                    </>
                                  )
                                }
                              </>
                            ) 
                          }
                        </div>
                      </CardHeader>
                      <CardBody>
                        {
                          order === undefined ? (
                            <>
                              No orders left to send.
                            </>
                          ) : (
                            <>
                              <Row className="flex-wrap md:flex-nowrap gap-6">
                                <Col  className='sm:w-8/12'>
                                  <Row>
                                    <Col>
                                      <div className="mb-4">
                                        <Link to={`/orders/${order?._id}`}> <h3 className="text-[23px]"> {order?.name} </h3> </Link>
                                        
                                        <h6 className="mb-4 font-medium">{(new Date(order.orderCreated).toString())}</h6>
                                        <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-" + order.badgeclass} color={order.badgeClass} pill>{shippingConverter(order?.shipping_type)?.toUpperCase()}</Badge>
                                        {
                                          order.flagged && (
                                            <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-danger"} pill>
                                              FLAGGED
                                            </Badge>
                                          )
                                        }
                                        <Badge className={"ml-2 px-4 py-2 font-size-12 badge-soft-" + pickToBadge(order.pick_status)} color={pickToBadge(order.pick_status)} pill>
                                          {pickToName(order.pick_status)}
                                        </Badge>
                                        {
                                          order.distanceFromHQ != null && (
                                            <Badge className={"ml-2  px-4 py-2 font-size-12 badge-soft-" + distanceToBadge(order.distanceFromHQ)} color={distanceToBadge(order.distanceFromHQ)} pill>
                                              {order.distanceFromHQ/1000} KM
                                            </Badge>
                                          )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                    {
                                      order?.line_items && order.line_items.map((lineItem, key) => {
                                        const isSelected = selectedItems[key]
                                        const handleSelected = () => {
                                          setSelectedItems(prev => {
                                            let newItems = [...prev]
                                            newItems[key] = !newItems[key]
                                            return newItems
                                          })
                                        }
                                        return (
                                          <Row>
                                            <Col sm="1" className='sm:w-1/12'>
                                              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                  lineItem.fulfillment_status !== 'deleted' && (
                                                    <input className="form-check-input" type="checkbox" onChange={handleSelected} checked={isSelected} id="defaultCheck1" />
                                                  )
                                                }
                                              </div>
                                            </Col>
                                            <Col sm="2" className='sm:w-2/12'>
                                              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                              </div>
                                            </Col>
                                            <Col sm="6" className="p-3 sm:w-6/12">
                                              <h6>{getVariant(lineItem.sku)?.name}</h6>
                                              <div>
                                                <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem.fulfillment_status)} color={fulfillmentToBadge(lineItem.fulfillment_status)} pill>
                                                  {lineItem.fulfillment_status ? lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                                </Badge>
                                              </div>
                                              <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                                            </Col>
                                            
                                            <Col sm="3" className="p-3 sm:w-3/12">
                                              <div> avaliable: {`${stockCount[(usePers || useEmbroidery) ? lineItem.sku.split('-').filter(val => val !== 'P').join('-') : lineItem.sku] || 0}`} </div>
                                              <div> quantity: {lineItem.quantity} </div>
                                            </Col>
                                          </Row>
                                        )
                                      })
                                    }
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs="4" className='sm:w-4/12'>
                                  {
                                    order?.name !== undefined && (
                                      <CommentHostory title="Notes" initCommentType={'Order'} initOrderName={order?.name} />
                                    )
                                  }
                                  {
                                    order != null && order?.shipping_address != null && order?.customer != null && (
                                      <div className="mt-6">
                                        <h6 style={{marginBottom: 16}} className="font-medium"> {`${order?.customer[0].first_name} ${order?.customer[0].last_name}`} </h6>
                                        <div> <span style={{opacity: 0.5}}>EMAIL:</span> {order?.customer[0].email} </div>
                                        <div> {order?.shipping_address[0].name} </div>
                                        <div> {order?.shipping_address[0].address1} </div>
                                        <div> {order?.shipping_address[0].address2 || ''} </div>
                                        <div> {`${order?.shipping_address[0].city || ''} ${order?.shipping_address[0].province || ''} ${order?.shipping_address[0].zip || ''}`} </div>
                                        <div> {order?.shipping_address[0].country || ''} </div>
                                        <div> {order?.shipping_address[0].phone || ''} </div>
                                      </div>
                                    )
                                  }
                                </Col>
                              </Row>  
                            </>
                          )
                        }
                      </CardBody>
                      {
                        order !== undefined && (
                          <CardFooter>
                            {
                              loadingData || stockCountState === 'loading' ? (
                                <div className="spinner-grow text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <>
                                  {/* <Button disabled={orderIndex === 0} onClick={prevOrder} color="warning"  className="mr-2"> Back </Button> */}
                                  <Button onClick={nextOrder} variant="warning"  className="mr-2"> Skip </Button>
                                  {
                                    (selected3PL.value !== 'EWE' || order.order_source !== 'shopify_us') && <Button onClick={handleSendTo3PL} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Send </Button>
                                  }
                                  
                                  {
                                    (selected3PL.value === 'EWE' || selected3PL.value === 'EWESYD'|| selected3PL.value === 'EWEBRS') && selectedType.value === 'Standard' && (
                                      <Button onClick={() => { setModalOpen(true); setCustomLabel(true) }} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Custom Label </Button>
                                    )
                                  }
                                </>
                              )
                            }
                          </CardFooter>
                        ) 
                      }

                    </>
                  )
                }
              </Card>
            </Col>
          </Row>
        </div>
       
  
          {
            order && order.line_items && modalOpen && (
              <div key={order._id}>
                <FulfillmentModal 
                  open={modalOpen}
                  sendFunction={customLabel ? null : sendFunction}
                  postcode={parseInt(order.shipping_address[0].zip)}
                  locationSelected={selected3PL.value}
                  orderName={order.name} 
                  refresh={nextOrder}
                  lineItems={order.line_items.filter(val => (val.fulfillment_status === 'unfulfilled') || (val.fulfillment_status === 'awaiting-stock' && val.monogram === false) || (val.fulfillment_status === 'to-be-monogrammed' && val.monogram === true)).filter(val => val.sku !== undefined)}
                  orderID={order._id} 
                  shipping_type={order.shipping_type} 
                  order_source={order.order_source} 
                  selectedItems={selectedItems} 
                  setModalOpen={setModalOpen} 
                  isPers={selectedType.value === 'Personalisation'}
                />
              </div>  
            )
          }
          
      
      </div>
    </>
  )
}

export default BulkSendTo3PL
