import React, { Component } from 'react';

import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
  } from '@shadcn/components/ui/dropdown-menu';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';


class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            username: ""
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    onClickProfile = () => {
        // console.log('Hiiiiiii')
        this.props.history.push('/profile');
        // console.log("this.state.adminDate", this.state.adminDetailsData);
        // this.props.history.push({
        //     pathname: RouteRules.adminProfile,
        //     state: {
        //         activeAdmindata: this.state.adminDetailsData
        //     }
        // })
    };

    render() {

        return (
            <React.Fragment>
               

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <button className="header-item btn waves-effect d-inline-flex align-items-center">
                        <img
                            className="rounded-circle header-profile-user"
                            style={{ objectFit: 'cover', width: 48, height: 48 }}
                            src={this.props?.user?.profile_image?.replace('.com//', '.com/')}
                            alt="Header Avatar"
                        />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.username}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => this.onClickProfile()}>
                        <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                        {this.props.t('Profile')}
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem asChild>
                        <a href="/logout" className="text-danger d-flex align-items-center">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1"></i>
                            <span>{this.props.t('Logout')}</span>
                        </a>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                    </DropdownMenu>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(ProfileMenu));
