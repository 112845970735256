import React from "react";

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
const Footer = () => {
    return (
        <React.Fragment>
            <footer className="bg-black text-white absolute bottom-0 left-0 right-0 p-2">
            <div className="w-full px-3 mx-auto xl:max-w-[85%]">
                    <Row>
                        <Col className="w-full" >
                            <div className="text-sm-right d-none d-sm-block">
                                JIFI - July Inventory and Fulfillment Interface. | {new Date().getFullYear()} © July.
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
