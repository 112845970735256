import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Row }  from '@shadcn/components/ui/row'
import { Col } from "@shadcn/components/ui/col";
import { Badge } from "@shadcn/components/ui/badge";
import { Button } from "@shadcn/components/ui/button";
import { Input } from "@shadcn/components/ui/input";
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card';

import { backendUrl } from '../../../helpers/consts.js';
import '../../Skote/Dashboard/skelton.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useQuery from '../../../helpers/JIFI/useQuery';
import "../datatables.scss";
import superagent from 'superagent';
import { Dialog, DialogContent } from "@shadcn/components/ui/dialog.jsx";

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}


const OrdersPage = ({ url = '/eiz' }) => {
  const [exportOpen, setExportModal] = useState();
  const toggleExportOpen = () => setExportModal(prev => !prev)
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({ refresh, url, queryData: {  } });
  const orders = data && data.data;

  console.log(orders)

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orders ? setSelected(orders.map(() => true)) : setSelected([])
  }, [orders])

  const selectedIds = orders ? orders.filter((val, key) => selected[key] === true) : []
  const orderIds = selectedIds?.map((order) => order._id)

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Manifest EIZ Shipments" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="p-5">
                  {
                    selectedIds.length > 0 && (
                      <Row className="mb-4 pt-6">
                        <Col sm="1">
                          <Button onClick={toggleExportOpen}> Manifest Selected </Button>
                        </Col>
                      </Row>
                    )
                  }
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <Input onChange={e => (orders ? setSelected(orders.map(() => e.target.checked)) : setSelected([]))} type="checkbox" className="custom-control-input" id="customCheck1" />
                              <label className="custom-control-label" htmlFor="customCheck1">&nbsp;</label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Pick Status</th>
                          <th>Fulfillment</th>
                          <th>Source</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Billing Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th>Total</th>
                          <th>Payment</th>
                          <th>Flag Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' ? (
                            orders && orders.map((order, key) => {
                              let skuCount = {}
                              order.line_items.map(val => {
                                if (skuCount[val.sku] === undefined) {
                                  skuCount[val.sku] = val.quantity
                                } else {
                                  skuCount[val.sku] += val.quantity
                                }
                              })

                              const lineSum = Object.keys(skuCount).map(sku => {
                                return {
                                  sku: sku,
                                  quantity: skuCount[sku]
                                }
                              })

                              return lineSum.map((line, keyLine) => keyLine === 0 ? (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + `${keyLine}` + key}>
                                  <td>
                                    <div
                                      className="custom-control custom-checkbox"
                                      onClick={() => setSelected(prev => {
                                        let newSelected = [...prev]
                                        newSelected[key] = !newSelected[key]
                                        return newSelected
                                      })}
                                    >
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={order.id}
                                        checked={selected[key]} />
                                      <label className="custom-control-label" htmlFor={order.id}>&nbsp;</label>
                                    </div>
                                  </td>
                                  <td><Link to={`/orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + pickToBadge(order.pick_status)} color={pickToBadge(order.pick_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {pickToName(order.pick_status)}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(order.fulfillment_status)} color={fulfillmentToBadge(order.fulfillment_status)} pill>
                                      <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {order.fulfillment_status ? order?.fulfillment_status?.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                    </Badge>
                                  </td>
                                  <td>{order?.order_source}</td>
                                  <td>
                                    {order?.shipping_type}
                                  </td>
                                  <td>
                                    {order?.shipping_address?.province}, {order?.shipping_address?.country}
                                  </td>
                                  <td>{order?.shipping_address?.name}</td>
                                  <td>
                                    {(new Date(order.orderCreated)).toDateString()}
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      {line.sku}
                                    </Badge> x{line.quantity}
                                  </td>
                                  <td>
                                    ${order.total_price}
                                  </td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-" + order.badgeclass} color={order.badgeClass} pill>{order?.financial_status?.toUpperCase()}</Badge>
                                  </td>
                                  <td>{order.flagtype?.name}</td>
                                </tr>
                              ) : (
                                <tr style={order.flagged ? { background: "#fde1e1" } : {}} key={"_order_" + `${keyLine}` + key}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      {line.sku}
                                    </Badge> x{line.quantity}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )).flat()
                            })
                          ) : (
                            true && (
                              [...Array(10)].map((_, i) => (
                                <tr className="py-8" style={{ padding: '16px 0' }} key={"_order_" + i}>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                </tr>

                              ))
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div >
      <Dialog open={exportOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" onOpenChange={toggleExportOpen}>
        <ExportModal toggleModal={toggleExportOpen} orderIds={orderIds} />
      </Dialog>
    </>
  );
}

const ExportModal = ({ orderIds }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [eizData, setEizData] = useState(undefined);
  const [shippingLabel, setShippingLabel] = useState(undefined);

  const handleSubmitConsignments = () => {
    setLoadingData(true)
    setEizData(undefined)
    superagent.post(`${backendUrl}/eiz/submitOrdersTobeManifested`)
      .send({
        orderIds
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoadingData(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setEizData(response.body);
          setShippingLabel(response.body[0].pdf);
          setLoadingData(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingData(false)
      });
  }

  return (
    <DialogContent>
    <div className="row justify-content-center">
      <Col lg="12">
        <div className="text-center">
          <div style={{ padding: 40 }}>
            {
              loadingData ? (
                <>
                  <h5>Manifest Orders</h5>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {shippingLabel ? (
                    <>
                      <div className="mb-4">
                        <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                      </div>
                      <h5>Download Labels</h5>
                      <a href={shippingLabel} target="_blank"
                        download={`consignment_id_${eizData[0].id}.pdf`}
                        title="Download pdf document" rel="noreferrer"
                      >
                        {" "}
                        <p className="text-slateGray">
                          {" "}
                          Labels download
                        </p>{" "}
                      </a>
                    </>
                  ) : (
                    <>
                      <h5>Manifest Orders</h5>
                      <Button onClick={handleSubmitConsignments} style={{ marginTop: 20 }}>Submit</Button>
                    </>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </Col>
    </div>
    </DialogContent>
  )
}

export default OrdersPage;
