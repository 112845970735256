// InputField.js
import { Input } from '@shadcn/components/ui/input';
import React from 'react';
import { Controller } from 'react-hook-form';

const InputField = ({ label, name, control, defaultValue, error, type = 'text' }) => (
  <div className="select2-container mb-4">
    <label className="control-label">{label}</label>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          {...field}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          type={type}
        />
      )}
    />
    {error && <div className="invalid-feedback">{error?.message}</div>}
  </div>
);

export default InputField;
