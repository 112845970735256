import React, { Component, Fragment, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { uploadFileS3Bucket } from '../../../utilities/uploadFileS3Bucket';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';

const UserProfile = ({ props }) => {
  const history = useHistory();
  const initialState = {
    userName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
    profileImage: null,
    isProfileImageUploaded: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let userData = localStorage.getItem('authUser');
    if (userData) {
      userData = JSON.parse(userData);
      let userId = userData._id;
      getUserProfile();
    }
  }, []);

  const getUserProfile = () => {
    superagent
      .get(`${backendUrl}/users/profile/`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          const { userName, email, profile_image } = response.body.data;
          setState((prevState) => {
            return {
              ...prevState,
              userName: userName,
              email: email,
              profileImage: profile_image
                ? profile_image.split('/').pop()
                : null,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (name, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;
    setState((prevState) => {
      return {
        ...prevState,
        profileImage: files[0],
        isProfileImageUploaded: true,
      };
    });
  };

  const handleUpdateUserProfile = async (e) => {
    e.preventDefault();
    const {
      userName,
      email,
      newPassword,
      profileImage,
      isProfileImageUploaded,
    } = state;

    let updateFields = {
      profile_image: profileImage,
      userName: userName,
      email: email,
    };

    if (email === '') {
      toast.error('Email address cannot be empty');
      return null;
    }

    if (newPassword !== '') {
      if (newPassword.length < 6) {
        toast.error('New Password Must Have Atleast 6-15 Characters');
        return null;
      }

      if (confirmPassword === '' || confirmPassword.length < 6) {
        toast.error(
          'Confirm Password Must Have Atleast 6-15 Characters and Not Empty'
        );
        return null;
      }

      if (newPassword !== confirmPassword) {
        toast.error('Please add same New Password and confirm Password');
        return null;
      }
      updateFields = { ...updateFields, password: newPassword };
    }

    let [userProfilePhoto] = await Promise.all([
      isProfileImageUploaded
        ? uploadFileS3Bucket(profileImage, `profile_photo_${Date.now()}`)
        : profileImage,
    ]);
    updateFields.profile_image = userProfilePhoto;
    superagent
      .put(`${backendUrl}/users/updateProfile/`)
      .send(updateFields)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          const oldUser = JSON.parse(localStorage.getItem('authUser'));
          localStorage.setItem(
            'authUser',
            JSON.stringify({
              ...oldUser,
              ...response.data,
            })
          );
          toast.success('Profile updated successfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toast.configure({ autoClose: 8000, hideProgressBar: true });
  const { email, userName, newPassword, confirmPassword, profileImage } = state;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="w-full px-3 mx-auto sm:max-w-[85%]">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Setting" breadcrumbItem="User Profile" />
          <Row>
            <Col className="w-full">
              <Card className="pt-6">
                <CardBody>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <div className="mb-4 flex flex-col sm:flex-row">
                          <label
                            htmlFor="taskname"
                            className="col-form-label w-full sm:w-2/12"
                          >
                            Profile Picture
                          </label>
                          <div className="w-full sm:w-10/12">
                            <Input
                              accept="image/*"
                              className="form-control"
                              type="file"
                              id="exampleCustomFileBrowser"
                              name="customFile"
                              label={profileImage || 'Choose an image file'}
                              onChange={(e) => handleFileChange(e)}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex flex-col sm:flex-row">
                          <label
                            htmlFor="userName"
                            className="col-form-label w-full sm:w-2/12"
                          >
                            User Name
                          </label>
                          <div className="w-full sm:w-10/12">
                            <input
                              name="userName"
                              type="text"
                              className="form-control border-black"
                              placeholder="Enter User Name..."
                              value={userName}
                              
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex flex-col sm:flex-row">
                          <label
                            htmlFor="email"
                            className="col-form-label w-full sm:w-2/12"
                          >
                            Email
                          </label>
                          <div className="w-full sm:w-10/12">
                            <input
                              name="email"
                              type="email"
                              className="form-control border-black"
                              placeholder="Enter User Email..."
                              value={email}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex flex-col sm:flex-row">
                          <label
                            htmlFor="newPassword"
                            className="col-form-label w-full sm:w-2/12"
                          >
                            New Password
                          </label>
                          <div className="w-full sm:w-10/12">
                            <input
                              name="newPassword"
                              type="password"
                              placeholder="*************"
                              className="form-control border-black"
                              value={newPassword}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex flex-col sm:flex-row">
                          <label
                            htmlFor="confirmPassword"
                            className="col-form-label w-full sm:w-2/12"
                          >
                            Confirm Password
                          </label>
                          <div className="w-full sm:w-10/12">
                            <input
                              name="confirmPassword"
                              type="password"
                              placeholder="*************"
                             className="form-control border-black"
                              value={confirmPassword}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                    <Col className="sm:w-10/12">
                      <Button
                        onClick={(e) => handleUpdateUserProfile(e)}
                        type="submit"
                        variant="primary"
                      >
                        Update Profile
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
