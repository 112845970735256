import { useState } from 'react';
import { Button } from '@shadcn/components/ui/button';


// Thêm import Dialog components
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@shadcn/components/ui/dialog';
import  RetailOrderNotesForm from './feedback';
import { ToastContainer } from 'react-toastify';




export default function FeedBackForm({orderName}) {
  const [open, setOpen] = useState(false);
  return (
   
      <>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <p className='px-2 py-[6px] font-[14px]'>
            Create Order Feedback
            </p>
          </DialogTrigger>
          <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
            <DialogHeader>
              <DialogTitle>Retail Order Notes - Ops & CX</DialogTitle>
            </DialogHeader>
          <RetailOrderNotesForm
          orderName={orderName}
          onModalChange={() => setOpen(false)}
          isForm={true}></RetailOrderNotesForm>
          </DialogContent>
        </Dialog>
        <ToastContainer />
      </>
    
  );
}
