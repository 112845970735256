import React from "react";
import { useForm } from "react-hook-form";
import { Input } from '@shadcn/components/ui/input';
import { Button } from '@shadcn/components/ui/button';

const ParcelForm = ({ initialData, onSubmit }) => {
  const defaultValues = {
    parcel_description: '',
    declared_price: '',
    weight: '',
    length: '',
    width: '', 
    depth: ''
  };

  const { register, handleSubmit, formState: { errors }, reset: resetForm } = useForm({
    defaultValues: initialData || defaultValues
  });

  React.useEffect(() => {
    if (initialData) {
      resetForm(initialData);
    } else {
      resetForm(defaultValues);
    }
  }, [initialData, resetForm]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="bg-white rounded-lg p-6 space-y-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="parcel_description" className="block text-sm font-medium text-gray-700">
              Parcel Description
            </label>
            <Input
              defaultValue={initialData?.parcel_description}
              {...register("parcel_description", { required: "Please enter description" })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter parcel description..."
            />
            {errors.parcel_description && (
              <p className="mt-1 text-sm text-red-600">{errors.parcel_description.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="declared_price" className="block text-sm font-medium text-gray-700">
              Declared Value
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <Input
                type="number"
                defaultValue={initialData?.declared_price}
                {...register("declared_price", {
                  required: "Please enter declared value",
                  min: { value: 0, message: "Value must be greater than 0" }
                })}
                className="pl-7 block w-full rounded-md border-gray-300"
                placeholder="0.00"
              />
            </div>
            {errors.declared_price && (
              <p className="mt-1 text-sm text-red-600">{errors.declared_price.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
              Weight (kg)
            </label>
            <Input
              type="number"
              defaultValue={initialData?.weight}
              {...register("weight", {
                required: "Please enter weight",
                min: { value: 0, message: "Weight must be greater than 0" }
              })}
              className="mt-1 block w-full rounded-md border-gray-300"
              placeholder="0.00"
            />
            {errors.weight && (
              <p className="mt-1 text-sm text-red-600">{errors.weight.message}</p>
            )}
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-4">Dimensions</h4>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label htmlFor="length" className="block text-sm font-medium text-gray-700">
                Length (cm)
              </label>
              <Input
                type="number"
                defaultValue={initialData?.length}
                {...register("length", {
                  required: "Please enter length",
                  min: { value: 0, message: "Length must be greater than 0" }
                })}
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder="0"
              />
              {errors.length && (
                <p className="mt-1 text-sm text-red-600">{errors.length.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="width" className="block text-sm font-medium text-gray-700">
                Width (cm)
              </label>
              <Input
                type="number" 
                defaultValue={initialData?.width}
                {...register("width", {
                  required: "Please enter width",
                  min: { value: 0, message: "Width must be greater than 0" }
                })}
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder="0"
              />
              {errors.width && (
                <p className="mt-1 text-sm text-red-600">{errors.width.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="depth" className="block text-sm font-medium text-gray-700">
                Height (cm)
              </label>
              <Input
                type="number"
                defaultValue={initialData?.depth}
                {...register("depth", {
                  required: "Please enter height",
                  min: { value: 0, message: "Height must be greater than 0" }
                })}
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder="0"
              />
              {errors.depth && (
                <p className="mt-1 text-sm text-red-600">{errors.depth.message}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <Button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save Information
        </Button>
      </div>
    </form>
  );
};

export default ParcelForm;
