import { useMemo, useState } from 'react';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Row } from '@shadcn/components/ui/row';

import { Col } from '@shadcn/components/ui/col';
// Editable
import { Link } from 'react-router-dom';
import Select from '@shadcn/components/ui/react-select';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import superagent from 'superagent';
import { TablePagination } from '../../../components/supply-chain/TablePagination';
//Import Breadcrumb

import useQuery from '../../../helpers/JIFI/useQuery';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import { backendUrl } from '../../../helpers/consts.js';
import { StatusBadge } from '../../../components/supply-chain/StatusBadge';
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';

import {
  Table as TableShadcn,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
} from '@shadcn/components/ui/table';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'disapproved', label: 'Disapproved' },
];

const columnHelper = createColumnHelper();
const PurchaseOrderList = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const dateString = new Date()
    .toDateString()
    .replace(' ', '-')
    .replace(' ', '-')
    .replace(' ', '-');
  const [purchaseOrders, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh,
    queryData: {
      page,
      perPage,
      status,
      searchTerm,
    },
  });

  const refreshData = () => setRefresh((prev) => !prev);

  const handleUpdatePOStatus = (status, id) => {
    superagent
      .post(`${backendUrl}/purchase-order/update-status/${id}`)
      .send({ status })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const purchaseOrderData = purchaseOrders?.data;
  const count = purchaseOrders?.totalCount;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('reference', {
        header: () => 'Reference',
        cell: ({ row }) => (
          <Link
            className="text-body font-weight-bold px-2 cursor-pointer"
            to={`/purchase-order/${row.original._id}`}
          >
            {row.original.reference}
          </Link>
        ),
      }),
      columnHelper.accessor('currency', {
        header: () => <p className="text-center">Currency</p>,
        cell: ({ row }) => (
          <div className="text-center">{row.original.currency}</div>
        ),
      }),

      columnHelper.accessor('expectedDeliveryDate', {
        header: () => (
          <div className="text-center"> Expected Delivery Date </div>
        ),
        cell: ({ row }) => (
          <div className="text-center">
            {row.original.expectedReleaseDate
              ? new Date(row.original.expectedReleaseDate).toDateString()
              : ''}
          </div>
        ),
      }),

      columnHelper.accessor('completedReleasedDate', {
        header: () => 'Actual Release Date',
        cell: ({ row }) => (
          <div className="text-center">
            {row.original.completedReleasedDate
              ? new Date(row.original.completedReleasedDate).toDateString()
              : ''}
          </div>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: ({ row }) => (
          <StatusBadge status={row.original.status || 'pending'} />
        ),
      }),

      columnHelper.accessor('action', {
        header: () => <div className="text-center">Action</div>,
        cell: ({ row }) => {
          const { _id, status } = row.original;

          return status === 'pending' || !status ? (
            <div className="flex flex-col gap-2 justify-center">
              <Button
                type="button"
                variant="success"
                className="text-white"
                onClick={() => handleUpdatePOStatus('approved', _id)}
              >
                Approve
              </Button>
              <Button
                type="button"
                variant="destructive"
                onClick={() => handleUpdatePOStatus('disapproved', _id)}
              >
                Disapprove
              </Button>
            </div>
          ) : null;
        },
      }),
    ],
    [handleUpdatePOStatus]
  );

  const table = useReactTable({
    data: purchaseOrderData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const csvData = [
    [
      'Reference',
      'Currency',
      'Expected Release Date',
      'Actual Release Date',
      'Status',
    ],
    ...(purchaseOrderData?.length > 0
      ? purchaseOrderData.map((purchaseOrderObject) => [
          purchaseOrderObject.reference,
          purchaseOrderObject.currency,
          purchaseOrderObject.expectedReleaseDate
            ? new Date(purchaseOrderObject.expectedReleaseDate).toDateString()
            : '',
          purchaseOrderObject.completedReleasedDate
            ? new Date(purchaseOrderObject.completedReleasedDate).toDateString()
            : '',
          purchaseOrderObject?.status ?? 'pending',
        ])
      : []),
  ];

  return (
    <>
      <SupplyChainNav>
        <Col className="w-full">
          <Card className="mb-10">
            <CardBody className="pt-4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                  }}
                >
                  <Link to="/purchase-order/create">
                    <div className="text-sm-right">
                      <Button
                        type="button"
                        variant="success"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1"></i> Create new{' '}
                      </Button>
                    </div>
                  </Link>
                </div>
                <CSVLink
                  style={{ float: 'right', marginBottom: '1.25rem' }}
                  data={csvData}
                  filename={`PURCHASE-ORDER-${dateString}.csv`}
                >
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-md btn-rounded"
                  >
                    CSV
                  </Button>
                </CSVLink>
              </div>

              <Row>
                <Col>
                  <label className="control-label">FILTER</label>
                  <Row>
                    <Col xs="3" className="sm:w-3/12">
                      <div className="search-box mb-2 d-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control h-[32x] py-1 rounded-md h-9"
                            placeholder="Search..."
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-4 sm:w-3/12">
                      <Select
                        options={statusOptions}
                        onChange={(val) => {
                          setPage(1);

                          setStatus(val.value);
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: '36px',
                            minHeight: '36px',
                            border: '1px solid black',
                            boxShadow: 'none',
                            '&:hover': {
                              border: '1px solid black',
                            },
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            backgroundColor: 'black',
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: 'black',
                            '&:hover': {
                              color: 'black',
                            },
                          }),
                        }}
                        value={statusOptions.find(
                          (val) => val.value === status
                        )}
                      ></Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

           
                <div className="table-responsive">
                  <TableShadcn className="w-full table-auto">
                    <TableHeader>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead key={header.id} className="text-left">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {purchaseOrderState === 'success' ?
                    <TableBody>
                      {table?.getRowModel().rows?.length > 0 ? (
                        table?.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}
                          >
                            {row?.getVisibleCells()?.map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} className="text-center">
                            No results.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  : <TableSkeleton columns={columns.length} centerIndex={[2]} perPage={perPage} />
                  }
                  </TableShadcn>
                </div>
              
              <TablePagination
                count={count}
                className="my-4"
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
        </Col>
      </SupplyChainNav>
    </>
  );
};

export default PurchaseOrderList;
