import { WarrantyKanbanBoard } from '@components/WarrantyKanban/WarrantyKanbanBoard';
import { Dialog, DialogContent } from '@shadcn/components/ui/dialog';
import { useTaskCard } from '@zustand/useTaskCard';
import moment from 'moment';
import { Badge } from '@shadcn/components/ui/badge';
import { CalendarDays, User, AlertTriangle, PackageCheck, Truck, ArrowRight, History } from 'lucide-react';
import { InfoBox } from '@shadcn/components/ui/infobox';


const WarrantyClaimPage = () => {
 
  const selected = useTaskCard((state) => state.selected);
  const card = useTaskCard((state) => state.task);
  const selectedCard = useTaskCard((state) => state.selectedCard);
  

  return (
    <div className="page-content">
      <div className="container mx-auto px-4 lg:max-w-[80%]">
        <WarrantyKanbanBoard/>
        <Dialog open={selected} onOpenChange={() => selectedCard({})}>
          <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              
              <div className="space-y-6">
                <div className="border-b pb-4">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
                    <span>Warranty Claim {card?.order?.name}</span>
                    <Badge variant="outline" className="text-sm bg-green-100 text-green-800">
                      {card?.fulfilled ? "Fulfilled" : "Processing"}
                    </Badge>
                  </h2>
                  <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                    <CalendarDays className="h-4 w-4" />
                    <span>Created at: {moment(card?.createdAt).format('DD/MM/YYYY HH:mm')}</span>
                  </div>
                </div>

                {/* Thông tin khách hàng */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold flex items-center gap-2">
                    <User className="h-5 w-5" />
                    Customer Information
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    <InfoBox label="Customer Name" value={card?.customerName} />
                    <InfoBox label="State" value={card?.state} />
                    <InfoBox label="Store Location" value={card?.storeLocation} />
                    <InfoBox label="Staff Member" value={card?.staffMemberName} />
                  </div>
                </div>

             
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold flex items-center gap-2">
                    <AlertTriangle className="h-5 w-5 text-red-500" />
                    Warranty Reason
                  </h3>
                  
                  {/* Thêm phần Customer Request */}
                  <div className="space-y-2">
                    <div className="p-4 bg-red-50 rounded-lg">
                      <div className="font-medium text-red-700">Customer Request:</div>
                      <p className="mt-2 text-sm text-red-600">
                        {card?.customerRequest || 'No special request'}
                      </p>
                    </div>
                    
                    <div className="p-4 bg-yellow-50 rounded-lg">
                      <div className="font-medium text-yellow-800">Fault Reason:</div>
                      <p className="mt-2 text-sm text-yellow-700">{card?.faultReason}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column - Products and logistics */}
              <div className="space-y-6">
                {/* Replacement product list */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold flex items-center gap-2">
                    <PackageCheck className="h-5 w-5 text-blue-500" />
                    Replacement Product
                  </h3>
                  <div className="space-y-3">
                    {card?.replacementSkus?.map((item, index) => (
                      <div key={index} className="p-4 bg-gray-50 rounded-lg">
                        <div className="flex items-center gap-2 text-sm">
                          <span className="line-through text-gray-500">{item.originalSku}</span>
                          <ArrowRight className="h-4 w-4 text-gray-400" />
                          <span className="font-medium">{item.replacementSku}</span>
                        </div>
                        {item.monogram && (
                          <div className="mt-2 flex items-center gap-2 text-xs">
                            <Badge variant="outline" className="bg-purple-100 text-purple-800">
                              Monogram: {item.monogram.text}
                            </Badge>
                            <span className="text-gray-500">{item.monogram.color}</span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

    
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold flex items-center gap-2">
                    <Truck className="h-5 w-5 text-green-500" />
                    Shipping Information
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    <InfoBox 
                      label="Shipping Method" 
                      value={card?.toBeShipped ? "Shipping" : "Pickup at Store"} 
                      icon={<Truck className="h-4 w-4" />}
                    />
                    <InfoBox 
                      label="Replacement Date" 
                      value={moment(card?.replacementIssuedDate).format('DD/MM/YYYY')}
                      icon={<CalendarDays className="h-4 w-4" />}
                    />
                  </div>
                </div></div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default WarrantyClaimPage;
