import React, { Component } from 'react';
import { Link } from "react-router-dom";


//Import Images
import error from "../../assets/images/error-img.png";
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';

class Pages500 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-12 pt-12">
                <div className="w-full px-3 mx-auto xl:max-w-[85%]">
                        <Row>
                            <Col>
                                <div className="text-center mb-12">
                                    <h1 className="display-2 font-weight-medium">5<i className="bx bx-buoy bx-spin text-primary display-3"></i>0</h1>
                                    <h4 className="text-uppercase">Internal Server Error</h4>
                                    <div className="mt-12 text-center">
                                        <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Back to Dashboard</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="md:w-8/12 xl:w-6/12">
                                <div>
                                    <img src={error} alt="" className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Pages500;
