import React from 'react';
import { Link } from 'react-router-dom';

const CategoryCard = ({ category, key }) => {
  return (
    <div className="w-full p-4" key={"_category_" + key}>
      <Link to={`/category/edit/${category._id}`}>
        <div className="border rounded-lg shadow hover:shadow-lg transition duration-150">
          <div className="p-4">
            <div className="overflow-hidden">
              <h5 className="text-lg font-medium truncate">
                <Link to={`/category/edit/${category._id}`} className="text-gray-900 text-[15px]">
                  {category.name}
                </Link>
              </h5>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CategoryCard;
