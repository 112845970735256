import React, { Component } from 'react';

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';


class Breadcrumbs extends Component {
    render() {
        // return <></>
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box flex flex-row items-center justify-between">
                            <h4 className="mb-0 font-size-18">{this.props.breadcrumbItem}</h4>
                            {this.props.addOn && this.props.addOn}
                            {/* <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    <BreadcrumbItem>
                                        <Link to="#">{this.props.title}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link to="#">{this.props.breadcrumbItem}</Link>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Breadcrumbs;
