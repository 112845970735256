import { backendUrl } from '@helpers/consts';
import { useState, useEffect, useCallback } from 'react';
import  jwt_decode  from "jwt-decode";
import superagent from 'superagent';

export function useWebSocketToken() {
  const [token, setToken] = useState(() => localStorage.getItem('websocketToken') || null);
  const [expiresIn, setExpiresIn] = useState(null);

  const fetchToken = useCallback(async () => {
    try {
      const response = await superagent.get(`${backendUrl}/auth/jwtWebSocketToken`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json');
      const { token } = response.body;


      localStorage.setItem('websocketToken', token);
      setToken(token);


      const decodedToken = jwt_decode(token);
      if (decodedToken.exp) {
        const expiresInMs = decodedToken.exp * 1000;
        setExpiresIn(expiresInMs);

        console.log('New token fetched and saved:', token);
      } else {
        console.error('Failed to decode token expiration time');
      }
    } catch (error) {
      console.error('Failed to fetch WebSocket token:', error);
    }
  }, []);

  
  const scheduleTokenRefresh = useCallback(() => {
   
    if (!expiresIn) return;
   
    const timeoutDuration = expiresIn - Date.now() - 60 * 1000;
    console.log('time duration...',timeoutDuration);
    if (timeoutDuration < 0) {
      setTimeout(() => {
        console.log('Refreshing WebSocket token...');
        fetchToken();
      }, timeoutDuration);
    }
  }, [expiresIn, fetchToken]);

  
  useEffect(() => {
    if (!token) {
      fetchToken();
    } else {
    
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp) {
        const expiresInMs = decodedToken.exp * 1000;
        setExpiresIn(expiresInMs);
      }
    }
  }, [token, fetchToken]);


  useEffect(() => {
    scheduleTokenRefresh();
  }, [expiresIn, scheduleTokenRefresh]);

  return token;
}
