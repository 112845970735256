import  { useState } from 'react';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';

import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts';
import WizardNav from './WizardNav';
import SKUToolTip from '../../helpers/SKUToolTip';
import useSKULevels from "../../helpers/useSKULevels.js";
import useSKUStatus from "../../helpers/useSKUStatus.js";
import useUpcomingStock from "../../helpers/useUpcomingStock.js";
import { DialogFooter } from '@shadcn/components/ui/dialog';
import { Button } from '@shadcn/components/ui/button';
import { Badge } from '@shadcn/components/ui/badge';

const fulfillmentToBadge = (fulfillment) => {
    if (fulfillment === 'unfulfilled') return 'warning';
    if (fulfillment === 'awaiting-stock') return 'secondary';
    const conversion = {
        'fulfilled': 'success',
        'partially_fulfilled': 'error',
    };
    return conversion[fulfillment];
};

const OrderWizardDeleteSKU = ({ togglemodal, refresh, order, orderID, getVariant, lineItems }) => {
    const availableItems = order.line_items.filter((item) => item.fulfillment_status !== 'deleted');
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const findSKULevel = useSKULevels()
    const findUpcomingStock = useUpcomingStock()
    const findSKUStatus = useSKUStatus()
    const [step, setStep] = useState(1);

    const handleDeleteLineItem = (id) => {
        superagent.post(`${backendUrl}/orders/cancelLineItem/${id}`)
            .send({
                orderID: orderID,
            })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .then(response => {
                const code = response.status;
                if (code > 400) {
                    // Handle error
                } else {
                    refresh();
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleNext = () => {
        const selectedLineItem = availableItems[selectedItemIndex];
        if (selectedLineItem) {
            handleDeleteLineItem(selectedLineItem._id);
            togglemodal();
        }
    };

    const handleItemClick = (index) => {
        setSelectedItemIndex(index === selectedItemIndex ? null : index);
    };

    return (
        <div>
            <WizardNav steps={['Delete Line Item']} activeTabProgress={step} />
            <div>
                <div className="form-group row">
                    <div className="col-md-12">
                        {lineItems.map((lineItem, index) => {
                            const isSelected = index === selectedItemIndex;
                            return (
                                <Row className="flex-wrap lg:flex-nowrap" key={index} onClick={() => handleItemClick(index)} style={{ cursor: 'pointer', border: '1px solid #ddd', borderRadius: '5px', margin: '5px 0', backgroundColor: isSelected ? '#f0f0f0' : 'inherit' }}>
                                    <Col sm="3" className="sm:w-3/12">
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                        </div>
                                    </Col>
                                    <Col sm="8" className="sm:w-8/12 p-3" >
                                        <h6>{getVariant(lineItem.sku)?.name}</h6>
                                        <div>
                                            <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem?.fulfillment_status)} color={fulfillmentToBadge(lineItem?.fulfillment_status)} pill>
                                                {lineItem?.fulfillment_status ? lineItem?.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                            </Badge>
                                        </div>
                                        <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button variant="destructive" onClick={togglemodal}> Close </Button>
                <Button onClick={handleNext}> Delete </Button>
            </DialogFooter>
        </div>
    );
};

export default OrderWizardDeleteSKU;
