import React from 'react';
import Select from 'react-select';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid black',
    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
    '&:hover': {
      borderColor: 'black',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "black",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "black",
    '&:hover': {
      color: "black",
    },
  }),
};

const CustomSelect = React.forwardRef(({ styles = {}, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      styles={{
        ...customStyles, 
        ...styles, 
      }}
      {...props}
    />
  );
});

CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
