import { create } from 'zustand'

const useParcelStore = create((set) => ({
  isOpen: false,
  edit: false,
  parcels: [],
  initialData: null,
  setIsOpen: (isOpen) => set({ isOpen }),
  setParcels: (parcels) => set({ parcels }),
  setParcelData: ( initialData) => set({  initialData }),
  setEdit: (edit) => set({ edit }),
  reset: () => set({ initialData: null, edit: false }),
}))

export default useParcelStore
