import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return {
    get: (key) => {
      const value = params.get(key);
     
      if (value) {
        try {
          return decodeURIComponent(decodeURIComponent(value));
        } catch (e) {
         
          try {
            return decodeURIComponent(value);
          } catch (e) {
         
            return value;
          }
        }
      }
      return value;
    },
    getAll: (key) => params.getAll(key),
    has: (key) => params.has(key),
  };
};
