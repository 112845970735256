import  { useState, useEffect } from "react";
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Card, CardContent as CardBody, CardHeader, CardFooter} from '@shadcn/components/ui/card'

import { Badge } from "@shadcn/components/ui/badge";
import { Button } from "@shadcn/components/ui/button";
import { Dialog, DialogContent, DialogFooter } from "@shadcn/components/ui/dialog";

import { Link } from "react-router-dom";

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';

import CommentHostory from "../../Skote/Dashboard/CommentHistory.js";
import useProductData from "../orders/useProductData.js";
import saveAs from 'save-as';
import superagent from 'superagent';
import {backendUrl} from '../../../helpers/consts.js'
import SKUToolTip from "../helpers/SKUToolTip.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useSKUStatus from "../helpers/useSKUStatus.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const distanceToBadge = (distance) => {
  if (distance < 10000) return 'success'
  return 'danger'
}

const shippingConverter = (pick) => {
  const conversion = {
    'pickup': 'Pickup QV',
    'pickup_hq': 'Pickup HQ',
    'standard': 'Standard',
    
    'next-day': 'Next Day',
    'same-day': 'Same Day',

    'express': 'Express',
    'uber': 'Uber',
    'Uber': 'Uber',
    'overnight': 'Overnight',
  }
  return conversion[pick] || pick
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',

    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const PickOrder = ({order, setModalOpen, nextOrder, sendLineItems}) => {
  const [loading, setLoading] = useState(false)

  const pickOrder = async () => {
    setLoading(true)

    const resData = await (
      new Promise(async (res, rej) => {
        await superagent.post(`${backendUrl}/orders/bookParcelsEmboss`)
          .send({ 
            line_items: sendLineItems, 
            orderId: order._id
          })
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('accept', 'json')
          .on('error', (response) => {
            res()
            setLoading(false)
          })
          .then(response => {
            setLoading(false)
            res(response.body)
          }).catch(err => {
            console.log(err)
            setLoading(false)
            res()
          });
      })
    )

    // fetch the label
    const labelUrl = resData.url
    //replave the direct url
    const binary = (await superagent.get(labelUrl).responseType('blob')).body

    saveAs(binary, `${order.name}.pdf`);
  }

  const closeModal = () => {
    nextOrder()
    setModalOpen(false)
  }

  useEffect(() => {
    pickOrder()
  }, [])
  return (
    <>
      <div>
        {
          loading ? (
            <div className="spinner-grow text-primary m-1 ml-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              Downloaded.
            </>
          )
        }
      </div>
      <DialogFooter>
        <Button variant="destructive" onClick={closeModal} disabled={loading}> Close </Button>
      </DialogFooter>
    </>
  )
}


const BulkSendEmbossing = () => {
  const [orderIndex, setOrderIndex] = useState(0)
  const [refresh, setRefresh] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [modalOpen, setModalOpenVar] = useState(false)
  const [stockCount, setStockCount] = useState({})
  const [selectedItems, setSelectedItems] = useState([])

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const getVariant = useProductData()

  const [allOrdersUnfiltered, stateA] = useQuery({
    url: `/orders/fetchEmbossingOrders`,
    refresh
  });
  const [stockCountFull, stockCountState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: 'HQ2'
    }
  });

  const allOrders = allOrdersUnfiltered
  const order = allOrders ? allOrders[orderIndex] : {}

  const selectedLineItems = selectedItems
    .map((val, key) => val ? order.line_items[key] : null)
    .filter(el => el != null);

  const sendLineItems = selectedLineItems.map((value, key) => {
    return {
      ...value,
      fulfill: true,
      book: true
    }
  })

  const setModalOpen = (val) => {
    setModalOpenVar(val)
  }

  const sendPressed = () => {
    if (selectedItems.filter(val => val).length === 0) return 

    setModalOpen(true)
  }
  const nextOrder = () => {
    setOrderIndex(prev => prev + 1)
  }

  useEffect(() => {
    setOrderIndex(0)
  }, [allOrdersUnfiltered])


  useEffect(() => {
    let collatedStockCount = {}
    if (stockCountFull) {
      stockCountFull.map(product => {
        product.variantCounts.map(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand
        })
      })
      setStockCount(collatedStockCount)
    }
  }, [stockCountFull])

  useEffect(() => {
    if (stockCount && Object.keys(stockCount).length > 0 && order != {} && order !== undefined && order.line_items && order.line_items.length > 0){
      setSelectedItems(prev => {
        const lineItemsAvaliable = order.line_items.map(lineItem => {
          const rootSku = lineItem.sku.split('-').filter(val => val !== 'P').join('-')

          return lineItem?.monogram_font?.includes('embos') && stockCount[rootSku] >= lineItem.quantity
        })
        return lineItemsAvaliable
      })
    }
  }, [orderIndex, stockCount])

  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Orders" breadcrumbItem="Bulk Send To 3PL" />
          <Row>
            <Col xs="12">
              <Card className='mb-6'>
                {
                  stateA === 'loading' ? (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                      <div className="spinner-grow text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <CardHeader>
                        <div className="mt-3">
                          {
                            order !== undefined && (
                              <>
                                {
                                  loadingData || stockCountState === 'loading' ? (
                                    <div className="spinner-grow text-primary m-1" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    <>
                                      {/* <Button disabled={orderIndex === 0} onClick={prevOrder} color="warning"  className="mr-2"> Back </Button> */}
                                      <Button onClick={nextOrder} variant="warning"  className="mr-2"> Skip </Button>
                                      <Button onClick={sendPressed} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Send </Button>
                                    </>
                                  )
                                }
                              </>
                            ) 
                          }
                        </div>
                      </CardHeader>
                      <CardBody>
                        {
                          order === undefined ? (
                            <>
                              No orders left to send.
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col xs="8" className='sm:w-8/12'>
                                  <Row>
                                    <Col xs="12">
                                      <div className="mb-4">
                                        <Link to={`/orders/${order?._id}`}> <h3 className="font-medium text-[23px]"> {order?.name} </h3> </Link>
                                        
                                        <h6 className="mb-4 font-medium">{(new Date(order.orderCreated).toString())}</h6>
                                        <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-" + order.badgeclass} variant={order.badgeClass} pill>{shippingConverter(order?.shipping_type)?.toUpperCase()}</Badge>
                                        {
                                          order.flagged && (
                                            <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-danger"} pill>
                                              FLAGGED
                                            </Badge>
                                          )
                                        }
                                        <Badge className={"ml-2 px-4 py-2 font-size-12 badge-soft-" + pickToBadge(order.pick_status)} variant={pickToBadge(order.pick_status)} pill>
                                          {pickToName(order.pick_status)}
                                        </Badge>
                                        {
                                          order.distanceFromHQ != null && (
                                            <Badge className={"ml-2  px-4 py-2 font-size-12 badge-soft-" + distanceToBadge(order.distanceFromHQ)} variant={distanceToBadge(order.distanceFromHQ)} pill>
                                              {order.distanceFromHQ/1000} KM
                                            </Badge>
                                          )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12">
                                    {
                                      order?.line_items && order.line_items.map((lineItem, key) => {
                                        const isSelected = selectedItems[key]
                                        const handleSelected = () => {
                                          setSelectedItems(prev => {
                                            let newItems = [...prev]
                                            newItems[key] = !newItems[key]
                                            return newItems
                                          })
                                        }
                                        return (
                                          <Row>
                                            <Col sm="1" className='sm:w-1/12'>
                                              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                  lineItem.fulfillment_status !== 'deleted' && (
                                                    <input className="form-check-input" type="checkbox" onChange={handleSelected} checked={isSelected} id="defaultCheck1" />
                                                  )
                                                }
                                              </div>
                                            </Col>
                                            <Col sm="2" className='sm:w-2/12'>
                                              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                              </div>
                                            </Col>
                                            <Col sm="6" className="p-3 sm:w-6/12" >
                                              <h6>{getVariant(lineItem.sku)?.name}</h6>
                                              <div>
                                                <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem.fulfillment_status)} variant={fulfillmentToBadge(lineItem.fulfillment_status)} pill>
                                                  {lineItem.fulfillment_status ? lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                                </Badge>
                                              </div>
                                              <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                                            </Col>
                                            
                                            <Col sm="3" className="p-3 sm:w-3/12">
                                              <div> avaliable: {`${stockCount[lineItem.sku.split('-').filter(val => val !== 'P').join('-')] || 0}`} </div>
                                              <div> quantity: {lineItem.quantity} </div>
                                            </Col>
                                          </Row>
                                        )
                                      })
                                    }
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs="4" className="sm:w-4/12">
                                  {
                                    order?.name !== undefined && (
                                      <CommentHostory title="Notes" initCommentType={'Order'} initOrderName={order?.name} />
                                    )
                                  }
                                  {
                                    order != null && order?.shipping_address != null && order?.customer != null && (
                                      <div>
                                        <h6 className="font-medium mt-6" style={{marginBottom: 16}}> {`${order?.customer[0].first_name} ${order?.customer[0].last_name}`} </h6>
                                        <div> <span style={{opacity: 0.5}}>EMAIL:</span> {order?.customer[0].email} </div>
                                        <div> {order?.shipping_address[0].name} </div>
                                        <div> {order?.shipping_address[0].address1} </div>
                                        <div> {order?.shipping_address[0].address2 || ''} </div>
                                        <div> {`${order?.shipping_address[0].city || ''} ${order?.shipping_address[0].province || ''} ${order?.shipping_address[0].zip || ''}`} </div>
                                        <div> {order?.shipping_address[0].country || ''} </div>
                                        <div> {order?.shipping_address[0].phone || ''} </div>
                                      </div>
                                    )
                                  }
                                </Col>
                              </Row>  
                            </>
                          )
                        }
                      </CardBody>
                      {
                        order !== undefined && (
                          <CardFooter>
                            {
                              loadingData || stockCountState === 'loading' ? (
                                <div className="spinner-grow text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <>
                                  {/* <Button disabled={orderIndex === 0} onClick={prevOrder} color="warning"  className="mr-2"> Back </Button> */}
                                  <Button onClick={nextOrder} variant="warning"  className="mr-2"> Skip </Button>
                                  <Button onClick={sendPressed} disabled={!selectedItems.filter(val => val).length > 0} variant="success" className="mr-2"> Send </Button>
                                </>
                              )
                            }
                          </CardFooter>
                        ) 
                      }

                    </>
                  )
                }
              </Card>
            </Col>
          </Row>
        </div>
        <Dialog
          size='lg'
          open={modalOpen}
          onOpenChange={() => setModalOpen(prev => !prev)}
          centered={true}
        >
          <DialogContent>
          <div className="modal-header">
            <h5 className="modal-title mt-0 font-medium text-[16px] ">Fulfillment method</h5>
            
          </div>
          {
            modalOpen && (
              <PickOrder 
                order={order}
                setModalOpen={setModalOpen}
                nextOrder={nextOrder}
                sendLineItems={sendLineItems}
              />
            )
          }
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export default BulkSendEmbossing
