import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'
import { Button } from "@shadcn/components/ui/button";
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import Table from "../component/Table";

import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import "@pages/JIFI/datatables.scss";
import useQuery from '../../../../helpers/JIFI/useQuery';


import SharedBatchModal from "../component/BatchModal";



const DeliveriesPage = () => {
  const url = '/orders/fetchLaunchMonoLines'

  const [batchModal, setBatchModal] = useState();
  const toggleBatchModal = () => setBatchModal(prev => !prev)

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [lineItems, state] = useQuery({ refresh, url });

  const [selected, setSelected] = useState([])
  useEffect(() => {
    lineItems ? setSelected(lineItems.map(() => false)) : setSelected([]) 
  }, [lineItems])
  const selectedIds = lineItems && lineItems.filter((val, key) => selected[key] === true)

  const handleGenerateBatch = () => {
    if (selectedIds && selectedIds.length > 0 && selectedIds.length <= 16){
      toggleBatchModal()
    }
  }

  return (
    <>
      <div>
       
          <Row>
            <Col xs="11" className="sm:w-11/12">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation List" />
            </Col>
            <Col xs="1" className="sm:w-1/12">
              <Link className="text-danger" to='/external-personalisation-batches-launch'> Batches </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="pt-6">
                <CardBody>
                  
                      <Table loading={state !== 'success'} lineItems={lineItems} selected={selected} setSelected={setSelected} handleGenerateBatch={handleGenerateBatch} selectedIds={selectedIds} />
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        
      </div>   
      
      
      <SharedBatchModal
        isVisible={batchModal}
        refresh={refreshData}
        togglemodal={toggleBatchModal}
        selectedIds={selectedIds}
        type='launch'
        location="LCHSLC"
      />

     

    </>
  );
}




export default DeliveriesPage;
