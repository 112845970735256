import React, { useEffect } from 'react'

import '../index.css'
import '../../../pages/JIFI/index.css'

import Block from './block'
import Bold from './bold'
import Casual from './casual'
import College from './college'
import Cursive from './cursive'
import Modern from './modern'
import Painted from './painted'
import Retro from './retro'
import Script from './script'
import Serif from './serif'
import FontWord from './shared/font-file'
import Signature from './signature'
import Wide from './wide'

import BoldNew from './bold-new'
import Bubble from './bubble'
import Decorative from './decorative'
import Graphic from './graphic'
import Heritage from './heritage'
import RetroSerif from './retro-serif'
import SansSerif from './sans-serif'


const textTransform = (text, font) => {
  // if (['Block', 'Wide', 'Casual'].includes(font)) {
  //   return text.toUpperCase()
  // } else {
  //   return text.replace(/\w\S*/g, (txt) =>  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  // }
  if (font === 'block' || font === 'wide') {
    return text.toUpperCase()
  }
  return text
}

const Word = ({
  mainCol,
  backCol,
  letters,
  fontSize,
  font,
  small,
  print,
  size,
  panelKey,
  maxLetters,
  passRefesh,
  left = false,
  refreshToken,
}) => {
  let isSmall = print === true ? true : small
  const newLetters = textTransform(letters, font)

  useEffect(() => {
    const renderCanvas = document.getElementById('RenderCanvas')
    if (renderCanvas) {
      renderCanvas.style.width = `${size.width}px`
      renderCanvas.style.height = `${size.height}px`
    }
  }, [])

  if (letters.length < 1) {
    return <> </>
  }

  const Components = {
    casual: Casual,
    college: College,
    painted: Painted,
    retro: Retro,
    script: Script,
    serif: Serif,
    signature: Signature,
    wide: Wide,
    block: Block,

    bold: Bold,
    cursive: Cursive,
    modern: Modern,

    'bold-new': BoldNew,
    'bubble': Bubble,
    'decorative': Decorative,
    'graphic': Graphic,
    'heritage': Heritage,
    'retro-serif': RetroSerif,
    'sans-serif': SansSerif,


    'zh-ser': FontWord,
    'zh-san': FontWord,
    'zh-block': FontWord,
    'zh-script': FontWord,
    'zh-casual': FontWord,

    'love-letter': FontWord,

  }
  const WordComponent = Components[font]
  if (WordComponent !== undefined) {
    return (
      <>
        <WordComponent
          passRefesh={passRefesh}
          maxLetters={maxLetters || 20}
          panelKey={panelKey}
          size={size}
          mainCol={mainCol}
          backCol={backCol}
          letters={newLetters}
          fontSize={fontSize}
          font={font}
          small={isSmall}
          left={left}
          refreshToken={refreshToken}
        />
      </>
    )
  } else {
    return (
      <>
        <FontWord
          passRefesh={passRefesh}
          maxLetters={maxLetters || 20}
          panelKey={panelKey}
          size={size}
          mainCol={mainCol}
          backCol={backCol}
          letters={newLetters}
          fontSize={fontSize}
          font={font}
          small={isSmall}
          left={left}
          refreshToken={refreshToken}
        />
      </>
    )
  }
}

export default Word
