import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import superagent from 'superagent';
import useQuery from '../../../helpers/JIFI/useQuery';
import { backendUrl } from '../../../helpers/consts';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from '@shadcn/components/ui/button';


const CategoryPage = ({ match: { params } }) => {
  const { categoryID } = params;
  const history = useHistory();

  const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm();
  const [flagTypes, setFlagTypes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const refreshData = () => setRefresh((prev) => !prev);

  const [category, state] = useQuery({
    refresh,
    url: `/categories/${categoryID}`,
    runQuery: categoryID ? true : false,
  });

  console.log(category)
  
  useEffect(() => {
    superagent.get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .then((response) => {
        setFlagTypes(response.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 
  useEffect(() => {
    if (category !== undefined) {
      setValue('name', category.name);
      setValue('flagType', category.flagType);
    }
  }, [category, setValue]);

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      flagTypeId: data.flagType,
      isActive: true
    };

    if (!categoryID) {

        superagent.post(`${backendUrl}/categories`)
        .send(payload)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .then((response) => {
          const code = response.status
          if (code > 400) {
          } else {
            toast.success('Category created successfully')
          }
        })
        .catch((err) => {
          toast.error('Failed to create category')
          console.log(err);
        });
    } else {
        superagent.put(`${backendUrl}/categories/${categoryID}`)
        .send(payload)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .then((response) => {
          if(response.status > 400) {
          } else {
            toast.success('Category updated successfully')
          }
        })
        .catch((err) => {
          toast.error('Failed to update category')
          console.log(err);
        });

    }
  };


  const deleteCategory = () => {
    superagent.delete(`${backendUrl}/categories/${categoryID}`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then(() => {
        history.push('/category/all');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  return (
    <>
      <div className="page-content">
        <div className="container mx-auto px-4 lg:max-w-[85%]">
          {/* Breadcrumb */}
          <div className="flex justify-between items-center my-4">
            <div>
              <h2 className="text-lg font-medium">{categoryID ? 'Edit Category' : 'New Category'}</h2>
            </div>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Category Name */}
              <div>
                <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">Category Name</label>
                <input
                  id="categoryName"
                  name="name"
                  type="text"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('name', { required: 'Category Name is required' })}
                />
                {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
              </div>

              {/* FlagType Dropdown */}
              <div>
                <label htmlFor="flagType" className="block text-sm font-medium text-gray-700">Flag Type</label>
                <select
                  id="flagType"
                  name="flagType"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('flagType', { required: 'Flag Type is required' })}
                >
                  <option value="">Select Flag Type</option>
                  {flagTypes.map((flagType) => (
                    <option selected={flagType._id === getValues('flagType')} key={flagType._id} value={flagType._id}>
                      {flagType.name}
                    </option>
                  ))}
                </select>
                {errors.flagType && <span className="text-red-500 text-sm">{errors.flagType.message}</span>}
              </div>
            </div>

            
            <div className="mt-4 flex space-x-4">
              <Button
                variant='success'
              >
                {categoryID ? 'Save Changes' : 'Add Category'}
              </Button>

              {categoryID && (
                <Button
                 variant='destructive'
                  onClick={deleteCategory}
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default CategoryPage;
