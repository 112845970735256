import React from 'react'
import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import FlagType from "./card-flagtype";
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Button } from '@shadcn/components/ui/button';

const FlagTypeListPage = ({}) => {
  const [data, state] = useQuery({
    url: `/orders/getFlagTypes`
  });
  const flagtype = data
  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
            <Breadcrumbs title="Flag Type" breadcrumbItem="All Flag Type" />
            <Row className="mb-2">
              <Col className="sm:w-10/12" />
              <Col className="sm:w-2/12" >
                <Link to='/flagtype/new'>
                  <div className="text-sm-right">
                    <Button type="button" variant="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row className="flex-wrap gap-4 mb-4">
                {
                  flagtype ? flagtype.map((val, key) => <FlagType flagtype={val} key={key} />) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                      <div className="spinner-grow text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
                }
            </Row>
        </div>
      </div>
    </>
  )
}

export default FlagTypeListPage
