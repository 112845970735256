import  { useState, useEffect, useRef } from "react";
import { Col } from "@shadcn/components/ui/col";
import { Row } from "@shadcn/components/ui/row";
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'

import { Link } from "react-router-dom";

import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import "@pages/JIFI/datatables.scss";
import useQuery from '../../../../helpers/JIFI/useQuery';

import Table from "../component/Table";

import SharedBatchModal from "../component/BatchModal";




const DeliveriesPage = () => {
  const url = '/orders/fetchAirboxMonoLines'

  const [batchModal, setBatchModal] = useState();
  const toggleBatchModal = () => setBatchModal(prev => !prev)

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [lineItems, state] = useQuery({ refresh, url });

  const [selected, setSelected] = useState([])
  useEffect(() => {
    lineItems ? setSelected(lineItems.map(() => false)) : setSelected([]) 
  }, [lineItems])
  const selectedIds = lineItems && lineItems.filter((val, key) => selected[key] === true)

  const handleGenerateBatch = () => {
    if (selectedIds && selectedIds.length > 0 && selectedIds.length <= 16){
      toggleBatchModal()
    }
  }

  return (
    <>
      <div>
        
          <Row>
            <Col xs="11" className="sm:w-11/12">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation List" />
            </Col>
            <Col xs="1" className="sm:w-1/12">
              <Link className="text-danger" to='/external-personalisation-batches-airbox'> Batches </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="pt-6">
                <CardBody>
                  {
                  
                      <>
                        <Row>
                          <Col xs="4" className="sm:w-4/12"> </Col>
                          <Col xs="4" className="sm:w-4/12"> </Col>
                       
                        </Row>
                      <Table loading={state !== 'success'} lineItems={lineItems} selected={selected} setSelected={setSelected} handleGenerateBatch={handleGenerateBatch} selectedIds={selectedIds} />
                      </>
                  
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        
      </div>   
      
      <SharedBatchModal
        isVisible={batchModal} 
        refresh={refreshData} 
        togglemodal={toggleBatchModal} 
        selectedIds={selectedIds} 
        type='airbox'
        location='Airbox UK'
      />
      
    </>
  );
}



export default DeliveriesPage;
