import  { useState, useEffect } from "react";




import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'
import {backendUrl} from '../../../helpers/consts.js'
import { DropdownMenu, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuContent } from '@shadcn/components/ui/dropdown-menu';


import Select from '@shadcn/components/ui/react-select';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';

import superagent from 'superagent'
import { Input } from "@shadcn/components/ui/input.jsx";
import { Button } from "@shadcn/components/ui/button.jsx";
import { Badge } from "@shadcn/components/ui/badge.jsx";
import { Dialog, DialogContent, DialogFooter } from "@shadcn/components/ui/dialog";

// 

const ShipBadge = ({status}) => {
  const conversion = {
    'awaiting-delivery': 'warning',
    'delivered': 'success',
    'out-for-delivery': 'secondary',
  }
  const badgeColor =  conversion[status]
  return (
    <Badge className={"font-size-12 badge-soft-" + badgeColor} color={badgeColor} pill>
      {status.split('-').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')}
    </Badge>
  )
}


const SKUDropdown = ({skus}) => {
  const [skuArray, setSkuArray] = useState(skus.split(' ')) 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setSkuArray(skus.split(' '))
  }, [skus])

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <DropdownMenu>
  <DropdownMenuTrigger
    href="#"
    className="p-3"
    onClick={toggle}
  >
    ▼
  </DropdownMenuTrigger>
  {dropdownOpen && (
    <DropdownMenuContent>
      {
        skuArray.map((sku, index) => (
          <DropdownMenuItem key={index}>{sku}</DropdownMenuItem>
        ))
      }
    </DropdownMenuContent>
  )}
</DropdownMenu>
  );

}





const DeliveriesPage = () => {
  const url = '/deliveries/listDeliveryBatch'
  const [stateData, setStateData] = useState({
    searchTerm: '',
    selectedShipStatus: { label: 'Out For Delivery', value: 'out-for-delivery' },
    shipStatusOptions: [{ label: 'Awaiting Delivery', value: 'awaiting-delivery' }, { label: 'Out For Delivery', value: 'out-for-delivery' }, { label: 'Delivered', value: 'delivered' }],
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, searchTerm: stateData.searchTerm, shipStatus: stateData.selectedShipStatus?.value } });
  const orders = data && data.data;
  const count = data && data.count;

  const { searchTerm, selectedShipStatus, shipStatusOptions } = stateData
  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }
  const handleSelectShipStatus = selectedSshipStatus => {
    console.log(selectedSshipStatus)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedShipStatus: selectedSshipStatus
      }
    }))
  };

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orders ? setSelected(orders.map(() => false)) : setSelected([]) 
  }, [orders])
  const selectedIds = orders && orders.filter((val, key) => selected[key] === true)

  const [approveId, setApproveId] = useState(null)
  const handleApprove = (id) => {
    setApproveId(id)
  }

  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Deliveries" breadcrumbItem="All Deliveries" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className='pt-6 mb-6'>
                  <div>
                    <div className="select2-container mb-4">
                        <label className="control-label">SEARCH</label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                         
                          </div>
                        </div>
                      </div>
                  </div>
                  <Row>
                    <Col xs="8">
                      <div className="select2-container mb-4">
                        <label className="control-label">SHIP STATUS</label>
                        <Select  value={selectedShipStatus} onChange={handleSelectShipStatus} placeholder="All" title="Ship Status" options={shipStatusOptions} />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Date</th>
                          <th>Ship Status</th>
                          <th>Orders</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) =>
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>{(new Date(order.createdAt)).toDateString()}</td>
                                <td><ShipBadge status={order.ship_status} /></td>
                                <td><SKUDropdown skus={order.deliveries.map(lineItem => lineItem.name).join(' ')}/></td>
                                <td>
                                  {
                                    order.ship_status === 'out-for-delivery' && (
                                      <Button onClick={() => handleApprove(order._id)} variant="success"> Approve </Button>
                                    )
                                  }
                                </td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  {
                    (orders && state === 'success') ? (
                      <div>

                      </div>
                      // <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>   
      <ApproveModal approveId={approveId} setApproveId={setApproveId} refresh={refreshData}/>
    </>
  );
}


const ApproveModal = ({approveId, setApproveId, refresh}) => {
  const [loading, setLoading] = useState(false)
  let cancelOpen = approveId !== null
  const toggleCancelOpen = () => {
    setApproveId(null)
  }

  const [orderData, setOrderData] = useState({})

  useEffect(() => {
    loadData()
  }, [approveId])

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orderData && orderData.deliveries ? setSelected(orderData.deliveries.map(() => false)) : setSelected([]) 
  }, [orderData])
  const selectedIds = orderData && orderData.deliveries && orderData.deliveries.filter((val, key) => selected[key] === true)
  
  const loadData = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/getDeliveryBatch`)
      .send({ 
        id: approveId
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setOrderData(response.body)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }


  const save = () => {
    console.log(selectedIds.map(val => val._id))
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/approveDeliveryBatch`)
      .send({ 
        ids: selectedIds.map(val => val._id),
        batchId: approveId
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          toggleCancelOpen()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }

  return (
    <Dialog open={cancelOpen} onOpenChange={toggleCancelOpen}>
      <DialogContent className='z-[1600]  min-w-[70vw] overflow-y-auto'>
      <div>
        <h3 className="text-[23px] font-medium">Approval Delivery Batch</h3>
      </div>
        {
          loading ? (
            <div>
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
              <>
                <div>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <Input onChange={e => (orderData && orderData.deliveries ? setSelected(orderData.deliveries.map(() => e.target.checked)) : setSelected([])) } type="checkbox" className="custom-control-input" id="customCheck1" />
                              <label className="custom-control-label" htmlFor="customCheck1">&nbsp;</label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Shipping Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderData?.deliveries && orderData.deliveries.map((order, key) => 
                            order.line_items.map((lineItem, keyLI) => keyLI === 0 ? (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>
                                  <div 
                                    className="custom-control custom-checkbox"
                                    onClick={() => setSelected(prev => {
                                      let newSelected = [...prev]
                                      newSelected[key] = !newSelected[key]
                                      return newSelected
                                    })} 
                                  >
                                    <Input 
                                      type="checkbox" 
                                      className="custom-control-input" 
                                      id={order.id} 
                                      checked={selected[key]} />
                                    <label className="custom-control-label" htmlFor={order.id}>&nbsp;</label>
                                  </div>
                                </td>
                                <td>{order.name}</td>
                                <td>{order.shipping_type}</td>
                                <td>{order?.shipping_address?.city}</td>
                                <td>{order?.shipping_address?.name}</td>
                                <td>{(new Date(order.orderCreated)).toDateString()}</td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ): (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <DialogFooter>
                  <Button onClick={save} variant="warning"> Confirm </Button>
                </DialogFooter>
              </>
          )
        }
</DialogContent>
    </Dialog>
  )
}

export default DeliveriesPage;
