// src/pages/JIFI/locations/components/steps/RetailConfigStep.js
import React, { useState } from 'react';
import { Card, CardContent } from '@shadcn/components/ui/card';
import MultiSelect from '@shadcn/components/ui/react-select';
import TopupSchedule from './TopupSchedule';
import useQuery from '@helpers/JIFI/useQuery';
import  { StockInput  } from '@pages/JIFI/locations'


const RetailConfigStep = ({
 
  topupSchedule,
  setTopupSchedule,
  stockPull,
  setStockPull,
  selectedTopupLocation,
  setSelectedTopupLocation,
  productData,
}) => {


  
  const [topupLocation, topupLocationState]= useQuery(
    {
      url: `/inventory/topUpLocations`,
     
    }
  )
  if(topupLocationState.isLoading){
    return <div>Loading...</div>
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-medium mb-4">Topup Locations</h3>
          <MultiSelect
          
            options={topupLocation?.map((val) => ({
              value: val._id,
              label: val.name,
            }))}
            value={selectedTopupLocation}
            onChange={setSelectedTopupLocation}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-medium mb-4">Top-up Schedule</h3>
          <TopupSchedule
            schedule={topupSchedule}
            onChange={setTopupSchedule}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-medium mb-4">Stock Configuration</h3>
          <StockInput
            stock={stockPull}
            setStock={setStockPull}
            productData={productData}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default RetailConfigStep;
