import React, {useState, useEffect} from 'react'

import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import { Card , CardTitle, CardContent as CardBody } from '@shadcn/components/ui/card'
import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import { useHistory } from "react-router-dom";

const FlagTypeListPage  = ({
  match: {
    params
  },
}) => {
  const history = useHistory();
  const { flagTypeID } = params

  const [flagTypeName, setFlagTypeName] = useState('')
  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)

  const [flag, state] = useQuery({
    refresh,
    url: `/orders/flagtype/${flagTypeID}`
  });

  useEffect(() => {
    if (flag !== undefined) {
      setFlagTypeName(flag.name)
    }
  }, [flag])

  const updateFlagType = (e) => {
    e.preventDefault()
  
    if (flag === undefined) {
      superagent.post(`${backendUrl}/orders/createFlagType`)
        .send({ 
          name: flagTypeName, 
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/flagtype/edit/${response.body._id}`)
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.put(`${backendUrl}/order/flagtype/${flagTypeID}`)
        .send({ 
          name: flagTypeName, 
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.refresh()
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  return (
    <>
        <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
            <Breadcrumbs title="Ecommerce" breadcrumbItem={flagTypeID !== undefined ? 'Edit Flag Type' : 'New Flag Type'} />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="mt-6 text-lg mb-2">Flag Type Information</CardTitle>
                            <div>
                                <Row>
                                    <Col sm="6" className="sm:w-6/12">
                                        <div className='mb-4'>
                                            <label htmlFor="locationname">Name</label>
                                            <Input id="locationname" name="locationname" type="text" className="form-control" value={flagTypeName} onChange={(e) => setFlagTypeName(e.target.value)} />
                                        </div>                                        
                                    </Col>                                                                      
                                </Row>
                                <Button onClick={updateFlagType} type="submit" variant="success" className="mr-1 waves-effect waves-light">{flagTypeID === undefined ? 'Add' : 'Save Changes'} </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      </div>
    </>
  )
}

export default FlagTypeListPage
