import { Button } from "@shadcn/components/ui/button";

const SuccessStep = ({ onClose }) => {
  return (
    <div className="text-center py-8">
      <h3 className="text-2xl font-bold text-green-600 mb-4">
        Location Created Successfully!
      </h3>
      <p className="text-gray-600 mb-8">
        Your new location has been created and saved.
      </p>
      <Button onClick={onClose}>
        Close
      </Button>
    </div>
  );
};

export default SuccessStep;
