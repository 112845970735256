import ParcelForm from "./ParcelForm";
import useParcelStore from "@zustand/useParcelStore";
import { Row } from "@shadcn/components/ui/row";
import { Col } from "@shadcn/components/ui/col";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@shadcn/components/ui/table";
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useState } from "react";

const columnHelper = createColumnHelper()
const CreateParcelStep = () => {
  const { parcels } = useParcelStore()
  const { setParcels } = useParcelStore()
  const [editingParcel, setEditingParcel] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  
  const columns = [
    columnHelper.accessor('dimensions', { 
      header: 'Dimensions', 
      cell: ({row: {original}}) => 
        <div className="pl-2">
        {original.length} x ${original.width} x ${original.depth}
        </div>
      }),
    columnHelper.accessor('weight', { header: 'Weight', cell: ({row: {original}}) => <div className="pl-2">{original.weight}</div> }),
    columnHelper.accessor('declared_price', { header: 'Declared Price', cell: ({row: {original}}) => <div className="pl-2">{original.declared_price}</div> }),
    columnHelper.accessor('parcel_description', { header: 'Parcel Description', cell: ({row: {original}}) => <div className="pl-2">{original.parcel_description}</div> }),
    columnHelper.accessor('actions', { 
      header: 'Actions',
      cell: ({row: {original, index}}) => (
        <div className="flex gap-2 pl-6">
          <button
            onClick={() => handleEdit(original, index)}
            className="text-blue-500 hover:text-blue-700"
          >
            <i className="bx bx-pencil"></i>
          </button>
          <button 
            onClick={() => setParcels(parcels.filter((_, i) => i !== index))}
            className="text-red-500 hover:text-red-700"
          >
            <i className="bx bx-trash"></i>
          </button>
        </div>
      )
    }),
  ]

  const handleEdit = (parcel, index) => {
    setEditingParcel(parcel);
    setEditingIndex(index);
  }
  
  const handleSubmit = (data) => {
    if (editingIndex !== null) {
      const updatedParcels = [...parcels];
      updatedParcels[editingIndex] = data;
      setParcels(updatedParcels);
      setEditingParcel(null);
      setEditingIndex(null);
    } else {
      setParcels([...parcels, data]);
    }
  }

  const table = useReactTable({
    data: parcels,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Col>
     <ParcelForm onSubmit={handleSubmit} initialData={editingParcel} />
    <Row>
    <Table> 
      <TableHeader> 
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>{header.column.columnDef.header}</TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
   
    </Row>
   
    </Col>
  )
}

export default CreateParcelStep;
