import { Col } from '@shadcn/components/ui/col';

import { Input } from '@shadcn/components/ui/input';
import { Button } from '@shadcn/components/ui/button';
import Select from '@shadcn/components/ui/react-select';

import { Label } from '@shadcn/components/ui/label';
import { useState } from 'react';
import useProductStore from '@zustand/useProductStore';
import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts';

export default function ProductDetailStep({ onNext }) {
  const { 
    categories, 
    factoryOptions, 
    addCategory, 
    addFactory, 
    setSelectedProduct,
    productName,
    setProductName,
    selectedCategory,
    setSelectedCategory,
    selectedFactory,
    setSelectedFactory
  } = useProductStore();
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [showNewFactory, setShowNewFactory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newFactory, setNewFactory] = useState('');


  console.log("selectedCategory", selectedCategory, selectedFactory)
  const handleSaveCategory = () => {
    if (!newCategory) return;

    superagent.post(`${backendUrl}/product/productCategory`)
      .send({ name: newCategory })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .then(response => {
        if(response.status > 400){

        } else {
          console.log("response.body", response.body)
          addCategory(response.body);
          setSelectedCategory({...response.body, label: response.body.name});
          setShowNewCategory(false);
          setNewCategory('');
        }
      });
  };

  const handleSaveFactory = () => {
    if (!newFactory) return;

    superagent.post(`${backendUrl}/product/productFactory`)
      .send({ name: newFactory })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .then(response => {
        if(response.status > 400){

        } else {
          addFactory(response.body);
          setSelectedFactory({...response.body, label: response.body.name});
          setShowNewFactory(false);
          setNewFactory('');
        }
      });
  };


  
  return (
    <Col className="space-y-6 p-6">
      <div className="space-y-2">
        <Label htmlFor="productName">Product Name</Label>
        <Input
          id="productName"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          placeholder="Enter product name"
          className="w-full"
        />
      </div>

      <div className="space-y-2">
        <Label>Category</Label>
        {!showNewCategory ? (
          <div className="flex gap-2">
            <Select
              value={selectedCategory}
              onChange={(option) => setSelectedCategory({id: option.value, name: option.label, label: option.label})}
              options={categories.map((category) => ({
                value: category._id,
                label: category.name
              }))}
              className="flex-1"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: '1px solid black',
                  boxShadow: 'none',
                  '&:hover': {
                    border: '1px solid black',
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '200px',
                  overflowY: 'scroll'
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  backgroundColor: 'black',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: 'black',
                  '&:hover': {
                    color: 'black',
                  },
                }),
              }}
            />
            <Button 
              variant="outline"
              onClick={() => setShowNewCategory(true)}
            >
              Add New
            </Button>
          </div>
        ) : (
          <div className="space-y-4">
            <Input
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter new category name"
            />
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                onClick={() => setShowNewCategory(false)}
                className="flex-1"
              >
                Cancel
              </Button>
              <Button 
                className="flex-1"
                onClick={handleSaveCategory}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="space-y-2">
        <Label>Factory</Label>
        {!showNewFactory ? (
          <div className="flex gap-2">
            <Select
              value={selectedFactory}
              onChange={(option) => setSelectedFactory({id: option.value, name: option.label,label: option.label})}
              options={factoryOptions.map((factory) => ({
                value: factory._id,
                label: factory.name
              }))}
              className="flex-1"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: '1px solid black',
                  boxShadow: 'none',
                  '&:hover': {
                    border: '1px solid black',
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '200px',
                  overflowY: 'scroll'
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  backgroundColor: 'black',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: 'black',
                  '&:hover': {
                    color: 'black',
                  },
                }),
              }}
            />
            <Button 
              variant="outline"
              onClick={() => setShowNewFactory(true)}
            >
              Add New
            </Button>
          </div>
        ) : (
          <div className="space-y-4">
            <Input
              value={newFactory}
              onChange={(e) => setNewFactory(e.target.value)}
              placeholder="Enter factory name"
            />
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                onClick={() => setShowNewFactory(false)}
                className="flex-1"
              >
                Cancel
              </Button>
              <Button 
                className="flex-1"
                onClick={handleSaveFactory}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>


    </Col>
  );
}
