import  {  useState } from "react";

import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'
import { Button } from "@shadcn/components/ui/button";
import useQuery from '../../../helpers/JIFI/useQuery';
import { backendUrl } from "../../../helpers/consts";

const HistoricStockLevels = () => {
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }

  const [csvList, csvListState] = useQuery({
    url: `/inventory/listFiles`,
    refresh,
  });

  const handleDownload = (fileKey) => {
    const url = `${backendUrl}/inventory/getFile?key=${encodeURIComponent(fileKey)}`;

    fetch(url)
      .then(response => response.blob()) // Convert the response to a Blob
      .then(blob => {
        // Create a new URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileKey; // Set the filename for the download

        // Append the link to the body, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the blob URL after the download
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(err => console.error('Error downloading file:', err));
  };

  const user = JSON.parse(localStorage.getItem("authUser"));

  return <>
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <Card className='mb-6'>
              <div style={{margin: '0 12px'}}>
                <h4 className="card-title my-6">History Stock Levels</h4>
              </div>
              <CardBody>
                <div style={{
                  display: 'block',
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}>
                {
                  csvList ? (
                    <table className="table mb-0">
                        <thead style={{position: 'sticky', top: 0, background: 'white', }}>
                            <tr>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            csvList.map(line => {
                              return (
                                <tr>
                                  <td>{line.Key.replace('stocklevel-history/', '')}</td>
                                  <td> <Button onClick={() => handleDownload(line.Key)}> Download </Button> </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                    </table>
                  ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
                }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </>
}

export default HistoricStockLevels
