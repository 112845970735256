import { useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { ToastContainer } from 'react-toastify';

import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell, TableSkeleton } from '@shadcn/components/ui/table';
import { Input } from "@shadcn/components/ui/input";
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';

import { TablePagination } from "@components/supply-chain/TablePagination";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import SupplyChainNav from "@components/supply-chain/nav";
import ProductWizard from './components/ProductWizard';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';
import { useEffect } from 'react';
import useProductStore from '@zustand/useProductStore';


const columnHelper = createColumnHelper();

const ProductListPage = () => {
  const [refresh, setRefresh] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const [data, loading] = useQuery({ 
    refresh, 
    url: `/product/findAll`, 
    queryData: { 
      perPage, 
      page, 
      searchTerm 
    } 
  });

  const { setFactories, setCategories, reset: resetProductStore  } = useProductStore();

  useEffect(() => {
    superagent
      .post(`${backendUrl}/product/productFactories`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
       
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        
        } else {
          setFactories(response.body);
         
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    superagent
      .get(`${backendUrl}/product/productCategory`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then((response) => {
        if (response.status === 200) {
          
          setCategories(response?.body);
          
        }
      });
  }, []);

  const productColumns = useMemo(
    () => [
      columnHelper.accessor('image', {
        header: () => 'Image',
        cell: ({ row }) => (
          <div className="w-12 h-12">
            {row.original.image ? (
              <img 
                src={row.original.image} 
                alt={row.original.name}
                className="w-full h-full object-cover rounded-md"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                <span className="text-gray-400 text-xs">No image</span>
              </div>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: ({ row }) => (
          <Link
            className="text-body font-weight-bold px-2 cursor-pointer"
            to={`/product/edit/${row.original._id}`}
          >
            {row.original.name}
          </Link>
        ),
      }),
      columnHelper.accessor('category', {
        header: () => 'Category',
        cell: ({ row }) => <div className='px-2'>{row.original.category}</div>,
      }),
      columnHelper.accessor('variants', {
        header: () => 'Variants Count',
        cell: ({ row }) => <Link to={`/variant/all?productID=${row.original._id}`} className='px-2'>{row.original.variants?.length || 0} variants</Link>,
      }),
      
      
      columnHelper.accessor('isArchived', {
        header: () => <div >Status</div>,
        cell: ({ row }) => (
          <div>
            <span className={`px-2 py-1 rounded-full text-xs ${
              row.original.isArchived 
                ? 'bg-red-100 text-red-800' 
                : 'bg-green-100 text-green-800'
            }`}>
              {row.original.isArchived ? 'Archived' : 'Active'}
            </span>
          </div>
        ),
      }),
    ],
    []
  );

  const productTable = useReactTable({
    data: data?.data || [],
    columns: productColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <SupplyChainNav menuType="products">
      <div>
        <div>
          <Card className="mb-10">        
            <CardBody className="pt-4">
              <Breadcrumbs title="Products" breadcrumbItem="All Products" />
              <Row className='justify-between'>
                <Col className="w-3/12 mb-4">
                  <div className="select2-container">
                    <label className="control-label">FILTER</label>
                    <div className="search-box mb-2 d-block">
                      <Input
                        type="text"
                        className="form-control h-[32px] py-1 rounded-md h-9"
                        placeholder="Search..."
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPage(1);
                            setSearchTerm(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <div className="flex justify-between mb-4">
                <ProductWizard />
                </div>
              </Row>

              <div className="table-responsive">
                <Table>
                  <TableHeader>
                    {productTable.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHead key={header.id} className="text-left">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  {loading !== 'success' ? (
                    <TableSkeleton perPage={perPage} columns={productColumns.length} />
                  ) : (
                    <TableBody>
                      {productTable?.getRowModel().rows?.length ? (
                      productTable?.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={productColumns.length} className="text-center">
                          No results.
                        </TableCell>
                      </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                count={data?.count}
                className="my-4"
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
        </div>
        <ToastContainer />
      </div>
    </SupplyChainNav>
  );
}

export default ProductListPage;
