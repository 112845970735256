import create from 'zustand';

const useVariantStore = create((set) => ({
  variants: [],
  deletedVariants: [],
  editedVariants: [],
  setInvalidVariants: [],
  setVariants: (variants) => set({ variants }),
  resetState: () => set({
    variants: [],
  })
}));

export default useVariantStore;
