import React, { Component } from "react";


import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Link } from "react-router-dom";


import CommentHistory from './CommentHistory'
import { Card, CardContent } from "@shadcn/components/ui/card";

const WelcomeComp = ({ stateData, dashboard, monogramData }) => {
    const { newOrders, corp } = stateData;
    const user = JSON.parse(localStorage.getItem("authUser"));
    return (
        <React.Fragment>
            <Card className="overflow-hidden border-solid border-[1av] border-black rounded-md">
                <div className="bg-black">
                    <Row>
                        <Col>
                            <div className="text-primary p-3 mb-0">
                                <h5 className="text-white text-lg font-medium text-center">Welcome Back {user.userName}</h5>
                            </div>
                        </Col>
                    </Row>
                </div>
                <CardContent className="pt-0">
                    <Row style={{borderBottom: '1px solid #eff2f7'}}>
                        <Col sm="2" className="w-2/12">
                            <div className="avatar-md profile-user-wid mb-4">
                                <img  style={{objectFit: 'cover', width: 64, height: 64}} src={user.profile_image?.replace('.com//', '.com/')} alt="" className="img-thumbnail rounded-circle" />
                            </div>
                        </Col>
                        <Col sm="10" className="w-10/12">
                            <div className="pt-4 pb-4" >
                                <Row>
                                    <Col xs="4" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Link to="/orders">
                                            <h5 className={`font-size-15 text-center ${dashboard ? '' : 'placeholder'}`}>{dashboard ? newOrders : ' '}</h5>
                                            <p className="text-slateGray mb-0">New Orders</p>
                                        </Link>
                                    </Col>
                                    <Col xs="6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Link to="/corp-orders">
                                            <h5 className={`font-size-15 text-center ${dashboard ? '' : 'placeholder'}`}>{dashboard ? corp : ' '}</h5>
                                            <p className="text-slateGray mb-0">Corporate Orders</p>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <CommentHistory title="Message Board" initCommentType={'Dashboard'} />
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        </React.Fragment>
    )

}

export default WelcomeComp;
