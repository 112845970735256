import { getFulfillmentBadgeColor, getPaymentBadgeColor } from '../utils/badgeColors';

export const StatusBadge = ({ status, type = 'fulfillment' }) => {
  const colors = type === 'fulfillment' 
    ? getFulfillmentBadgeColor(status)
    : getPaymentBadgeColor(status);
  return (
    <span 
      className={`
        inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium  ${colors.backgroundColor} ${colors.textColor} border ${colors.borderColor}`}
    >
      {status?.replace(/_/g, ' ').toUpperCase()}
    </span>
  );
};
