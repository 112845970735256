import { useEffect, useState } from 'react';
import useQuery from '../../../../helpers/JIFI/useQuery';
import { Input } from '@shadcn/components/ui/input';
import { Button } from '@shadcn/components/ui/button';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import {
  Card,
  CardContent as CardBody,

} from '@shadcn/components/ui/card';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import classnames from 'classnames';
import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts.js';

import Select from '@shadcn/components/ui/react-select';
import SupplyChainNav from '@components/supply-chain/nav';
import { TableSkeleton } from '@shadcn/components/ui/table';
import { Tooltip , TooltipContent, TooltipTrigger, TooltipProvider} from '@shadcn/components/ui/tooltip';

const variantSelectOptions = [
  'Mark as unavaliable site once Buffer is reached',
  'Keep selling, even when OOS',
  'Sell as preorder',
  'Take off site completely',
];

const valueLookups = {
  AU: {
    no_reorder: 'no_reorder',
    isArchived: 'isArchived',
    stock_buffer: 'stock_buffer',
    oos_message: 'oos_message',
    continue_selling: 'continue_selling',
    discontinued: 'discontinued',
    preorder: 'preorder',
    preorder_buffer: 'preorder_buffer',
    pause_preorder: 'pause_preorder',
  },
  US: {
    no_reorder: 'no_reorder',
    isArchived: 'isArchived',
    stock_buffer: 'stock_buffer_us',
    oos_message: 'oos_message_us',
    continue_selling: 'continue_selling_us',
    discontinued: 'discontinued_us',
    preorder: 'preorder_us',
    allow_au: 'us_allow_au',
    preorder_buffer: 'preorder_buffer_us',
    pause_preorder: 'pause_preorder_us',
  },
  UK: {
    no_reorder: 'no_reorder',
    isArchived: 'isArchived',
    stock_buffer: 'stock_buffer_uk',
    oos_message: 'oos_message_uk',
    continue_selling: 'continue_selling_uk',
    discontinued: 'discontinued_uk',
    preorder: 'preorder_uk',
    allow_au: 'uk_allow_au',
    preorder_buffer: 'preorder_buffer_uk',
    pause_preorder: 'pause_preorder_uk',
  },
};

const switchStringToBools = (lookupString) => {
  const lookUp = {
    'Mark as unavaliable site once Buffer is reached': {
      continue_selling: false,
      discontinued: false,
      preorder: false,
    },
    'Keep selling, even when OOS': {
      continue_selling: true,
      discontinued: false,
      preorder: false,
    },
    'Sell as preorder': {
      continue_selling: false,
      discontinued: false,
      preorder: true,
    },
    'Take off site completely': {
      continue_selling: false,
      discontinued: true,
      preorder: false,
    },
  };
  return lookUp[lookupString];
};

const switchBoolsToString = (continue_selling, discontinued, preorder) => {
  if (continue_selling == false && discontinued == false && preorder == false) {
    return 'Mark as unavaliable site once Buffer is reached';
  }
  if (continue_selling == true && discontinued == false && preorder == false) {
    return 'Keep selling, even when OOS';
  }
  if (continue_selling == false && discontinued == false && preorder == true) {
    return 'Sell as preorder';
  }
  if (continue_selling == false && discontinued == true && preorder == false) {
    return 'Take off site completely';
  }
};

export const VariantTableLine = ({
  variant,
  locale,
  setVariant,
  lineIndex,
  }) => {
  const getValue = (key) => {
    return variant[valueLookups[locale][key]];
  };
  const setValue = (key, value) => {
    setVariant({
      ...variant,
      [valueLookups[locale][key]]: value,
    });
  };
  const setValues = (newFields) => {
    let localisedNewFields = {};
    Object.keys(newFields).forEach((key) => {
      localisedNewFields[valueLookups[locale][key]] = newFields[key];
    });
    setVariant({
      ...variant,
      ...localisedNewFields,
    });
  };
  const oosString = switchBoolsToString(
    getValue('continue_selling'),
    getValue('discontinued'),
    getValue('preorder')
  );
  const handleOOSSelectChange = (e) => {
    const { continue_selling, discontinued, preorder } = switchStringToBools(
      e.value
    );

    setValues({
      continue_selling: continue_selling,
      discontinued: discontinued,
      preorder: preorder,
    });
  };

  const handleOOSMessageChange = (e) => {
    setValues({
      oos_message: e.target.value,
    });
  };

  const handleBufferChange = (e) => {
    setValues({
      stock_buffer: Number(e.target.value) || 0,
    });
  };

  const handlePreorderBufferChange = (e) => {
    setValues({
      preorder_buffer: Number(e.target.value) || 0,
    });
  };

  const handlePausePreorderChange = (e) => {
    setValues({
      pause_preorder: !getValue('pause_preorder'),
    });
  };

  const handleIsArchived = (e) => {
    setValues({
      isArchived: !getValue('isArchived'),
    });
  };

  const handleNoReorder = (e) => {
    setValues({
      no_reorder: !getValue('no_reorder'),
    });
  };

  const handleAllowAU = (e) => {
    setValues({
      allow_au: !getValue('allow_au'),
    });
  };

  const useStockBuffer =
    !getValue('continue_selling') &&
    !getValue('discontinued') &&
    !getValue('preorder');
  const usePreorderBuffer = getValue('preorder');

  return (
    <tr
      id={`variantLine-${lineIndex}`}
      key={lineIndex}
      style={variant.changed === true ? { background: '#ccc' } : {}}
    >
      <td>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <a  className='font-medium' href={ variant?.product ? `/product/edit/${variant.product}/variant/edit/${variant._id}` : '#'}>{variant.sku}</a>
          </TooltipTrigger>
          <TooltipContent>
            <div className="w-24 h-24">
             {variant.image ?
             
             <img 
                src={variant.image} 
                alt={variant.name}
                className="w-full h-full object-cover rounded-md"
              />
             : 
             <div className="w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
             <span className="text-gray-400 text-xs">No image</span>
           </div>}
             </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      </td>
      <td>
        <div className="mb-4">
          
          <Input
            value={getValue('oos_message')}
            onChange={handleOOSMessageChange}
            id="oosMessage"
            name="oosMessage"
            type="text"
            className="form-control"
          />
        </div>
      </td>
      <td>
        <div className="mb-4">
          {useStockBuffer && (
            <input
              value={getValue('stock_buffer')}
              onChange={handleBufferChange}
              id="buffer"
              type="number"
              step={1}
              min={0}
              className="form-control border-black"
            />
          )}
          {usePreorderBuffer && (
            <input
              value={getValue('preorder_buffer')}
              onChange={handlePreorderBufferChange}
              id="preorderbuffer"
              type="number"
              step={1}
              min={0}
              className="form-control"
            />
          )}
        </div>
      </td>
      <td>
        <div className="mb-4" style={{ minWidth: 200 }}>
          
          <Select
            value={{ label: oosString, value: oosString }}
            options={variantSelectOptions.map((val) => ({
              label: val,
              value: val,
            }))}
            onChange={handleOOSSelectChange}
            styles={{
              control: (provided) => ({
                ...provided,
                border: '1px solid black',
                boxShadow: 'none',
                '&:hover': {
                  border: '1px solid black',
                },
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: 'black',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: 'black',
                '&:hover': {
                  color: 'black',
                },
              }),
            }}
          />
        </div>
      </td>
      <td>
        <div
          className="mb-4"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          
          <Input
            id="isArchived"
            name="isArchived"
            type="checkbox"
            className="w-3 h-3"
            onChange={handlePausePreorderChange}
            checked={getValue('pause_preorder')}
          />
        </div>
      </td>
      <td>
        <div
          className="mb-4"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          
          <Input
            id="isArchived"
            name="isArchived"
            type="checkbox"
            className="w-3 h-3"
            onChange={handleIsArchived}
            checked={getValue('isArchived')}
          />
        </div>
      </td>

      <td>
        <div
          className="mb-4"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          
          <Input
            id="noReorder"
            name="noReorder"
            type="checkbox"
            className="w-3 h-3"
            onChange={handleNoReorder}
            checked={getValue('no_reorder')}
          />
        </div>
      </td>

      {locale !== 'AU' && (
        <td>
          <div
            className="mb-4"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            
            <Input
              id="isArchived"
              name="isArchived"
              type="checkbox"
              className="w-3 h-3"
              onChange={handleAllowAU}
              checked={getValue('allow_au')}
            />
          </div>
        </td>
      )}
    </tr>
  );
};

const saveVariant = (variant) => {
  return new Promise((resolve, reject) => {
    superagent
      .put(`${backendUrl}/product/variant/${variant._id}`)
      .send({
        no_reorder: variant.no_reorder,
        stock_buffer: variant.stock_buffer,
        continue_selling: variant.continue_selling,
        discontinued: variant.discontinued,
        oos_message: variant.oos_message,
        preorder: variant.preorder,

        stock_buffer_us: variant.stock_buffer_us,
        continue_selling_us: variant.continue_selling_us,
        discontinued_us: variant.discontinued_us,
        oos_message_us: variant.oos_message_us,
        preorder_us: variant.preorder_us,

        stock_buffer_uk: variant.stock_buffer_uk,
        continue_selling_uk: variant.continue_selling_uk,
        discontinued_uk: variant.discontinued_uk,
        oos_message_uk: variant.oos_message_uk,
        preorder_uk: variant.preorder_uk,

        us_allow_au: variant.us_allow_au,
        uk_allow_au: variant.uk_allow_au,
        isArchived: variant.isArchived,

        preorder_buffer: variant.preorder_buffer,
        preorder_buffer_us: variant.preorder_buffer_us,
        preorder_buffer_uk: variant.preorder_buffer_uk,

        pause_preorder: variant.pause_preorder,
        pause_preorder_us: variant.pause_preorder_us,
        pause_preorder_uk: variant.pause_preorder_uk,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        resolve({
          _id: variant._id,
          status: 'error',
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          resolve({
            _id: variant._id,
            status: 'error',
          });
        } else {
          resolve({
            _id: variant._id,
            status: 'success',
          });
        }
      })
      .catch((err) => {
        resolve({
          _id: variant._id,
          status: 'error',
        });
      });
  });
};

const VariantBulkEditor = ({ skus, productID, showTitle = true }) => {
  const [refresh, setRefresh] = useState(true);
  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  const [variantsRaw, variantsState] = useQuery({
    refresh,
    url: `/product/findAllVariantsFull`,
    queryData: {
      skus: skus,
      productID: productID,
    },
  });

  const [variants, setVariants] = useState([]);
  useEffect(() => {
    if (variantsRaw) {
      setVariants(variantsRaw);
    }
  }, [variantsRaw]);

  const [loacaleTab, setLocaleTab] = useState('AU');
  const changedCount = variants.filter((val) => val.changed === true).length;

  const [loadingSend, setLoadingSend] = useState(false);

  const handleSubmit = async (e) => {
    if (loadingSend === true) return;
    setLoadingSend(true);

    const changedVariants = variants.filter((val) => val.changed === true);

    console.log(changedVariants);

    let updatedPromises = changedVariants.map((val) => saveVariant(val));
    let variantsUpdated = await Promise.all(updatedPromises);
    console.log(variantsUpdated);
    refreshData();
    setLoadingSend(false);
  };
  
  if(showTitle === false && !productID) {
    return <>No Data</>
  }



  return showTitle ? (
    <SupplyChainNav menuType="products">
      <BulkEditVariantsTable
        {...{
          changedCount,
          loadingSend,
          variantsRaw,
          variants,
          handleSubmit,
          loacaleTab,
          setLocaleTab,
          showTitle,
          variantsState,
          setVariants,
        }}
      />
    </SupplyChainNav>
  ) : (
    <BulkEditVariantsTable
      {...{
        changedCount,
        showTitle,
        loadingSend,
        variantsRaw,
        variants,
        handleSubmit,
        loacaleTab,
        setLocaleTab,
        showTitle,
        variantsState,
        setVariants,
      
      
      }}
    />
  );
};

const BulkEditVariantsTable = ({
  changedCount,
  loadingSend,
  variantsRaw,
  variants,
  handleSubmit,
  loacaleTab,
  setLocaleTab,
  showTitle = true,
  variantsState,
  setVariants,

}) => {
  console.log("loacaleTab", loacaleTab);
  return (
    
      <Row className="w-full">
        {changedCount > 0 && (
          <Button
            variant="success"
            onClick={handleSubmit}
            style={{
              position: 'fixed',
              right: 32,
              bottom: 48,
              paddingLeft: 48,
              paddingRight: 48,
              zIndex: 99,
            }}
            className={`btn-lg btn-rounded btn btn-success px-6 ${
              loadingSend ? 'disabled' : ''
            }`}
          >
            {loadingSend
              ? variantsRaw === undefined
                ? 'Loading Audits...'
                : 'Submitting...'
              : `Submit ${changedCount}`}
          </Button>
        )}
        <Row className="w-full">
          <Col xs="12">
            <Card className="pt-6 mb-10">
              <CardBody>
               {showTitle && <Breadcrumbs
                  title="Ecommerce"
                  breadcrumbItem={'Bulk Edit Variants'}
                />
               }

                <Row>
                  <Col sm="12">
                    <ul className="flex justify-around pt-4 nguyen221">
                      {['AU', 'US', 'UK'].map((locale) => (
                        <li key={locale} className="w-full text-center">
                          <div
                            style={{ cursor: 'pointer' }}
                            className={classnames(
                              'pb-2', 
                              loacaleTab === locale
                                ? 'border-b-4 border-primary'
                                : '' 
                            )}
                            onClick={() => {
                              setLocaleTab(locale);
                            }}
                          >
                            <span className="d-none d-sm-block">{locale}</span>
                            <span className="d-block d-sm-none"></span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="w-full">
                    <table className="vert-hover">
                      <thead
                        style={{
                          position: 'sticky',
                          top: 123,
                          background: 'white',
                          zIndex: 10,
                        }}
                      >
                        <tr>
                          <th> SKU </th>
                          <th> OOS Message </th>
                          <th> Buffer Amount </th>
                          <th> OOS Options </th>
                          <th> Pause Preorder </th>
                          <th> Archived </th>
                          <th> Do not reorder </th>
                          {loacaleTab !== 'AU' && <th> Allow AU Stock </th>}
                        </tr>
                      </thead>
                      {variantsState === 'loading' ? (
                        <TableSkeleton columns={7} perPage={4} />
                      ) : (
                        variants &&
                        variants.map((variant, index) => {
                          const setVariant = (variant) => {
                            setVariants((prev) => {
                              let newVariants = [...prev];
                              return newVariants.map((v, i) => {
                                if (i === index) {
                                  return {
                                    ...variant,
                                    changed: true,
                                  };
                                } else {
                                  return v;
                                }
                              });
                            });
                          };
                          if (variant.sku === '') {
                            return <></>;
                          }
                          return (
                            <VariantTableLine
                              lineIndex={index}
                              locale={loacaleTab}
                              variant={variant}
                              setVariant={setVariant}
                              
                            />
                          );
                        })
                      )}
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Row>
    
  );
};

export default VariantBulkEditor;
