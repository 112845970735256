import React, { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { navBarMenu } from '../supply-chain/nav-menu';

import { withNamespaces } from 'react-i18next';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  
} from '@shadcn/components/ui/navigation-menu';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@shadcn/components/ui/dropdown-menu';
import { useWebSocketToken } from '@helpers/JIFI/useWebSocketToken';




const Navbar = (props) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('authUser')) || {}
  );

  const token = useWebSocketToken()
  const location = useLocation();

  const fulfillmentRoutes = [
    '/orders',
    '/corp-orders',
    '/awaiting-stock',
    '/eiz',
    '/returns',
    '/order/create',
  ];

  
  const isActive = (routes) =>
    routes.some((route) => location.pathname.includes(route));

  const getDefaultNavItem = (type, permissions = []) => {
    const permission = permissions.find((val) =>
      user.permissions.includes(val)
    );
    return navBarMenu[type]?.find((val) => val.permission === permission);
  };


  const [isFulfillmentOpen, setFulfillmentOpen] = useState(false);
  const [isStockLevelOpen, setStockLevelOpen] = useState(false);
  const [isInventoryOpen, setInventoryOpen] = useState(false);
  const [isFinancialOpen, setFinancialOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);


  const toggleFulfillment = () => setFulfillmentOpen(!isFulfillmentOpen);
  const toggleStockLevel = () => setStockLevelOpen(!isStockLevelOpen);
  const toggleInventory = () => setInventoryOpen(!isInventoryOpen);
  const toggleFinancial = () => setFinancialOpen(!isFinancialOpen);
  const toggleSettings = () => setSettingsOpen(!isSettingsOpen);

  const renderNavItem = React.useCallback(
    (permission, label, path) => {
      if (!permission || user.permissions.includes(permission)) {
        return (
          <DropdownMenuItem asChild key={path}>
            <Link
              className="text-white ml-1 hover:text-gray-300 pl-6 !text-13px"
              to={path}
            >
              {label}
            </Link>
          </DropdownMenuItem>
        );
      }
      return null;
    },
    [user.permissions]
  );

  const renderDesktopNavItem = React.useCallback((permission, label, path) => {
    if (!permission || user.permissions.includes(permission)) {
      return (
        <li key={path}>
          <NavigationMenuLink
          className='block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-900 min-w-[20vw]'
          asChild>
            <Link className="break-normal text-white" to={path}>
              {label}
            </Link>
          </NavigationMenuLink>
        </li>
      );
    }
    return null;
  }, [user.permissions]);

  const fulfilmentMenuItems = React.useMemo(
    () => [
      { permission: null, label: 'Ecommerce Orders', path: '/orders' },
      { permission: null, label: 'Corporate Orders', path: '/corp-orders' },
      { permission: null, label: 'EIZ Manifests', path: '/eiz' },

      {
        permission: 'CreateOrder',
        label: 'Create Order',
        path: '/order/create',
      },
      {
        permission: null, label: 'Personalisation Batches', path: getDefaultNavItem('fulfillment-location', ['UNISPersonalisation', 'LaunchPersonalisation', 'ViewOrders', 'AirboxPersonalisation'])?.url,
      },

      { permission: null, label: 'Order Tickets', path: '/order-tickets' },
      { permission: null, label: 'Feedback', path: '/fulfillment/feedback' },
      { permission: null, label: 'Warranty Claim', path: '/warranty-claim' },
      {permission: null, label: 'Warranty Board', path: '/warranty-board'}
    ],
    []
  );


  const stockMenuItems = React.useMemo(
    () => [
      {
        permission: 'ViewTotalCount',
        label: 'Total Stock',
        path: '/all-stock',
      },
      {
        permission: 'ViewContainer',
        label: 'Upcoming Containers',
        path: '/upcoming-container',
      },
      {
        permission: 'ViewTotalCount',
        label: 'Stock Warnings',
        path: '/stock-warning',
      },
      {
        permission: 'ViewOrders',
        label: 'Awaiting Stock Summary',
        path: '/awaiting-stock',
      },
    ],
    []
  );

  const inventoryMenuItems = React.useMemo(
    () => [
      { permission: 'CreateApproval', label: 'Stock Audit', path: '/stock' },
      {
        permission: 'TransferStock',
        label: 'Transfer Stock',
        path: '/stock/transfer',
      },
      {
        permission: 'BookStockDrop',
        label: 'Store Replenishments',
        path: '/stock/replenish',
      },
      // {
      //   permission: 'BookStockDrop',
      //   label: 'Warehouse Transfers',
      //   path: '/stock/warehouse-transfer',
      // },
      {
        permission: 'ViewMovementHistory',
        label: 'Movement History',
        path: '/movement-history',
      },
    ],
    []
  );

  const financeMenuItems = React.useMemo(() => [
    { permission: 'ViewApprovals', label: 'Audit Approvals', path: '/approvals' },
    { permission: 'ViewDemand', label: 'Forecast', path: '/forecast' },
    { permission: 'ViewDemand', label: 'Historic Stock Status', path: '/demand/stock-status' },
    { permission: 'ViewDemand', label: 'Historic Stock Status Months', path: '/demand/stock-status-month' },
    { permission: 'ViewDemand', label: 'Mis-Picked Orders', path: '/mispick-stock' },
  ], []);


  const settingMenuItems = React.useMemo(() => [
    { permission: 'EditProductVariant', label: 'Products', path: '/product/all' },
    { permission: 'EditLocations', label: 'Locations', path: '/location/all' },
    { permission: 'EditUser', label: 'Users', path: '/users/all' },
    { permission: 'EditReturns', label: 'Parcels', path: '/parcels' },
    // { permission: 'EditFlagType', label: 'Flag Type', path: '/flagtype/all' },
    // { permission: null, label: 'COG', path: '/cog' },  // No permission required
    { permission: 'ViewFactories', label: 'Factories', path: '/factories' },
    { permission: null, label: 'Categories', path: '/category/all' },

    { permission: 'ViewPurchaseOrders', label: 'Purchase Orders', path: '/purchase-order' },
    { permission: 'ViewErrorLog', label: 'Error Logs', path: '/error-logs/all' },
  ], []);

  return (
    <div style={{  top: 0 }} className="hidden lg:block bg-black">
      <div>
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu py-[7px]"
          id="navigation"
        >
          <DropdownMenu
            modal={false}
            className="hidden lg:block"
            open={props.menuOpen}
            
          >
            <DropdownMenuTrigger className="sm:hidden text-white  duration-300">
              
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              className="lg:hidden bg-black p-4 shadow-lg rounded-lg max-h-64 !overflow-y-auto w-[100vw] fixed top-[70px]"
            >
              <ul>
                {/* Dashboard */}
                <DropdownMenuItem asChild>
                  <Link
                    className={`text-white hover:text-gray-300 `}
                    to="/dashboard"
                  >
                    
                    {props.t('Dashboard')}
                  </Link>
                </DropdownMenuItem>

                {/* Fulfillment Dropdown - Mobile */}
                {(user.permissions.includes('ViewOrders') ||
                  user.permissions.includes('ViewDeliveries') ||
                  user.permissions.includes('CreateOrder') ||
                  user.permissions.includes('UNISPersonalisation') ||
                  user.permissions.includes('AirboxPersonalisation')) && (
                  <>
                    
                    {/* Fulfillment toggle */}
                    <DropdownMenuItem
                      asChild
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="text-white  hover:text-gray-300 flex justify-between items-center cursor-pointer"
                        onClick={toggleFulfillment}
                      >
                        <span>
                          Fulfillment
                        </span>
                        <i
                          className={`bx ${
                            isFulfillmentOpen
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          }`}
                        ></i>
                      </div>
                    </DropdownMenuItem>

                    {/* Sub-menu for Fulfillment */}
                    {isFulfillmentOpen && (
                      fulfilmentMenuItems.map(({ permission, label, path }) =>
                        renderNavItem(permission, label, path)
                      )
                    )}
                  </>
                )}

                {/* Total Stock Mobile */}

                {(user.permissions.includes('ViewTotalCount') ||
                  user.permissions.includes('ViewContainer')) && (
                  <>
                    {/* Total Stock toggle */}
                    <DropdownMenuItem
                      asChild
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="text-white  hover:text-gray-300 flex justify-between items-center cursor-pointer"
                        onClick={toggleStockLevel}
                      >
                        <span>
                         Stock Levels
                        </span>
                        <i
                          className={`bx ${
                            isStockLevelOpen
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          }`}
                        ></i>
                      </div>
                    </DropdownMenuItem>

                    {/* Sub-menu for Stock Levels */}
                    {isStockLevelOpen && (
                        stockMenuItems.map(({ permission, label, path }) =>
                            renderNavItem(permission, label, path)
                        )
                    )}
                  </>
                )}

                {/* Inventory Mobile */}

                {(user.permissions.includes('CreateApprovals') ||
                  user.permissions.includes('TransferStock') ||
                  user.permissions.includes('BookStockDrop') ||
                  user.permissions.includes('ViewMovementHistory')) && (
                  <>
                    {/* Inventory toggle */}
                    <DropdownMenuItem
                      asChild
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="text-white  hover:text-gray-300 flex justify-between items-center cursor-pointer"
                        onClick={toggleInventory}
                      >
                        <span>
                          Inventory
                        </span>
                        <i
                          className={`bx ${
                            isStockLevelOpen
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          }`}
                        ></i>
                      </div>
                    </DropdownMenuItem>

                    {/* Sub-menu for Inventory*/}
                    {isInventoryOpen && (
                        inventoryMenuItems.map(({ permission, label, path }) =>
                            renderNavItem(permission, label, path)
                        )   
                    )}
                  </>
                )}


                {/* Financial Mobile */}

                {(user.permissions.includes('ViewApprovals') ||
                  user.permissions.includes('CreateASN') ||
                  user.permissions.includes('ViewDemand')) && (
                  <>
                    {/* Financial toggle */}
                    <DropdownMenuItem
                      asChild
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="text-white  hover:text-gray-300 flex justify-between items-center cursor-pointer"
                        onClick={toggleFinancial}
                      >
                        <span>
                          Financial
                        </span>
                        <i
                          className={`bx ${
                            isStockLevelOpen
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          }`}
                        ></i>
                      </div>
                    </DropdownMenuItem>

                    {/* Sub-menu for Financial*/}
                    {isFinancialOpen && (
                        financeMenuItems.map(({ permission, label, path }) =>
                            renderNavItem(permission, label, path)
                        )   
                    )}
                  </>
                )}



                {/* Setting Mobile */}

                {(
                 user.permissions.includes('EditProductVariant') ||
                  user.permissions.includes('EditLocations') ||
                  user.permissions.includes('EditUser') ||
                  user.permissions.includes('ViewFactories') ||
                  user.permissions.includes('ViewPurchaseOrders')) && (
                  <>
                    {/* Setting toggle */}
                    <DropdownMenuItem
                      asChild
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="text-white  hover:text-gray-300 flex justify-between items-center cursor-pointer"
                        onClick={toggleSettings}
                      >
                        <span>
                          Settings
                        </span>
                        <i
                          className={`bx ${
                            isStockLevelOpen
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          }`}
                        ></i>
                      </div>
                    </DropdownMenuItem>

                    {/* Sub-menu for Setting*/}
                    {isSettingsOpen && (
                        settingMenuItems.map(({ permission, label, path }) =>
                            renderNavItem(permission, label, path)
                        )   
                    )}
                  </>
                )}
              </ul>
            </DropdownMenuContent>
          </DropdownMenu>
          {/* desktop */}
          <NavigationMenu  className="hidden md:flex">
            <NavigationMenuList className="flex flex-row py-2">
              <NavigationMenuItem>
                <Link
                  className={`text-white bg-transparent  px-3 text-[14px] ${location.pathname === '/dashboard' ? 'opacity-90' : 'opacity-60'}`}
                  to="/dashboard"
                >
                  
                  {props.t('Dashboard')}
                </Link>
              </NavigationMenuItem>

              {/* Fulfillment Menu */}
              {(user.permissions.includes('ViewOrders') ||
                user.permissions.includes('ViewDeliveries') ||
                user.permissions.includes('CreateOrder') ||
                user.permissions.includes('UNISPersonalisation') ||
                user.permissions.includes('AirboxPersonalisation')) && (
                <NavigationMenuItem  className="bg-transparent">
                  <NavigationMenuTrigger
                  
                    className={`text-white bg-transparent font-normal px-3 
                      ${isActive(fulfilmentMenuItems.map(({ path }) => path)) ? 'opacity-90' : 'opacity-60'}`}
                    style={{  }}
                  >
                     Fulfillment
                  </NavigationMenuTrigger>
                  <NavigationMenuContent  className=" bg-black p-4 shadow-lg rounded-lg  left-0">
                    <ul className="">
                      {fulfilmentMenuItems.map(({ permission, label, path }) =>
                        renderDesktopNavItem(permission, label, path)
                      )}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )}

              {/* Stock Levels Menu */}
              {(user.permissions.includes('ViewTotalCount') ||
                user.permissions.includes('ViewContainer')) && (
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className={`text-white bg-transparent font-normal px-3
                         ${isActive(stockMenuItems.map(({ path }) => path)) ? 'opacity-90' : 'opacity-60'}`}
                    style={{  }}
                  >
                     Stock Levels
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-black p-4 shadow-lg rounded-lg w-fit left-0">
                    <ul className="w-fit">
                      {stockMenuItems.map(({ permission, label, path }) =>
                        renderDesktopNavItem(permission, label, path)
                      )}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )}

              {/* Inventory Menu */}
              {(user.permissions.includes('CreateApproval') ||
                user.permissions.includes('TransferStock') ||
                user.permissions.includes('BookStockDrop') ||
                user.permissions.includes('ViewMovementHistory')) && (
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                     className={`text-white opacity-60 bg-transparent font-normal px-3
                      ${isActive(inventoryMenuItems.map(({ path }) => path)) ? 'opacity-90' : 'opacity-60'}`}
                    style={{  }}
                  >
                     Inventory
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-black p-4 shadow-lg rounded-lg left-0">
                    <ul className="">
                      {inventoryMenuItems.map(({ permission, label, path }) =>
                        renderDesktopNavItem(permission, label, path)
                      )}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )}

              {/* Finance Menu */}
              {(user.permissions.includes('ViewApprovals') ||
                user.permissions.includes('CreateASN') ||
                user.permissions.includes('ViewDemand')) && (
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className={`text-white opacity-60 bg-transparent  font-normal px-3
                         ${isActive(financeMenuItems.map(({ path }) => path)) ? 'opacity-90' : 'opacity-60'}`}
                    style={{  }}
                  >
                     Finance
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-black p-4 shadow-lg rounded-lg  left-0">
                    <ul>
                      {financeMenuItems.map(({ permission, label, path }) =>
                        renderDesktopNavItem(permission, label, path)
                      )}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )}

              {/* Settings Menu */}
              {(user.permissions.includes('EditProductVariant') ||
                user.permissions.includes('EditLocations') ||
                user.permissions.includes('EditUser') ||
                user.permissions.includes('ViewFactories') ||
                user.permissions.includes('ViewPurchaseOrders')) && (
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className={`text-white opacity-60 bg-transparent  font-normal px-3
                         ${isActive(settingMenuItems.map(({ path }) => path)) ? 'opacity-90' : 'opacity-60'}`}
                    style={{  }}
                  >
                    Settings
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-black p-4 shadow-lg rounded-lg  left-0">
                    <ul className="">
                      {settingMenuItems.map(({ permission, label, path }) =>
                        renderDesktopNavItem(permission, label, path)
                      )}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              )}
            </NavigationMenuList>
            
          </NavigationMenu>
        </nav>
      </div>
    </div>
  );
};

export default withNamespaces()(Navbar);
