import  { useRef, useState,useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  
  Row,
} from '@shadcn/components/ui/row';
import superagent from 'superagent';

import { backendUrl } from '../../../../helpers/consts.js';
import "../../datatables.scss";
import useProductData from '../useProductData';
import OrderWizardDeleteSKU from './deleteWizard.js';
import OrderWizardEditModalShipping from './editAddressWizard.js';
import OrderWizardSKUchange from './editLineItemWizard.js';
import OrderWizardFlaggedModal from './flagWizard.js';
// import OrderWizardFulfillALL from './fulfillWizard.js';
import OrderWizardEditShipping from './shippingWizard.js';
import OrderWizardSplitOrder from './splitOrderWizard.js';
import OptionTypeCard from './typeCard.js';

import { Dialog, DialogContent, DialogTitle } from '@shadcn/components/ui/dialog.jsx';
import { DialogFooter, DialogHeader } from '@shadcn/components/ui/dialog';
import { Button } from '@shadcn/components/ui/button.jsx';
import FeedBack from '@pages/JIFI/order-ticket/feedback.js';

const OrderWizardComponent = ({ togglemodal, findSKULevel, order, orderID, refreshOrder, handleEditLineItem, open }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const getVariant = useProductData()

  const deliveryLabels = useRef();
  const printDeliveryLabels = useReactToPrint({
    content: () => deliveryLabels.current,
  });

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setCurrentStep(currentStep + 1);
  };
  const lineItemsProps = order.line_items.filter(val => (val.fulfillment_status === 'unfulfilled') || (val.fulfillment_status === 'awaiting-stock' && val.monogram === false)).filter(val => val.sku !== undefined)
  const nonDeletedLineItems = order.line_items.filter(val => (val.fulfillment_status !== 'deleted')).filter(val => val.sku !== undefined)
  const allLineItems = order.line_items.filter(val => val.sku !== undefined)

  useEffect(() => {
    if (!open) {
      const timeoutId = setTimeout(() => {
        setCurrentStep(1);
        setSelectedOption(null);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);
  
  const handleStepTwo = () => {
    switch (selectedOption) {
      case 'Flag':
        return <OrderWizardFlaggedModal
          orderID={orderID}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          order={order}
        />
      case 'Unflag':
        return <OrderWizardFlaggedModal
          orderID={orderID}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          order={order}
        />
      case 'Change Address':
        return <OrderWizardEditModalShipping
          orderID={orderID}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          order={order}
        />
      case 'Change Shipping':
        return <OrderWizardEditShipping
          orderID={orderID}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          order={order}
        />
      case 'Delete Line Item':
        return <OrderWizardDeleteSKU
          orderID={orderID}
          order={order}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          shipping_address={order.shipping_address}
          postcode={order.shipping_address.zip}
          pick_status={order.pick_status}
          findSKULevel={findSKULevel}
          printDeliveryLabels={printDeliveryLabels}
          getVariant={getVariant}
          distanceFromHQ={order.distanceFromHQ}
          contains_monogram={order.contains_monogram}
          shipping_type={order?.shipping_type}
          order_source={order?.order_source}
          orderName={order.name}
          lineItems={nonDeletedLineItems}
        />
        // this step can happen
      // case 'Fulfill':
      //   return <OrderWizardFulfillALL
      //     orderID={orderID}
      //     order={order}
      //     refresh={refreshOrder}
      //     togglemodal={togglemodal}
      //     shipping_address={order.shipping_address}
      //     postcode={order.shipping_address.zip}
      //     pick_status={order.pick_status}
      //     findSKULevel={findSKULevel}
      //     printDeliveryLabels={printDeliveryLabels}
      //     getVariant={getVariant}
      //     distanceFromHQ={order.distanceFromHQ}
      //     contains_monogram={order.contains_monogram}
      //     shipping_type={order?.shipping_type}
      //     order_source={order?.order_source}
      //     orderName={order.name}
      //     lineItems={lineItemsProps}
      //   />
      case 'Split Order':
        return <OrderWizardSplitOrder
          orderID={orderID}
          order={order}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          shipping_address={order.shipping_address}
          postcode={order.shipping_address.zip}
          pick_status={order.pick_status}
          findSKULevel={findSKULevel}
          printDeliveryLabels={printDeliveryLabels}
          getVariant={getVariant}
          distanceFromHQ={order.distanceFromHQ}
          contains_monogram={order.contains_monogram}
          shipping_type={order?.shipping_type}
          order_source={order?.order_source}
          orderName={order.name}
          lineItems={allLineItems}
        />
      case 'Edit Line Item':
        return <OrderWizardSKUchange
          orderID={orderID}
          order={order}
          refresh={refreshOrder}
          togglemodal={togglemodal}
          shipping_address={order.shipping_address}
          postcode={order.shipping_address.zip}
          pick_status={order.pick_status}
          findSKULevel={findSKULevel}
          printDeliveryLabels={printDeliveryLabels}
          getVariant={getVariant}
          distanceFromHQ={order.distanceFromHQ}
          contains_monogram={order.contains_monogram}
          shipping_type={order?.shipping_type}
          order_source={order?.order_source}
          orderName={order.name}
          lineItems={lineItemsProps}
          handleEditLineItem={handleEditLineItem}
        />
      case 'Create Order Feedback':
        return <FeedBack
          isWizard={true}
          isForm={true}
          onModalChange={togglemodal}

          orderName={order.name}
        />
      default:
        break;
    }
  };

  const handleFlag = (e) => {
    e.preventDefault();
    setLoading(true);
    superagent.get(`${backendUrl}/orders/${orderID}/getFlagged`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          refreshOrder();
          setLoading(false);
          togglemodal();
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleUnpick = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/unpick`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshOrder()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const handleUnfulfill = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/cancelFulfillments`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshOrder()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const handleUnpickAndUnfulfill = (e) => {
    handleUnpick(e)
    handleUnfulfill(e)
    togglemodal()
  }


  const options = [
    ...(
      order.flagged ? (
        [
          { name: 'Unflag', desc: 'Remove any marked status or alert associated with an item or order.', availiable: true, onClick: handleFlag },
        ]
      ) : (
        [
          { name: 'Flag', desc: 'Mark an item or order with a status or alert.', availiable: true },
        ]
      )
    ),
    ...(order.fulfillment_status === 'fulfilled' ? [{ name: 'Unfulfill & Unpick', desc: 'Unfulfill and unpick order. Put order in a state ready to be shipped.', availiable: true, onClick: handleUnpickAndUnfulfill }] : []),
    ...(order.fulfillment_status === 'fulfilled' ? [{ name: 'Unfulfill', desc: 'Unfulfill the order but leave any pick state', availiable: true, handleUnfulfill }] : []),
    ...(order.pick_status !== 'none' ? [{ name: 'Unpick', desc: 'Remove any pick status on the order', availiable: true, onClick: handleUnpick }] : []),

    // { name: 'Fulfill', desc: 'Process and complete the necessary steps to dispatch and deliver an order to the customer.', availiable: lineItemsProps.length > 0 },
    // { name: 'Add Monogram', desc: 'Add monogram to a line item.', availiable: lineItemsProps.length > 0 },

    { name: 'Change Shipping', desc: 'Modify the designated shipping method or details for an order.', availiable: true },
    { name: 'Change Address', desc: 'Update the delivery address information for a particular order.', availiable: true },
    { name: 'Split Order', desc: 'Divide a single order into multiple distinct orders, each with its own details.', availiable: true },
    { name: 'Delete Line Item', desc: 'Remove a specific item from the order list or transaction details.', availiable: nonDeletedLineItems.length > 0 },
    { name: 'Edit Line Item', desc: 'Make modifications to the details or attributes of a particular item within an order.', availiable: lineItemsProps.length > 0 },
    { name: 'Create Order Feedback', desc: 'Create a feedback for the order.', availiable: true },
  ];


  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <Row className='flex-wrap gap-6'>
              {options.map((item) => (
                <OptionTypeCard 
                  onClick={(e) => {
                    if (!item.availiable) return e.preventDefault();
                    if (item.onClick) return item.onClick(e);
                    return handleOptionSelect(item.name)
                  }}
                  name={item.name}
                  description={item.desc}
                  available={item.availiable}
                />
              ))}
            </Row>
          </div>
        );
      case 2:
        return (
          <div>
            {handleStepTwo()}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      <DialogContent className="z-[1500] max-h-[80vh] overflow-y-auto min-w-[60vw]">
        <DialogHeader>
          <p className='text-[16px] font-medium'>
          {selectedOption || 'Order Wizard'}
          </p>
          </DialogHeader>
        <div className="wizard-container mt-2">{renderStepContent()}</div>
        {currentStep === 1 && (
          <DialogFooter>
            <Button variant="destructive" onClick={togglemodal}>
              Cancel
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderWizardComponent;
