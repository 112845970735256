import React, { Component } from 'react';


import { Col } from '@shadcn/components/ui/col'
import { Card , CardContent as CardBody} from '@shadcn/components/ui/card'
class CardMaintenance extends Component {

    render() {
        return (
            <React.Fragment>
                <Col md="4" className="md:w-[30%]">
                    <Card className="mt-4 maintenance-box">
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default CardMaintenance;
