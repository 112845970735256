import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardContent, CardDescription, CardHeader } from "@shadcn/components/ui/card";
import { Button } from "@shadcn/components/ui/button";
import { cva } from "class-variance-authority"; 
import { GripVertical, CalendarDays, AlertCircle, Truck, CheckCircle2, ArrowRight } from "lucide-react"; 
import { Badge } from "@shadcn/components/ui/badge";

import { useTaskCard } from "@zustand/useTaskCard";
import { useEffect } from "react";

const statusTitle = {
    todo: 'To do',
    'in-progress': 'In Progress',
    complete: 'Complete',
  };

const statusColors = {
    todo: 'red',
    'in-progress': 'yellow',
    complete: 'green'
};

const priorityColors = {
    high: 'bg-red-100 text-red-800',
    medium: 'bg-yellow-100 text-yellow-800',
    low: 'bg-gray-100 text-gray-800'
};

export function TaskCard({ task, isOverlay }) {
    
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id, 
        data: {
            type: "Task",
            task, 
        },
        attributes: {
            roleDescription: "Task",
        },
    });

    const selectedCard = useTaskCard ((state) => state.selectedCard);
    const selected = useTaskCard((state => state.selected))
    const taskCard  = useTaskCard((state => state.task))

    useEffect(() => {
        if(task.selected) {
            if(selected === undefined) {
                selectedCard({...task})
            }
        }
    },[])
    
    const style = {
        transition, 
        transform: CSS.Translate.toString(transform),
     
      };

      const cardClass = task?.id === taskCard?.id
        ? "border-2 border-black shadow-lg"
        : "bg-white border border-gray-300 shadow-sm";

   
    const variants = cva("", {
        variants: {
            dragging: {
                over: "ring-2 opacity-30", 
                overlay: "ring-2 ring-primary",
            },
        },
    });
    const formattedDate = new Date(task.replacementIssuedDate).toLocaleDateString();


    console.log("test taask", task)
    return (
        <Card
            onClick={() => selectedCard(task)}
            ref={setNodeRef}
            style={style}
            className={`${cardClass} cursor-pointer ${variants({
                dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
            })} transition-all duration-300 rounded-xl p-4 hover:shadow-lg border-l-4 border-${statusColors[task.status]}-500`}
        >
            <CardHeader className="px-2 py-2 flex flex-row items-start gap-3">
                <Button
                    variant={"ghost"}
                    {...attributes}
                    {...listeners}
                    className="p-1 text-muted-foreground h-auto cursor-grab hover:bg-gray-100 rounded-full"
                >
                    <GripVertical className="h-4 w-4" />
                </Button>

                <div className="flex-1 space-y-2">
                    <div className="flex items-center gap-2">
                        <span className={`inline-block h-2 w-2 rounded-full bg-${statusColors[task.status]}-500`} />
                        <div className="flex-1">
                            <span className="font-medium text-sm text-gray-900">
                                {task.customerName}
                            </span>
                            <Badge variant="outline" className="ml-2 text-xs py-0 px-2 border-gray-200">
                                {task.order.name}
                            </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                            {task.toBeShipped && <Truck className="h-4 w-4 text-blue-500" />}
                            {task.fulfilled && <CheckCircle2 className="h-4 w-4 text-green-500" />}
                        </div>
                    </div>

                    <div className="flex items-center gap-2">
                        <Badge variant="destructive" className="text-xs">
                            {task.faultReason}
                        </Badge>
                        <Badge className="text-xs" variant="outline">
                            {statusTitle[task.status]}
                        </Badge>
                    </div>

                    <div className="space-y-1">
                        {task.replacementSkus?.map((item, index) => (
                            <div key={index} className="flex items-center gap-2 text-xs">
                                <span className="text-gray-500 line-through">{item.originalSku}</span>
                                <ArrowRight className="h-3 w-3 text-gray-400" />
                                <span className="font-medium text-gray-900">
                                    {item.replacementSku}
                                   
                                </span>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-between text-xs text-gray-500">
                        <div className="flex items-center gap-1">
                            <CalendarDays className="h-4 w-4" />
                            <span>{formattedDate}</span>
                        </div>
                        {task.priority && (
                            <div className={`flex items-center gap-1 px-2 py-1 rounded-full ${priorityColors[task.priority]}`}>
                                <AlertCircle className="h-4 w-4" />
                                <span className="capitalize">{task.priority}</span>
                            </div>
                        )}
                    </div>
                </div>
            </CardHeader>
        </Card>
    );
}
