import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { Input } from '@shadcn/components/ui/input';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';


import Select from '@shadcn/components/ui/react-select';
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';
import { Button } from '@shadcn/components/ui/button';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Col } from '@shadcn/components/ui/col';
import { Row } from '@shadcn/components/ui/row';
import { Dialog, DialogContent, DialogFooter } from '@shadcn/components/ui/dialog';
const ProductRow = ({
  lineKey,
  item,
  products, 
  editQuantityRow,
  editVariantRow,
  editProductRow,
  removeRow,
}) => {
  const handleRemoveRow = () => {
    removeRow(lineKey)
  }

  const setSelectedVariant = (a, b) => {
    editProductRow(lineKey, a?.product)
    editVariantRow(lineKey, a?._id, a?.sku, a?.name)
  }
  
  return (
    <Row style={{ padding: '16px 0' }} className="gap-6">
      <Col md="8" className="md:w-8/12">
        <ProductVariantSelector
          products={products}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={item}
          hidePersonalisationOptions
        />
      </Col>
      <Col md="3" className="md:w-3/12">
      
        <Input style={{ width: '90%', }} className={`${item['quantity'] <=0 ? "border border-red-500 text-red-500" : ""}`} type="number" value={item['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)} />
      </Col>
      <Col xs="1" className="md:w-1/12">
        <Button onClick={handleRemoveRow} variant="destructive">
          X
        </Button>{' '}
      </Col>
    </Row>
  );
};
const StockInput = ({ stock, setStock, productData }) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]._id
    }
    const item = {
      product: "",
      variant: "",
      name: "",
      quantity: 0
    };
    setStock(prev => [...prev, item])
  };

  const editProductRow = (id, productId) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['product'] = productId
      const variant = productData.find(val => `${val._id}` === `${productId}`)
      newList[id]['variant'] = variant?.variants[0]?._id
      return newList
    })
  };

  const editVariantRow = (id, val, sku, name) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      newList[id]['quantity'] = 0
      newList[id]['sku'] = sku
      newList[id]['name'] = name
      return newList
    })
  };
  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['quantity'] = parseInt(val)
      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((_, key) => key !== id)
      return newList
    })
  };

  useEffect(() => {
    if(productData){
      // editProductRow(0, productData[0]._id)
      // editVariantRow(0, productData[0]['variants'][0]._id)
    }
  }, [productData])


  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id),
        sku: variant.sku,
        product: product._id
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="8" className="sm:w-8/12">
        Variant
      </Col>
      <Col xs="4" className="sm:w-4/12">
        Quantity
      </Col>
    </Row>
    <br />
    {
      productData && stock.map((stockObjectRow, lineKey) =>
        <ProductRow
          key={lineKey}
          item={stockObjectRow}
          lineKey={lineKey}
          products={products}
          editQuantityRow={editQuantityRow}
          editProductRow={editProductRow}
          editVariantRow={editVariantRow}
          removeRow={removeRow}
        />
      )
    }
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
      <Button onClick={handleAddRow} variant="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
  </>
}


const TransferStockPage = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const [stockPush, setStockPush] = useState([])
  const [modal, setModal] = useState(false)
  const [locationFrom, setLocationFrom] = useState('HQ2')
  const [locationTo, setLocationTo] = useState('HQ2')
  const [loading, setlLoading] = useState(false)

  console.log("stockPush", stockPush)

  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data

  const togglemodal = () => setModal(prev => !prev)

  const [bookableLocations, bookableLocationsState] = useQuery({
    url: `/inventory/getReplenishLocations`,
    refresh
  });

  const [selectedFromLocationOptions, setSelectedFromLocationOptions] = useState()
  const [selectedFromLocation, setSelectedFromLocation] = useState()

  const [selectedToLocationOptions, setSelectedToLocationOptions] = useState()
  const [selectedToLocation, setSelectedToLocation] = useState()

  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      setSelectedFromLocationOptions(bookableLocations.map(val => ({ label: val.display_name, value: val.name })))
      let firstFromLocation = bookableLocations[0]
      setSelectedFromLocation({
        label: firstFromLocation.display_name,
        value: firstFromLocation.name,
      })


      let toLocationOptions = bookableLocations.filter(val => val.name !== firstFromLocation.name)

      if (toLocationOptions?.length > 0) {
        setSelectedToLocationOptions(toLocationOptions.map(val => ({ label: val.display_name, value: val.name })))
        let firstToLocation = toLocationOptions[0]
        setSelectedToLocation({
          label: firstToLocation.display_name,
          value: firstToLocation.name,
        })
      }
    }
  }, [bookableLocations])

  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      let fromLocation = selectedFromLocation?.value

      let toLocationOptions = bookableLocations.filter(val => val.name !== fromLocation)

      if (toLocationOptions?.length > 0) {
        setSelectedToLocationOptions(toLocationOptions.map(val => ({ label: val.display_name, value: val.name })))
        let firstToLocation = toLocationOptions[0]
        setSelectedToLocation({
          label: firstToLocation.display_name,
          value: firstToLocation.name,
        })
      }
    }
  }, [selectedFromLocation])

  const handleSubmit = () => {
    setlLoading(true)
    const exportData = {
      locationFrom: selectedFromLocation?.value,
      locationTo: selectedToLocation?.value,
      pushStock: stockPush,
      stockTypeFrom: 'on_hand',
      stockTypeTo: 'on_hand',
    }

    superagent.post(`${backendUrl}/inventory/transfer`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.go(0)
        }
      }).catch(err => {
        console.log(err)
      });
  }

  return <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
      <Row>
        <Col xs="8" className="sm:w-8/12">
          <Breadcrumbs title="Inventory" breadcrumbItem={'Transfer Stock'} />
        </Col>
        <Col xs="4" className="sm:w-4/12">
          <Link to="/pull" className="dropdown-item text-danger">Add/Remove Stock</Link>
        </Col>
      </Row>
      <Row className="mb-2 gap-6 flex-wrap sm:flex-nowrap ">
        <Col className="w-full sm:w-8/12" >
          <Card className="pt-6">
            <CardBody>
              <Row>
                <Col xs="5" className="sm:w-5/12">
                  <div className='mb-4'>
                    <label htmlFor="location">From:</label>
                    <Select
                      value={selectedFromLocation}
                      options={selectedFromLocationOptions}
                      onChange={setSelectedFromLocation}
                    />
                  </div>
                </Col>
                <Col xs="1" className="sm:w-1/12">
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <i className="bx bx-right-arrow"></i>
                  </div>
                </Col>
                <Col xs="6" className="sm:w-6/12">
                  <div className='mb-4'>
                    <label htmlFor="location">To:</label>
                    <Select
                      value={selectedToLocation}
                      options={selectedToLocationOptions}
                      onChange={setSelectedToLocation}
                    />
                  </div>
                </Col>
              </Row>
              <br /><br />
              <div id="accordion">
                <StockInput stock={stockPush} setStock={setStockPush} productData={productData} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
                <span />
                {(productData && (stockPush.length > 0)) && (
                  <Button onClick={togglemodal} variant="primary" className="btn-lg btn-rounded">
                    Submit
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" m="4" lg="4" xl="4" className="w-full sm:w-4/12">
          <Card className="pt-6">
            <CardBody>
              <h4 className="card-title mb-4">Summary</h4>

              {
                productData && stockPush.length > 0 && 'Adding:'
              }
              {
                productData && stockPush.map((line, lineKey) => {
                  return <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
                })
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    <Dialog open={modal}  onOpenChange={togglemodal}>
      <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[50vw]">
        <h4 className="card-title mb-4">INTERNAL STOCK SUMMARY</h4>
        <br />
        {
          productData && stockPush.map((line) => (
            <>
              Adding:
              <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
            </>
          ))
        }
      
      <DialogFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} variant="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
}

export default TransferStockPage
