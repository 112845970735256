export const shippingConverter = (pick) => {
  const conversion = {
    pickup: 'Pickup QV',
    pickup_hq: 'Pickup HQ',
    standard: 'Standard',
    'next-day': 'Next Day',
    'same-day': 'Same Day',

    express: 'Express',
    uber: 'Uber',
    Uber: 'Uber',
    overnight: 'Overnight',
  };
  return conversion[pick] || pick;
};
