import * as React from "react"
import { cn } from "@shadcn/lib/utils"

export const Col = React.forwardRef(({ className, ...props }, ref) => {


 return ( <div
    ref={ref}
    className={cn(
      'w-full',
      className
    )}
    {...props} />
)
}
)
