import { Button } from '@shadcn/components/ui/button';  
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { Row   } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';

import useStockStore from '@zustand/useStockStore.js';
import { Input } from '@shadcn/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/components/ui/select';
import ProductVariantSelector from '@pages/JIFI/helpers/ProductVariantSelector';

const StockInput = ({ productData, purchaseOrders, handleSubmit, validate, toggleModal, label }) => {
  const { stock, addStock, updateStock, removeStock } = useStockStore();
  const handleAddRow = () => {
    const item = {
      product: productData[0]._id,
      variant: productData[0].variants[0]._id,
      quantity: 0,
      costPerItem: 0,
      purchaseOrder: '',
    };
    addStock(item);
  };

  const editRow = (id, field, value) => {
    updateStock(id, field, value);
  };

  const removeRow = (id) => {
    removeStock(id);
  };

  const products = productData || [];

  return (
    <Card className="mb-1">
      <CardBody>
        <Row className="pt-4">
          <Col className="sm:w-4/12">Variant</Col>
          <Col className="sm:w-2/12">Quantity</Col>
          <Col className="sm:w-2/12">FOB</Col>
          <Col className="sm:w-3/12">Purchase Order</Col>
        </Row>
        <br />
        {productData &&
          stock?.map((line, lineKey) => {
            const filteredPurchaseOrders = purchaseOrders?.filter((val) =>
              val?.lines?.some(
                (poLine) => poLine?.variant?._id === line?.variant?._id
              )
            );
            return (
              <Row
                key={lineKey}
                style={{ padding: '12px 0' }}
                className="gap-2"
              >
                <Col className="sm:w-4/12">
                  <ProductVariantSelector
                    hidePersonalisationOptions
                    products={products}
                    setSelectedVariant={(val) =>
                      editRow(lineKey, 'variant', val)
                    }
                    selectedVariant={line.variant}
                  />
                </Col>
                <Col className="sm:w-2/12">
                  <Input
                    style={{ width: '90%', height: '100%' }}
                    type="number"
                    value={line.quantity}
                    onChange={(e) =>
                      editRow(lineKey, 'quantity', e.target.value)
                    }
                  />
                </Col>
                <Col className="sm:w-2/12">
                  <Input
                    style={{ width: '90%', height: '100%' }}
                    type="number"
                    value={line.costPerItem}
                    onChange={(e) =>
                      editRow(lineKey, 'costPerItem', e.target.value)
                    }
                  />
                </Col>
                <Col className="sm:w-2/12">
                  <Select
                    value={line.purchaseOrder?._id || line?.purchaseOrder}
                    onValueChange={(value) =>
                      editRow(lineKey, 'purchaseOrder', value)
                    }
                  >
                    <SelectTrigger style={{ width: '100%', height: '100%' }}>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem>none</SelectItem>
                      {filteredPurchaseOrders && filteredPurchaseOrders?.map((purchaseOrder) => (
                        <SelectItem
                          key={purchaseOrder._id}
                          value={purchaseOrder._id}
                        >
                          {purchaseOrder.reference}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </Col>
                <Col className="sm:w-1/12">
                  <Button
                    onClick={() => removeRow(lineKey)}
                    variant="destructive"
                  >
                    X
                  </Button>
                </Col>
              </Row>
            );
          })}
        {productData &&
          [
            stock.reduce(
              (sum, line) => {
                sum.sum += parseInt(line.quantity);
                sum.total += line.quantity * line.costPerItem;
                return sum;
              },
              { sum: 0, total: 0 }
            ),
          ].map((val) => {
            if (val.sum === 0) {
              return <></>;
            }
            return (
              <Row className="gap-2" key="total" style={{ padding: '12px 0' }}>
                <Col className="sm:w-4/12">Total</Col>

                <Col className="sm:w-2/12 px-3">
                  {isNaN(val.sum) ? 0 : val.sum}
                </Col>
                <Col className="sm:w-2/12 px-3">
                  {isNaN(val.total) ? 0 : val.total}
                </Col>

                <Col className="sm:w-1/12"></Col>
              </Row>
            );
          })}
        {productData?.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 12,
            }}
          >
            <Button onClick={handleAddRow} variant="primary">
              Add
            </Button>
            <span />
          </div>
        )}
      </CardBody>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 12,
        }}
      >
        <span />
        {productData && stock.length > 0 && (
          <Button
            
            onClick={handleSubmit(async (data) => {
              const result = await validate(data);
              if (result) {
                toggleModal();
              }
            })}
            variant="primary"
            className="btn-lg btn-rounded mb-4 mr-4"
          >
            {label}
          </Button>
        )}
      </div>
    </Card>
  );
};


export default StockInput;
