import { Collapsible, CollapsibleContent } from "@shadcn/components/ui/collapsible";
import { Card, CardContent as CardBody, CardHeader } from '@shadcn/components/ui/card';
import { Badge } from "@shadcn/components/ui/badge";
import { Button } from "@shadcn/components/ui/button";
import Word from '../../../../components/Personalisation/font';

const displaySize = { 
  height: 24,
  width: 120,
  padding: {
    top: 4,
    bottom: 4,
    left: 0,
    right: 4
  }
};

const Batch = ({batch, doneID, setDone, openNames, setOpenNames, getVariant, state}) => {
  const isOpen = openNames.includes(batch.name);
  const toggleIsOpen = () => {
    setOpenNames(prev => {
      if (prev.includes(batch.name)) {
        return prev.filter(val => val !== batch.name);
      } else {
        return [...prev, batch.name];
      }
    });
  };
  
  const numberOfLines = batch.line_items.length;
  const numberOfComplete = batch.line_items.filter(val => val.fulfillment_status === 'fulfilled' || val.fulfillment_status === 'deleted' || val.fulfillment_status === 'unfulfilled').length;

  return (
    <Card style={numberOfLines === numberOfComplete ? {display: 'none'} : {display: 'block'}}>
      <CardHeader onClick={() => toggleIsOpen()}>
        <h4 className="text-[20px] font-medium" style={{cursor: 'pointer'}}>{batch.name}</h4>
        <Badge className={"mr-2 px-4 py-2 w-fit font-size-12 badge-soft-warning"} color={numberOfLines === numberOfComplete ? 'success' : 'warning'} pill> 
          {numberOfComplete}/{numberOfLines} 
        </Badge>
      </CardHeader>
      <CardBody className="pb-0">
        <Collapsible open={isOpen} onOpenChange={toggleIsOpen}>
          <CollapsibleContent>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>Monogram ID</th>
                    <th>Date</th>
                    <th>SKU</th>
                    <th>Variant</th>
                    <th>Monogram Text</th>
                    <th>Monogram Font</th>
                    <th>Monogram Color</th>
                    <th>Monogram Secondary Color</th>
                    <th>Monogram Location</th>
                    <th>Pets</th>
                    <th>Preview</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    batch.line_items && batch.line_items.sort((a, b) => a.monogramID.localeCompare(b.monogramID)).map((lineItem, key) =>
                      <tr key={"_order_" + key}>
                        <td>{lineItem.monogramID}</td>
                        <td>{(new Date(lineItem.orderSent)).toDateString()}</td>
                        <td> {lineItem.sku} </td>
                        <td> {getVariant(lineItem.sku.replace('-P', '')).name} </td>
                        <td> {lineItem.monogram_text} </td>
                        <td> {lineItem.monogram_font} </td>
                        <td> {lineItem.monogram_color} </td>
                        <td> {lineItem.monogram_shadow} </td>
                        <td> {lineItem.monogram_position} </td>
                        <td> {lineItem.monogram_pets && JSON.parse(atob(lineItem.monogram_pets)).map(pet => pet.name).join(' + ')} </td>
                        <td>
                          {
                            !lineItem.pet && (
                              <Word 
                                left={true} 
                                maxLetters={20} 
                                size={displaySize} 
                                mainCol={lineItem.monogram_color} 
                                backCol={lineItem.monogram_shadow} 
                                letters={lineItem.monogram_text} 
                                fontSize={32} 
                                font={lineItem.monogram_font} 
                              />
                            )
                          }
                        </td>
                        <td> 
                          {
                            lineItem.fulfillment_status === 'in-for-monogram' && (
                              state === 'loading' ? (
                                <Button variant="disabled" disabled={true}> 
                                  <div className="spinner-grow text-primary m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </Button>
                              ) : (
                                <Button variant="success" onClick={() => setDone(lineItem)}> Done </Button>
                              )
                            )
                          }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardBody>
    </Card>
  );
}

export default Batch;
