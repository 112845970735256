import  { useState, useEffect } from "react";

import { Card, CardContent as CardBody } from "@shadcn/components/ui/card";
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';


import { Link } from "react-router-dom";
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CSVLink } from "react-csv";



import Select from '@shadcn/components/ui/react-select';

import Breadcrumbs from '@components/Common/Breadcrumb';
import "@pages/JIFI/datatables.scss";
import useQuery from '@helpers/JIFI/useQuery';

import moment from 'moment';


import SKUToolTip from "../../helpers/SKUToolTip.js";
import useUpcomingStock from "../../helpers/useUpcomingStock.js";
import useSKULevels from "../../helpers/useSKULevels.js";
import useSKUStatus from "../../helpers/useSKUStatus.js";
import { TablePagination } from "@components/supply-chain/TablePagination";
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "@shadcn/components/ui/dialog";
import { TableSkeleton } from "@shadcn/components/ui/table";


const SKUCell = ({ lineItem, findSKULevel, findUpcomingStock, findSKUStatus }) => {
  const [mouseHover, setMouseHover] = useState(false);

  return (
    <td 
      className={lineItem.fulfillment_status === 'awaiting-stock' ? 'text-danger' : 'text-success'}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      {mouseHover ? (
        <>
          <SKUToolTip 
            sku={lineItem.sku} 
            findSKULevel={findSKULevel} 
            findUpcomingStock={findUpcomingStock} 
            findSKUStatus={findSKUStatus} 
          /> x {lineItem.quantity} 
        </>
      ) : (
        <p>{lineItem.sku} x {lineItem.quantity}</p>
      )}
    </td>
  );
};

const StockList = ({ url = '/orders/awaiting' }) => {

  const isMisPick = url.includes('mispick')   
  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedSource: null,
    filterSource: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedState: null,
    filterState: null,
    selectedStatus: [{ label: 'Awaiting Stock', value: 'awaiting-stock' }],
    filterStatus: ['awaiting-stock'],
    selectedOrder: null,
    filterOrder: null,
    selectedShipping: null,
    filterShipping: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    regionOptions: [{ label: 'Australia', value: 'Australia' }, { label: 'China', value: 'China' }, { label: 'International', value: 'International' }],
    orderSourceOptions: [{ label: 'AU Shopify', value: 'shopify_au' }, { label: 'TMALL', value: 'tmall' }, { label: 'US Shopify', value: 'shopify_us' }, { label: 'NZ Shopify', value: 'shopify_nz' }, { label: 'UK Shopify', value: 'shopify_uk' }, { label: 'QV Store', value: 'qv_store' }, { label: 'Greene St', value: 'greene' }, { label: 'Galeries Store', value: 'galeries_store' }, { label: 'Bondi Store', value: 'bondi' }, { label: '80 Collins', value: '80_collins' }, { label: 'HQ Store', value: 'hq_store' }, { label: 'Pitt St', value: 'pitt_st' }, { label: 'PacFair', value: 'pacfair' }],
    stateOptions: [{ label: 'KY', value: 'KY' }, { label: 'VIC', value: 'Victoria' }, { label: 'NSW', value: 'New South Wales' }, { label: 'ACT', value: 'Australian Capital Territory' }, { label: 'QLD', value: 'Queensland' }, { label: 'WA', value: 'Western Australia' }, { label: 'SA', value: 'South Australia' }, { label: 'NT', value: 'Northern Territory' }, { label: 'TAS', value: 'Tasmania' }],
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Fulfilled', value: 'fulfilled' }, { label: 'Partially fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' }, ],
    orderOptions: [{ label: 'Has personalisation', value: 'Has-personalisation' }, { label: 'Flagged', value: 'Flagged' }, { label: 'POS', value: 'POS' }, { label: 'Ready to ship', value: 'ready-to-ship' }],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'Same Day', value: 'same-day' }, { label: 'Next Day', value: 'next-day' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD'}, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
  }

  const [stateData, setStateData] = useState(initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(200);

  const [exportOpen, setExportModal] = useState();
  const toggleExportOpen = () => setExportModal(prev => !prev)

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }

  const handleSelectSource = selectedSource => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedSource: selectedSource,
        filterSource: Array.isArray(selectedSource) && selectedSource.map(a => a.value)
      }
    }))
  };

  const ResetField = e => {
    e.preventDefault();
    setStateData(prevState => {
      return {
        ...prevState,
        selectedRegion: null,
        filterRegion: null,
        selectedPickStatus: null,
        filterPickStatus: null,
        selectedSource: null,
        filterSource: null,
        selectedState: null,
        filterState: null,
        selectedStatus: null,
        filterStatus: null,
        selectedOrder: null,
        filterOrder: null,
        selectedShipping: null,
        filterShipping: null,
        searchTerm: '',
        orderStartDate: '',
        filterStartDate: '',
        orderEndDate: '',
        filterEndDate: ''
      };
    });
  };

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  }

  const handleWeekChange = (date) => {
   
    if(moment(date).isValid()) {
      const startOfWeek = moment(date).startOf('week').format('YYYY-MM-DD');
      const endOfWeek = moment(date).endOf('week').format('YYYY-MM-DD');

    
      setStateData((prevState) => ({
        ...prevState,
        orderStartDate: startOfWeek,
        orderEndDate: endOfWeek,
        filterStartDate: startOfWeek,
        filterEndDate: endOfWeek
      }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        orderStartDate: '',
        orderEndDate: '',
        filterStartDate: '',
        filterEndDate: ''
      }));
    }
  };

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }
  console.log(stateData)
  const [data, state] = useQuery({ 
    refresh, 
    url, 
    queryData: {
      page: page, 
      perPage: perPage, 
      searchTerm: stateData.searchTerm, 
      filterStatus: stateData.filterStatus, 
      filterStartDate: stateData.filterStartDate, 
      filterEndDate: stateData.filterEndDate, 
      filterOrder: stateData.filterOrder, 
      filterShipping: stateData.filterShipping, 
      filterState: stateData.filterState, 
      filterRegion: stateData.filterRegion, 
      filterPickStatus: stateData.filterPickStatus, 
      filterSource: stateData.filterSource
    } 
  });
  const orders = data && data.data;
  const count = data && data.count;

  const { orderStartDate, orderEndDate, searchTerm, orderSourceOptions, selectedSource } = stateData;

  const [tableData, setTableData] = useState({})

  const [products, productState] = useQuery({
    url: `/product`,
    refresh,
  });

  const [containers, containerState] = useQuery({
    url: `/inventory/fetchUpcomingContainer/`,
    refresh,
  });

  useEffect(() => {
    if (products && containers) {
      const updatedTable = {}

      products.data.map(product => {
        product.variants.map(variant => {
          const sku = variant.sku

          let usLocations = ['USA - Dallas', 'UNIS', 'LCHSLC', 'LCHKTC']

          let containerLineData = [...containers].reverse().map(container => {
            let containerLines = container.containerLines.find(variantCount => variantCount.variant.sku === sku)
            let count = containerLines == undefined ? 0 : containerLines.quantity
            let location = container.landingLocation.name
            let date = container.arrivalDate
            return {
              count,
              location,
              date,
              country: usLocations.includes(location) ? 'US' : 'AU'
            }
          }).filter(val => val.count !== 0)
          let usList = containerLineData.filter(val => val.country === 'US')
          let auList = containerLineData.filter(val => val.country === 'AU')

          updatedTable[sku] = {
            usNextContainer: usList.length > 0 ? usList[0] : null, 
            auNextContainer: auList.length > 0 ? auList[0] : null, 
          }
        })
      })
      console.log(updatedTable)
      setTableData(updatedTable)
    }
  }, [products, containers])


  return (
    <>
      <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs title="Fulfillment" breadcrumbItem={`${isMisPick ? 'Mis-Picked Orders' : 'All Orders'}`} />
          <Row>
            <Col>
              <Card className="pt-6 mb-6">
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <div className="select2-container mb-4">
                        <label className="control-label">FILTER</label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                          
                          </div>
                        </div>
                      </div>
                      <Row className="flex-wrap gap-6">
                        { isMisPick ? (
                          <Col sm="6" className="sm:w-[48%]">
                            <div className="select2-container mb-4">
                              <label className="control-label">WEEK</label>
                              <DatePicker
                                selected={orderStartDate ? moment(orderStartDate).toDate() : null}
                                onChange={handleWeekChange}
                                selectsWeek
                                startDate={orderStartDate ? moment(orderStartDate).toDate() : null}
                                endDate={orderEndDate ? moment(orderEndDate).toDate() : null}
                                className="form-control border-black"
                              />
                            </div>
                          </Col>
                        ) : (
                          <>
                            <Col sm="6" className="sm:w-[48%]">
                              <div className="select2-container mb-4">
                                <label className="control-label">STARTING</label>
                                <input className="form-control border-black" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                              </div>
                            </Col>
                            <Col sm="6" className="sm:w-[48%]">
                              <div className="select2-container mb-4">
                                <label className="control-label">ENDING</label>
                                <input className="form-control border-black" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                              </div>
                            </Col>
                          </>
                        )}
                        <Col sm="6" className="sm:w-full">
                          <div className="select2-container mb-4">
                            <label className="control-label">ORDER SOURCE</label>
                            <Row className="gap-6">
                            <Select className="w-[48%]"  value={selectedSource} onChange={handleSelectSource} placeholder="All" title="Source" options={orderSourceOptions} isMulti />
                            <Button type="button" variant="info" className="btn-rounded waves-effect waves-light" onClick={ResetField} > Reset</Button>
                            </Row>
                            
                          </div>
                        </Col>
                        
                      </Row>
                    </Col>
                  
                  </Row>
                  <style dangerouslySetInnerHTML={{__html: `
                    .checkmark {
                      display:inline-block;
                      width: 22px;
                      height:22px;
                      -ms-transform: rotate(45deg); /* IE 9 */
                      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                      transform: rotate(45deg);
                    }
                    
                    .checkmark_stem {
                        position: absolute;
                        width:3px;
                        height:9px;
                        background-color:#000;
                        left:11px;
                        top:6px;
                    }
                    
                    .checkmark_kick {
                        position: absolute;
                        width:3px;
                        height:3px;
                        background-color:#000;
                        left:8px;
                        top:12px;
                    }
                  `}}/>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Billing Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          {!isMisPick && (
                            <>
                              <th>Expected</th>
                              <th>Contacted</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      {
                        (orders && state === 'success') ? (
                      <tbody>
                        {
                            orders && orders.map((order, key) =>
                              order.line_items?.filter(val => val.fulfillment_status !== 'deleted').map((lineItem, key) => {
                                if (key === 0) {
                                  return (
                                    <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                      <td><Link to={`/orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>
                                      <td>
                                        {order?.shipping_type}
                                      </td>
                                      <td>
                                        {order?.shipping_address?.province}, {order?.shipping_address?.country}
                                      </td>
                                      <td>{order?.shipping_address?.name}</td>
                                      <td>
                                        {(new Date(order.orderCreated)).toDateString()}
                                      </td>
                                      <SKUCell 
                                        lineItem={lineItem}
                                        findSKULevel={findSKULevel}
                                        findUpcomingStock={findUpcomingStock}
                                        findSKUStatus={findSKUStatus}
                                      />
                                      {!isMisPick && (
                                        <>
                                          <td>
                                            { lineItem.fulfillment_status === 'awaiting-stock' &&
                                              <> {tableData && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')] && <> {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <>AU: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer.date).format('YYYY-MM-DD')} </>}{tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer !== null && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <br/>} {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer && <>US: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer.date).format('YYYY-MM-DD')} </>} </>} </>
                                            }
                                          </td>
                                          <td>
                                            {
                                              order.contacted === true && lineItem.fulfillment_status === 'awaiting-stock' && (
                                                <span className="checkmark">
                                                  <div className="checkmark_stem"></div>
                                                  <div className="checkmark_kick"></div>
                                                </span>
                                              )
                                            }
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  )
                                }
                                return (
                                  <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <SKUCell 
                                      lineItem={lineItem}
                                      findSKULevel={findSKULevel}
                                      findUpcomingStock={findUpcomingStock}
                                      findSKUStatus={findSKUStatus}
                                    />
                                    {!isMisPick && (
                                      <>
                                        <td>
                                          { lineItem.fulfillment_status === 'awaiting-stock' &&
                                              <> {tableData && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')] && <> {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <>AU: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer.date).format('YYYY-MM-DD')} </>}{tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer !== null && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <br/>} {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer && <>US: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer.date).format('YYYY-MM-DD')} </>} </>} </>
                                          }
                                        </td>
                                        <td>
                                          {
                                            order.contacted === true && lineItem.fulfillment_status === 'awaiting-stock' && (
                                              <span className="checkmark">
                                                <div className="checkmark_stem"></div>
                                                <div className="checkmark_kick"></div>
                                              </span>
                                            )
                                          }
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                )
                              })
                            )
                          
                        }
                      </tbody>
                      ) : (
                        <TableSkeleton perPage={perPage} columns={isMisPick ? 6 : 8} />
                      )
                    }
                    </table>
                  </div>
                  {
                    (orders && state === 'success') && (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) 
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>  
      <Dialog open={exportOpen}  onOpenChange={toggleExportOpen}>
        <ExportModal toggleModal={toggleExportOpen} stateData={stateData}/>
      </Dialog>

    </>
  );
}

const ExportModal = ({toggleModal, stateData}) => {
  const [csvData, setCsvData] = useState([])
  const { orderStartDate, orderEndDate, selectedRegion, selectedState, selectedStatus, selectedOrder, selectedShipping, searchTerm, regionOptions, stateOptions, stepOptions, orderOptions, shippingOptions, pickStatusOptions, selectedPickStatus, filterPickStatus, orderSourceOptions, selectedSource, filterSource} = stateData;

  const [data, state] = useQuery({ url: '/orders/export', queryData: { searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterOrder: stateData.filterOrder, filterShipping: stateData.filterShipping, filterState: stateData.filterState, filterRegion: stateData.filterRegion, filterPickStatus: stateData.filterPickStatus, filterSource: stateData.filterSource } });
  const orders = data && data.data;
  const count = data && data.count;

  useEffect(() => {
    if (orders) {
      let lines = [
        [
          'Name',	'Email',	'Financial Status',	'Paid at',	'Fulfillment Status',	'Fulfilled at',	
          'Accepts Marketing',	'Currency',	'Subtotal',	'Shipping',	'Taxes',	'Total',	'Discount Code',	
          'Discount Amount',	'Shipping Method',	'Created at',	'Lineitem quantity',	'Lineitem name',	
          'Lineitem price',	'Lineitem compare at price',	'Lineitem sku',	'Lineitem requires shipping',	'Lineitem taxable',	
          'Lineitem fulfillment status',	'Billing Name',	'Billing Street',	'Billing Address1',	'Billing Address2',	'Billing Company',	
          'Billing City',	'Billing Zip',	'Billing Province',	'Billing Country',	'Billing Phone',	'Shipping Name',	'Shipping Street',	
          'Shipping Address1',	'Shipping Address2',	'Shipping Company',	'Shipping City',	'Shipping Zip',	'Shipping Province',	
          'Shipping Country',	'Shipping Phone',	'Notes',	'Note Attributes',	'Cancelled at',	'Payment Method',	'Payment Reference',	
          'Refunded Amount',	'Vendor',	'Outstanding Balance',	'Employee',	'Location',	'Device ID',	'Id',	'Tags',	'Risk Level',	
          'Source',	'Lineitem discount',	'Tax 1 Name',	'Tax 1 Value',	'Tax 2 Name',	'Tax 2 Value',	'Tax 3 Name',	'Tax 3 Value',	
          'Tax 4 Name',	'Tax 4 Value',	'Tax 5 Name',	'Tax 5 Value',	'Phone',	'Receipt Number', 'Order Source'
        ]
      ]
      for (let order of orders) {
        let key = 0
        for (let lineItem of order.line_items) {
          if (lineItem.fulfillment_status !== 'deleted') {
            lines.push([
              order.name,
              order.email,
              order.financial_status,
              '',
              order.fulfillment_status,
              '',
              order.customer?.accepts_marketing || false,
              order.currency,
              order.total_line_items_price,
              '',
              key === 0 ? order.total_tax : '',
              key === 0 ? order.total_price: '',
              '',
              order.total_discounts,
              order.shipping_type,
              order.orderCreated,
              lineItem.quantity,
              '',
              lineItem.price,
              lineItem.price,
              lineItem.sku,
              true,
              true,
              lineItem.fulfillment_status,
              order.billing_address?.name || '',
              `${order.billing_address?.address1 || '' } ${order.billing_address?.address2 || '' }`,
              order.billing_address?.address1,
              order.billing_address?.address2,
              order.billing_address?.company,
              order.billing_address?.city,
              order.billing_address?.zip,
              order.billing_address?.province,
              order.billing_address?.country,
              order.billing_address?.phone,
              order.shipping_address?.name,
              `${order.shipping_address?.address1 || '' } ${order.shipping_address?.address2 || '' }`,
              order.shipping_address?.address1,
              order.shipping_address?.address2,
              order.shipping_address?.company,
              order.shipping_address?.city,
              order.shipping_address?.zip,
              order.shipping_address?.province,
              order.shipping_address?.country,
              order.shipping_address?.phone,
              '',
              '',
              '',
              order.gateway,
              '',
              '',
              'July',
              '',
              '',
              '',
              '', 
              order.shopify_id,
              '',
              'Low',
              '2894495',
              '',
              ...(
                key === 0 ? (
                  [
                    'GST 10%',
                    Math.round(((order.total_price / 11 )+ Number.EPSILON) * 100) / 100
                  ]
                ) : (
                  [
                    '', 
                    ''
                  ]
                )
              ),
              '',
              '',
              '',
              '', 
              '',
              '',
              '',
              '', 
              order.shipping_address?.phone,
              '',
              order.order_source
            ])
          }
          key++
        }
      }
      setCsvData(lines)
    }
  }, [orders])
  
  return (
    <DialogContent>
      <DialogTitle>
        Export Orders
      </DialogTitle>
      <div>
        Exporting { count } orders.
      </div>
      <DialogFooter>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
        {
          !data ? (
            <div className="spinner-grow text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CSVLink 
              data={csvData}
              filename={`order_export.csv`}
            >
              <Button variant="primary"> Download </Button>
            </CSVLink>
          )
        }
        </div>
      </DialogFooter>
    </DialogContent>
  )
}

export default StockList;
