import  { useState } from 'react';
import { Button } from '@shadcn/components/ui/button';
import BasicInfoStep from './BasicInfoStep';
import AddressStep from './AddressStep';
import RetailConfigStep from './RetailConfigStep';
import ShopifyStep from './ShopifyStep';
import WizardNav from '../../orders/Wizard/WizardNav';
import useQuery from '@helpers/JIFI/useQuery';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';
import SuccessStep from './SuccessStep';
import { Dialog, DialogContent, DialogTrigger } from '@shadcn/components/ui/dialog';

const STEPS = {
  BASIC_INFO: 0,
  ADDRESS: 1, 
  RETAIL_CONFIG: 2,
  SHOPIFY: 3,
  CONFIRMATION: 4,
  SUCCESS: 5
};

const LABEL = {
  BASIC_INFO: 'Basic Info',
  ADDRESS: 'Address',
  RETAIL_CONFIG: 'Retail Config',
  SHOPIFY: 'Shopify',
  CONFIRMATION: 'Review',
  SUCCESS: 'Success'
}

const emptyAddress = {
  address1: '',
  address2: '',
  phone: '',
  city: '',
  name: '',
  zip: '',
  country: 'Australia',
  company: '',
  province: 'Victoria',
};

const LocationWizard = ({
}) => {
  
  const [currentStep, setCurrentStep] = useState(STEPS.BASIC_INFO);
  const [locationName, setLocationName] = useState('');
  const [code, setCode] = useState('');
  const [region, setRegion] = useState('');
  const [isRetailStore, setIsRetailStore] = useState(false);
  const [address, setAddress] = useState(emptyAddress);
  const [selectedTopupLocation, setSelectedTopupLocation] = useState(null);
  const [selectedReplenLocations, setSelectedReplenLocations] = useState([]);
  const [topupSchedule, setTopupSchedule] = useState(null);
  const [stockPull, setStockPull] = useState([]);
  const [shopifyLinks, setShopifyLinks] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [locationAustralian, setLocationAustralian] = useState(false);
  const [locationLogisticsProvider, setLocationLogisticsProvider] = useState(null);
  const [locationFactory, setLocationFactory] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const resetState = () => {
    setCurrentStep(STEPS.BASIC_INFO);
    setLocationName('');
    setCode('');
    setRegion('');
    setIsRetailStore(false);
    setAddress(emptyAddress);
    setSelectedTopupLocation(null);
    setSelectedReplenLocations([]);
    setTopupSchedule(null);
    setStockPull([]);
    setShopifyLinks([]);
    setLocationAustralian(false);
    setLocationLogisticsProvider(null);
    setLocationFactory(null);
  };

  const onSave = () => {
    setIsSaving(true);
    const params = {
      name: locationName,
      code: code,
      australian: locationAustralian,
      stock: stockPull,
      logistics_provider: locationLogisticsProvider,
      shopify_links: shopifyLinks.map((val) => ({
        ...val,
        locationVariantIds: null,
      })),
      address: {
        ...address,
        _id: undefined
      },
      isRetailStore,
      topupLocation: selectedTopupLocation?.label,
      region,
      ...(topupSchedule ? { topupSchedule: topupSchedule } : {}), 
      stockDropLocations: selectedReplenLocations?.map((val) => val.value) ?? [],
      ...(locationFactory?.value != null
        ? { factory: locationFactory.value }
        : {}),
    }
    superagent.post(`${backendUrl}/inventory/createLocation`)
      .send(params)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then((response) => {
        if (response.status > 400) {
          
        } else {
          setCurrentStep(STEPS.SUCCESS);
        }
      })
      .finally(() => {
        setIsSaving(false);
      })
  }

  const [data, productState] = useQuery({
    url: `/product`,
    refresh,
  });

  const [regionOptions, regionOptionsState] = useQuery({
    url: `/inventory/getRegions`,
  });

  const [replenLocations, replenLocationStates] = useQuery({
    refresh,
    url: `/inventory/getReplenishLocations`,
  });

  const productData = data && data.data;

  const nextStep = () => {
    if (currentStep === STEPS.BASIC_INFO) {
      setCurrentStep(STEPS.ADDRESS);
    } else if (currentStep === STEPS.ADDRESS) {
      if (isRetailStore) {
        setCurrentStep(STEPS.RETAIL_CONFIG);
      } else {
        setCurrentStep(STEPS.SHOPIFY);
      }
    } else if (currentStep === STEPS.RETAIL_CONFIG) {
      setCurrentStep(STEPS.SHOPIFY);
    } else if (currentStep === STEPS.SHOPIFY) {
      setCurrentStep(STEPS.CONFIRMATION);
    }
  };

  const prevStep = () => {
    if (currentStep === STEPS.CONFIRMATION) {
      setCurrentStep(STEPS.SHOPIFY);
    } else if (currentStep === STEPS.SHOPIFY) {
      if (isRetailStore) {
        setCurrentStep(STEPS.RETAIL_CONFIG);
      } else {
        setCurrentStep(STEPS.ADDRESS);
      }
    } else if (currentStep === STEPS.RETAIL_CONFIG) {
      setCurrentStep(STEPS.ADDRESS);
    } else if (currentStep === STEPS.ADDRESS) {
      setCurrentStep(STEPS.BASIC_INFO);
    }
  };

  const getSteps = () => {
    const steps = ['Basic Info', 'Address'];
    if (isRetailStore) {
      steps.push('Retail Config');
    }
    steps.push('Shopify', 'Review');
    return steps;
  };

  const getActiveTabProgress = () => {
    let progress = currentStep + 1;
    if (!isRetailStore && currentStep > STEPS.ADDRESS) {
      progress -= 1;
    }
    return progress;
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
  };

  const renderConfirmationStep = () => {
    return (
      <div className="max-w-[58vw] mx-auto bg-white rounded-lg shadow-lg p-8">
        <h3 className="text-2xl font-bold text-gray-800 mb-8 border-b pb-4">Location Details Review</h3>
        
        <div className="space-y-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">Basic Information</h4>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Location Name</span>
                <span className="font-medium">{locationName}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Code</span>
                <span className="font-medium">{code}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Region</span>
                <span className="font-medium">{regionOptions.find(val => val.id === region)?.label}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Type</span>
                <span className="font-medium">{isRetailStore ? 'Retail Store' : 'Non-Retail Location'}</span>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg">
            <h4 className="text-lg font-semibold text-gray-700 mb-4">Address Details</h4>
            <div className="space-y-2">
              <p className="font-medium text-gray-800">{address.name}</p>
              <p className="text-gray-600">{address.address1}</p>
              {address.address2 && <p className="text-gray-600">{address.address2}</p>}
              <p className="text-gray-600">{address.city}, {address.province} {address.zip}</p>
              <p className="text-gray-600">{address.country}</p>
              <p className="text-gray-600 mt-2">
                <span className="font-medium">Phone:</span> {address.phone}
              </p>
            </div>
          </div>

          {isRetailStore && (
            <div className="bg-gray-50 p-6 rounded-lg">
              <h4 className="text-lg font-semibold text-gray-700 mb-4">Retail Configuration</h4>
              <div className="space-y-3">
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">Topup Location</span>
                  <span className="font-medium">{selectedTopupLocation?.label}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">Stock Pull Items</span>
                  <span className="font-medium">{stockPull.length}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">Topup Schedule Type</span>
                  <span className="font-medium">{topupSchedule?.type ?? ""}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-500">Topup Schedule</span>
                  <span className="font-medium">
                    {topupSchedule?.daysOfWeek?.length > 0 
                      ? topupSchedule.daysOfWeek.join(", ") 
                      : topupSchedule?.dayOfMonth 
                        ? topupSchedule?.dayOfMonth 
                        : topupSchedule?.specificDate?.toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          )}

          {shopifyLinks.length > 0 && (
            <div className="bg-gray-50 p-6 rounded-lg">
              <h4 className="text-lg font-semibold text-gray-700 mb-4">Shopify Integration</h4>
              <div className="grid gap-4">
                {shopifyLinks.map((link, index) => (
                  <div key={index} className="flex justify-between items-center p-3 bg-white rounded border">
                    <span className="font-medium text-gray-700">{link.store}</span>
                    <span className="text-sm text-gray-500">{link.sync_type}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 p-4 bg-blue-50 rounded-lg">
          <p className="text-sm text-blue-600 flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
            Please review all information carefully before proceeding with location creation
          </p>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch(currentStep) {
      case STEPS.BASIC_INFO:
        return (
          <BasicInfoStep
            locationName={locationName}
            setLocationName={setLocationName}
            code={code}
            setCode={setCode}
            region={region}
            setRegion={setRegion}
            isRetailStore={isRetailStore}
            setIsRetailStore={setIsRetailStore}
            regionOptions={regionOptions}
            replenLocations={replenLocations}
            selectedReplenLocations={selectedReplenLocations}
            setSelectedReplenLocations={setSelectedReplenLocations}
            setLocationLogisticsProvider={setLocationLogisticsProvider}
          />
        );

      case STEPS.ADDRESS:
        return (
          <AddressStep 
            address={address}
            setAddress={setAddress}
          />
        );

      case STEPS.RETAIL_CONFIG:
        return  <RetailConfigStep
            selectedTopupLocation={selectedTopupLocation}
            setSelectedTopupLocation={setSelectedTopupLocation} 
            topupSchedule={topupSchedule}
            setTopupSchedule={setTopupSchedule}
            stockPull={stockPull}
            setStockPull={setStockPull}
            productData={productData}
          /> 

      case STEPS.SHOPIFY:
        return (
          <ShopifyStep
            shopifyLinks={shopifyLinks}
            setShopifyLinks={setShopifyLinks}
            address={address}
          />
        );

      case STEPS.CONFIRMATION:
        return renderConfirmationStep();

      case STEPS.SUCCESS:
        return <SuccessStep onClose={handleClose} />;

      default:
        return null;
    }
  };
  
  const isNextButtonDisabled = () => {
    const validationMap = {
      [STEPS.ADDRESS]: () => {
        return address.address1 === '' || 
               address.phone === '' || 
               address.name === '' || 
               address.city === '' || 
               address.zip === '' || 
               address.country === '';
      },
      [STEPS.BASIC_INFO]: () => {
        return locationName === '' || code === '' || region === '';
      }
    };

    return validationMap[currentStep] ? validationMap[currentStep]() : false;
  };

  if(productState.isLoading || replenLocationStates.isLoading || regionOptionsState.isLoading) {
    return <div></div>
  }

  return (
    <Dialog open={open} onOpenChange={(isOpen) => {
      setOpen(isOpen);
      if (!isOpen) {
        resetState();
      }
    }}>
        <DialogTrigger>
            <Button>Location Wizard</Button>
        </DialogTrigger>
        
        <DialogContent className="z-[1500] min-w-[70vw] max-h-[80vh]  overflow-y-auto">
        <div className="w-full">
          <WizardNav 
            steps={getSteps()}
            activeTabProgress={getActiveTabProgress()} 
          />
      
      {renderStep()}

          {currentStep !== STEPS.SUCCESS && (
            <div className={`flex ${currentStep > STEPS.BASIC_INFO ? 'justify-between' : 'justify-end'} mt-8 space-x-4`}>
              {currentStep > STEPS.BASIC_INFO && (
                <Button onClick={prevStep}>
                  Previous
                </Button>
              )}
              
              { currentStep !== STEPS.CONFIRMATION ? (
                <Button 
                disabled={isNextButtonDisabled()}
                onClick={nextStep}>
                  Next
                </Button>
              ) : (
                <Button onClick={onSave} variant="success" disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Save Location'}
                </Button>
              )}
            </div>
          )}
        </div>
        </DialogContent>
    </Dialog>
  );
};

export default LocationWizard;
