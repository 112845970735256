import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { Input } from '@shadcn/components/ui/input';
import { Row } from '@shadcn/components/ui/row'
import { Col } from '@shadcn/components/ui/col'
import { Button } from '@shadcn/components/ui/button';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card.jsx';
import superagent from 'superagent'
import {backendUrl} from '../../helpers/consts.js'

export const AddProduct = ({refreshData, toggle}) => {
    const history = useHistory();
    const [name, setName] = useState('')
    const createProduct = (e) => {
        e.preventDefault()
        superagent.post(`${backendUrl}/product`)
            .send({ name: name })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history?.location?.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history?.location?.pathname, {
                errorStatusCode: code
                });
            } else {
                refreshData()
                toggle()
            }
            }).catch(err => {
            console.log(err)
            });
    }
    return (
        <React.Fragment>
                <div className="w-full px-3 mx-auto xl:max-w-[85%]">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row>
                                            <Col className="sm:w-6/12">
                                                <div className='mb-4'>
                                                    <label htmlFor="name">Product Name</label>
                                                    <Input value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" type="text" className="form-control" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button onClick={createProduct} color="primary" className="mr-1 waves-effect waves-light">Create</Button>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
        </React.Fragment>
    );
}

export const AddVariant = ({id, refreshData, toggle}) => {
    const history = useHistory();
    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const createVariant = (e) => {
        e.preventDefault()
        superagent.post(`${backendUrl}/product/variant`)
            .send({ name: name, sku: sku, product: id })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history?.location?.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history?.location?.pathname, {
                errorStatusCode: code
                });
            } else {
                refreshData()
                toggle()
            }
            }).catch(err => {
            console.log(err)
            });
    }
    
    return (
        <React.Fragment>
                <div className="w-full px-3 mx-auto xl:max-w-[85%]">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row>
                                            <Col className="sm:w-6/12 mt-6">
                                                <div className='mb-4'>
                                                    <label htmlFor="name">Variant Title</label>
                                                    <Input value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" type="text" className="form-control" />
                                                </div>
                                                <div>
                                                    <label htmlFor="sku">SKU</label>
                                                    <Input value={sku} onChange={(e) => setSku(e.target.value)} id="sku" name="sku" type="text" className="form-control" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button onClick={createVariant} variant="primary" className="mr-1 waves-effect waves-light mt-6">Create</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
        </React.Fragment>
    );
}

export default AddProduct;
