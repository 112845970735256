import { useState, useEffect } from 'react';

import { Input } from '@shadcn/components/ui/input';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import {
  Card,
  CardContent as CardBody,
  CardTitle,
} from '@shadcn/components/ui/card';
import Dropzone from 'react-dropzone';
import useQuery from '../../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';

import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts.js';
import { useHistory } from 'react-router-dom';
import { uploadFileS3Bucket } from '../../../../utilities/uploadFileS3Bucket';

import classnames from 'classnames';
import { Button } from '@shadcn/components/ui/button';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@shadcn/components/ui/tabs';
import {
  Collapsible,
  CollapsibleContent,
} from '@shadcn/components/ui/collapsible';
import SupplyChainNav from '@components/supply-chain/nav';

const ProductListPage = ({ match: { params } }) => {
  const history = useHistory();
  const { productID, variantID } = params;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [variantName, setVariantName] = useState('');
  const [variantSku, setVariantSku] = useState('');
  const [variantImage, setVariantImage] = useState('');

  const [continueSelling, setContinueSelling] = useState();
  const [discontinued, setDiscontinued] = useState();
  const [oosMessage, setOosMessage] = useState();
  const [stockBuffer, setStockBuffer] = useState();
  const [preorder, setPreorder] = useState();
  const [isArchived, setIsArchived] = useState();

  const [continueSellingUS, setContinueSellingUS] = useState();
  const [discontinuedUS, setDiscontinuedUS] = useState();
  const [oosMessageUS, setOosMessageUS] = useState();
  const [stockBufferUS, setStockBufferUS] = useState();
  const [preorderUS, setPreorderUS] = useState();

  const [continueSellingUK, setContinueSellingUK] = useState();
  const [discontinuedUK, setDiscontinuedUK] = useState();
  const [oosMessageUK, setOosMessageUK] = useState();
  const [stockBufferUK, setStockBufferUK] = useState();
  const [preorderUK, setPreorderUK] = useState();

  const [averageCogAU, setAverageCogAU] = useState();
  const [averageCogUS, setAverageCogUS] = useState();
  const [averageCogUK, setAverageCogUK] = useState();

  const [usAllowAu, setUsAllowAu] = useState();
  const toggleUsAllowAu = () => {
    setUsAllowAu((prev) => !prev);
  };

  const [ukAllowAu, setUkAllowAu] = useState();
  const toggleUkAllowAu = () => {
    setUkAllowAu((prev) => !prev);
  };

  const [refresh, setRefresh] = useState(false);
  const refreshData = () => setRefresh((prev) => !prev);

  const [pausePreorder, setPausePreorder] = useState();
  const [pausePreorderUS, setPausePreorderUS] = useState();
  const [pausePreorderUK, setPausePreorderUK] = useState();

  const [noReorder, setNoReorder] = useState();
  

  const [product, state] = useQuery({
    url: `/product/${productID}`,
    refresh,
  });
  const variant =
    product && product.variants.find((val) => `${val._id}` === variantID);

  useEffect(() => {
    if (product != undefined && variant != undefined) {
      setVariantSku(variant.sku);
      setVariantName(variant.name);
      setVariantImage(variant.image);

      setContinueSelling(variant.continue_selling);
      setDiscontinued(variant.discontinued);
      setOosMessage(variant.oos_message);
      setStockBuffer(variant.stock_buffer);
      setPreorder(variant.preorder);

      setContinueSellingUS(variant.continue_selling_us);
      setDiscontinuedUS(variant.discontinued_us);
      setOosMessageUS(variant.oos_message_us);
      setStockBufferUS(variant.stock_buffer_us);
      setPreorderUS(variant.preorder_us);
      setUsAllowAu(variant.us_allow_au);

      setContinueSellingUK(variant.continue_selling_uk);
      setDiscontinuedUK(variant.discontinued_uk);
      setOosMessageUK(variant.oos_message_uk);
      setStockBufferUK(variant.stock_buffer_uk);
      setPreorderUK(variant.preorder_uk);
      setUkAllowAu(variant.uk_allow_au);

      setAverageCogAU(variant.average_cog_au);
      setAverageCogUS(variant.average_cog_us);
      setAverageCogUS(variant.average_cog_uk);
      setIsArchived(variant.isArchived);
      setPausePreorder(variant.pause_preorder);
      setPausePreorderUS(variant.pause_preorder_us);
      setPausePreorderUK(variant.pause_preorder_uk);
      setNoReorder(variant.no_reorder);
      
    }
  }, [product]);

  const handleIsArchived = () => {
    setIsArchived((prev) => !prev);
  };

  const updateVariant = (e, image) => {
    e.preventDefault();
    if (image) {
      setVariantImage(image);
    }
    if (variantID === undefined) {
      superagent
        .post(`${backendUrl}/product/variant`)
        .send({
          name: variantName,
          sku: variantSku,
          product: productID,
          isArchived: isArchived,
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.push(
              `/product/edit/${productID}/variant/edit/${response.body._id}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      superagent
        .put(`${backendUrl}/product/variant/${variantID}`)
        .send({
          name: variantName,
          sku: variantSku,
          image: image || variantImage,

          stock_buffer: stockBuffer,
          continue_selling: continueSelling,
          discontinued: discontinued,
          oos_message: oosMessage,
          preorder: preorder,

          stock_buffer_us: stockBufferUS,
          continue_selling_us: continueSellingUS,
          discontinued_us: discontinuedUS,
          oos_message_us: oosMessageUS,
          preorder_us: preorderUS,

          stock_buffer_uk: stockBufferUK,
          continue_selling_uk: continueSellingUK,
          discontinued_uk: discontinuedUK,
          oos_message_uk: oosMessageUK,
          preorder_uk: preorderUK,

          us_allow_au: usAllowAu,
          uk_allow_au: ukAllowAu,
          isArchived: isArchived,
          pause_preorder: pausePreorder,
          pause_preorder_us: pausePreorderUS,
          pause_preorder_uk: pausePreorderUK,
          no_reorder: noReorder,
       
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAcceptedFiles = async (files) => {
    const cancel = !files.length;
    if (cancel) return;
    let images = await Promise.all(
      files.map((file) =>
        uploadFileS3Bucket(file, `comment_file_${Date.now()}`)
      )
    );

    superagent
      .put(`${backendUrl}/product/addImages/${productID}`)
      .send({ images: images })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [imagesOpen, setimagesOpen] = useState(false);

  const [loacaleTab, setLocaleTab] = useState('1');

  return (
    <SupplyChainNav menuType="products">
      <div className="w-full p-6 pt-4">
        <Breadcrumbs
          title="Ecommerce"
          breadcrumbItem={
            variantID != undefined ? 'Edit Variant' : 'New Variant'
          }
        />
        <Row>
          <Col xs="12" className="w-full">
            <Card className="pt-6">
              <CardBody>
                <CardTitle className="text-lg">Variant Information</CardTitle>
                <div>
                  <Row className="gap-6">
                    <Col className="w-6/12">
                      <div className="mb-4">
                        <label htmlFor="productname">Variant Name</label>
                        <Input
                          id="productname"
                          name="productname"
                          type="text"
                          className="form-control"
                          value={variantName}
                          onChange={(e) => setVariantName(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="productname">Variant SKU</label>
                        <Input
                          id="productname"
                          name="productname"
                          type="text"
                          className="form-control"
                          value={variantSku}
                          onChange={(e) => setVariantSku(e.target.value)}
                        />
                      </div>
                      <br />
                      <br />
                      <Row className="gap-6">
                        <Col className="w-6/12">
                          <div className="mb-4">
                            <label htmlFor="productname">Average COG AU</label>
                            <Input
                              id="productname"
                              name="productname"
                              type="text"
                              className="form-control"
                              value={averageCogAU}
                            />
                          </div>
                        </Col>
                        <Col className="w-6/12">
                          <div className="mb-4">
                            <label htmlFor="productname">Average COG US</label>
                            <Input
                              id="productname"
                              name="productname"
                              type="text"
                              className="form-control"
                              value={averageCogUS}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="gap-6">
                        <div className="mb-4">
                          <Col className="flex flex-col">
                            <label htmlFor="isArchived">Archived</label>
                            <Input
                              id="isArchived" 
                              name="isArchived"
                              className="w-5 h-5"
                              type="checkbox"
                              onChange={handleIsArchived}
                              checked={isArchived}
                              style={{
                                width: 20,
                                height: 20,
                                marginLeft: 2,
                              }}
                            />
                          </Col>
                        </div>
                        <div className="mb-4">
                          <Col className="flex flex-col">
                            <label htmlFor="noReorder">No Reorder</label>
                            <Input
                              id="noReorder"
                              type="checkbox" 
                              className="w-5 h-5"
                              onChange={() =>
                                setNoReorder((prev) => !prev)
                              }
                              checked={noReorder}
                              style={{
                                width: 20,
                                height: 20,
                                marginLeft: 2,
                              }}
                            />
                          </Col>
                        </div>
                      </Row>
                    </Col>
                    {variantID != undefined &&
                      continueSelling !== undefined && (
                        <Col className="w-6/12">
                          <Row>
                            <Col sm="12" className="w-full">
                              <Tabs defaultValue="1" className="w-full">
                                <TabsList className="nav-tabs-custom nav-justified ml-3">
                                  <TabsTrigger
                                    value="1"
                                    className={classnames({
                                      active: loacaleTab === '1',
                                    })}
                                    onClick={() => setLocaleTab('1')}
                                  >
                                    <span className="d-none d-sm-block">
                                      AU
                                    </span>
                                    <span className="d-block d-sm-none"></span>
                                  </TabsTrigger>
                                  <TabsTrigger
                                    value="2"
                                    className={classnames({
                                      active: loacaleTab === '2',
                                    })}
                                    onClick={() => setLocaleTab('2')}
                                  >
                                    <span className="d-none d-sm-block">
                                      US
                                    </span>
                                    <span className="d-block d-sm-none"></span>
                                  </TabsTrigger>
                                  <TabsTrigger
                                    value="3"
                                    className={classnames({
                                      active: loacaleTab === '3',
                                    })}
                                    onClick={() => setLocaleTab('3')}
                                  >
                                    <span className="d-none d-sm-block">
                                      UK
                                    </span>
                                    <span className="d-block d-sm-none"></span>
                                  </TabsTrigger>
                                </TabsList>

                                <TabsContent
                                  value="1"
                                  className="p-3 text-slateGray"
                                >
                                  <Row>
                                    <Col className="w-full">
                                      <div className="mb-0">
                                        <div className="mb-4">
                                          <label htmlFor="productname">
                                            Out of stock message
                                          </label>
                                          <Input
                                            id="oosMessage"
                                            name="oosMessage"
                                            type="text"
                                            className="form-control"
                                            value={oosMessage}
                                            onChange={(e) =>
                                              setOosMessage(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="mb-4">
                                          <label htmlFor="buffer">
                                            Buffer Amount
                                          </label>
                                          <Input
                                            id="buffer"
                                            value={stockBuffer}
                                            onChange={(e) =>
                                              setStockBuffer(e.target.value)
                                            }
                                            type="number"
                                            step={1}
                                            min={0}
                                            className="form-control"
                                          />
                                        </div>
                                        <br />
                                        <div className="mb-4">
                                          <div className="">
                                            <label check>
                                              <input
                                                defaultChecked={
                                                  continueSelling === false &&
                                                  discontinued === false &&
                                                  preorder === false
                                                }
                                                onChange={(e) => {
                                                  setContinueSelling(false);
                                                  setDiscontinued(false);
                                                  setPreorder(false);
                                                }}
                                                type="radio"
                                                name="radio1"
                                              />{' '}
                                              Mark as unavaliable site once
                                              Buffer is reached
                                            </label>
                                          </div>
                                          <div className="">
                                            <label check>
                                              <input
                                                defaultChecked={
                                                  continueSelling === true
                                                }
                                                onChange={(e) => {
                                                  setContinueSelling(true);
                                                  setDiscontinued(false);
                                                  setPreorder(false);
                                                }}
                                                type="radio"
                                                name="radio1"
                                              />{' '}
                                              Keep selling, even when OOS
                                            </label>
                                          </div>
                                          <div className="">
                                            <label check>
                                              <input
                                                defaultChecked={
                                                  preorder === true
                                                }
                                                onChange={(e) => {
                                                  setPreorder(true);
                                                  setContinueSelling(false);
                                                  setDiscontinued(false);
                                                }}
                                                type="radio"
                                                name="radio1"
                                              />{' '}
                                              Sell as preorder
                                            </label>
                                          </div>
                                          <div className="">
                                            <label check>
                                              <input
                                                defaultChecked={
                                                  discontinued === true
                                                }
                                                onChange={(e) => {
                                                  setDiscontinued(true);
                                                  setContinueSelling(false);
                                                  setPreorder(false);
                                                }}
                                                type="radio"
                                                name="radio1"
                                              />{' '}
                                              Take off site completely
                                            </label>
                                          </div>

                                          
                                        </div>
                                              <Row className='gap-6'>
                                        <div className='custom-control custom-checkbox'>
                                            <Input
                                                id="pausePreorder"
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={() =>
                                                  setPausePreorder((prev) => !prev)
                                                }
                                                checked={pausePreorder}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={'pausePreorder'}
                                              >
                                                Pause Preorder
                                              </label>
                                            </div>

                                       
                                            </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </TabsContent>

                                <TabsContent
                                  value="2"
                                  className="p-3 text-slateGray"
                                >
                                  <>
                                    <Row>
                                      <Col className="w-full">
                                        <div className="mb-0">
                                          <div className="mb-4">
                                            <label htmlFor="productname">
                                              Out of stock message
                                            </label>
                                            <Input
                                              id="oosMessage"
                                              name="oosMessage"
                                              type="text"
                                              className="form-control"
                                              value={oosMessageUS}
                                              onChange={(e) =>
                                                setOosMessageUS(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="mb-4">
                                            <label htmlFor="buffer">
                                              Buffer Amount
                                            </label>
                                            <input
                                              id="buffer"
                                              value={stockBufferUS}
                                              onChange={(e) =>
                                                setStockBufferUS(e.target.value)
                                              }
                                              type="number"
                                              step={1}
                                              min={0}
                                              className="form-control"
                                            />
                                          </div>
                                          <br />
                                          <div className="mb-4" tag="fieldset">
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    continueSellingUS ===
                                                      false &&
                                                    discontinuedUS === false &&
                                                    preorderUS === false
                                                  }
                                                  onChange={(e) => {
                                                    setContinueSellingUS(false);
                                                    setDiscontinuedUS(false);
                                                    setPreorderUS(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Mark as unavaliable site once
                                                Buffer is reached
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    continueSellingUS === true
                                                  }
                                                  onChange={(e) => {
                                                    setContinueSellingUS(true);
                                                    setDiscontinuedUS(false);
                                                    setPreorderUS(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Keep selling, even when OOS
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    preorderUS === true
                                                  }
                                                  onChange={(e) => {
                                                    setPreorderUS(true);
                                                    setContinueSellingUS(false);
                                                    setDiscontinuedUS(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Sell as preorder
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    discontinuedUS === true
                                                  }
                                                  onChange={(e) => {
                                                    setDiscontinuedUS(true);
                                                    setContinueSellingUS(false);
                                                    setPreorderUS(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Take off site completely
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className='gap-6'>
                                      <div className="custom-control custom-checkbox">
                                        <Input
                                          id="pausePreorderUS"
                                          type="checkbox"
                                          className="custom-control-input"
                                          onChange={() =>
                                            setPausePreorderUS((prev) => !prev)
                                          }
                                          checked={pausePreorderUS}
                                        />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={'pausePreorderUS'}
                                          >
                                            Pause Preorder
                                          </label>
                                        
                                      </div>

                                      <div
                                        className="custom-control custom-checkbox"
                                        onClick={toggleUsAllowAu}
                                      >
                                        <Input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={'toggleEWE'}
                                          onChange={toggleUsAllowAu}
                                          checked={usAllowAu}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={'toggleEWE'}
                                        >
                                          Allow AU Stock
                                        </label>
                                      </div>
                                    
                                    

                                    </Row>

                                                                  
                                  </>
                                </TabsContent>

                                <TabsContent
                                  value="3"
                                  className="p-3 text-slateGray"
                                >
                                  <>
                                    <Row>
                                      <Col sm="12" className="w-full">
                                        <div className="mb-0">
                                          <div className="mb-4">
                                            <label htmlFor="productname">
                                              Out of stock message
                                            </label>
                                            <Input
                                              id="oosMessage"
                                              name="oosMessage"
                                              type="text"
                                              className="form-control"
                                              value={oosMessageUK}
                                              onChange={(e) =>
                                                setOosMessageUK(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="mb-4">
                                            <label htmlFor="buffer">
                                              Buffer Amount
                                            </label>
                                            <input
                                              id="buffer"
                                              value={stockBufferUK}
                                              onChange={(e) =>
                                                setStockBufferUK(e.target.value)
                                              }
                                              type="number"
                                              step={1}
                                              min={0}
                                              className="form-control"
                                            />
                                          </div>
                                          <br />
                                          <div className="mb-4" tag="fieldset">
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    continueSellingUK ===
                                                      false &&
                                                    discontinuedUK === false &&
                                                    preorderUK === false
                                                  }
                                                  onChange={(e) => {
                                                    setContinueSellingUK(false);
                                                    setDiscontinuedUK(false);
                                                    setPreorderUK(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Mark as unavaliable site once
                                                Buffer is reached
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    continueSellingUK === true
                                                  }
                                                  onChange={(e) => {
                                                    setContinueSellingUK(true);
                                                    setDiscontinuedUK(false);
                                                    setPreorderUK(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Keep selling, even when OOS
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    preorderUK === true
                                                  }
                                                  onChange={(e) => {
                                                    setPreorderUK(true);
                                                    setContinueSellingUK(false);
                                                    setDiscontinuedUK(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Sell as preorder
                                              </label>
                                            </div>
                                            <div className="" check>
                                              <label check>
                                                <input
                                                  defaultChecked={
                                                    discontinuedUK === true
                                                  }
                                                  onChange={(e) => {
                                                    setDiscontinuedUK(true);
                                                    setContinueSellingUK(false);
                                                    setPreorderUK(false);
                                                  }}
                                                  type="radio"
                                                  name="radio1"
                                                />{' '}
                                                Take off site completely
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className='gap-6'>

                                    <div className='custom-control custom-checkbox'>
                                        <Input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={'pausePreorderUK'}
                                          onChange={() =>
                                            setPausePreorderUK((prev) => !prev)
                                          }
                                          checked={pausePreorderUK}
                                        />  
                                        <label
                                          className="custom-control-label"
                                          htmlFor={'pausePreorderUK'}
                                        >
                                          Pause Preorder
                                        </label>
                                      </div>

                                    <div
                                      className="custom-control custom-checkbox"
                                      onClick={toggleUkAllowAu}
                                    >
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'toggleEWE'}
                                        onChange={toggleUkAllowAu}
                                        checked={ukAllowAu}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={'toggleEWE'}
                                      >
                                        Allow AU Stock
                                      </label>
                                                  </div>

                                     

                                     
                                      </Row>
                                  </>
                                </TabsContent>
                              </Tabs>
                            </Col>
                          </Row>
                        </Col>
                      )}
                  </Row>
                  <Button
                    onClick={updateVariant}
                    type="submit"
                    variant="primary"
                    className="mr-1 waves-effect waves-light"
                    style={{ marginTop: '36px' }}
                  >
                    Save Changes
                  </Button>
                </div>
              </CardBody>
            </Card>
            {variantID != undefined && (
              <Card className="pt-6 my-6">
                <CardBody>
                  <CardTitle
                    style={{ cursor: 'pointer' }}
                    onClick={() => setimagesOpen((prev) => !prev)}
                    className="mb-3 text-lg"
                  >
                    Variant Images
                  </CardTitle>
                  <Collapsible
                    open={imagesOpen}
                    onOpenChange={() => setimagesOpen((prev) => !prev)}
                  >
                    <CollapsibleContent>
                      <div className="p-3 mb-3 bg-light rounded">
                        <Row className="flex-wrap">
                          {product &&
                            product.files &&
                            product.files.map((image, key) => (
                              <Col
                                className="w-2/12"
                                key={`_product_image_${key}`}
                                onClick={(e) => {
                                  updateVariant(e, image);
                                }}
                              >
                                <img
                                  className={`rounded ${
                                    image == variantImage
                                      ? 'border border-success'
                                      : ''
                                  }`}
                                  style={{ width: '100%' }}
                                  src={image}
                                />
                              </Col>
                            ))}
                        </Row>
                      </div>
                      <div>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-slateGray bx bxs-cloud-upload"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </CollapsibleContent>
                  </Collapsible>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </SupplyChainNav>
  );
};

export default ProductListPage;
