import { useState } from 'react';
import { useYupValidationResolver } from './hooks/useYupValidationResolver';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import InputField from './components/InputField';
import SelectField from './components/SelectField';
import DatePicker from './components/DatePicker';

import { Button } from '@shadcn/components/ui/button';

import StockInput from './components/StockInput';
import '../datatables.scss';

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from 'react-router-dom';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';

import LocationSelector from '../shared/LocationSelector';
import { useSelector } from 'react-redux';

import { CSVReader } from 'react-papaparse';
import SupplyChainNav from '../../../components/supply-chain/nav.js';

import { Col } from '@shadcn/components/ui/col';
import { Row } from '@shadcn/components/ui/row';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from '@shadcn/components/ui/dialog';
import useStockStore from '@zustand/useStockStore';
import ContainerSummary from './components/ContainerSummary';
import { containerSchema } from './schema/containerSchema';





const PullPage = () => {
  const history = useHistory();
  const resolver = useYupValidationResolver(containerSchema);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const {
    control,
    setValue,
    formState,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      containerName: '',
      containerRef: '',
      departureDate: '',
      requestedDate: '',
      warehouseDatePlanned: '',
      arrivalDate: '',
      bookingDate: '',
      warehouseDateActual: '',
      portDate: '',
      freightCost: '',
      localCost: '',
      destinationPort: '',
      originPort: '',
      shippingCarrier: '',
      containerType: '',
      containerState: 'draft',
    },
    resolver,
  });
  const selectedLocation = useSelector((state) => state.Layout.location);
  

  const setStock = useStockStore((state) => state.setStock);
  const getStock = useStockStore((state) => state.getStock);


  const [data, state] = useQuery({
    url: `/product`,
    refresh: true,
  });

  const productData = data && data.data;
  const [purchaseOrdersPagination, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh: true,
    queryData: {
      status: 'approved',
    },
  });

  const purchaseOrders =
    purchaseOrdersPagination && purchaseOrdersPagination.data;


  const toggleModal = () => setModal((prev) => !prev);

  const validate = async (data) => {
  
    return Object.keys(errors).length === 0;
  };

  const onSubmit = async (data) => {
    if (!validate(data)) {
      return;
    }

    setLoading(true);
    const exportData = {
      stock: getStock(),
      ...data,
      name: data.containerName,
      ref: data.containerRef,
      status: data.containerState,
      location: selectedLocation,
    };

    superagent
      .post(`${backendUrl}/inventory/createContainer`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          history.go(0);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
      });
  };

  const handleOnDrop = (data) => {
    let items = [];
    let invalidSkus = [];
    
    data.forEach((line, key) => {
      if (key > 0) {
        const count = line.data[1];
        const sku = line.data[0];
        let product = productData.find((val) =>
          val.variants.find((variant) => variant.sku === sku)
        );
        if (product && sku !== '') {
          let variant = product.variants.find((variant) => variant.sku === sku);
          if (variant) {
            const item = {
              product: product._id,
              variant: variant,
              quantity: count,
              sku: sku,
            };
            items.push(item);
          }
        } else if (sku !== '') {
          invalidSkus.push({
            line: key,
            sku: sku,
          });
        }
      }
    });

    if (invalidSkus.length > 0) {
      toast.warning(`The following SKUs do not exist in JIFI: ${invalidSkus.map(item => `Line ${item.line}: ${item.sku}`).join(', ')}`, {
        autoClose: false
      });
    }

    items = items.sort((a, b) => (a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0));
    setStock(items);
  };

  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = () => {
    // Handle file removal
  };


  return (
    <SupplyChainNav>
      <ToastContainer />
      <div className="p-4">
        <div>
          
          <Row className="flex-wrap mb-14 lg:flex-nowrap gap-6">
            <Col className="w-full lg:w-8/12 gap-4">
              <Card>
                <CardBody className="pt-6">
                  <Row className="gap-2">
                    <Col className="sm:w-6/12">
                      <div className="select2-container mb-4">
                        {/* <label className="control-label">Supplier Invoice Reference</label> */}
                        <InputField
                          label="Supplier Invoice Reference"
                          name="containerName"
                          control={control}
                          defaultValue={formState.containerName}
                          error={errors.containerName}
                        />
                      </div>
                    </Col>
                    <Col xs="6" className="sm:w-6/12">
                      <div className="select2-container mb-4">
                        <InputField
                          label="Container Reference"
                          name="containerRef"
                          control={control}
                          defaultValue={formState.containerRef}
                          error={errors.containerRef}
                        />
                        
                      </div>
                    </Col>
                  </Row>
                  <Row className="gap-2">
                    <Col xs="6" className="sm:w-6/12">
                      <div className="select2-container mb-4">

                        <SelectField
                          label="Destination Port"
                          name="destinationPort"
                          control={control}
                          setValue={setValue}
                          defaultValue={formState.destinationPort}
                          error={errors.destinationPort}
                          options={[{ label: 'Port 1', value: 'draft' }]}
                        />

                     
                      </div>
                    </Col>
                    <Col xs="6" className="sm:w-6/12">
                      <div className="select2-container mb-4">
                        <SelectField
                          label="Origin Port"
                          name="originPort"
                          control={control}
                          setValue={setValue}
                          defaultValue={formState.originPort}
                          error={errors.originPort}
                          options={[{ label: 'Port 1', value: 'draft' }]}
                        />

                       
                      </div>
                    </Col>
                  </Row>
                  <Row className="gap-2">
                    <Col xs="6" className="sm:w-6/12">
                      <div className="select2-container mb-4">
                        <SelectField
                          label="Shipping Carrier"
                          name="shippingCarrier"
                          control={control}
                          setValue={setValue}
                          defaultValue={formState.shippingCarrier}
                          error={errors.shippingCarrier}
                          options={[{ label: 'Carrier 1', value: 'draft' }]}
                        />
                        
                      </div>
                    </Col>
                    <Col xs="6" className="sm:w-6/12">
                      <div className="select2-container mb-4">
                        {/* <label className="control-label">Container Type</label> */}
                        <SelectField
                          label="Container Type"
                          name="containerType"
                          control={control}
                          setValue={setValue}
                          defaultValue={formState.containerType}
                          error={errors.containerType}
                          options={[{ label: '40ft', value: 'draft' }]}
                        />
                     
                      </div>
                    </Col>
                  </Row>
                  <div className="select2-container mb-4">
                    {/* <label className="control-label">Container State</label> */}

                    <SelectField
                      label="Container State"
                      name="containerState"
                      control={control}
                      setValue={setValue}
                      defaultValue={formState.containerState}
                      error={errors.containerState}
                      options={[
                        { label: 'Draft', value: 'draft' },
                        { label: 'Packing', value: 'packing' },
                        { label: 'In Transit', value: 'in-transit' },
                        { label: 'At Port', value: 'at-port' },
                        { label: 'Ready to unload', value: 'ready' },
                        { label: 'Completed', value: 'completed' },
                        { label: 'Cancelled', value: 'cancelled' },
                      ]}
                    />
                   
                  </div>
                  <div className="select2-container mb-4">
                    <label className="control-label">Landing Location</label>
                    <LocationSelector />
                  </div>
                  <Row className="gap-2">
                    <Col className="sm:w-4/12">
                      <div className="select2-container mb-4">
                        <DatePicker
                          label="Departure Date"
                          name="departureDate"
                          control={control}
                          defaultValue={formState.departureDate}
                          error={errors.departureDate}
                        />

                        
                      </div>
                      <div className="select2-container mb-4">
                       
                        <DatePicker
                          label="Arrival Date"
                          name="arrivalDate"
                          control={control}
                          defaultValue={formState.arrivalDate}
                          error={errors.arrivalDate}
                        />
                      </div>
                      <div className="select2-container mb-4">
                      
                        <DatePicker
                          label="Port Date"
                          name="portDate"
                          control={control}
                          defaultValue={formState.portDate}
                          error={errors.portDate}
                        />
                      </div>
                    </Col>
                    <Col xs="4" className="sm:w-4/12">
                      <div className="select2-container mb-4">
                       
                        <DatePicker
                          label="Requested Date"
                          name="requestedDate"
                          control={control}
                          defaultValue={formState.requestedDate}
                          error={errors.requestedDate}
                        />
                      </div>
                      <div className="select2-container mb-4">
                      
                        <DatePicker
                          label="Booking Date"
                          name="bookingDate"
                          control={control}
                          defaultValue={formState.bookingDate}
                          error={errors.bookingDate}
                        />
                      </div>
                    </Col>
                    <Col className="sm:w-4/12">
                      <div className="select2-container mb-4">
                      
                        <DatePicker
                          label="Warehouse Date Planned"
                          name="warehouseDatePlanned"
                          control={control}
                          defaultValue={formState.warehouseDatePlanned}
                          error={errors.warehouseDatePlanned}
                        />
                      </div>
                      <div className="select2-container mb-4">
                       
                        <DatePicker
                          label="Warehouse Date Actual"
                          name="warehouseDateActual"
                          control={control}
                          defaultValue={formState.warehouseDateActual}
                          error={errors.warehouseDateActual}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="select2-container mb-4">
                    
                    <InputField
                      type="number"
                      label="Freight Cost - $USD"
                      name="freightCost"
                      control={control}
                      defaultValue={formState.freightCost}
                      error={errors.freightCost}
                    />
                  </div>
                  <div className="select2-container mb-4">
                    
                    <InputField
                      type="number"
                      label="Local Cost - $USD"
                      name="localCost"
                      control={control}
                      defaultValue={formState.localCost}
                      error={errors.localCost}
                    />
                  </div>
                  <br />
                  <div>
                    <div className="mb-2 text-[#1f3dd0]">
                      <a href="https://jifi-storage-staging.s3-ap-southeast-2.amazonaws.com/comment_file_1637273421528.csv">
                        CSV Template
                      </a>
                    </div>
                    <CSVReader
                      onDrop={handleOnDrop}
                      onError={handleOnError}
                      onRemoveFile={handleOnRemoveFile}
                    >
                      <span>Drop CSV file here or click to upload.</span>
                    </CSVReader>
                  </div>
                  <br />
                  <div id="accordion">
                    <StockInput
                      label="Create Container"
                      productData={productData}
                      purchaseOrders={purchaseOrders}
                      handleSubmit={handleSubmit}
                      validate={validate}
                      toggleModal={toggleModal}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="w-full lg:w-4/12 mt-6 md:mt-0">
              <Card>
                <CardBody className="pt-4">
                  <ContainerSummary productData={productData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Dialog open={modal} onOpenChange={toggleModal}>
        <DialogContent>
          <CardBody>
            <ContainerSummary productData={productData} />
          </CardBody>
          <DialogFooter>
            {loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="primary"
                className="btn-lg btn-rounded"
              >
                Confirm
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </SupplyChainNav>
  );
};

export default PullPage;
