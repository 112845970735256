
export const getFulfillmentBadgeColor = (status) => {
  const positiveStatuses = ['fulfilled', 'success'];
  const negativeStatuses = ['cancelled'];
  const warningStatuses = ['unfulfilled', 'partial'];
  const pauseStatuses = ['awaiting-stock'];


  if (positiveStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-green-50',
      textColor: 'text-green-700',
      borderColor: 'border-green-100'
    };
  }

  if (negativeStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-red-50',
      textColor: 'text-red-700',
      borderColor: 'border-red-100'
    };
  }

  if (warningStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-yellow-50',
      textColor: 'text-yellow-700',
      borderColor: 'border-yellow-100'
    };
  }

  if (pauseStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-grey-50',
      textColor: 'text-grey-700',
      borderColor: 'border-grey-100'
    };
  }

  // Default color
  return {
    backgroundColor: 'bg-gray-50',
    textColor: 'text-gray-700',
    borderColor: 'border-gray-100'
  };
};

// Hàm xử lý màu cho payment status
export const getPaymentBadgeColor = (status) => {
  const positiveStatuses = ['paid','authorized',
    'partially_paid',];
  const negativeStatuses = ['refunded'];
  const warningStatuses = [
    
    'partially_refunded',
    'pending'
  ];

  if (positiveStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-green-50',
      textColor: 'text-green-700',
      borderColor: 'border-green-100'
    };
  }

  if (negativeStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-red-50',
      textColor: 'text-red-700',
      borderColor: 'border-red-100'
    };
  }

  if (warningStatuses.includes(status?.toLowerCase())) {
    return {
      backgroundColor: 'bg-yellow-50',
      textColor: 'text-yellow-700',
      borderColor: 'border-yellow-100'
    };
  }

  // Default color
  return {
    backgroundColor: 'bg-gray-50',
    textColor: 'text-gray-700',
    borderColor: 'border-gray-100'
  };
};
