import React, { useState, useEffect } from 'react'

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Card, CardContent as CardBody} from '@shadcn/components/ui/card'

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

import useQuery from '../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";


import Select from '@shadcn/components/ui/react-select';

import * as moment from 'moment-timezone';


import useSKULevels from './helpers/useSKULevels.js';
import useSKUStatus from './helpers/useSKUStatus.js';
import useUpcomingStock from './helpers/useUpcomingStock.js';
import { TablePagination } from '@components/supply-chain/TablePagination';
import { TableSkeleton } from '@shadcn/components/ui/table';




const MovementPage = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setlLoading] = useState(false)
  const [selectedType, setSelectedType] = useState(null)

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const [data, state] = useQuery({
    url: `/inventory/movement-history/`,
    refresh,
    queryData: { page: page + 1, perPage: perPage, type: selectedType?.value }
  });
  const historyData = data && data.data
  const count = data ? data.count : 0

  useEffect(() => {
    document.title = `Movement History | JIFI`
  }, [])

  const [orders, setOrders] = useState([])

  const handleSelectType = selectedType => {
    setSelectedType(selectedType)
  }
  const selectTypeOptions = [
    { label: 'All', value: undefined },
    // { label: 'Misc', value: 'misc' },
    { label: 'Picked', value: 'picked' },
    { label: 'Transfer', value: 'transfer' },
    // { label: 'Added', value: 'added' },
    // { label: 'Container', value: 'container' },
    { label: 'Audit', value: 'audit' },
  ]



  return <div className="page-content">
    <div className="w-full px-3 mx-auto xl:max-w-[85%]">
      <Breadcrumbs title="Inventory" breadcrumbItem={'Movement History'} />
      <Row className="mb-2">
        <Col className="sm:w-4/12" >
          <div className="select2-container mb-4">
            <label className="control-label">TYPE</label>
            <Select
              placeholder="All"
              title="Type"
         
              value={selectedType}
              onChange={handleSelectType}
              options={selectTypeOptions}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-2 ">
        <Col className="w-full">
          <Card className="pt-6 mb-6">
            <CardBody>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>Location</th>
                    <th>Stock In</th>
                    <th>Stock Out</th>
                    <th>Reason</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                
              
                  {
                    state === 'success' ? (
                      <tbody>
                       {historyData && historyData.map((historyLine, key) => {
                        let inCount = []
                        let outCount = []
                        historyLine.counts.map(count => {
                          if (count?.count?.on_hand > 0) {
                            inCount.push({
                              sku: count?.variant?.sku,
                              count: count?.count?.on_hand
                            })
                          } else {
                            outCount.push({
                              sku: count?.variant?.sku,
                              count: count?.count?.on_hand
                            })
                          }
                        })
                        return (
                         
                            <tr>
                              <td> {historyLine.location.display_name} </td>
                            <td>
                              {
                                inCount.map(val => <div>
                                  {val.sku}
                                  {" "} x <span className="text-success" >+{val.count}</span>
                                </div>)
                              }
                            </td>
                            <td>
                              {
                                outCount.map(val => <div>
                                  {val.sku}
                                  {" "} x <span className="text-danger" >{val.count}</span>
                                </div>)
                              }
                            </td>
                            <td> {historyLine.type} </td>
                            <td> {historyLine.message} </td>
                              <td> {moment.tz(historyLine.createdAt, "Australia/Melbourne").format('D MMM, h:mm a z')}  </td>
                            </tr>
                        
                        )
                      })} 
                      </tbody>
                      
                    ) : (
                      <TableSkeleton perPage={perPage} columns={6} />
                    )
                  }
                
              </table>
            </div>
            {
              (orders && state === 'success') && (
                <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
              ) 
            }
          </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </div>
}

export default MovementPage
